import {
  supportApi,
  orgActions,
  initialPageSize,
  inputInitializer,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

import { OrganizationCS } from '@unikey/unikey-commons/release/csupp';

const initialOrganizationsState = populateInitialReduxPaginatedListState(['name'], 'forward');
export const organizations = (state = initialOrganizationsState, action: any) => {
  switch (action.type) {
    case orgActions.GET_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case orgActions.GET_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialOrganizationsState.data
      }
    case orgActions.UPDATE_ORG_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case orgActions.UPDATE_ORG_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialOrgDetailsState = {
  loading: true,
  orgData: ({
    id: '',
    dealerId: '',
    dealer: {},
    created: '',
    brand: '',
    ...inputInitializer('', ['name', 'status', 'creditsAllocated', 'creditsClaimed']),
  } as any),
  saving: false
};

const setEditOrgFieldsBasedOnOrgResult = (orgResult: OrganizationCS) => {
  return {
    id: orgResult.id,
    dealerId: orgResult.dealerId,
    dealer: orgResult.dealer,
    created: orgResult.created,
    brand: orgResult.brand,
    name: {
      value: orgResult.name,
      valid: true
    },
    status: {
      value: orgResult.status,
      valid: true,
    },
    creditsAllocated: {
      value: orgResult.creditsAllocated,
      valid: true,
    },
    creditsClaimed: {
      value: orgResult.creditsClaimed,
      valid: true,
    },

  }
}

export const orgDetails = (state = initialOrgDetailsState, action: any) => {
  switch (action.type) {
    case orgActions.GET_SINGLE_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_SINGLE_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: action.pending,
      }
    case orgActions.GET_SINGLE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        orgData: setEditOrgFieldsBasedOnOrgResult(action.value),
        unchangedOrgData: action.org
      }
    case orgActions.HANDLE_ORG_CHANGE:
      return {
        ...state,
        orgData: Object.assign({}, state.orgData, {
          name: action.name ? action.name : state.orgData.name,
          status: action.status ? action.status : state.orgData.status!,
          creditsAllocated: action.creditsAllocated ? action.creditsAllocated : state.orgData.creditsAllocated!
        })
      }
    case orgActions.UPDATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        saving: true,
      }
    case orgActions.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        saving: action.pending,
        orgData: setEditOrgFieldsBasedOnOrgResult(action.value)
      }
    case orgActions.UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        saving: action.pending
      }
    default:
      return state
  }
}

const initialOrgReadersState = populateInitialReduxPaginatedListState(['name'], 'forward');
export const orgReaders = (state = initialOrgReadersState, action: any) => {
  switch (action.type) {
    case orgActions.GET_ORG_READERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORG_READERS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(orgActions.GET_ORG_READERS_REQUEST) > 0,
        data: action.readersResult,
      }
    case orgActions.GET_ORG_READERS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(orgActions.GET_ORG_READERS_REQUEST) > 0,
        data: initialOrgReadersState.data
      }
    case orgActions.UPDATE_ORG_READERS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case orgActions.UPDATE_ORG_READERS_TABLE_META:
      const stateToUpdate: any = {};

      if (action.filters) {
        stateToUpdate.tableFilters = action.filters;
      }

      if (action.sorts) {
        stateToUpdate.tableSorts = action.sorts;
      }
      if (Number.isInteger(Number(action.currPage)) || Number.isInteger(Number(action.pageSize)) || Number.isInteger(Number(action.totalCount)) || Number.isInteger(Number(action.pageCount))) {
        const paginationToUpdate: any = {};
        if (Number.isInteger(Number(action.currPage))) {
          paginationToUpdate.currPage = action.currPage;
        }
        if (Number.isInteger(Number(action.pageSize))) {
          paginationToUpdate.pageSize = action.pageSize;
        }
        if (Number.isInteger(Number(action.totalCount))) {
          paginationToUpdate.totalCount = action.totalCount;
          paginationToUpdate.pageCount = Math.ceil(action.totalCount / (action.pageSize || state.paginationSummary.pageSize));
        }
        stateToUpdate.paginationSummary = Object.assign({}, state.paginationSummary, paginationToUpdate);
      }
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialOrgCredsState = populateInitialReduxPaginatedListState(['email'], 'forward');
export const orgCreds = (state = initialOrgCredsState, action: any) => {
  switch (action.type) {
    case orgActions.GET_ORG_CREDENTIALS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORG_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(orgActions.GET_ORG_CREDENTIALS_REQUEST) > 0,
        data: action.credentialsResult
      }
    case orgActions.GET_ORG_CREDENTIALS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(orgActions.GET_ORG_CREDENTIALS_REQUEST) > 0,
        data: initialOrgCredsState.data
      }
    case orgActions.UPDATE_ORG_CREDENTIALS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case orgActions.UPDATE_ORG_CREDENTIALS_TABLE_META:
      const stateToUpdate: any = {};

      if (action.filters) {
        stateToUpdate.tableFilters = action.filters;
      }

      if (action.sorts) {
        stateToUpdate.tableSorts = action.sorts;
      }
      if (action.currPage || action.pageSize || action.totalCount || action.pageCount) {
        const paginationToUpdate: any = {};
        if (Number.isInteger(Number(action.currPage))) {
          paginationToUpdate.currPage = action.currPage;
        }
        if (Number.isInteger(Number(action.pageSize))) {
          paginationToUpdate.pageSize = action.pageSize;
        }
        if (Number.isInteger(Number(action.totalCount))) {
          paginationToUpdate.totalCount = action.totalCount;
          paginationToUpdate.pageCount = Math.ceil(action.totalCount / (action.pageSize || state.paginationSummary.pageSize));
        }
        stateToUpdate.paginationSummary = Object.assign({}, state.paginationSummary, paginationToUpdate);
      }
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}


const initialOrgAdminsState = populateInitialReduxPaginatedListState(['status'], 'forward');
export const orgAdmins = (state = initialOrgAdminsState, action: any) => {
  switch (action.type) {
    case orgActions.GET_ORG_ADMINS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORG_ADMINS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(orgActions.GET_ORG_ADMINS_REQUEST) > 0,
        data: action.adminsResult
      }
    case orgActions.GET_ORG_ADMINS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(orgActions.GET_ORG_ADMINS_REQUEST) > 0,
        data: initialOrgAdminsState.data
      }
    case orgActions.UPDATE_ORG_ADMINS_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case orgActions.UPDATE_ORG_ADMINS_TABLE_META:
      const stateToUpdate: any = {};

      if (action.filters) {
        stateToUpdate.tableFilters = action.filters;
      }

      if (action.sorts) {
        stateToUpdate.tableSorts = action.sorts;
      }
      if (Number.isInteger(Number(action.currPage)) || Number.isInteger(Number(action.pageSize)) || Number.isInteger(Number(action.totalCount)) || Number.isInteger(Number(action.pageCount))) {
        const paginationToUpdate: any = {};
        if (Number.isInteger(Number(action.currPage))) {
          paginationToUpdate.currPage = action.currPage;
        }
        if (Number.isInteger(Number(action.pageSize))) {
          paginationToUpdate.pageSize = action.pageSize;
        }
        if (Number.isInteger(Number(action.totalCount))) {
          paginationToUpdate.totalCount = action.totalCount;
          paginationToUpdate.pageCount = Math.ceil(action.totalCount / (action.pageSize || state.paginationSummary.pageSize));
        }
        stateToUpdate.paginationSummary = Object.assign({}, state.paginationSummary, paginationToUpdate);
      }
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}