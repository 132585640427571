import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniGauge,
  UniTable,
  UniConditionalRender,
  UniLocalize,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Column,
  IUniTable_Sort,
  IUniTable_Filter,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IOrganizationCS,
  OrganizationCS,
  EOrganizationStatus
} from '@unikey/unikey-commons/release/csupp';

import {
  toggleModal,
  attemptRetrieveOrganizations,
  updateOrgListQueryParams,
  updateOrgTableMeta,
  brandCodeDisplay,
  navConfig, ENavPages,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection, getTableParamsFromUpdate,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  orgData: IPaginatedResponseCS<IOrganizationCS, OrganizationCS>,
  orgListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  paginationSummary: IUniTable_PaginationSummary,
  showSubBrand?: boolean,
  getOrgs(): Promise<void>,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginatedParamsCS): void,
}

class OrganizationListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);
    
    this._updateTable = buildTableUpdateFunc(
      props.getOrgs,
      props.updateTableMeta,
      props.updateQueryParams
    )

  }

  componentDidMount() {
    this.props.getOrgs();
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        template: (rowItem: OrganizationCS) => rowItem.name ? rowItem.name : (<i><UniLocalize translate="notProvided" /></i>),
        size: this.props.showSubBrand ? 7 : 10,
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        type: 'enum-tagdot',
        enumType: EOrganizationStatus,
        size: 4
      })
      .set('brand', {
        nameKey: 'brand',
        isSortable: false,
        isFilterable: false, // TODO: not available yet
        type: 'tag',
        evalItalicized: (rowItem: OrganizationCS) => !rowItem.brand,
        size: this.props.showSubBrand ? 3 : 0,
        filterName: 'brandCode'
      })
      .set('creditSummary', {
        nameKey: 'creditSummary',
        isSortable: false,
        size: 4,
        template: (rowItem: OrganizationCS, rowIndex: number) => (
          <>
            {/* limited org credits display */}
            <UniConditionalRender visible={rowItem.creditsAllocated !== null}>
              <UniGauge
                id={`organizationCreditAllocation-gauge-${rowIndex}`}
                className="table-row-gauge"
                key={rowIndex}
                nameKey="organizationCreditAllocation"
                size="sm"
                stacked={false}
                max={rowItem.creditsAllocated!}
                vals={[
                  {
                    nameKey: 'organizationCreditsConsumed',
                    value: rowItem.creditsClaimed!,
                    theme: 'secondary'
                  }
                ]}
              />
            </UniConditionalRender>
            {/* unlimited org credits display */}
            <UniConditionalRender visible={rowItem.creditsAllocated === null}>
              <i><UniLocalize translate="unlimited" /></i>
            </UniConditionalRender>
          </>

        )
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 2
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.organizationDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();
    return (
      <section className='orgList-container'>

        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="organizationsList"
          createButtonTextKey="organization"
          handleUpdate={this._updateTable}
          data={this.props.orgData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    orgData: state.organizations.data,
    orgListQueryParams: state.organizations.queryParams,
    listLoading: state.organizations.loading,
    appliedFilters: state.organizations.tableFilters,
    appliedSorts: state.organizations.tableSorts,
    paginationSummary: state.organizations.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getOrgs: attemptRetrieveOrganizations,
  updateQueryParams: updateOrgListQueryParams,
  updateTableMeta: updateOrgTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(OrganizationListContainer)
    ), { componentName: 'OrganizationList' })
