import {
  authActions,
} from '../../internal'

const initialAuthenticatedUser = {
  loading: false,
  parsedToken: undefined,
  user: undefined,
  isUniKeyActor: false,
  actorTypeSet: false,
};

export const authenticatedUser = (state = initialAuthenticatedUser, action: any) => {
  switch (action.type) {
    case authActions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case authActions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        parsedToken: action.user,
        actorTypeSet: action.user?.profile?.sub === state?.user?.id,
        isUniKeyActor: action.isUniKeyActor
      }
    case authActions.LOGIN_FAILURE:
      return {
        ...state,
        loading: false
      }
    case authActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case authActions.LOGOUT_SUCCESS:
      return {
        ...state,
        user: undefined,
        parsedToken: undefined,
        loading: false,
        isUniKeyActor: false,
        actorTypeSet: false,
      }
      case authActions.SET_ACTOR_TYPE: {
        return {
          ...state,
          isUniKeyActor: !!action.isUniKeyActor,
          user: action.user,
          actorTypeSet: true
        }
      }
    case authActions.GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        user: action.value
      }
    }
    default:
      return state
  }
};

const initialLoginForm = {
  username: '',
  password: ''
};

export const loginForm = (state = initialLoginForm, action: any) => {
  switch (action.type) {
    case authActions.UPDATE_USERNAME:
      return {
        ...state,
        username: action.text
      }
    case authActions.UPDATE_PASSWORD:
      return {
        ...state,
        password: action.text
      }
    default:
      return state;

  }
};

