import {
  couponActions,
  inputInitializer,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialCouponsState = populateInitialReduxPaginatedListState(['created'], 'reverse');

export const coupons = (state = initialCouponsState, action: any) => {
  switch (action.type) {
    case couponActions.GET_COUPONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case couponActions.GET_COUPONS_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case couponActions.GET_COUPONS_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialCouponsState.data
      }
    case couponActions.UPDATE_COUPON_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case couponActions.UPDATE_COUPON_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

function setNewCouponForm() {
  return {
    workflowStepIndex: 0,
    saving: false,
    giveaway: false,
    ...inputInitializer('', ['units', 'email', 'duplicates']) as any,
  };
}

const initialCouponFormState = {
  modalOpen: false,
  newCoupon: {
    ...setNewCouponForm(),
    jobReqsTotal: 0,
    jobReqsOutstanding: 0,
  }
};
export const couponForm = (state = initialCouponFormState, action: any) => {
  switch (action.type) {
    case couponActions.TOGGLE_CREATE_COUPON_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case couponActions.HANDLE_COUPON_CHANGE:
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          duplicates: action.duplicates ? action.duplicates : state.newCoupon.duplicates,
          units: action.units ? action.units : state.newCoupon.units,
          email: action.emailTo ? action.emailTo : state.newCoupon.email!,
          giveaway: action.giveaway !== undefined ? action.giveaway : state.newCoupon.giveaway!
        }
      }
    case couponActions.HANDLE_COUPON_CREATE_STEP_CHANGE:
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          workflowStepIndex: action.newStepIndex
        }
      }
    case couponActions.CLEAR_CREATE_COUPON_FORM:
      return {
        ...state,
        modalOpen: false,
        newCoupon: {
          ...setNewCouponForm(),
          jobReqsTotal: 0,
          jobReqsOutstanding: 0,
        }
      }
    case couponActions.CREATE_COUPON_REQUEST:
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          saving: true,
          jobReqsTotal: 0,
          jobReqsOutstanding: 0,
        }
      }
    case couponActions.CREATE_COUPON_SUCCESS:
      return {
        ...state,
        newCoupon: {
          ...setNewCouponForm(),
          saving: action.pending,
          jobReqsTotal: 0,
          jobReqsOutstanding: 0,
        }
      }
    case couponActions.CREATE_COUPON_FAILURE:
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          saving: action.pending
        }
      }
    case couponActions.UPDATE_CREATE_COUPON_JOB_STATUS:
      const outstandingReqs = action.numPending;
      return {
        ...state,
        newCoupon: {
          ...state.newCoupon,
          jobReqsTotal: (outstandingReqs > state.newCoupon.jobReqsTotal) ? outstandingReqs : state.newCoupon.jobReqsTotal,
          jobReqsOutstanding: outstandingReqs
        }
      }
    default:
      return state;
    }
  }

const initialCouponDetailsState = {
  loading: true,
  couponData: {}
};

export const couponDetails = (state = initialCouponDetailsState, action: any) => {
  switch (action.type) {
    case couponActions.GET_SINGLE_COUPON_REQUEST:
      return {
        ...state,
        loading: true
      }
    case couponActions.GET_SINGLE_COUPON_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    case couponActions.GET_SINGLE_COUPON_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        couponData: action.value
      }
    default:
      return state
  }
}


function buildCreditSummary(summay?: any) {
  return {
    claimed: summay?.units_claimed ?? NaN,
    created: summay?.units_created ?? NaN,
    giveaway: summay?.giveaway_units ?? NaN,
    limit: summay?.credit_unit_limit ?? NaN,
  }
}
function setEditLimitFieldsBasedOnResult(summary: any) {
  return {
    units: {
      value: summary.credit_unit_limit,
      valid: true
    }
  }
}

const initialCreditsState = {
  loading: true,
  creditSummary: buildCreditSummary(),
  updateLimit: inputInitializer('', ['units']) as any,
  saving: false
};

export const credits = (state = initialCreditsState, action: any) => {
  switch (action.type) {
    case couponActions.GET_CREDIT_UNIT_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case couponActions.GET_CREDIT_UNIT_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case couponActions.GET_CREDIT_UNIT_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        updateLimit: setEditLimitFieldsBasedOnResult(action.value),
        creditSummary: buildCreditSummary(action.value)
      }
    case couponActions.HANDLE_UNIT_LIMIT_CHANGE:
      return {
        ...state,
        updateLimit: Object.assign({}, state.updateLimit, {
          units: action.units ? action.units : state.updateLimit.units
        })
      }
    case couponActions.UPDATE_UNIT_LIMIT_REQUEST:
      return {
        ...state,
        saving: true,
      }
    case couponActions.UPDATE_UNIT_LIMIT_SUCCESS:
      return {
        ...state,
        saving: action.loading,
        updateLimit: setEditLimitFieldsBasedOnResult(action.value),
        creditSummary: buildCreditSummary(action.value)
      }
    case couponActions.UPDATE_UNIT_LIMIT_FAILURE:
      return {
        ...state,
        saving: action.loading,
      }
    default:
      return state
  }
}

// coupon assignment

const clearCouponAssignmentForm = () => {
  return {
    saving: false,
    workflowStepIndex: 0,
    pendingDealersMap: new Map(),
    pendingCouponsMap: new Map(),
    jobReqsTotal: 0,
    jobReqsOutstanding: 0
  };
}

const initialCouponDealerAssignmentState = {
  modalOpen: false,
  ...clearCouponAssignmentForm()
};

export const couponAssignment = (state = initialCouponDealerAssignmentState, action: any) => {
  switch (action.type) {
    case couponActions.TOGGLE_ASSIGN_COUPON_MODAL: 
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case couponActions.ASSIGN_COUPONS_TO_DEALER_REQUEST:
      return {
        ...state,
        saving: true
      }
    case couponActions.ASSIGN_COUPONS_TO_DEALER_FAILURE:
      return {
        ...state,
        saving: action.pending,
      }
    case couponActions.ASSIGN_COUPONS_TO_DEALER_SUCCESS:
      return {
        ...state,
        saving: action.pending,
      }
    case couponActions.UPDATE_ASSIGN_COUPONS_TO_DEALER_JOB_STATUS:
      const outstandingReqs = action.numPending;
      return {
        ...state,
        jobReqsTotal: (outstandingReqs > state.jobReqsTotal) ? outstandingReqs : state.jobReqsTotal,
        jobReqsOutstanding: outstandingReqs
      }
    case couponActions.HANDLE_ASSIGN_COUPONS_TO_DEALER_CHANGE: 
      return {
        ...state,
        pendingDealersMap: action.dealersMap ?? state.pendingDealersMap,
        pendingCouponsMap: action.couponsMap ?? state.pendingCouponsMap
      }
    case couponActions.HANDLE_ASSIGN_COUPONS_TO_DEALER_STEP_CHANGE:
      return {
        ...state,
        workflowStepIndex: action.newStepIndex
      }
    case couponActions.CLEAR_ASSIGN_COUPONS_TO_DEALER_FORM: 
      return {
        ...state,
        ...clearCouponAssignmentForm()
      }
    default:
      return state
  }
}
