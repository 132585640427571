import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniConditionalRender,
  UniLocalize,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  IUniTable_Filter,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IDealerCS,
  DealerCS,
  S10nModelC,
  ES10nModelType,
  ES10nModelTypeNames,
  UniTag,
  IUniTable_Column
} from '@unikey/unikey-commons/release/csupp';

import {
  attemptRetrieveDealers,
  updateDealerListQueryParams,
  updateDealerTableMeta,
  navConfig, ENavPages,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  dealerData: IPaginatedResponseCS<IDealerCS, DealerCS>,
  dealerListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  paginationSummary: IUniTable_PaginationSummary,
  showSubBrand?: boolean,
  getDealers(): Promise<void>,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginatedParamsCS): void,
}

class DealerListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);


    this._updateTable = buildTableUpdateFunc(
      props.getDealers,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getDealers();
  }

  _buildColumnsAndActions = () => {

    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        size: this.props.showSubBrand ? 8 : 10,
      })
      .set('brand', {
        nameKey: 'brand',
        isSortable: false,
        isFilterable: false, // TODO: not available yet
        type: 'tag',
        evalItalicized: (rowItem: DealerCS) => !rowItem.brand,
        size: this.props.showSubBrand ? 4 : 0,
        filterName: 'brandCode'
      })
      .set('subscription', {
        nameKey: 'subscriptionModel',
        isSortable: true,
        isFilterable: true,
        evalItalicized: (rowItem: DealerCS) => rowItem.subscriptionModel === ES10nModelType.unknown,
        template: (rowItem: DealerCS) => (
          <UniTag textKey={S10nModelC.getNameKeyFromModelType(rowItem.subscriptionModel)} inverted />
        ),
        type: 'enum',
        enumType: ES10nModelTypeNames,
        size: 6
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        size: 2
      })
      .set('id', {
        nameKey: 'id',
        isFilterable: true,
        type: 'uuid',
        size: 0
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.dealerDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='dealerList-container'>
        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="dealersList"
          createButtonTextKey="dealer"
          handleUpdate={this._updateTable}
          data={this.props.dealerData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    dealerData: state.dealers.data,
    dealerListQueryParams: state.dealers.queryParams,
    listLoading: state.dealers.loading,
    appliedFilters: state.dealers.tableFilters,
    appliedSorts: state.dealers.tableSorts,
    paginationSummary: state.dealers.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getDealers: attemptRetrieveDealers,
  updateQueryParams: updateDealerListQueryParams,
  updateTableMeta: updateDealerTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DealerListContainer)
    ), { componentName: 'DealerList' })
