import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system';

import {
  Editable,
  UniSteps,
  UniSelect,
  UniLoader,
  UniWorkflow,
  UniInput,
  UniToggle,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  IMultiInputUpdate,
  IUniSteps_StepConfig,
  UniConditionalRender,
  TValidationType,
  IValidationType,
  minV10n,
  maxV10n,
  emailV10n
} from '@unikey/unikey-commons/release/csupp';

import {
  clearAndToggleCreateCouponForm,
  attemptCreateCoupons,
  attemptRetrieveCoupons,
  handleCouponChange,
  handleCouponCreateStepChange,
  attemptRetrieveCreditUnitSummary,
  calcLoaderPercentageComplete,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  modalOpen: boolean,
  currentStepIndex: number,

  emailTo: Editable<string>,
  newCouponUnits: Editable<number>,
  numDuplicateCoupons?: Editable<number>,
  isGiveaway: boolean,
  numUnitsAvailableToCreate: number,
  saving: boolean,
  loading: boolean,
  couponCreateJobPercentageComplete: number,

  changeWorkflowStep(stepTo: number): void,
  updateNewCoupon(changes: IMultiInputUpdate, isGiveaway?: boolean): void
  toggleModal(): void,
  createNewCoupon(): Promise<void>,
  getUnitSummary(): void,
  getCoupons(): void,
}

class CouponCreateContainer extends Component<IProps> {
  steps: IUniSteps_StepConfig[];

  constructor(props: IProps) {
    super(props);

    this.steps = [
      { nameKey: 'buildCoupons' },
      { nameKey: 'review' }
    ];
  }

  componentDidMount() {
    this.props.getUnitSummary();

    // if the credit limit is reached, we can only create free coupons, 
    // so intially set the toggle to "giveaway" type
    if (this.props.numUnitsAvailableToCreate <= 0) {
      this.props.updateNewCoupon({}, true);
    }
  }

  _saveCouponAndReloadList() {
    this.props.createNewCoupon().then(() => {
      this.props.toggleModal();
      this.props.getCoupons();
      this.props.getUnitSummary();
    })
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }
    const maxGiveawayCouponSize = 1000;
    const minCreditsPerCoupon = 5;
    const optionalEmailValid = this.props.emailTo.valid ? true : this.props.emailTo.value === '';

    return (
      <section className='coupon-create-container'>
        <UniWorkflow
          inModal
          titleKey="createNewCoupon"
          titleIcon="confirmationNumber"
          size="wider" 
          handleClose={this.props.toggleModal}>
          <UniLoader
            hidden={!this.props.saving}
            type='linear'
            percentageComplete={this.props.couponCreateJobPercentageComplete} />

          <UniSteps
            steps={this.steps}
            activeStepIndex={this.props.currentStepIndex}
            allStepsUnlocked={false}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          {/* Step 1 - Enter Data */}
          <UniConditionalRender visible={this.props.currentStepIndex === 0}>

            <p><UniLocalize translate="_availableUnitsBeforeLimitColon" /> <strong>{this.props.numUnitsAvailableToCreate}</strong></p>
            <p><UniLocalize translate="_chooseNumberOfUnits" /></p>
            <Row>
              <Col md={12}>
                <UniInput
                  editable={this.props.newCouponUnits}
                  labelKey="units"
                  type="number"
                  placeholderKey="numberOfUnits"
                  handleUpdate={(units: Editable<number>) => this.props.updateNewCoupon({ units })}
                  triggerV10nCheck={`${this.props.numUnitsAvailableToCreate / (this.props.numDuplicateCoupons?.value || 1)}`}
                  focusOnInitialRender={false}
                  min={minCreditsPerCoupon}
                  max={this.props.isGiveaway ? maxGiveawayCouponSize : Math.floor(this.props.numUnitsAvailableToCreate / (this.props.numDuplicateCoupons?.value || 1))}
                  validations={new Map([
                    minV10n(minCreditsPerCoupon, 'couponUnitsMustBeAtLeast5'),
                    this.props.isGiveaway ?
                      maxV10n(maxGiveawayCouponSize, 'couponUnitsMustBeLessThanMaxGiveawaySize') :
                      maxV10n(this.props.numUnitsAvailableToCreate / (this.props.numDuplicateCoupons?.value || 1), 'couponUnitsMustBeLessThanCreditUnitLimit')
                  ])} />
              </Col>

              <Col md={12}>
                <UniSelect
                  value={(this.props.isGiveaway ? 1 : (this.props.numDuplicateCoupons?.value || 1))}
                  name="duplicates"
                  labelKey="numberOfCoupons"
                  translateValues={false}
                  options={[1, 2, 3, 4, 5, 10, 15, 20, 25, 50, 100, 200].map((num: number) => {
                    return {
                      value: num,
                      nameKey: `${num}`
                    };
                  })}
                  disabled={this.props.isGiveaway}
                  handleUpdate={(duplicates: Editable<number>) => this.props.updateNewCoupon({ duplicates })}
                />
              </Col>
            </Row>

            <p><UniLocalize translate="_sendNewCouponCodesToAdmin" /></p>
            <UniInput
              value={`${this.props.emailTo.value}`}
              type="string"
              placeholderKey="_exampleAdminEmail"
              labelKey="emailCouponTo"
              handleUpdate={(emailTo: Editable<string>) => this.props.updateNewCoupon({ emailTo })}
              focusOnInitialRender={false}
              validations={new Map([emailV10n])} />

            {/* should only display the giveaway toggle if the user is a UniKey internal admin */}
            <UniConditionalRender visible={this.props.isUniKeyActor} >
              <h5><UniLocalize translate="couponType" /></h5>
              <UniToggle
                size="sm"
                options={[
                  { value: false, nameKey: 'paidType', disabled: this.props.numUnitsAvailableToCreate <= 0 },
                  { value: true, nameKey: 'giveawayType' }
                ]}
                value={this.props.isGiveaway}
                handleUpdate={(isGiveaway: boolean) => this.props.updateNewCoupon({}, isGiveaway)} />
            </UniConditionalRender>

            {/* Show the max if we're creating a non-giveaway coupon 
              <UniConditionalRender visible={!this.props.isGiveaway}>
              <p><UniLocalize translate="_availableUnitsBeforeLimitColon" /> <strong>{this.props.numUnitsAvailableToCreate}</strong></p>
            </UniConditionalRender> */}

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={!this.props.newCouponUnits.valid || !optionalEmailValid}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleModal}
                textKey="cancel"
                icon="close"
                secondary={true} />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End Step 1 */}


          {/* Step 2 - Review */}
          <UniConditionalRender visible={this.props.currentStepIndex === 1}>

            <p><UniLocalize translate="_createCouponReviewStepMessage" /></p>
            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="creditsPerCoupon" /></strong></Col>
              <Col>
                {this.props.newCouponUnits.value}
              </Col>
            </Row>

            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="numberOfCoupons" /></strong></Col>
              <Col>
                {this.props.isGiveaway ? 1 : this.props.numDuplicateCoupons?.value || 1}
              </Col>
            </Row>

            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="emailCouponTo" /></strong></Col>
              <Col>
                <UniConditionalRender visible={this.props.emailTo.value !== '' && this.props.emailTo.valid}>
                  {this.props.emailTo.value}
                </UniConditionalRender>
                <UniConditionalRender visible={this.props.emailTo.value === '' || !this.props.emailTo.valid}>
                  <UniLocalize translate="noOne" />
                </UniConditionalRender>
              </Col>
            </Row>

            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="couponType" /></strong></Col>
              <Col>{this.props.isGiveaway}
                <UniConditionalRender visible={this.props.isGiveaway}>
                  <UniLocalize translate="giveawayType" />
                </UniConditionalRender>
                <UniConditionalRender visible={!this.props.isGiveaway}>
                  <UniLocalize translate="paidType" />
                </UniConditionalRender>
              </Col>
            </Row>

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={() => this._saveCouponAndReloadList()}
                textKey="save"
                icon="save"
                disabled={!this.props.newCouponUnits.valid || !optionalEmailValid}
                showLoader={!!this.props.saving} />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleModal}
                textKey="cancel"
                icon="close"
                secondary={true} />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End step 2 */}

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    modalOpen: state.couponForm.modalOpen,
    currentStepIndex: state.couponForm.newCoupon.workflowStepIndex,
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,

    newCouponUnits: state.couponForm.newCoupon.units,
    numDuplicateCoupons: state.couponForm.newCoupon.duplicates,
    emailTo: state.couponForm.newCoupon.email,
    isGiveaway: state.couponForm.newCoupon.giveaway,
    numUnitsAvailableToCreate: state.credits.creditSummary.limit - state.credits.creditSummary.created,
    saving: state.couponForm.newCoupon.saving,
    couponCreateJobPercentageComplete: calcLoaderPercentageComplete(state.couponForm.newCoupon.jobReqsOutstanding, state.couponForm.newCoupon.jobReqsTotal),
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  createNewCoupon: attemptCreateCoupons,
  toggleModal: clearAndToggleCreateCouponForm,
  updateNewCoupon: handleCouponChange,
  getUnitSummary: attemptRetrieveCreditUnitSummary,
  getCoupons: attemptRetrieveCoupons,
  changeWorkflowStep: handleCouponCreateStepChange,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CouponCreateContainer)
    ), { componentName: 'CouponCreate' })
