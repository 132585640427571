
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc';

import { decodeJwt } from '@unikey/unikey-commons/release/csupp'

import {
    supportApi,
    userManager,
    ENavPages,
    navConfig,
    SplashContainer,
    oidcForgotPasswordRedirect,
    oidcLoginSuccess,
    oidcLoginFailure,
    addAlert,
    portalRedirect,
    loginKey,
    verifyKey,
    getCurrentBaseDomain
} from '../internal'

interface IProps {
    match: any,
    history: any,
    loginSuccess(user: any, isUniKeyActor: boolean): void,
    loginFailure(err: any): void,
}

class OidcContainer extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    successCallback = (user: any) => {
        const decoded: any = decodeJwt(user.access_token);
        const userId = decoded?.sub || user.profile?.sub;
        const loginMethod = decoded?.idp || user.profile?.idp;
        const loginIdpUserId = decoded?.idp_sub || user.profile?.idp_sub;
        const deviceId = decoded?.device_id || user.profile?.device_id;
        
        supportApi.setAccessToken(user.access_token, userId, deviceId, user.refresh_token, loginMethod, loginIdpUserId);
        this.props.loginSuccess(user, decoded?.role?.indexOf?.('unikey_admin') >= 0);
        
        // send the user to the landing default page
        // using this instead of history since history is not able to
        // remove the query parameters returned by the OIDC response.
        const continueTo = supportApi.cacheImpl.get('continueTo');
        supportApi.cacheImpl.remove('continueTo');
        var goToLoc = '';
        if (decoded.account_verified?.toLowerCase?.() !== 'true') {
            portalRedirect(verifyKey);
        } 
        else if (continueTo && continueTo !== 'null') {
          const continueHashRoute = decodeURIComponent(continueTo);
          goToLoc = `${getCurrentBaseDomain()}#${continueHashRoute}`;
          window.location.href = goToLoc;
        } else {
            portalRedirect(navConfig.get(ENavPages.landing)!.linkTo());
        }
    };

    errorCallback = (error: any) => {
        supportApi.cacheImpl.remove('continueTo');
        if (error.message === 'No matching state found in storage') {
            window.location.href = `${getCurrentBaseDomain()}#${navConfig.get(ENavPages.landing)!.linkTo()}`;
        } else if (/User\+does\+not\+exist/.test(error.error_description)) {
            // User Needs to Register
            this.props.loginFailure('userNotRegistered');
            portalRedirect(loginKey);
        } else if (/The\+user\+has\+forgotten\+their\+password/.test(error.error_description)) {
            // Need to redirect to reset password
            window.location.href = oidcForgotPasswordRedirect;
        } else if (error.error_description) {
            // Unknown Problem
            this.props.loginFailure('unknownError')
            portalRedirect(loginKey);
        } else {
            portalRedirect(loginKey);
        }
    };

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={this.successCallback}
                errorCallback={this.errorCallback}>
                   <SplashContainer
                        match={this.props.match}
                        history={this.props.history}
                        externalRedirect={true} />
            </CallbackComponent>
        );
    }
}

function mapStateToProps(state: any) {
    return {

    }
  }

  const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    loginSuccess: oidcLoginSuccess,
    loginFailure: oidcLoginFailure
  }, dispatch)

  export default connect(mapStateToProps, mapDispatchToProps)(OidcContainer)