import { Dispatch } from 'redux'

import {
  Editable,
  CredentialCS,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  ICredentialCS,
  IPartnerCredImpls,
  EMessageType,
  IUniTable_UpdatePaginationSummary,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  setPageSizeInCache,
} from '../../internal'

export enum deviceActions {

  GET_SINGLE_DEVICE_REQUEST = 'GET_SINGLE_DEVICE_REQUEST',
  GET_SINGLE_DEVICE_SUCCESS = 'GET_SINGLE_DEVICE_SUCCESS',
  GET_SINGLE_DEVICE_FAILURE = 'GET_SINGLE_DEVICE_FAIL',

  GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST',
  GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS',
  GET_DEVICES_FAILURE = 'GET_DEVICES_FAIL',
  
  UPDATE_DEVICES_QUERY_PARAMS = 'UPDATE_DEVICES_QUERY_PARAMS',
  UPDATE_DEVICES_TABLE_META = 'UPDATE_DEVICES_TABLE_META',

  TOGGLE_PUSH_NOTIFICATION_MODAL = 'TOGGLE_PUSH_NOTIFICATION_MODAL',
  UPDATE_PUSH_NOTIFICATION_MESSAGE = 'TOGGLE_PUSH_NOTIFICATION_MESSAGE',

  SEND_PUSH_NOTIFICATION_REQUEST = 'SEND_PUSH_NOTIFICATION_REQUEST',
  SEND_PUSH_NOTIFICATION_SUCCESS = 'SEND_PUSH_NOTIFICATION_SUCCESS',
  SEND_PUSH_NOTIFICATION_FAILURE = 'SEND_PUSH_NOTIFICATION_FAILURE',

}

// DEVICES
const getDeviceListAction = new ApiReduxAction(supportApi, {
  request: { type: deviceActions.GET_DEVICES_REQUEST },
  success: { type: deviceActions.GET_DEVICES_SUCCESS },
  failure: {
    type: deviceActions.GET_DEVICES_FAILURE,
    title: 'getDevicesFail',
  },
  tableMetaUpdate: {
    type: deviceActions.UPDATE_DEVICES_TABLE_META
  }
}, (dux: IExposeRedux): any => {
  const params = dux.getState().devices.queryParams;
  return supportApi.devc.getAllDevices.bind(supportApi.devc, params);
});
export const attemptRetrieveDevices = getDeviceListAction.go;

export function updateDeviceListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: deviceActions.UPDATE_DEVICES_QUERY_PARAMS,
    queryParams
  }
}

export function updateDeviceTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: deviceActions.UPDATE_DEVICES_TABLE_META,
    ...meta
  }
}

// GET SINGLE DEVICE
const getSingleDeviceAction = new ApiReduxAction(supportApi, {
  request: { type: deviceActions.GET_SINGLE_DEVICE_REQUEST },
  success: { type: deviceActions.GET_SINGLE_DEVICE_SUCCESS },
  failure: {
    type: deviceActions.GET_SINGLE_DEVICE_FAILURE,
    title: 'getSingleDeviceFail',
  }
}, (dux: IExposeRedux, deviceId: string): any => {
  return supportApi.devc.getDevice.bind(supportApi.devc, deviceId);
});
export const attemptRetrieveSingleDevice = getSingleDeviceAction.go;


// SEND DEVICE PUSH NOTIFICATION
export interface ISendPushRequestActionParams {
  deviceId: string,
  message?: string
}
const sendPushToDeviceAction = new ApiReduxAction(supportApi, {
  request: { type: deviceActions.SEND_PUSH_NOTIFICATION_REQUEST },
  success: {
    type: deviceActions.SEND_PUSH_NOTIFICATION_SUCCESS,
    title: 'pushNotificationQueued'
  },
  failure: {
    type: deviceActions.SEND_PUSH_NOTIFICATION_FAILURE,
    title: 'sendPushNotificationFail',
  }
}, (dux: IExposeRedux, params: ISendPushRequestActionParams): any => {
  return supportApi.devc.messageDevice.bind(supportApi.devc, params.deviceId, params.message ?? '');
});
export const attemptSendDevicePushNotification = sendPushToDeviceAction.go;

export const togglePushNotificationForm = () => {
  return {
    type: deviceActions.TOGGLE_PUSH_NOTIFICATION_MODAL,
  }
}


export const updatePushNotificationMessage = (message: Editable<string>) => {
  return {
    type: deviceActions.UPDATE_PUSH_NOTIFICATION_MESSAGE,
    message
  }
}