import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniIcon,
  UniGauge,
  UniTable,
  UniConditionalRender,
  UniKeyVal,
  UniLocalize,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Column,
  IUniTable_Sort,
  IUniTable_Filter,
  Editable,
  IMultiInputUpdate,
  IUniToast_Alert,
  IPaginatedParamsCS,
  CouponCS,
  ECouponStatus,
  EMessageType,
  minV10n
} from '@unikey/unikey-commons/release/csupp';

import {
  toggleAssignCouponModal,
  attemptRetrieveCoupons,
  attemptRetrieveCreditUnitSummary,
  attemptUpdateUnitLimit,
  toggleCreateCouponsModal,
  handleUnitLimitChange,
  updateCouponListQueryParams,
  updateCouponTableMeta,
  navConfig, ENavPages,
  CouponCreateContainer,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection, getTableParamsFromUpdate,
  addAlert,
  copyToClipboard,
  CouponAssignmentContainer,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,

  coupons: CouponCS[],
  couponListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  paginationSummary: IUniTable_PaginationSummary,
  couponCreateModalOpen: boolean,
  couponAssignModalOpen: boolean,

  summaryLoading: boolean,
  createdUnits: number,
  claimedUnits: number,
  giveawayUnits: number,
  originalUnitLimit: number,
  numUnitsAvailableToCreate: number,

  savingLimit: boolean,
  unitLimit: Editable<number>,
  adminEmailToSend: Editable<string>,
  showSubBrand: string,

  getAllCoupons(): Promise<void>,
  handleUnitLimitChange(updates: IMultiInputUpdate): void,
  getCreditUnitSummary(): Promise<void>,
  saveNewUnitLimit(): Promise<void>,
  toggleCreateModal(): void,
  toggleCouponAssignmentModal(): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginatedParamsCS): void,
  showAlertNotice(notice: IUniToast_Alert): void
}

interface IState {
  selectedCouponForRedeem?: CouponCS;
}

class CouponListContainer extends Component<IProps, IState> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedCouponForRedeem: undefined
    };

    this._updateTable = buildTableUpdateFunc(
      props.getAllCoupons,
      props.updateTableMeta,
      props.updateQueryParams
    )

  }

  componentDidMount() {
    this.props.getCreditUnitSummary();
    this.props.getAllCoupons();
  }

  componentDidUpdate(prevProps: IProps) {
    // if have 0 createable credits and we've just hit the limit or we are loading from fresh
    // then show the warning (this way we dont show it every update)
    if ((prevProps.numUnitsAvailableToCreate > 0 || Number.isNaN(prevProps.numUnitsAvailableToCreate)) && this.props.numUnitsAvailableToCreate <= 0) {
      if (!this.props.isUniKeyActor) {
        this.props.showAlertNotice({
          id: Date.now(),
          titleKey: 'creditUnitLimitReached',
          type: EMessageType.warn,
          messageKeys: ['youHaveCreatedTheMaxCreditUnitsContactAdminsitratorToIncreaseYourCreditLimit'],
          duration: 16000
        })
      }
    }
  }

  _isClaimedOrAssigned = (c: CouponCS) => {
    return c.status === ECouponStatus.assigned || c.status === ECouponStatus.claimed;
  }

  _buildColumnsAndActions() {

    const columns = new Map<string, IUniTable_Column>()
      .set('units', {
        nameKey: 'units',
        isSortable: true,
        size: 3
      })
      .set('claimed', {
        nameKey: 'claimed',
        isSortable: false,
        size: 3,
        template: (rowItem: CouponCS, rowIndex: number) => (
          <UniGauge
            id={`couponUnitConsumption-gauge-${rowIndex}`}
            className="table-row-gauge"
            key={rowIndex}
            nameKey="couponUnitsClaimed"
            size="sm"
            stacked={false}
            max={rowItem.units!}
            vals={[
              {
                nameKey: 'unitsClaimed',
                value: rowItem.nonFreeClaimsCount,
                theme: 'secondary'
              }

            ]}
          />
        )
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        type: 'enum-tagdot',
        enumType: ECouponStatus,
        sortFields: ['status', 'created'],
        evalItalicized: this._isClaimedOrAssigned,
        size: 4
      })
      .set('dealer', {
        nameKey: 'dealerName',
        isFilterable: true,
        isSortable: false,
        isPrimaryFilter: true,
        template: (rowItem: CouponCS) => rowItem.dealer?.name?.value,
        filterName: 'dealer_name',
        size: 4,
      })
      .set('giveaway', {
        nameKey: '_emptyString',
        isSortable: false,
        type: 'boolean',
        size: 2,
        template: (rowItem: CouponCS) => rowItem.isGiveaway ? (<UniIcon name="moneyOff" tooltipTextKeys={['giveawayCoupon']} size="xs" />) : (<></>),
      })
      .set('brand', {
        nameKey: 'brand',
        isSortable: false,
        isFilterable: false, // TODO: not available yet
        type: 'tag',
        size: this.props.showSubBrand ? 3 : 0,
        // NOTE: Some of the early redeemed coupons in multi-brand environments 
        // have the defualt brand set on the coupon, even if the dealer that redeemed it
        // is not part of the default brand (ex: BLW redeemed UNI coupons)
        // HERE, we are showing the dealer's brand or the coupon's brand if not redeemd to avoid visual confusion.
        // TODO: Can remove this once we have sorted out the correct brand code on early coupons
        filterName: 'brand_code'
      })
      .set('createdDate', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 3
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        collapsed: true,
        size: 2
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('dealerId', {
        nameKey: 'dealerId',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
      actions.set('view', {
        nameKey: 'view',
        icon: 'removeRedEye',
        isDefaultAction: true,
        func: (rowItem: CouponCS) => this.props.history.push(navConfig.get(ENavPages.couponDetails)!.linkTo([rowItem.id])!),
        evalDisabled: (rowItem: CouponCS) => false,
        evalVisible: (rowItem: CouponCS) => true
      })
      .set('assignCoupon', {
        nameKey: 'assignCoupon',
        icon: 'confirmationNumber',
        func: (rowItem: CouponCS) => {
          this.setState({ selectedCouponForRedeem: rowItem });
          this.props.toggleCouponAssignmentModal();
        },
        evalDisabled: this._isClaimedOrAssigned,
      })
      .set('copySerialNumber', {
        nameKey: 'copySerialNumber',
        icon: 'contentCopy',
        func: (rowItem: CouponCS) => copyToClipboard({ fromValue: rowItem.serialNumber }, this.props.showAlertNotice),
        evalDisabled: this._isClaimedOrAssigned,
        evalVisible: (rowItem: CouponCS) => true
      })
      .set('copyAuthCode', {
        nameKey: 'copyAuthCode',
        icon: 'contentCopy',
        func: (rowItem: CouponCS) => copyToClipboard({ fromValue: rowItem.authCode }, this.props.showAlertNotice),
        evalDisabled: this._isClaimedOrAssigned,
        evalVisible: (rowItem: CouponCS) => true
      })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='orgList-container'>

        <Row>
          <Col>
            <h3><UniLocalize translate="creditUnitSummary" /></h3>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <UniKeyVal
              label="credit-units-claimed-details"
              stacked={true}
              showLoader={this.props.summaryLoading}
              preventEdit={true}
              fields={[
                {
                  keyName: 'claimedUnits',
                  value: `${this.props.claimedUnits}`,
                  type: 'number',
                  keyInfo: {
                    icon: 'infoOutline',
                    textKeys: ['claimedUnits', '_explainClaimedUnits']
                  }
                }
              ]} />
          </Col>

          <Col sm={8}>
            <UniKeyVal
              label="credit-unit-total-details"
              stacked={true}
              showLoader={this.props.summaryLoading}
              preventEdit={true}
              fields={[
                {
                  keyName: 'totalUnits',
                  value: `${this.props.createdUnits}`,
                  type: 'number',
                  keyInfo: {
                    icon: 'infoOutline',
                    textKeys: ['totalUnits', '_explainTotalUnits']
                  }
                }
              ]} />
          </Col>

          <Col sm={8}>
            <UniKeyVal
              label="credit-unit-limit-details"
              stacked={true}
              showLoader={this.props.summaryLoading}
              saveClick={this.props.saveNewUnitLimit}
              cancelClick={this.props.getCreditUnitSummary}
              isSaving={this.props.savingLimit}
              preventSave={this.props.originalUnitLimit === this.props.unitLimit.value || !this.props.unitLimit.valid}
              preventEdit={!this.props.isUniKeyActor}
              fields={[
                {
                  keyName: 'creditLimit',
                  value: `${this.props.unitLimit.value}`,
                  type: 'number',
                  handleUpdate: (units: Editable<number>) => this.props.handleUnitLimitChange({ units }),
                  min: this.props.createdUnits,
                  validations: new Map([minV10n(this.props.createdUnits, 'creditUnitLimitCannotBeLessThanCurrentTotal')]),
                  keyInfo: {
                    icon: this.props.numUnitsAvailableToCreate > 0 ? 'infoOutline' : 'warning',
                    textKeys: [
                      'creditLimit',
                      this.props.numUnitsAvailableToCreate > 0 ? '_explainCreditLimit' : 'maxCreditUnitsCreated',
                      !this.props.isUniKeyActor ? 'contactToRaiseCreditLimit' : undefined
                    ]
                  }
                }
              ]} />
          </Col>
        </Row>

        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="couponsList"
          createButtonTextKey="coupon"
          handleUpdate={this._updateTable}
          handleCreateClick={this.props.toggleCreateModal}
          createDisabled={!this.props.isUniKeyActor && this.props.numUnitsAvailableToCreate <= 0}
          data={this.props.coupons}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />

        {/* Workflow Modal */}
        <UniConditionalRender visible={this.props.couponCreateModalOpen}>
          <CouponCreateContainer match={this.props.match} history={this.props.history} />
        </UniConditionalRender>

        {/* Display the coupon assignment modal when open */}
        <UniConditionalRender visible={!!this.props.couponAssignModalOpen}>
          <CouponAssignmentContainer
            initialCoupon={this.state.selectedCouponForRedeem}
            actionAfterSave={this.props.getAllCoupons}  />
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    coupons: state.coupons.data.models,
    couponListQueryParams: state.coupons.queryParams,
    listLoading: state.coupons.loading,
    appliedFilters: state.coupons.tableFilters,
    appliedSorts: state.coupons.tableSorts,
    paginationSummary: state.coupons.paginationSummary,

    claimedUnits: state.credits.creditSummary.claimed,
    createdUnits: state.credits.creditSummary.created,
    giveawayUnits: state.credits.creditSummary.giveaway,
    originalUnitLimit: state.credits.creditSummary.limit,
    summaryLoading: state.credits.loading,
    numUnitsAvailableToCreate: state.credits.creditSummary.limit - state.credits.creditSummary.created,

    savingLimit: state.credits.saving,
    unitLimit: state.credits.updateLimit.units,
    adminEmailToSend: state.credits.updateLimit.email,
    couponCreateModalOpen: state.couponForm.modalOpen,
    couponAssignModalOpen: state.couponAssignment.modalOpen,
    
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getAllCoupons: attemptRetrieveCoupons,
  updateQueryParams: updateCouponListQueryParams,
  updateTableMeta: updateCouponTableMeta,
  getCreditUnitSummary: attemptRetrieveCreditUnitSummary,
  toggleCreateModal: toggleCreateCouponsModal,
  toggleCouponAssignmentModal: toggleAssignCouponModal,
  handleUnitLimitChange,
  saveNewUnitLimit: attemptUpdateUnitLimit,
  showAlertNotice: addAlert,

}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CouponListContainer)
    ), { componentName: 'CouponList' })
