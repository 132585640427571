
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import classNames from 'classnames'

import {
    UniConditionalRender,
    noop,
    darkThemeName,
  } from '@unikey/unikey-commons/release/csupp';

import partnerConfig from '@alias-current-partner-customizations'

import {
    mockAuth,
    SplashContainer,
    wrapWithInsights,
    environment, 
    setAuthActorFromAccessTokenOrRedirect
} from '../internal';

export interface ILocaleDefinitions {
    [key: string]: any
}

export interface IContainerCustomization {
    props?: any,
    currentExpiry?: string,
    tokenExpired?: boolean,
    tokenRefreshAttemptCompleted?: boolean,
    render?(): any,
    aboveRender?(): any,
    belowRender?(): any,
    setAuthActorFromAccessTokenOrRedirect(): void,
}

export interface ICustomContainerConfigs {
    [key: string]: IContainerCustomization
}

export interface IPartnerConfig {
    assets: {
        [key: string]: any
    },
    locales: {
        en?: ILocaleDefinitions,
        es?: ILocaleDefinitions
    },
    containers: () => ICustomContainerConfigs,
}

const getPartnerOverrides = (containerKey: string): any => {
    const defaultOverride = {};
    const config: any = partnerConfig.containers();
    if (config && containerKey) {
      const containerConfig = config[containerKey];
      return containerConfig || defaultOverride;
    }
    // default override
    return defaultOverride;
  }

export interface IPartnerCustomizations {
    props: any,
    isUniKeyActor: boolean,
    isActorSet: boolean,
    parsedToken?: any,
    render(props?: any): any,
}

export interface IPartnerCustomizationOpts { 
    componentName: string,
    waitForToken?: boolean,
    unauthenticated?: boolean
}
  
export const PartnerCustomizations = (WrappedComponent: React.ComponentType<any>, opts: IPartnerCustomizationOpts): any => {
  
    class CustomizedComponent extends Component<any> {
        partnerContainerConfig: any;
        extendedProps: any;

        constructor(props: any) {
            super(props);

            this.partnerContainerConfig = getPartnerOverrides(opts.componentName);
            this.extendedProps = Object.assign({}, props, this.partnerContainerConfig.props);
        }

        render() {
            if (!this.props.isActorSet) {
              // in the case of refreshing the page or coming in without logging in 
              // or using the splash screen, we need to set the actor type in order to ensurue accurate permissions.
              // if the type is currently unset invoke check to decode jwt and set it
              this.props.setAuthActorFromAccessTokenOrRedirect();
            }

            const customizedWrappedComponentClass = classNames({
              'partner-custom': Object.keys(this.partnerContainerConfig).length !== 0 && environment === 'development'
            });
      
            const customizedAboveComponentClass = classNames({
              'partner-custom': !!this.partnerContainerConfig.aboveRender && environment === 'development'
            });
            const customizedBelowComponentClass = classNames({
              'partner-custom': !!this.partnerContainerConfig.belowRender && environment === 'development'
            });
      
            const aboveElems = this.partnerContainerConfig.aboveRender ? (
              <div className={customizedAboveComponentClass}>
                {this.partnerContainerConfig.aboveRender()}
              </div>
            ) : '';
      
            const belowElems = this.partnerContainerConfig.belowRender ? (
              <div className={customizedBelowComponentClass}>
                {this.partnerContainerConfig.belowRender()}
              </div>
            ) : '';

            const waitingOnToken = opts.waitForToken === true && !this.props.isActorSet && !this.props.tokenRefreshAttemptCompleted && !mockAuth;
            return (
              <>
                {/* 
                  * if the wrapped component should not be rendered until we have a valid token,
                  * then show the spalsh screen while token is invalid
                 */}
                <UniConditionalRender visible={waitingOnToken}>
                  <SplashContainer 
                    match={this.props.match}
                    history={this.props.history} 
                    externalRedirect={true} />
                </UniConditionalRender>
      
                {/* render wrapped component */}
                <UniConditionalRender visible={!waitingOnToken}>
                  <>
                    {aboveElems}
                    <div className={customizedWrappedComponentClass}>
                      <WrappedComponent
                        {...this.extendedProps}
                        darkModeActive={this.props.darkModeTheme === darkThemeName}
                        render={this.partnerContainerConfig.render}>
                        {this.partnerContainerConfig.children}
                      </WrappedComponent>
                    </div>
                    {belowElems}
                  </>
                </UniConditionalRender>
              </>
            )
        }
    }

    const mapStateToProps = (state: any) => {
        return {
            isUniKeyActor: state.authenticatedUser.isUniKeyActor,
            isActorSet: state.authenticatedUser.actorTypeSet,
            parsedToken: state.authenticatedUser.parsedToken,
            tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted, 
            currentExpiry: state.oidcAuthStatus.currentExpiry,
            tokenExpired: state.oidcAuthStatus.isExpired
        }
    }

    const mapDispatchToProps = (dispatch: any) => bindActionCreators({
        setAuthActorFromAccessTokenOrRedirect,
    }, dispatch)

    
  const containerName: string = opts.componentName;
  return connect(mapStateToProps, mapDispatchToProps)(wrapWithInsights(CustomizedComponent, containerName));
}