import { ICredentialC, UniKeyV1CredentialC, ICredentialEncodeAndBuild, IPartnerCredentialFields, ICredentialFieldConfig } from '@unikey/unikey-commons/release/csupp'

export class PdkUniKeyV1Credential extends UniKeyV1CredentialC {

  static getCredentialConfig(): Partial<IPartnerCredentialFields> {
    return {
      facilityCode: (): Partial<ICredentialFieldConfig> => {
        return {
          value: undefined,
          disabled: true
        };
      },
      credentialData: (): Partial<ICredentialFieldConfig> => {
        return {
          value: undefined
        };
      },
      cardNumber: (): Partial<ICredentialFieldConfig> => {
        return {
          value: undefined,
          validations: {
            min: 0,
            max: 1e27,
            message: 'valueLessThan1e27'
          }
        };
      },
      requiredFields: () => {
        return new Set(['email', 'cardNumber'])
      }
    }
  }

  // custom encode for pdk's UniKeyV1Credential implementation
  static encode(cred: ICredentialEncodeAndBuild): ICredentialEncodeAndBuild {

    const cardNumber: number = cred.cardNumber!;
    const binary = decimalToBinary(`${cardNumber}`);

    // get the number of bytes we need to pad to based on the partial bits
    const numberOfBytes = Math.ceil(binary.length / 8);
    // adding padding left to fill total number of bytes with data in the least signifigant bits
    const paddedCardBinString = padLeft(binary, numberOfBytes * 8, '0');
    // convert bin -> hex -> base64
    // Note: we cannot go directly from binary to base64 due to left padding 
    // getting stripped off. To preserve the left padding, we convert first to hex.
    const hex = parseInt(paddedCardBinString, 2).toString(16)

    cred.credentialData = hexToBase64(hex),
      cred.credentialDataBitLength = binary.length;
    return cred;
  }

  constructor(cred: ICredentialC) {
    super(cred);
  }

  decode() {
    if (!this.credentialDataBitLength) {
      this.credentialDataBitLength = atob(this.credentialData!)[0].charCodeAt(0);
    }
    const numberOfBytes = Math.ceil(this.credentialDataBitLength! / 8) + 1;
    let stringOfBits = "";
    let i;
    for (i = 0; i < numberOfBytes; i++) {
      let byteRepresentation = atob(this.credentialData!)[i];
      if (!byteRepresentation) {
        byteRepresentation = ' ';
      }
      stringOfBits += padLeft(byteRepresentation.charCodeAt(0).toString(2), 8, '0');
    }

    // strip off the first byte and return the rest of the binary string (containing card data)
    const cardNumberBytes = stringOfBits.slice(8);

    // for PDK we need to count backwards from the end of the byte string in order to get the relevant bits
    const relevantBits = cardNumberBytes.slice(cardNumberBytes.length - this.credentialDataBitLength);

    // no card number for PDK
    this.cardNumber = parseInt(relevantBits, 2);

    // no facility code for PDK
    this.facilityCode = NaN;
    return this;
  }

}

function decimalToBinary(dec: string) {
  return Number(parseInt(dec, 10)).toString(2);
}

function padLeft(stringToPad: string, width: number, charToPadWith: string) {
  return (String(charToPadWith).repeat(width) + String(stringToPad)).slice(String(stringToPad).length)
}

function hexToBase64(hexstring: string) {
  // if the hexstring is an odd number of characters, apply a left padded 0
  hexstring = hexstring.length % 2 !== 0 ? '0' + hexstring : `${hexstring}`;
  return btoa(hexstring.match(/\w{2}/g)!.map((a) => {
    return String.fromCharCode(parseInt(a, 16));
  }).join("")!);
}

