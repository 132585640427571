import { Dispatch } from 'redux'

import {
  UserCS,
  RoleCS,
  IUserCS,
  InviteCS,
  IRoleCS,
  IDealerInvitationCS,
  IPaginatedParamsCS,
  IPaginatedResponseCS,
  IMultiInputUpdate,
  IUserUpdateRequestCS,
  EMessageType,
  IUniTable_UpdatePaginationSummary,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  setPageSizeInCache,
} from '../../internal'

export enum adminActions {
  GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST',
  GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS',
  GET_ADMINS_FAILURE = 'GET_ADMINS_FAIL',

  GET_ADMIN_DETAILS_REQUEST = 'GET_ADMIN_DETAILS_REQUEST',
  GET_ADMIN_DETAILS_SUCCESS = 'GET_ADMIN_DETAILS_SUCCESS',
  GET_ADMIN_DETAILS_FAILURE = 'GET_ADMIN_DETAILS_FAILURE',

  GET_ADMIN_INVITE_REQUEST = 'GET_ADMIN_INVITE_REQUEST',
  GET_ADMIN_INVITE_SUCCESS = 'GET_ADMIN_INVITE_SUCCESS',
  GET_ADMIN_INVITE_FAILURE = 'GET_ADMIN_INVITE_FAILURE',
  
  UPDATE_ADMIN_INVITE_REQUEST = 'UPDATE_ADMIN_INVITE_REQUEST',
  UPDATE_ADMIN_INVITE_SUCCESS = 'UPDATE_ADMIN_INVITE_SUCCESS',
  UPDATE_ADMIN_INVITE_FAILURE = 'UPDATE_ADMIN_INVITE_FAILURE',

  GET_ADMIN_TOKENS_REQUEST = 'GET_ADMIN_TOKENS_REQUEST',
  GET_ADMIN_TOKENS_SUCCESS = 'GET_ADMIN_TOKENS_SUCCESS',
  GET_ADMIN_TOKENS_FAILURE = 'GET_ADMIN_TOKENS_FAILURE',

  UPDATE_ADMINS_QUERY_PARAMS = 'UPDATE_ADMINS_QUERY_PARAMS',
  UPDATE_ADMINS_TABLE_META = 'UPDATE_ADMINS_TABLE_META',

  HANDLE_ADMIN_FORM_DATA_CHANGE = 'HANDLE_ADMIN_FORM_DATA_CHANGE',
  UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST',
  UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS',
  UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE',

  GET_ADMIN_ROLES_REQUEST = 'GET_ADMIN_ROLES_REQUEST',
  GET_ADMIN_ROLES_FAILURE = 'GET_ADMIN_ROLES_FAILURE',
  GET_ADMIN_ROLES_SUCCESS = 'GET_ADMIN_ROLES_SUCCESS',
  UPDATE_ADMIN_ROLES_QUERY_PARAMS = 'UPDATE_ADMIN_ROLES_QUERY_PARAMS',
  UPDATE_ADMIN_ROLES_TABLE_META = 'UPDATE_ADMIN_ROLES_TABLE_META',

  GET_ADMIN_INVITES_REQUEST = 'GET_ADMIN_INVITES_REQUEST',
  GET_ADMIN_INVITES_FAILURE = 'GET_ADMIN_INVITES_FAILURE',
  GET_ADMIN_INVITES_SUCCESS = 'GET_ADMIN_INVITES_SUCCESS',
  UPDATE_ADMIN_INVITES_QUERY_PARAMS = 'UPDATE_ADMIN_INVITES_QUERY_PARAMS',
  UPDATE_ADMIN_INVITES_TABLE_META = 'UPDATE_ADMIN_INVITES_TABLE_META',
}

// Admins
const getAdminListAction = new ApiReduxAction(supportApi, {
  request: { type: adminActions.GET_ADMINS_REQUEST },
  success: { type: adminActions.GET_ADMINS_SUCCESS },
  failure: {
    type: adminActions.GET_ADMINS_FAILURE,
    title: 'getAdminsFail',
  },
  tableMetaUpdate: {
    type: adminActions.UPDATE_ADMINS_TABLE_META
  }
}, (dux: IExposeRedux) => {
  const params = dux.getState().admins.queryParams;
  return supportApi.user.getAllUsers.bind(supportApi.user, params);
});
export const attemptRetrieveAdmins = getAdminListAction.go;

export function updateAdminListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: adminActions.UPDATE_ADMINS_QUERY_PARAMS,
    queryParams
  }
}

export function updateAdminsTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: adminActions.UPDATE_ADMINS_TABLE_META,
    ...meta
  }
}


// SINGLE ADMIN
const getSingleAdminAction = new ApiReduxAction(supportApi, {
  request: { type: adminActions.GET_ADMIN_DETAILS_REQUEST },
  success: { type: adminActions.GET_ADMIN_DETAILS_SUCCESS },
  failure: {
    type: adminActions.GET_ADMIN_DETAILS_FAILURE,
    title: 'getAdminDetailsFail',
  }
}, (dux: IExposeRedux, adminId: string) => {
  return supportApi.user.getUser.bind(supportApi.user, adminId);
});
export const getAdminById = getSingleAdminAction.go;


// Update Admin
const updateSingleAdminAction = new ApiReduxAction(supportApi, {
  request: { type: adminActions.UPDATE_ADMIN_REQUEST },
  success: {
    type: adminActions.UPDATE_ADMIN_SUCCESS,
    title: 'changesSaved',
    message: 'successfullyUpdatedAdmin'
  },
  failure: {
    type: adminActions.UPDATE_ADMIN_FAILURE,
    title: 'updateAdminFail',
  }
}, (dux: IExposeRedux, adminId: string) => {
  const editAdminData = dux.getState().adminDetails.adminData;

  const adminUpdateBody: Partial<IUserUpdateRequestCS> = {
    new_first_name: editAdminData.firstName.value,
    new_last_name: editAdminData.lastName.value,
    new_phone_number: editAdminData.phoneNumber.value,
    new_email_status: editAdminData.emailStatus.value,
  };
  return supportApi.user.updateUser.bind(supportApi.user, adminId, adminUpdateBody);
});
export const attemptUpdateAdmin = updateSingleAdminAction.go;


export function handleAdminChange(changes: IMultiInputUpdate) {
  return {
    type: adminActions.HANDLE_ADMIN_FORM_DATA_CHANGE,
    ...changes
  }
}

// ADMIN ROLES LIST
const getAdminRolesList = new ApiReduxAction(supportApi, {
  request: { type: adminActions.GET_ADMIN_ROLES_REQUEST },
  success: { type: adminActions.GET_ADMIN_ROLES_SUCCESS },
  failure: {
    type: adminActions.GET_ADMIN_ROLES_FAILURE,
    title: 'getAdminRolesFail',
  },
  tableMetaUpdate: {
    type: adminActions.UPDATE_ADMIN_ROLES_TABLE_META
  },
}, (dux: IExposeRedux, adminId: string) => {
  const params = dux.getState().adminRoles.queryParams;
  return supportApi.user.getRolesForUser.bind(supportApi.user, adminId, params);
});
export const attemptRetrieveAdminRoles = getAdminRolesList.go;

export function updateRoleListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: adminActions.UPDATE_ADMIN_ROLES_QUERY_PARAMS,
    queryParams
  }
}

export function updateAdminRolesTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: adminActions.UPDATE_ADMIN_ROLES_TABLE_META,
    ...meta
  }
}


// admin invite
const getSingleAdminInvitesList = new ApiReduxAction(supportApi, {
  request: { type: adminActions.GET_ADMIN_INVITES_REQUEST },
  success: { type: adminActions.GET_ADMIN_INVITES_SUCCESS },
  failure: {
    type: adminActions.GET_ADMIN_INVITES_FAILURE,
    title: 'getAdminInvitesFail'
  },
  tableMetaUpdate: {
    type: adminActions.UPDATE_ADMIN_INVITES_TABLE_META
  }
}, (dux: IExposeRedux, adminId: string) => {
  const params = dux.getState().adminInvites.queryParams;
  return supportApi.user.getUserInvites.bind(supportApi.user, adminId, params);
});
export const attemptRetrieveAdminInvitesByAdminId = getSingleAdminInvitesList.go;

export function updateAdminInvitesQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: adminActions.UPDATE_ADMIN_INVITES_QUERY_PARAMS,
    queryParams
  }
}

export function updateAdminInvitesTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: adminActions.UPDATE_ADMIN_INVITES_TABLE_META,
    ...meta
  }
}

// resend admin invite

const resendAdminInviteAction = new ApiReduxAction(supportApi, {
  request: { type: adminActions.UPDATE_ADMIN_INVITE_REQUEST },
  success: { 
    type: adminActions.UPDATE_ADMIN_INVITE_SUCCESS,
    title: 'inviteSent',
    message: '_adminInviteCheckInbox',
  },
  failure: {
    type: adminActions.UPDATE_ADMIN_INVITE_FAILURE,
    title: 'resendAdminInviteFail'
  }
}, (dux: IExposeRedux, inviteId: string) => {
  const params = dux.getState().adminInvites.queryParams;
  return supportApi.invi.resendInvite.bind(supportApi.invi, inviteId);
});
export const attemptResendAdminInvite = resendAdminInviteAction.go;