import React, { Component } from 'react'
import { IContainerCustomization } from '../PartnerCustomizations'
import {
  UniKeyV1CredentialC,
  RawCredentialC,
  WiegandCredentialC,
  Corp1000CredentialC,
  TSBnFCredentialC,
  TSBwFCredentialC,
  ECredentialType,
  IPartnerCredImpls
} from '@unikey/unikey-commons/release/csupp'

export default () => {

  const partnerCredImpls: IPartnerCredImpls = {
    PartnerRCred: RawCredentialC,
    PartnerWCred: WiegandCredentialC,
    PartnerU1Cred: UniKeyV1CredentialC,
    PartnerC1000Cred: Corp1000CredentialC,
    Partner37BwFCred: TSBwFCredentialC,
    Partner37BnFCred: TSBnFCredentialC,
  };

  const CredentialDetails: IContainerCustomization = {
    props: {
      partnerCredImpls,
    }
  }

  const CredentialList: IContainerCustomization = {
    props: {
      partnerCredImpls,
    }
  }

  return {
    CredentialDetails,
    CredentialList
  }
}
