import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system';

import {
  Editable,
  UniIcon,
  UniSteps,
  UniTable,
  UniSelect,
  UniLoader,
  UniWorkflow,
  UniInput,
  UniToggle,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  IUniTable_Sort,
  IUniTable_Filter,
  IPaginatedParamsCS,
  IUniTable_Column,
  IMultiInputUpdate,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  IUniSteps_StepConfig,
  UniConditionalRender,
  TValidationType,
  IValidationType,
  ECouponStatus,
  CouponCS,
  DealerCS,
  S10nModelC,
  ES10nModelType,
  minV10n,
  maxV10n,
  emailV10n,
  UniTag
} from '@unikey/unikey-commons/release/csupp';

import {
  clearCouponAssignmentForm,
  attemptRetrieveCouponById,
  attemptRetrieveDealerById,
  toggleAssignCouponModal,
  attemptRetrieveCoupons,
  updateCouponListQueryParams,
  updateCouponTableMeta,

  attemptRetrieveDealers,
  updateDealerListQueryParams,
  updateDealerTableMeta,
  attemptAssignCouponsToDealer,
  handleAssignCouponStepChange,
  handleAssignCouponFormChange,
  calcLoaderPercentageComplete,

  getTableParamsFromUpdate,
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig, ENavPages,
  IAssignCouponToDealerActionParams,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  match: any,
  modalOpen: boolean,
  currentStepIndex: number,
  initialDealer?: DealerCS,
  initialCoupon?: CouponCS,
  saving: boolean,
  couponAssignmentJobPercentageComplete: number,

  
  pendingDealer?: DealerCS,
  pendingDealersMap: Map<string, DealerCS>,
  pendingCouponsMap: Map<string, CouponCS>,
  numPendingCoupons: number,
  pendingDealerSelectionUpdater: number,

  availableCoupons: CouponCS[],
  couponListQueryParams: IPaginatedParamsCS,
  couponListAppliedFilters: IUniTable_Filter[],
  couponListAppliedSorts: IUniTable_Sort[],
  couponListLoading: boolean,
  couponListPaginationSummary: IUniTable_PaginationSummary,
  
  showSubBrand?: boolean,
  dealers: DealerCS[],
  dealerListQueryParams: IPaginatedParamsCS,
  dealerListAppliedFilters: IUniTable_Filter[],
  dealerListAppliedSorts: IUniTable_Sort[],
  dealerListLoading: boolean,
  dealerListPaginationSummary: IUniTable_PaginationSummary

  toggleModal(): void,
  changeWorkflowStep(stepTo: number): void,
  updateDealerCouponAssignment(dealerMap?: Map<string, DealerCS>, couponMap?: Map<string, CouponCS>): void
  assignCoupons(assignParams: IAssignCouponToDealerActionParams): Promise<void>,

  getDealers(): Promise<void>,
  updateDealerTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateDealerQueryParams?(params: IPaginatedParamsCS): void,

  getAllCoupons(): Promise<void>,
  updateCouponTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateCouponQueryParams?(params: IPaginatedParamsCS): void,

  clearCouponAssignmentForm(): void,
  actionAfterSave(): Promise<void>
}

enum StepNameKeys {
  SelectDealer = 'selectDealer',
  SelectCoupons = 'selectCoupons',
  Review = 'review'
}

class CouponAssignmentContainer extends Component<IProps> {
  _updateDealerTable: TTableUpdateFunc; 
  _updateCouponTable: TTableUpdateFunc; 
  stepNameKeys: StepNameKeys[];

  constructor(props: IProps) {
    super(props);

    this._updateDealerTable = buildTableUpdateFunc(
      props.getDealers,
      props.updateDealerTableMeta,
      props.updateDealerQueryParams
    )
    this._updateCouponTable = buildTableUpdateFunc(
      props.getAllCoupons,
      props.updateCouponTableMeta,
      props.updateCouponQueryParams
    )

    // deafault case: nothing passed in -- shouldnt get to this state, but here as a fallback.
    // step order should be coupons, dealer, review (starting on step dealer)
    this.stepNameKeys = [
      StepNameKeys.SelectCoupons,
      StepNameKeys.SelectDealer,
      StepNameKeys.Review
    ];
    
    if (props.initialDealer) {
      // a dealerId was passed in 
      // step order should be dealer, coupons, review (starting on step coupons)
      this.stepNameKeys = [
        StepNameKeys.SelectCoupons,
        StepNameKeys.Review
      ];
    } 
    else if (props.initialCoupon) {
      // a desired coupon(s) was passed in 
      // step order should be coupons, dealer, review (starting on step dealer)
      this.stepNameKeys = [
        StepNameKeys.SelectDealer,
        StepNameKeys.Review
      ];
     
    }
  }

  componentDidMount() {
    let initialDealerMap  = new Map(); 
    let initialCouponMap = new Map();
    
    if (this.props.initialDealer) {
      // a desired dealer was passed in 
      initialDealerMap = new Map().set(this.props.initialDealer?.id, {
        ...this.props.initialDealer, 
        // adding name here since the initial dealer (coming from dealer-details) will
        // have an editable name and we dont need that for the dealer assignment map
        name: this.props.initialDealer?.name?.value
      });
    } 
    if (this.props.initialCoupon) {
      // a desired coupon(s) was passed in 
      initialCouponMap = new Map().set(this.props.initialCoupon?.id, this.props.initialCoupon);
    }
    this.props.updateDealerCouponAssignment(initialDealerMap, initialCouponMap);

    this.props.getAllCoupons();
    this.props.getDealers();
  }

  _clearAndCloseAssignmentModal = () => {
    this.props.clearCouponAssignmentForm();
    this.props.toggleModal(); 
  }

  _saveCouponAssignmentAndToggleModal() {
    const couponIds = [...this.props.pendingCouponsMap.keys()];

    this.props.assignCoupons({ couponIds, dealerId: this.props.pendingDealer!.id! })
      .then(() => {
        let activePageRefreshRequest = Promise.resolve();
        if (this.props.actionAfterSave) {
         activePageRefreshRequest = this.props.actionAfterSave();
        }
        return activePageRefreshRequest;
      }).then(this._clearAndCloseAssignmentModal);
  }

  _isCouponNotSelectable = (coupon: CouponCS) => {
    const brandMismatch = coupon.brand !== (this.props.pendingDealer?.brand ?? coupon.brand);
    return (coupon.status === ECouponStatus.assigned || coupon.status === ECouponStatus.claimed ||  brandMismatch); 
  }

  _isDealerNotSelectable = (dealer: DealerCS) => {    
    let brandMismatch = false;
    const selectedCouponBrand = [...this.props.pendingCouponsMap.values()]?.[0]?.brand;
    if (selectedCouponBrand) {
      brandMismatch = selectedCouponBrand !== (dealer?.brand);
    }
    return  (brandMismatch || dealer.subscriptionModel === ES10nModelType.reader || dealer.subscriptionModel === ES10nModelType.unknown); 
  }

  _toggleSelectedCoupon = (coupon: CouponCS): any => {
    if (this.props.pendingCouponsMap.has(coupon.id)) {
      this.props.pendingCouponsMap.delete(coupon.id);
    } else {
      this.props.pendingCouponsMap.set(coupon.id, coupon);
    }
    this.props.updateDealerCouponAssignment(undefined, this.props.pendingCouponsMap);
  }

  _buildCouponListColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('units', {
        nameKey: 'units',
        isSortable: true,
        evalItalicized: this._isCouponNotSelectable,
        size: 6,
      })
      .set('brand', {
        nameKey: 'brand',
        filterName: 'brand_code',
        isSortable: false,
        isFilterable: false, // TODO: not available yet
        type: 'tag',
        evalItalicized: this._isCouponNotSelectable,
        size: this.props.showSubBrand ? 6 : 0,
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        type: 'enum-tagdot',
        enumType: ECouponStatus,
        evalItalicized: this._isCouponNotSelectable,
        size: 6
      })
      .set('giveaway', {
        nameKey: '_emptyString',
        type: 'boolean',
        size: 2,
        evalItalicized: this._isCouponNotSelectable,
        template: (rowItem: CouponCS) => rowItem.isGiveaway ? (<UniIcon name="moneyOff" tooltipTextKeys={['giveawayCoupon']} size="xs" />) : (<></>),
      })
      .set('dealerName', {
        nameKey: 'dealerName',
        filterName: 'dealer_name',
        isSortable: false,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        size: 0
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('dealerId', {
        nameKey: 'dealerId',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();

    return { columns, actions };
  }
  
  _toggleSelectedDealer = (dealer: DealerCS): any => {
    // single select style
    this.props.pendingDealersMap.clear();
    if (dealer) {
      this.props.pendingDealersMap.set(dealer.id!, dealer);
    }
    this.props.updateDealerCouponAssignment(this.props.pendingDealersMap);
  }

  _buildDealerListColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        template: (rowItem: DealerCS) => rowItem.name ? rowItem.name?.value : (<i><UniLocalize translate="notProvided" /></i>),
        evalItalicized: this._isDealerNotSelectable,
        size: this.props.showSubBrand ? 10 : 12,
      })
      .set('brand', {
        nameKey: 'brand',
        isSortable: false,
        isFilterable: false, // TODO: not available yet
        type: 'tag',
        evalItalicized: this._isDealerNotSelectable,
        size: this.props.showSubBrand ? 4 : 0,
        filterName: 'brandCode'
      })
      .set('subscription', {
        nameKey: 'subscriptionModel',
        isSortable: true,
        isFilterable: true,
        type: 'string',
        evalItalicized: this._isDealerNotSelectable,
        template: (rowItem: DealerCS) => (
          <UniTag textKey={S10nModelC.getNameKeyFromModelType(rowItem.subscriptionModel)} inverted />
        ),
        size: 8
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.dealerDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }

    const { columns: couponColumns, actions: couponActions } = this._buildCouponListColumnsAndActions();
    const { columns: dealerColumns, actions: dealerActions } = this._buildDealerListColumnsAndActions();

    return (
      <section className='assign-coupon-container'>
        <UniWorkflow
          inModal
          titleKey="assignCouponToDealer"
          titleIcon="confirmationNumber"
          size="widest" 
          handleClose={this.props.toggleModal}>
          
          <UniLoader
            hidden={!this.props.saving}
            type='linear'
            percentageComplete={this.props.couponAssignmentJobPercentageComplete} />

          <UniSteps
            steps={this.stepNameKeys.map(k => ({ nameKey: k }))}
            activeStepIndex={this.props.currentStepIndex}
            allStepsUnlocked={false}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          {/* Step - Dealer Select */}
          <UniConditionalRender visible={this.props.currentStepIndex === this.stepNameKeys.indexOf(StepNameKeys.SelectDealer)}>
            
            <p><UniLocalize translate="selectDesiredDealerToRedeemCoupons" /></p>

            <UniTable
              searchable={true}
              selectable={true}
              singleSelectable={true}
              advancedFiltering={true}
              titleKey="dealersList"
              createButtonTextKey="dealer"
              handleUpdate={this._updateDealerTable}
              handleRowItemSelectToggle={this._toggleSelectedDealer}
              evalSelectableRowDisabled={this._isDealerNotSelectable}
              selectedItems={this.props.pendingDealersMap}
              data={this.props.dealers}
              paginationSummary={this.props.dealerListPaginationSummary}
              columnConfig={dealerColumns}
              activeSorts={this.props.dealerListAppliedSorts}
              actionsConfig={dealerActions}
              activeFilters={this.props.dealerListAppliedFilters}
              showLoader={this.props.dealerListLoading} />          

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={this.props.pendingDealersMap?.size !== 1}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this._clearAndCloseAssignmentModal}
                textKey="cancel"
                icon="close"
                secondary={true} />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End Step - Dealer Select */}

          {/* Step - Coupon Select */}
          <UniConditionalRender visible={this.props.currentStepIndex === this.stepNameKeys.indexOf(StepNameKeys.SelectCoupons)}>
            
            <p><UniLocalize translate="_selectCouponsToAssign" /></p>
            
            <UniTable
              searchable={true}
              selectable={true}
              advancedFiltering={true}
              titleKey="couponsList"
              createButtonTextKey="coupon"
              handleUpdate={this._updateCouponTable}
              handleRowItemSelectToggle={this._toggleSelectedCoupon}
              evalSelectableRowDisabled={this._isCouponNotSelectable}
              selectedItems={this.props.pendingCouponsMap}
              data={this.props.availableCoupons}
              paginationSummary={this.props.couponListPaginationSummary}
              columnConfig={couponColumns}
              activeSorts={this.props.couponListAppliedSorts}
              actionsConfig={couponActions}
              activeFilters={this.props.couponListAppliedFilters}
              showLoader={this.props.couponListLoading} />

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={this.props.pendingCouponsMap?.size < 1}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this._clearAndCloseAssignmentModal}
                textKey="cancel"
                icon="close"
                secondary={true} />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End step - coupon select */}

          {/* Step - Review */}
          <UniConditionalRender visible={this.props.currentStepIndex === this.stepNameKeys.indexOf(StepNameKeys.Review)}>
            
            <p><UniLocalize translate="_assignCouponToDealerReviewStepMessage" /></p>

            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="dealer" /></strong></Col>
              <Col>{this.props.pendingDealer?.name?.value}</Col>
            </Row>

            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="numCoupons" /></strong></Col>
              <Col>{this.props.pendingCouponsMap.size}</Col>
            </Row>

            <Row nogutter className="review-row">
              <Col md={8}><strong><UniLocalize translate="totalCreditsAdded" /></strong></Col>
              <Col>{[...this.props.pendingCouponsMap.values()].reduce((tot, c: CouponCS) => tot + c.units, 0)}</Col>
            </Row>

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={() => this._saveCouponAssignmentAndToggleModal()}
                textKey="save"
                icon="save"
                disabled={this.props.pendingDealersMap?.size !== 1 || this.props.pendingCouponsMap?.size === 0}
                showLoader={!!this.props.saving} />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={false}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this._clearAndCloseAssignmentModal}
                textKey="cancel"
                icon="close"
                secondary={true} />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End step - review */}

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    modalOpen: state.coupons.modalOpen,
    currentStepIndex: state.couponAssignment.workflowStepIndex,
    pendingDealersMap: state.couponAssignment.pendingDealersMap,
    pendingDealer: [...state.couponAssignment.pendingDealersMap.values()]?.[0],
    pendingDealerSelectionUpdater: state.couponAssignment.pendingDealersMap?.size, // needed to trigger selectable table component re-render
    pendingCouponsMap: state.couponAssignment.pendingCouponsMap,
    numPendingCoupons: state.couponAssignment.pendingCouponsMap?.size, // needed to trigger selectable table component re-render
    saving: state.couponAssignment.saving,

    availableCoupons: state.coupons.data.models,
    couponListQueryParams: state.coupons.queryParams,
    couponListLoading: state.coupons.loading,
    couponListAppliedFilters: state.coupons.tableFilters,
    couponListAppliedSorts: state.coupons.tableSorts,
    couponListPaginationSummary: state.coupons.paginationSummary,

    dealers: state.dealers.data.models,
    dealerListQueryParams: state.dealers.queryParams,
    dealerListLoading: state.dealers.loading,
    dealerListAppliedFilters: state.dealers.tableFilters,
    dealerlistAppliedSorts: state.dealers.tableSorts,
    dealerListPaginationSummary: state.dealers.paginationSummary,
    
    couponAssignmentJobPercentageComplete: calcLoaderPercentageComplete(state.couponAssignment.jobReqsOutstanding, state.couponAssignment.jobReqsTotal),
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal: toggleAssignCouponModal,
  assignCoupons: attemptAssignCouponsToDealer,
  updateDealerCouponAssignment: handleAssignCouponFormChange,
  changeWorkflowStep: handleAssignCouponStepChange,

  getAllCoupons: attemptRetrieveCoupons,
  updateCouponQueryParams: updateCouponListQueryParams,
  updateCouponTableMeta,

  getDealers: attemptRetrieveDealers,
  updateDealerQueryParams: updateDealerListQueryParams,
  updateDealerTableMeta,
  
  clearCouponAssignmentForm,
  refreshUpdatedCoupon: attemptRetrieveCouponById,
  refreshUpdatedDealer: attemptRetrieveDealerById,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CouponAssignmentContainer)
  ), { componentName: 'CouponAssignment' })
