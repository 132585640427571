import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniGauge,
  IUniGauge_Config,
  Editable,
  UniTable,
  UniKeyVal,
  UniLocalize,
  UniConditionalRender,
  ReaderCS,
  UserCS,
  ECredentialType,
  ECredentialStatus,
  CredentialCS,
  EUserStatusCS,
  FirmwareCS,
  OrganizationCS,
  IOrganizationCS,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  IUniConfirm_Config,
  IMultiInputUpdate,
  IFirmwarePutCS,
  IFirmwareImageCS,
  EOrganizationStatus,
  EReaderStatus,
  S10nModelC,
  notBlankV10n,
  IUniTable_Action,
  UniTag
} from '@unikey/unikey-commons/release/csupp';

export enum EOrganizationStatusEditable {
  unknown = 0,
  enabled,
  disabled
}

import {
  attemptRetrieveOrganizationById,
  attemptUpdateOrganization,
  handleOrgChange,
  attemptRetrieveOrgCredentials,
  updateOrgCredentialsQueryParams,
  updateOrgCredentialsTableMeta,
  attemptRetrieveOrgReaders,
  updateOrgReadersQueryParams,
  updateOrgReadersTableMeta,
  attemptResetReaderEnrollment,
  attemptRetrieveOrgAdmins,
  updateOrgAdminsQueryParams,
  updateOrgAdminsTableMeta,
  userManager,
  navConfig, ENavPages,
  openConfirmModal,
  closeConfirmModal,
  getTableSortDirection, getTableParamsFromUpdate,
  PartnerCustomizations, IPartnerCustomizations,
  dateOrLoading
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  unlimitedAllocation: boolean,
  organization: OrganizationCS,
  orgName: Editable<string>,
  orgStatus: Editable<number>,
  creditsAllocated: Editable<number>,
  creditsClaimed: Editable<number>,
  history: any,
  saving: boolean,
  orgReaders: ReaderCS[],
  orgReadersListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  loading: boolean,
  paginationSummary: IUniTable_PaginationSummary,
  showSubBrand?: boolean,
  
  credentials: CredentialCS[],
  credentialListQueryParams: IPaginatedParamsCS,
  credentialListAppliedFilters: IUniTable_Filter[],
  credentialListAppliedSorts: IUniTable_Sort[],
  credentialListLoading: boolean,
  credentialListPaginationSummary: IUniTable_PaginationSummary,

  orgAdmins: UserCS[],
  orgAdminsTableQueryParams: IPaginatedParamsCS,
  orgAdminsTableAppliedFilters: IUniTable_Filter[],
  orgAdminsTableAppliedSorts: IUniTable_Sort[],
  orgAdminsTablePaginationSummary: IUniTable_PaginationSummary,
  orgAdminsListLoading: boolean,

  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,

  handleOrgChange(changes: IMultiInputUpdate): void,
  getOrganizationById(organizationId: string): void,
  attemptUpdateOrganization(organizationId: string): Promise<any>,
  
  getOrgCredentials(orgId: string, orgListQueryParams: IPaginatedParamsCS): void,
  updateOrgCredentialTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateOrgCredentialQueryParams?(params: IPaginatedParamsCS): void,

  getOrgReaders(orgId: string, orgReadersListQueryParams: IPaginatedParamsCS): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams(params: IPaginatedParamsCS): void,
  
  resetEnrollment(readerId: string): Promise<void>,
  
  getOrgAdmins(orgId: string, orgAdminsListQueryParams: IPaginatedParamsCS): void,
  updateOrgAdminsTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateOrgAdminsTableQueryParams(params: IPaginatedParamsCS): void,
}

class OrganizationDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getOrganizationById(this.props.match && this.props.match.params.organizationId);
    this.props.getOrgReaders(this.props.match.params.organizationId, this.props.orgReadersListQueryParams)
    this.props.getOrgAdmins(this.props.match.params.organizationId, this.props.orgAdminsTableQueryParams);
    this.props.getOrgCredentials(this.props.match.params.organizationId, this.props.credentialListQueryParams);
  }

  _updateAndReloadOrganization = (): Promise<any> => {
    return this.props.attemptUpdateOrganization(this.props.organization.id).then(() => {
      return this.props.getOrganizationById(this.props.match && this.props.match.params.organizationId);
    });
  }

  _reloadOnCancel = (): Promise<any> => {
    return Promise.resolve(this.componentDidMount())
  }

  _updateTable = (update: IUniTable_UpdatePaginationSummary) => {
    if (this.props.updateQueryParams) {
      const params = getTableParamsFromUpdate(update);

      this.props.updateTableMeta(update);
      this.props.updateQueryParams(params);
      this.props.getOrgReaders(this.props.match.params.organizationId, params);
    }
  }

  _buildOrgReadersColumnsAndActions = () => {

    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        type: 'string',
        template: (rowItem: ReaderCS) => rowItem.name ? rowItem.name : (<i><UniLocalize translate="notProvided" /></i>),
        size: 8
      })
      .set('serialNumber', {
        nameKey: 'serialNumber',
        isSortable: false,
        isFilterable: true, 
        type: 'exact-string',
        size: 4
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true, 
        type: 'enum-tagdot',
        enumType: EReaderStatus,
        size: 4
      })
      .set('firmwareVersion', {
        nameKey: 'version',
        isSortable: false,
        type: 'string',
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 4
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'uuid',
        size: 0
      })
      .set('productId', {
        nameKey: 'productId',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: ReaderCS) => this.props.history.push(navConfig.get(ENavPages.readerDetails)!.linkTo([rowItem.id])!),
      evalDisbled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    });

    return { columns, actions };
  }
  
  _updateOrgAdminsTable = (update: IUniTable_UpdatePaginationSummary) => {
    if (this.props.updateOrgAdminsTableQueryParams) {
      const params = getTableParamsFromUpdate(update);

      this.props.updateOrgAdminsTableMeta(update);
      this.props.updateOrgAdminsTableQueryParams(params);
      this.props.getOrgAdmins(this.props.match.params.organizationId, params);
    }
  }
  
  _buildAdminTableColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
    .set('email', {
      nameKey: 'email',
      isSortable: true,
      isFilterable: true,
      type: 'string',
      size: 6,
    })
    .set('roleNameKey', {
      nameKey: 'role',
      isSortable: false,
      isFilterable: false,
      type: 'tag',
      size: 4
    })
    .set('status', {
      nameKey: 'status',
      isSortable: true,
      isFilterable: true,
      type: 'enum-tagdot',
      translate: true,
      enumType: EUserStatusCS,
      size: 4
    })
    .set('firstName', {
      nameKey: 'firstName',
      isSortable: true,
      isFilterable: true,
      type: 'string',
      size: 2,
    })
    .set('lastName', {
      nameKey: 'lastName',
      isSortable: true,
      isFilterable: true,
      type: 'string',
      size: 4
    })
    .set('actions', {
      nameKey: 'actions',
      isSortable: false,
      size: 0,
    })
    .set('id', {
      nameKey: 'id',
      isSortable: false,
      isFilterable: true,
      isPrimaryFilter: true,
      type: 'uuid',
      size: 0
    })

    const actions = new Map<string, IUniTable_Action>();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.adminDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    })

    return { columns, actions };
  }

  
  _updateOrgCredentialTable = (update: IUniTable_UpdatePaginationSummary) => {
    if (this.props.updateOrgCredentialQueryParams) {
      const params = getTableParamsFromUpdate(update);

      this.props.updateOrgCredentialTableMeta(update);
      this.props.updateOrgCredentialQueryParams(params);
      this.props.getOrgCredentials(this.props.match.params.organizationId, params);
    }
  }

  _credentialStatusTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-status">
        <UniTag textKey={rowItem.getStatusNameKey()} dot />
      </div>
    )
  }

  _credentialOrganizationNameTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-organization">
        {rowItem.getOrganizationName()}
      </div>
    )
  }

  // credential table
  _credentialTypeTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-type">
        <UniTag textKey={rowItem.getTypeNameKey()} inverted />
      </div>
    )
  }

  _buildOrgCredentialTableColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        size: 5
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        template: this._credentialStatusTemplate,
        type: 'enum',
        enumType: ECredentialStatus,
        size: 4
      })
      .set('type', {
        nameKey: 'type',
        isSortable: false,
        isFilterable: true,
        template: this._credentialTypeTemplate,
        type: 'enum',
        enumType: ECredentialType,
        size: 3,
        // called type on the credential model, but db has it as credential_type. This is why it must be provided here.
        filterName: 'credentialType'
      })
      .set('organization', {
        nameKey: 'organization',
        isSortable: false,
        template: this._credentialOrganizationNameTemplate,
        size: 5
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 3
      })
      .set('actions', {
        nameKey: 'actions',
        collapsed: true,
        size: 2
      })
      .set('cardNumber', {
        nameKey: 'cardNumber',
        isFilterable: true,
        type: 'string',
        size: 0
      })
      .set('label', {
        nameKey: 'label',
        type: 'string',
        size: 0
      })
      .set('id', {
        nameKey: 'id',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('organizationId', {
        nameKey: 'organizationId',
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: CredentialCS) => this.props.history.push(navConfig.get(ENavPages.credentialDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: CredentialCS) => false,
      evalVisible: (rowItem: CredentialCS) => true
    })

    return { columns, actions };
  }

  _dispayDealerNameOrId = () => {
    if (this.props.organization.dealer) {
      const translatedSubscriptionName =this.props.intl.formatMessage({ id: S10nModelC.getNameKeyFromModelType(this.props.organization!.dealer.subscriptionModel!) });
      return `${this.props.organization.dealer?.name?.value} - (${translatedSubscriptionName})`;
    } else {
      return this.props.organization.dealerId;
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const orgReaderColsAndActions = this._buildOrgReadersColumnsAndActions();
    const orgAdminColsAndActions = this._buildAdminTableColumnsAndActions();
    const credTableColsAndActions = this._buildOrgCredentialTableColumnsAndActions();

    return (
      <section className='organizationDetails-container'>
        {/* Key vals for images belonging to this product */}
        <Row>
          <Col>
            <h3 className="page-title-non-table">{this.props.orgName.value}</h3>
          </Col>
        </Row>
        <UniKeyVal
          label="organization-details"
          showLoader={!this.props.organization.id}
          saveClick={this._updateAndReloadOrganization}
          cancelClick={this._reloadOnCancel}
          isSaving={this.props.saving}
          fields={[
            {
              keyName: 'id',
              value: this.props.organization.id,
              type: 'string',
              disabled: true
            },
            {
              keyName: 'name',
              value: `${this.props.orgName.value}`,
              type: 'string',
              preventTranslate: true,
              handleUpdate: (name: Editable<string>) => this.props.handleOrgChange({ name }),
              validations: new Map([notBlankV10n])
            },
            {
              keyName: 'status',
              value: `${this.props.orgStatus.value}`,
              type: 'enum',
              enumType: EOrganizationStatus,
              enumTypeEdit: EOrganizationStatusEditable,
              handleUpdate: (status: Editable<EOrganizationStatus>) => {
                if (status.value !== EOrganizationStatus.deleted) {
                  this.props.handleOrgChange({ status });
                }
              },
            },
            {
              keyName: 'createdDate',
              value: dateOrLoading(this.props.organization.created, this.props.loading),
              type: 'string',
              disabled: true
            },
            {
              keyName: 'creditsAllocated',
              value: this.props.unlimitedAllocation ? this.props.intl.formatMessage({ id: 'unlimited' }) : `${this.props.creditsAllocated.value}`!,
              type: this.props.unlimitedAllocation ? 'string' : 'number',
              disabled: true,
              hidden: !this.props.unlimitedAllocation
            },
            {
              keyName: 'creditsClaimed',
              value: `${this.props.creditsClaimed.value!}`,
              type: 'number',
              disabled: true,
              hidden: !this.props.unlimitedAllocation 
            },
            {
              keyName: 'creditSummary',
              type: 'gauge',
              value: `${this.props.creditsClaimed.value!}`,
              hidden: this.props.unlimitedAllocation,
              gaugeOpts: {
                nameKey: 'organizationCreditAllocation',
                preventReorder: true,
                size: 'lg',
                stacked: true,
                max: this.props.unlimitedAllocation ? this.props.creditsClaimed.value : this.props.creditsAllocated.value!,
                vals: [
                  {
                    nameKey: 'organizationCreditsConsumed',
                    value: this.props.creditsClaimed.value!,
                    theme: 'secondary'
                  }
                ]
              }
            },
            {
              keyName: 'dealer',
              value: this._dispayDealerNameOrId(),
              type: 'link',
              linkTo: navConfig.get(ENavPages.dealerDetails)!.linkTo([this.props.organization.dealerId])!
            },
            {
              keyName: 'brand',
              value: this.props.organization?.brand ?? '',
              disabled: true,
              hidden: !this.props.showSubBrand,
              type: 'tag'
            }
          ]} />

        {/* Display the org specific admins  */}
        <section className='orgAdminsList-container'>
          <UniTable
            searchable={false}
            advancedFiltering={false}
            titleKey="limitedAdminList"
            createButtonTextKey="admin"
            handleUpdate={this._updateOrgAdminsTable}
            data={this.props.orgAdmins}
            paginationSummary={this.props.orgAdminsTablePaginationSummary}
            columnConfig={orgAdminColsAndActions.columns}
            activeSorts={this.props.orgAdminsTableAppliedSorts}
            actionsConfig={orgAdminColsAndActions.actions}
            activeFilters={this.props.orgAdminsTableAppliedFilters}
            allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
            showLoader={this.props.orgAdminsListLoading} />

        </section>

        {/* Display the org specific readers  */}
        <section className='orgReadersList-container'>
          <UniTable
            searchable={true}
            advancedFiltering={true}
            titleKey="readersList"
            createButtonTextKey="reader"
            handleUpdate={this._updateTable}
            data={this.props.orgReaders}
            paginationSummary={this.props.paginationSummary}
            columnConfig={orgReaderColsAndActions.columns}
            activeSorts={this.props.appliedSorts}
            actionsConfig={orgReaderColsAndActions.actions}
            activeFilters={this.props.appliedFilters} 
            allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
            showLoader={this.props.listLoading} />
        </section>

         {/* Display the credentials with access to this org */}
         <section className='credentialList-container'>
          <UniTable
            searchable={true}
            advancedFiltering={true}
            titleKey="credentialsList"
            createButtonTextKey="credential"
            handleUpdate={this._updateOrgCredentialTable}
            data={this.props.credentials}
            paginationSummary={this.props.credentialListPaginationSummary}
            columnConfig={credTableColsAndActions.columns}
            activeSorts={this.props.credentialListAppliedSorts}
            actionsConfig={credTableColsAndActions.actions}
            activeFilters={this.props.credentialListAppliedFilters}
            allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
            showLoader={this.props.credentialListLoading} />
        </section>

      </section>
    )
  }
}

function mapStateToProps(state: any, props: IProps) {
  const unlimitedAllocation = state.orgDetails?.orgData?.creditsAllocated?.value === null;
  return {
    unlimitedAllocation,
    organization: state.orgDetails.orgData,
    orgName: state.orgDetails.orgData.name,
    creditsAllocated: state.orgDetails.orgData.creditsAllocated,
    creditsClaimed: state.orgDetails.orgData.creditsClaimed,
    orgStatus: state.orgDetails.orgData.status,
    orgBrandCode: state.orgDetails.orgData.brand,
    loading: state.orgDetails.loading,
    saving: state.orgDetails.saving,
    // credential list stuff
    credentials: state.orgCreds.data.models,
    credentialListQueryParams: state.orgCreds.queryParams,
    credentialListLoading: state.orgCreds.loading,
    credentialListAppliedFilters: state.orgCreds.tableFilters,
    credentialListAppliedSorts: state.orgCreds.tableSorts,
    credentialListPaginationSummary: state.orgCreds.paginationSummary,
    // reader list stuff
    orgReaders: state.orgReaders.data.models,
    orgReadersListQueryParams: state.orgReaders.queryParams,
    listLoading: state.orgReaders.loading,
    appliedFilters: state.orgReaders.tableFilters,
    appliedSorts: state.orgReaders.tableSorts,
    paginationSummary: state.orgReaders.paginationSummary,
    // admins list stuff
    orgAdmins: state.orgAdmins.data.models,
    orgAdminsTableQueryParams: state.orgAdmins.queryParams,
    orgAdminsListLoading: state.orgAdmins.loading,
    orgAdminsAppliedFilters: state.orgAdmins.tableFilters,
    orgAdminsAppliedSorts: state.orgAdmins.tableSorts,
    orgAdminsTablePaginationSummary: state.orgAdmins.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  handleOrgChange,
  attemptUpdateOrganization,
  getOrganizationById: attemptRetrieveOrganizationById,

  getOrgCredentials: attemptRetrieveOrgCredentials,
  updateOrgCredentialQueryParams: updateOrgCredentialsQueryParams,
  updateOrgCredentialTableMeta: updateOrgCredentialsTableMeta,

  getOrgReaders: attemptRetrieveOrgReaders,
  updateQueryParams: updateOrgReadersQueryParams,
  updateTableMeta: updateOrgReadersTableMeta,

  resetEnrollment: attemptResetReaderEnrollment,
  openConfirmDialog: openConfirmModal,
  closeConfirmModal,

  getOrgAdmins: attemptRetrieveOrgAdmins,
  updateOrgAdminsTableQueryParams: updateOrgAdminsQueryParams,
  updateOrgAdminsTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(OrganizationDetailsContainer)
    ), { componentName: 'OrganizationDetails' })
