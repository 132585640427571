import { Dispatch } from 'redux'

import {
  OrganizationCS,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IOrganizationCS,
  IReaderCS,
  ReaderCS,
  IUserCS,
  UserCS,
  ICredentialCS,
  CredentialCS,
  IMultiInputUpdate,
  IUniTable_UpdatePaginationSummary,
  EMessageType,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  setPageSizeInCache,
} from '../../internal'

export enum orgActions {

  GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST',
  GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAIL',

  SET_ACTIVE_ORG = 'SET_ACTIVE_ORG',

  UPDATE_ORG_QUERY_PARAMS = 'UPDATE_ORG_QUERY_PARAMS',
  UPDATE_ORG_TABLE_META = 'UPDATE_ORG_TABLE_META',

  GET_SINGLE_ORGANIZATION_REQUEST = 'GET_SINGLE_ORGANIZATION_REQUEST',
  GET_SINGLE_ORGANIZATION_SUCCESS = 'GET_SINGLE_ORGANIZATION_SUCCESS',
  GET_SINGLE_ORGANIZATION_FAILURE = 'GET_SINGLE_ORGANIZATION_FAILURE',

  HANDLE_ORG_CHANGE = 'HANDLE_ORG_CHANGE',

  UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST',
  UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAIL',

  GET_ORG_READERS_REQUEST = 'GET_ORG_READERS_REQUEST',
  GET_ORG_READERS_SUCCESS = 'GET_ORG_READERS_SUCCESS',
  GET_ORG_READERS_FAILURE = 'GET_ORG_READERS_FAILURE',
  UPDATE_ORG_READERS_QUERY_PARAMS = 'UPDATE_ORG_READERS_QUERY_PARAMS',
  UPDATE_ORG_READERS_TABLE_META = 'UPDATE_ORG_READERS_TABLE_META',

  GET_ORG_CREDENTIALS_REQUEST = 'GET_ORG_CREDENTIALS_REQUEST',
  GET_ORG_CREDENTIALS_SUCCESS = 'GET_ORG_CREDENTIALS_SUCCESS',
  GET_ORG_CREDENTIALS_FAILURE = 'GET_ORG_CREDENTIALS_FAILURE',
  UPDATE_ORG_CREDENTIALS_QUERY_PARAMS = 'UPDATE_ORG_CREDENTIALS_QUERY_PARAMS',
  UPDATE_ORG_CREDENTIALS_TABLE_META = 'UPDATE_ORG_CREDENTIALS_TABLE_META',

  GET_ORG_ADMINS_REQUEST = 'GET_ORG_ADMINS_REQUEST',
  GET_ORG_ADMINS_SUCCESS = 'GET_ORG_ADMINS_SUCCESS',
  GET_ORG_ADMINS_FAILURE = 'GET_ORG_ADMINS_FAILURE',
  UPDATE_ORG_ADMINS_QUERY_PARAMS = 'UPDATE_ORG_ADMINS_QUERY_PARAMS',
  UPDATE_ORG_ADMINS_TABLE_META = 'UPDATE_ORG_ADMINS_TABLE_META',
}

// ORGS
const getOrganizationListAction = new ApiReduxAction(supportApi, {
  request: { type: orgActions.GET_ORGANIZATIONS_REQUEST },
  success: { type: orgActions.GET_ORGANIZATIONS_SUCCESS },
  failure: {
    type: orgActions.GET_ORGANIZATIONS_FAILURE,
    title: 'getOrgsFail',
  },
  tableMetaUpdate: {
    type: orgActions.UPDATE_ORG_TABLE_META
  }
}, (dux: IExposeRedux) => {
  const params = dux.getState().organizations.queryParams;
  return supportApi.orgz.getAllOrgs.bind(supportApi.orgz, params);
});
export const attemptRetrieveOrganizations = getOrganizationListAction.go;

export function updateOrgListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: orgActions.UPDATE_ORG_QUERY_PARAMS,
    queryParams
  }
}

export function updateOrgTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: orgActions.UPDATE_ORG_TABLE_META,
    ...meta
  }
}

export function handleOrgChange(changes: IMultiInputUpdate) {
  return {
    type: orgActions.HANDLE_ORG_CHANGE,
    ...changes
  };
}

const getOrgAction = new ApiReduxAction(supportApi, {
  request: { type: orgActions.GET_SINGLE_ORGANIZATION_REQUEST },
  success: { type: orgActions.GET_SINGLE_ORGANIZATION_SUCCESS },
  failure: {
    type: orgActions.GET_SINGLE_ORGANIZATION_FAILURE,
    title: 'getSingleOrgFail',
  }
}, (dux: IExposeRedux, orgId: string) => {
  return supportApi.orgz.getOrg.bind(supportApi.orgz, orgId);
});
export const attemptRetrieveOrganizationById = getOrgAction.go;



// Org Reader List
export function attemptRetrieveOrgReaders(orgId: string): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    const params = getState().orgReaders.queryParams;
    dispatch(getOrgReadersRequest())
    return supportApi.orgz.getOrgReaders(orgId, params).then((readersResult: IPaginatedResponseCS<IReaderCS, ReaderCS>) => {
      dispatch(getOrgReadersSuccess(readersResult))
      dispatch(updateOrgReadersTableMeta({ totalCount: readersResult.total_count, pageNum: readersResult.paginationSummary.currPage, pageSize: readersResult.paginationSummary.pageSize }))
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else if (err.status === 404) {
        const pagination = getState().orgReaders.queryParams;
        // if the api responds with a 404, we should fake success with 0 readers instead of erroring
        dispatch(getOrgReadersSuccess({
          total_count: 0, results: [], models: [], paginationSummary: {
            pageCount: 1,
            currPage: 1,
            totalCount: 0,
            pageSize: pagination.pageSize
          }
        }))
        dispatch(updateOrgReadersTableMeta({
          pageCount: 1,
          pageNum: 1,
          totalCount: 0,
          pageSize: pagination.pageSize
        }))
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'getOrgReadersFail',
          type: EMessageType.error,
          messageKeys: [err?.data?.Messages?.[0]]
        }));
        dispatch(getOrgReadersFailure())
      }
    });
  }
}


export function getOrgReadersRequest() {
  return {
    type: orgActions.GET_ORG_READERS_REQUEST
  }
}

export function getOrgReadersSuccess(readersResult: IPaginatedResponseCS<IReaderCS, ReaderCS>) {
  return {
    type: orgActions.GET_ORG_READERS_SUCCESS,
    readersResult
  }
}

export function getOrgReadersFailure() {
  return {
    type: orgActions.GET_ORG_READERS_FAILURE,
  }
}


export function updateOrgReadersQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: orgActions.UPDATE_ORG_READERS_QUERY_PARAMS,
    queryParams
  }
}

export function updateOrgReadersTableMeta(meta: any) {
  return {
    type: orgActions.UPDATE_ORG_READERS_TABLE_META,
    filters: meta.filters,
    sorts: meta.sorts,
    currPage: meta.pageNum,
    pageSize: meta.pageSize,
    totalCount: meta.totalCount
  }
}

// Update Organization Details
const updateOrgAction = new ApiReduxAction(supportApi, {
  request: { type: orgActions.UPDATE_ORGANIZATION_REQUEST },
  success: { type: orgActions.UPDATE_ORGANIZATION_SUCCESS },
  failure: {
    type: orgActions.UPDATE_ORGANIZATION_FAILURE,
    title: 'updateOrgFail',
  }
}, (dux: IExposeRedux, orgId: string) => {
  const orgDetails = dux.getState().orgDetails;
  const name = orgDetails.orgData.name.value;
  const status = orgDetails.orgData.status.value;
  const creditsAllocated = orgDetails.orgData.creditsAllocated.value;

  return supportApi.orgz.updateOrg.bind(supportApi.orgz, orgId, creditsAllocated, name, status);
});
export const attemptUpdateOrganization = updateOrgAction.go;

// READER CREDENTIALS LIST
export function attemptRetrieveOrgCredentials(orgId: string): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    const params = getState().orgCreds.queryParams;
    
    dispatch(getOrgCredentialsRequest())
    const tracking = setupJobTrackingFor(orgActions.GET_ORG_CREDENTIALS_REQUEST);
    return supportApi.orgz.getOrgCredentials(orgId, params, tracking).then((credentialsResult: IPaginatedResponseCS<ICredentialCS, CredentialCS>) => {
      dispatch(getOrgCredentialsSuccess(credentialsResult))
      dispatch(updateOrgCredentialsTableMeta({ totalCount: credentialsResult.total_count, pageNum: credentialsResult.paginationSummary.currPage, pageSize: credentialsResult.paginationSummary.pageSize }))
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else if (err.status === 404) {
        const pagination = getState().orgCreds.queryParams;
        // if the api responds with a 404, we should fake success with 0 readers instead of erroring
        dispatch(getOrgCredentialsSuccess({
          total_count: 0, results: [], models: [], paginationSummary: {
            pageCount: 1,
            currPage: 1,
            totalCount: 0,
            pageSize: pagination.pageSize
          }
        }))
        dispatch(updateOrgCredentialsTableMeta({
          pageCount: 1,
          pageNum: 1,
          totalCount: 0,
          pageSize: pagination.pageSize
        }))
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'getOrgCredentialsFail',
          type: EMessageType.error,
          messageKeys: [err?.data?.Messages?.[0]]
        }));
        dispatch(getOrgCredentialsFailure())
      }
    });
  }
}


export function getOrgCredentialsRequest() {
  return {
    type: orgActions.GET_ORG_CREDENTIALS_REQUEST
  }
}

export function getOrgCredentialsSuccess(credentialsResult: IPaginatedResponseCS<ICredentialCS, CredentialCS>) {
  return {
    type: orgActions.GET_ORG_CREDENTIALS_SUCCESS,
    credentialsResult
  }
}

export function getOrgCredentialsFailure() {
  return {
    type: orgActions.GET_ORG_CREDENTIALS_FAILURE,
  }
}


export function updateOrgCredentialsQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: orgActions.UPDATE_ORG_CREDENTIALS_QUERY_PARAMS,
    queryParams
  }
}

export function updateOrgCredentialsTableMeta(meta: any) {
  return {
    type: orgActions.UPDATE_ORG_CREDENTIALS_TABLE_META,
    filters: meta.filters,
    sorts: meta.sorts,
    currPage: meta.pageNum,
    pageSize: meta.pageSize,
    totalCount: meta.totalCount
  }
}

// Org Admins
export function attemptRetrieveOrgAdmins(orgId: string): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    const params = getState().orgAdmins.queryParams;

    dispatch(getOrgAdminsRequest())
    const tracking = setupJobTrackingFor(orgActions.GET_ORG_ADMINS_REQUEST);
    return supportApi.user.getAdminsForOrg(orgId, params, tracking).then((adminsResult: IPaginatedResponseCS<IUserCS, UserCS>) => {
      dispatch(getOrgAdminsSuccess(adminsResult))
      dispatch(updateOrgAdminsTableMeta({ totalCount: adminsResult.total_count, pageNum: adminsResult.paginationSummary.currPage, pageSize: adminsResult.paginationSummary.pageSize }))
      return adminsResult;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else if (err.status === 404) {
        const pagination = getState().orgAdmins.queryParams;
        // if the api responds with a 404, we should fake success with 0 readers instead of erroring
        dispatch(getOrgAdminsSuccess({
          total_count: 0, results: [], models: [], paginationSummary: {
            pageCount: 1,
            currPage: 1,
            totalCount: 0,
            pageSize: pagination.pageSize
          }
        }))
        dispatch(updateOrgTableMeta({
          pageCount: 1,
          currPage: 1,
          totalCount: 0,
          pageSize: pagination.pageSize
        }))
      } else if (err.status ===  500) {
        // this particular endpoint is failing with a 500 when there are no admins found in the desired organization. 
        // it needs to be fixed in the backend to not fail on an association lookup when a mandatory piece is missing, 
        // however for now it is 500ing. When there are admins in the organization, the request succeeds, 
        // so we dont want to completely stop calling this endpoint, just fail silently instead of showing error messages.
        dispatch(getOrgAdminsFailure())
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'getOrgAdminsFail',
          type: EMessageType.error,
          messageKeys: [err?.data?.Messages?.[0]]
        }));
        dispatch(getOrgAdminsFailure())
      }
    })
  }
}

export function getOrgAdminsRequest() {
  return {
    type: orgActions.GET_ORG_ADMINS_REQUEST
  }
}

export function getOrgAdminsSuccess(adminsResult: IPaginatedResponseCS<IUserCS, UserCS>) {
  return {
    type: orgActions.GET_ORG_ADMINS_SUCCESS,
    adminsResult
  }
}

export function getOrgAdminsFailure() {
  return {
    type: orgActions.GET_ORG_ADMINS_FAILURE,
  }
}

export function updateOrgAdminsQueryParams(queryParams: IPaginatedParamsCS) {
  return {
    type: orgActions.UPDATE_ORG_ADMINS_QUERY_PARAMS,
    queryParams
  }
}

export function updateOrgAdminsTableMeta(meta: any) {
  return {
    type: orgActions.UPDATE_ORG_ADMINS_TABLE_META,
    filters: meta.filters,
    sorts: meta.sorts,
    currPage: meta.pageNum,
    pageSize: meta.pageSize,
    totalCount: meta.totalCount
  }
}
