import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  IUniTable_Filter,
  IUniTable_Column,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IUserCS,
  UserCS,
  EUserStatusCS,
  UniLocalize
} from '@unikey/unikey-commons/release/csupp';

import {
  navConfig, ENavPages,
  getTableSortDirection, getTableParamsFromUpdate,
  toggleModal,
  updateAdminsTableMeta,
  updateAdminListQueryParams,
  attemptRetrieveAdmins,
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  loading: boolean,
  history: any,
  match?: any,
  adminData: IPaginatedResponseCS<IUserCS, UserCS>,
  adminListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  showSubBrand?: boolean,
  updateQueryParams?(params: IPaginatedParamsCS): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  getAllAdmins(): Promise<void>
}

class AdminListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    
    this._updateTable = buildTableUpdateFunc(
      props.getAllAdmins,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getAllAdmins();
  }

  _nameOrPending(name: string, status: EUserStatusCS) {
    if (status === EUserStatusCS.invited) {
      return (<i><UniLocalize translate="_notApplicable" /></i>);
    } else if (name === "") {
      return (<i><UniLocalize translate="notProvided" /></i>);
    } else {
      return name;
    }
  }

  _buildColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('firstName', {
        nameKey: 'firstNameShort',
        filterName: 'first_name',
        sortFields: ['firstName', 'lastName'],
        isSortable: true,
        isFilterable: true,
        template: (rowItem: UserCS) => this._nameOrPending(rowItem.firstName, rowItem.status),
        size: this.props.showSubBrand ? 2 : 4,
      })
      .set('lastName', {
        nameKey: 'lastNameShort',
        filterName: 'last_name',
        sortFields: ['lastName', 'firstName'],
        isSortable: true,
        isFilterable: true,
        template: (rowItem: UserCS) => this._nameOrPending(rowItem.lastName, rowItem.status),
        size: 4
      })
      .set('email', {
        nameKey: 'email',
        isPrimaryFilter: true,
        isSortable: true,
        isFilterable: true,
        size: 6
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        type: 'enum-tagdot',
        enumType: EUserStatusCS,
        size: 4
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 4
      })
      .set('brand', {
        nameKey: 'brand',
        isSortable: false,
        isFilterable: false, // TODO: not available yet
        type: 'tag',
        evalItalicized: (rowItem: UserCS) => !rowItem.brand,
        size: this.props.showSubBrand ? 2 : 0,
        filterName: 'brandCode'
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        collapsed: true,
        size: 2
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: UserCS) => this.props.history.push(navConfig.get(ENavPages.adminDetails)!.linkTo([rowItem.id])!),
      evalDisbled: (rowItem: UserCS) => false,
      evalVisible: (rowItem: UserCS) => true
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='adminList-container'>
        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="adminsList"
          createButtonTextKey="admin"
          handleUpdate={this._updateTable}
          data={this.props.adminData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.loading} />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    adminData: state.admins.data,
    modalOpen: state.admins.modalOpen,
    adminListQueryParams: state.admins.queryParams,
    loading: state.admins.loading,
    appliedFilters: state.admins.tableFilters,
    appliedSorts: state.admins.tableSorts,
    paginationSummary: state.admins.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal,
  getAllAdmins: attemptRetrieveAdmins,
  updateTableMeta: updateAdminsTableMeta,
  updateQueryParams: updateAdminListQueryParams
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminListContainer)
    ), { componentName: 'AdminList' })
