import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col, Hidden } from 'react-grid-system'

import {
  UniTable,
  UniChart,
  UniKeyVal,
  UniLocalize,
  UniBackdrop,
  UniButton,
  UniToggle,
  UniConditionalRender,
  IStatResultsCS,
  IUniTable_Sort,
  IUniSelect_Option,
  IUniTable_Filter,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  IOverallStatsCS,
  IDeviceStatsCS
} from '@unikey/unikey-commons/release/csupp';

import {
  IChartOptions,
  EChartInterval,
  EChartAggregate,
  EChartTimePeriod,
  upadateChartOptions,
  changeMaximizedChart,
  attemptRetrieveDeviceStats,
  attemptRetrieveOverallStats,
  attemptRetrieveCurrentReadersPerOrgStats,
  attemptRetrieveCurrentOrgsPerDealerStats,
  attemptRetrieveCurrentCredentialsPerOrgStats,

  aggregateReaderStatBetweenDates,
  aggregateOrgStatBetweenDates,
  aggregateCredentialStatBetweenDates,
  aggregateCreditStatBetweenDates,
  aggregateDeviceStatBetweenDates,
  aggregateDealerStatBetweenDates,

  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,

  chartInterval: EChartInterval,
  chartStartDate: Date,
  chartEndDate: Date,
  chartTimePeriod: EChartTimePeriod,
  chartAggregate: EChartAggregate,
  maximizedChart: string,

  isUniKeyActor: boolean,
  loadingReader: boolean,
  readerChartConfig: any,
  loadingOrg: boolean,
  orgChartConfig: any,
  loadingCredential: boolean,
  credentialChartConfig: any,
  loadingCredit: boolean,
  creditChartConfig: any,
  loadingDevice: boolean,
  deviceChartConfig: any,
  loadingDealer: boolean,
  dealerChartConfig: any,

  loadingReadersPerOrg: boolean,
  readersPerOrgPieConfig: any,
  readersPerOrgHistogramConfig: any,
  readersPerOrgHistogramKeys: any,

  loadingCredentialsPerOrg: boolean,
  credentialsPerOrgPieConfig: any,
  credentialsPerOrgHistogramConfig: any,
  credentialsPerOrgHistogramKeys: any,

  loadingOrgsPerDealer: boolean,
  orgsPerDealerPieConfig: any,
  orgsPerDealerHistogramConfig: any,
  orgsPerDealerHistogramKeys: any,

  loadingOverall: boolean,
  overallChartConfig: any,
  overallData: IOverallStatsCS,

  loadingTotalDeviceStats: boolean,
  totalDeviceChartConfig: any,
  currentDeviceChartConfig: any,


  updateChartOptions(options: IChartOptions): void,
  changeMaximizedChart(name: string): void,

  getOverallStats(): void,
  getDeviceStats(): void,
  getAllTimeDeviceStats(): void,
  getCurrentReadersPerOrgStats(totalReaders: number): void,
  getCurrentCredentialsPerOrgStats(totalCreds: number): void,
  getCurrentOrgsPerDealerStats(totalOrgs: number): void,

  getAggregateReaderStats(start: Date, end: Date, intervalType: EChartInterval, aggregateEachInterval?: EChartAggregate): void,
  getAggregateOrgStats(start: Date, end: Date, intervalType: EChartInterval, aggregateEachInterval?: EChartAggregate): void,
  getAggregateCredentialStats(start: Date, end: Date, intervalType: EChartInterval, aggregateEachInterval?: EChartAggregate): void,
  getAggregateCreditStats(start: Date, end: Date, intervalType: EChartInterval, aggregateEachInterval?: EChartAggregate): void,
  getAggregateDeviceStats(start: Date, end: Date, intervalType: EChartInterval, aggregateEachInterval?: EChartAggregate): void,
  getAggregateDealerStats(start: Date, end: Date, intervalType: EChartInterval, aggregateEachInterval?: EChartAggregate): void,

}

class ReportsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {

    this.props.getOverallStats();
    this.props.getAllTimeDeviceStats();
    this.requestHistoricalChartData();
  }

  componentDidUpdate(prevProps: IProps) {
    const chartOptionsChange = prevProps.chartStartDate !== this.props.chartStartDate ||
      prevProps.chartEndDate !== this.props.chartEndDate ||
      prevProps.chartInterval !== this.props.chartInterval ||
      prevProps.chartAggregate !== this.props.chartAggregate;

    // if something about the chart options changed, re-populate the charts
    if (chartOptionsChange) {
      this.requestHistoricalChartData();
    }

    if (this.props.overallData.total_organizations !== prevProps.overallData.total_organizations) {
      this.props.getCurrentReadersPerOrgStats(this.props.overallData.total_readers);
      this.props.getCurrentCredentialsPerOrgStats(this.props.overallData.total_credentials);
      this.props.getCurrentOrgsPerDealerStats(this.props.overallData.total_organizations);

    }
  }

  setChartToFullScreen = (desiredFullScreenChartName: string) => {
    this.props.changeMaximizedChart(desiredFullScreenChartName === this.props.maximizedChart ? '' : desiredFullScreenChartName);
  }

  requestHistoricalChartData = () => {
    // stagger the results so that the view doesnt glitch so much rendering all the charts at once
    this.props.getAggregateReaderStats(this.props.chartStartDate, this.props.chartEndDate, this.props.chartInterval, this.props.chartAggregate);
    setTimeout(() => this.props.getAggregateOrgStats(this.props.chartStartDate, this.props.chartEndDate, this.props.chartInterval, this.props.chartAggregate), 400);
    setTimeout(() => this.props.getAggregateCredentialStats(this.props.chartStartDate, this.props.chartEndDate, this.props.chartInterval, this.props.chartAggregate), 800);
    setTimeout(() => this.props.getAggregateCreditStats(this.props.chartStartDate, this.props.chartEndDate, this.props.chartInterval, this.props.chartAggregate), 1200);
    setTimeout(() => this.props.getAggregateDeviceStats(this.props.chartStartDate, this.props.chartEndDate, this.props.chartInterval, this.props.chartAggregate), 1600);
    setTimeout(() => this.props.getAggregateDealerStats(this.props.chartStartDate, this.props.chartEndDate, this.props.chartInterval, this.props.chartAggregate), 2000);
  }

  toggleAggregateData = (newType: EChartAggregate) => {
    this.props.updateChartOptions({
      aggregate: newType
    });
  }

  handleIntervalChange = (interval: EChartInterval) => {
    this.props.updateChartOptions({
      interval
    });
  }

  handlePeriodChange = (periodType: EChartTimePeriod) => {

    const currentDate: Date = new Date();
    var start: Date = new Date(currentDate);
    var adjustAmt: number;
    const end: Date = currentDate;
    var newInterval: number;

    switch (periodType) {
      case EChartTimePeriod.oneMonth:
        adjustAmt = currentDate.getMonth() - 1;
        start = new Date(start.setMonth(adjustAmt));
        // reset interval if appropriate
        if (this.props.chartInterval > 1) {
          newInterval = 1
        }
        break;
      case EChartTimePeriod.threeMonths:
        adjustAmt = currentDate.getMonth() - 3;
        start = new Date(start.setMonth(adjustAmt));
        // reset interval if appropriate
        if (this.props.chartInterval > 3 || this.props.chartInterval < 2) {
          newInterval = 2
        }
        break;
      case EChartTimePeriod.sixMonths:
        adjustAmt = currentDate.getMonth() - 6;
        start = new Date(start.setMonth(adjustAmt));
        // reset interval if appropriate
        if (this.props.chartInterval < 2 || this.props.chartInterval > 3) {
          newInterval = 3;
        }
        break;
      case EChartTimePeriod.oneYear:
        adjustAmt = currentDate.getFullYear() - 1;
        start = new Date(start.setFullYear(adjustAmt));
        // reset interval if appropriate
        if (this.props.chartInterval < 3) {
          newInterval = 3;
        }
        break;
      case EChartTimePeriod.threeYears:
        adjustAmt = currentDate.getFullYear() - 3;
        start = new Date(start.setFullYear(adjustAmt));
        // reset interval if appropriate
        if (this.props.chartInterval < 3) {
          newInterval = 3;
        }
        break;
      default:
        // default is 1 year
        adjustAmt = currentDate.getFullYear() - 1;
        start = new Date(start.setFullYear(adjustAmt));
        newInterval = 3;
        break;
    }

    this.props.updateChartOptions({
      start: start!,
      // start: new Date(new Date().setMonth(-10)),
      end: end!,
      timePeriod: periodType,
      interval: Number.isInteger(newInterval!) ? newInterval! : this.props.chartInterval
    });
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const atAGlanceStats = Object.keys(this.props.overallData).map((statType: string) => {
      return (
        <Col sm={12} md={8} lg={6} xl={4} className="at-a-glance-stat" key={`at-a-glance-stat-${statType}`}>
          <UniKeyVal
            label={statType}
            stacked={true}
            primaryStateButtonSet={[]}
            secondaryStateButtonSet={[]}
            fields={[
              {
                keyName: statType,
                value: '' + Number((this.props.overallData as any)[statType]).toLocaleString()
              }
            ]} />
        </Col>
      )
    });

    return (
      <section className='reports-container'>
        <Row>

          <Col xs={24}>
            <h3><UniLocalize translate="partnerTotals" /></h3>
            <Row>
              {atAGlanceStats}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={10} lg={16} md={14} xs={24} >
            <h3><UniLocalize translate="overallData" /></h3>
            <UniChart
              showLoader={this.props.loadingOverall}
              titleKey="overallData"
              hideTitle={true}
              yLabel="Counts"
              bar={{
                dataSetKeys: this.props.overallChartConfig.dataSetKeys,
                data: this.props.overallChartConfig.data
              }} />
          </Col>

          <Col xl={6} lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="deviceDistribution" /></h3>
            <UniChart
              showLoader={this.props.loadingDevice}
              titleKey="allTimeDevices" 
              hideTitle={true}
              hideLegend={false}
              pie={{
                data: this.props.totalDeviceChartConfig
              }}/>
          </Col>

          {/* <Col xl={6}>
            <h3><UniLocalize translate="Averages"/></h3>
            <Row>
              <Col lg={8} xl={24} className="at-a-glance-stat">
                <UniKeyVal
                  label="credentialsPerDevice"
                  stacked={true}
                  primaryStateButtonSet={[]}
                  secondaryStateButtonSet={[]}
                  fields={[
                    {
                      keyName: "credentialsPerDevice",
                      value: '' + Number(this.props.overallData.total_credentials / this.props.overallData.total_devices).toLocaleString()
                    }
                  ]} />
              </Col>

              <Col lg={8} xl={24} className="at-a-glance-stat">
                <UniKeyVal
                  label="credentialsPerReader"
                  stacked={true}
                  primaryStateButtonSet={[]}
                  secondaryStateButtonSet={[]}
                  fields={[
                    {
                      keyName: "credentialsPerReader",
                      value: '' + Number(this.props.overallData.total_credentials / this.props.overallData.total_readers).toLocaleString()
                    }
                  ]} />
              </Col>

              <Col lg={8} xl={24} className="at-a-glance-stat">
                <UniKeyVal
                  label="readersPerOrganization"
                  stacked={true}
                  primaryStateButtonSet={[]}
                  secondaryStateButtonSet={[]}
                  fields={[
                    {
                      keyName: "readersPerOrganization",
                      value: '' + Number(this.props.overallData.total_readers / this.props.overallData.total_organizations).toLocaleString()
                    }
                  ]} />
              </Col>
            </Row>

          </Col> */}
        </Row>

        <Row>
          <Col lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="readerDistribution" /></h3>
            <UniChart
              showLoader={this.props.loadingReadersPerOrg}
              titleKey="readersPerOrg"
              hideTitle={true}
              hideLegend={true}
              pie={{
                data: this.props.readersPerOrgPieConfig
              }} />
          </Col>

          <Col lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="credentialDistribution" /></h3>
            <UniChart
              showLoader={this.props.loadingCredentialsPerOrg}
              titleKey="credentialsPerOrg"
              hideTitle={true}
              hideLegend={true}
              pie={{
                data: this.props.credentialsPerOrgPieConfig
              }} />
          </Col>

          <Col lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="orgDistribution" /></h3>
            <UniChart
              showLoader={this.props.loadingOrgsPerDealer}
              titleKey="orgsPerDealer"
              hideTitle={true}
              hideLegend={true}
              pie={{
                data: this.props.orgsPerDealerPieConfig
              }} />
          </Col>
        </Row>
        {/* histogram for above data */}
        <Row>
          <Col lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="readerHistogram" /></h3>
            <UniChart
              showLoader={this.props.loadingReadersPerOrg}
              titleKey="readersInOrgs"
              hideTitle={true}
              hideLegend={true}
              xLabel="# of Readers in Orgs"
              yLabel="Frequency"
              bar={{
                dataSetKeys: ['value'],
                data: this.props.readersPerOrgHistogramConfig
              }} />
          </Col>

          <Col lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="credentialHistogram" /></h3>
            <UniChart
              showLoader={this.props.loadingCredentialsPerOrg}
              titleKey="credentialsInOrgs"
              hideTitle={true}
              hideLegend={true}
              xLabel="# of Credentials in Orgs"
              yLabel="Frequency"
              bar={{
                dataSetKeys: ['value'],
                data: this.props.credentialsPerOrgHistogramConfig
              }} />
          </Col>

          <Col lg={8} md={8} xs={24} >
            <h3><UniLocalize translate="orgHistogram" /></h3>
            <UniChart
              showLoader={this.props.loadingOrgsPerDealer}
              titleKey="orgsInDealers"
              hideTitle={true}
              xLabel="# of Orgs in Dealers"
              yLabel="Frequency"
              hideLegend={true}
              bar={{
                dataSetKeys: ['value'],
                data: this.props.orgsPerDealerHistogramConfig
              }} />
          </Col>
        </Row>

        <Row>
          <Col sm={24}>
            <h3><UniLocalize translate="historicalChartOptions" /></h3>
            <section className="report-controls">
              <Row>
                <Col md={6}>
                  <h4><UniLocalize translate="displayType" /></h4>
                  <UniToggle
                    size="sm"
                    options={[
                      { value: EChartAggregate.cumulative, nameKey: 'cumulative' },
                      { value: EChartAggregate.isolated, nameKey: 'isolated' }
                    ]}
                    value={this.props.chartAggregate}
                    handleUpdate={this.toggleAggregateData} />
                </Col>

                <Col md={8}>
                  <h4><UniLocalize translate="timePeriod" /></h4>
                  <UniToggle
                    size="sm"
                    options={[
                      { value: EChartTimePeriod.oneMonth, nameKey: 'oneMonth' },
                      { value: EChartTimePeriod.threeMonths, nameKey: 'threeMonths' },
                      { value: EChartTimePeriod.sixMonths, nameKey: 'sixMonths' },
                      { value: EChartTimePeriod.oneYear, nameKey: 'oneYear' },
                      { value: EChartTimePeriod.threeYears, nameKey: 'threeYears' }
                    ]}
                    value={this.props.chartTimePeriod}
                    handleUpdate={this.handlePeriodChange} />
                </Col>

                <Col md={8}>
                  <h4><UniLocalize translate="interval" /></h4>
                  <UniToggle
                    size="sm"
                    options={[
                      { value: EChartInterval.daily, nameKey: 'daily', disabled: this.props.chartTimePeriod !== EChartTimePeriod.oneMonth },
                      { value: EChartInterval.weekly, nameKey: 'weekly', disabled: this.props.chartTimePeriod > EChartTimePeriod.sixMonths },
                      { value: EChartInterval.monthly, nameKey: 'monthly', disabled: this.props.chartTimePeriod === EChartTimePeriod.oneMonth },
                      { value: EChartInterval.quarterly, nameKey: 'quarterly', disabled: this.props.chartTimePeriod <= EChartTimePeriod.sixMonths }
                    ]}
                    value={this.props.chartInterval}
                    handleUpdate={this.handleIntervalChange} />
                </Col>

              </Row>

            </section>
          </Col>
        </Row>

        <Row>
          <Col xl={8} lg={12}>
            <h3><UniLocalize translate="historicalReaderStats" /></h3>
            <UniChart
              titleKey="readerStats"
              hideTitle={true}
              showLoader={this.props.loadingReader}
              area={{
                dataSetKeys: this.props.readerChartConfig.dataSetKeys,
                data: this.props.readerChartConfig.entries
              }}
              handleOpenFullScreen={this.setChartToFullScreen} />
          </Col>

          <Col xl={8} lg={12}>
            <h3><UniLocalize translate="historicalOrganizationStats" /></h3>
            <UniChart
              titleKey="orgStats"
              hideTitle={true}
              showLoader={this.props.loadingOrg}
              area={{
                dataSetKeys: this.props.orgChartConfig.dataSetKeys,
                data: this.props.orgChartConfig.entries
              }}
              handleOpenFullScreen={this.setChartToFullScreen} />
          </Col>

          <Col xl={8} lg={12}>
            <h3><UniLocalize translate="historicalCredentialStats" /></h3>
            <UniChart
              titleKey="credStats"
              hideTitle={true}
              showLoader={this.props.loadingCredential}
              area={{
                dataSetKeys: this.props.credentialChartConfig.dataSetKeys,
                data: this.props.credentialChartConfig.entries
              }}
              handleOpenFullScreen={this.setChartToFullScreen} />
          </Col>

          <Col xl={8} lg={12}>
            <h3><UniLocalize translate="historicalCreditStats" /></h3>
            <UniChart
              titleKey="creditStats"
              hideTitle={true}
              showLoader={this.props.loadingCredit}
              area={{
                dataSetKeys: this.props.creditChartConfig.dataSetKeys,
                data: this.props.creditChartConfig.entries
              }}
              timeseries={true}
              handleOpenFullScreen={this.setChartToFullScreen} />
          </Col>

          <Col xl={8} lg={12}>
            <h3><UniLocalize translate="historicalDeviceStats" /></h3>
            <UniChart
              titleKey="deviceStats"
              hideTitle={true}
              showLoader={this.props.loadingDevice}
              area={{
                dataSetKeys: this.props.deviceChartConfig.dataSetKeys,
                data: this.props.deviceChartConfig.entries
              }}
              handleOpenFullScreen={this.setChartToFullScreen} />
          </Col>

          <Col xl={8} lg={12}>
            <h3><UniLocalize translate="historicalDealerStats" /></h3>
            <UniChart
              titleKey="dealerStats"
              hideTitle={true}
              showLoader={this.props.loadingDealer}
              area={{
                dataSetKeys: this.props.dealerChartConfig.dataSetKeys,
                data: this.props.dealerChartConfig.entries
              }}
              handleOpenFullScreen={this.setChartToFullScreen} />
          </Col>
        </Row>

        {/* Maximized Chart */}
        <UniConditionalRender visible={this.props.maximizedChart !== ''}>
          <UniBackdrop handleClick={() => this.setChartToFullScreen('')}>
            <Container>
              <Row className="maximized-chart" onClick={(e) => e.stopPropagation()}>
                <Col xs={24} >

                  <UniConditionalRender visible={this.props.maximizedChart === 'readerStats'}>
                    <h3><UniLocalize translate="historicalReaderStats" /></h3>
                    <UniChart
                      showLoader={this.props.loadingReader}
                      titleKey="readerStats"
                      hideTitle={true}
                      height={350}
                      area={{
                        dataSetKeys: this.props.readerChartConfig.dataSetKeys,
                        data: this.props.readerChartConfig.entries
                      }}
                      handleCloseFullScreen={this.setChartToFullScreen} />
                  </UniConditionalRender>

                  <UniConditionalRender visible={this.props.maximizedChart === 'orgStats'}>
                    <h3><UniLocalize translate="historicalOrganizationStats" /></h3>
                    <UniChart
                      showLoader={this.props.loadingOrg}
                      titleKey="orgStats"
                      hideTitle={true}
                      height={350}
                      area={{
                        dataSetKeys: this.props.orgChartConfig.dataSetKeys,
                        data: this.props.orgChartConfig.entries
                      }}
                      handleCloseFullScreen={this.setChartToFullScreen} />
                  </UniConditionalRender>

                  <UniConditionalRender visible={this.props.maximizedChart === 'credStats'}>
                    <h3><UniLocalize translate="historicalCredentialStats" /></h3>
                    <UniChart
                      showLoader={this.props.loadingCredential}
                      titleKey="credStats"
                      hideTitle={true}
                      height={350}
                      area={{
                        dataSetKeys: this.props.credentialChartConfig.dataSetKeys,
                        data: this.props.credentialChartConfig.entries
                      }}
                      handleCloseFullScreen={this.setChartToFullScreen} />
                  </UniConditionalRender>

                  <UniConditionalRender visible={this.props.maximizedChart === 'creditStats'}>
                    <h3><UniLocalize translate="historicalCreditStats" /></h3>
                    <UniChart
                      showLoader={this.props.loadingCredit}
                      titleKey="creditStats"
                      hideTitle={true}
                      height={350}
                      area={{
                        dataSetKeys: this.props.creditChartConfig.dataSetKeys,
                        data: this.props.creditChartConfig.entries
                      }}
                      handleCloseFullScreen={this.setChartToFullScreen} />
                  </UniConditionalRender>

                  <UniConditionalRender visible={this.props.maximizedChart === 'deviceStats'}>
                    <h3><UniLocalize translate="historicalDeviceStats" /></h3>
                    <UniChart
                      titleKey="deviceStats"
                      hideTitle={true}
                      height={350}
                      showLoader={this.props.loadingDevice}
                      area={{
                        dataSetKeys: this.props.deviceChartConfig.dataSetKeys,
                        data: this.props.deviceChartConfig.entries
                      }}
                      handleCloseFullScreen={this.setChartToFullScreen} />
                  </UniConditionalRender>

                  <UniConditionalRender visible={this.props.maximizedChart === 'dealerStats'}>
                    <h3><UniLocalize translate="historicalDealerStats" /></h3>
                    <UniChart
                      showLoader={this.props.loadingReader}
                      titleKey="dealerStats"
                      hideTitle={true}
                      height={350}
                      area={{
                        dataSetKeys: this.props.dealerChartConfig.dataSetKeys,
                        data: this.props.dealerChartConfig.entries
                      }}
                      handleCloseFullScreen={this.setChartToFullScreen} />
                  </UniConditionalRender>

                  <section className="report-controls">
                    <Row>
                      <Col md={6}>
                        <h4><UniLocalize translate="displayType" /></h4>
                        <UniToggle
                          size="sm"
                          options={[
                            { value: EChartAggregate.cumulative, nameKey: 'cumulative' },
                            { value: EChartAggregate.isolated, nameKey: 'isolated' }
                          ]}
                          value={this.props.chartAggregate}
                          handleUpdate={this.toggleAggregateData} />
                      </Col>

                      <Col md={8}>
                        <h4><UniLocalize translate="timePeriod" /></h4>
                        <UniToggle
                          size="sm"
                          options={[
                            { value: EChartTimePeriod.oneMonth, nameKey: 'oneMonth' },
                            { value: EChartTimePeriod.threeMonths, nameKey: 'threeMonths' },
                            { value: EChartTimePeriod.sixMonths, nameKey: 'sixMonths' },
                            { value: EChartTimePeriod.oneYear, nameKey: 'oneYear' },
                            { value: EChartTimePeriod.threeYears, nameKey: 'threeYears' }
                          ]}
                          value={this.props.chartTimePeriod}
                          handleUpdate={this.handlePeriodChange} />
                      </Col>

                      <Col md={8}>
                        <h4><UniLocalize translate="interval" /></h4>
                        <UniToggle
                          size="sm"
                          options={[
                            { value: EChartInterval.daily, nameKey: 'daily', disabled: this.props.chartTimePeriod !== 1 },
                            { value: EChartInterval.weekly, nameKey: 'weekly', disabled: this.props.chartTimePeriod > 3 },
                            { value: EChartInterval.monthly, nameKey: 'monthly', disabled: this.props.chartTimePeriod === 1 },
                            { value: EChartInterval.quarterly, nameKey: 'quarterly', disabled: this.props.chartTimePeriod <= 3 }
                          ]}
                          value={this.props.chartInterval}
                          handleUpdate={this.handleIntervalChange} />
                      </Col>
                    </Row>
                  </section>

                </Col>
              </Row>
            </Container>
          </UniBackdrop>
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,

    maximizedChart: state.statsOptions.maximizedChart,

    chartEndDate: state.statsOptions.endDate,
    chartStartDate: state.statsOptions.startDate,
    chartInterval: state.statsOptions.interval,
    chartTimePeriod: state.statsOptions.timePeriod,
    chartAggregate: state.statsOptions.chartAggregate,

    loadingReader: state.readerStats.loading,
    readerChartConfig: state.readerStats.chartConfig,
    loadingOrg: state.orgStats.loading,
    orgChartConfig: state.orgStats.chartConfig,
    loadingCredit: state.creditStats.loading,
    creditChartConfig: state.creditStats.chartConfig,
    loadingDevice: state.deviceStats.loading,
    deviceChartConfig: state.deviceStats.chartConfig,
    loadingCredential: state.credentialStats.loading,
    credentialChartConfig: state.credentialStats.chartConfig,
    loadingDealer: state.dealerStats.loading,
    dealerChartConfig: state.dealerStats.chartConfig,

    loadingReadersPerOrg: state.readersPerOrgStats.loading,
    readersPerOrgPieConfig: state.readersPerOrgStats.pie,
    readersPerOrgHistogramConfig: state.readersPerOrgStats.histogram,
    readersPerOrgHistogramKeys: state.readersPerOrgStats.histogramKeys,
    
    loadingCredentialsPerOrg: state.credentialsPerOrgStats.loading,
    credentialsPerOrgPieConfig: state.credentialsPerOrgStats.pie,
    credentialsPerOrgHistogramConfig: state.credentialsPerOrgStats.histogram,
    credentialsPerOrgHistogramKeys: state.credentialsPerOrgStats.histogramKeys,
    
    loadingOrgsPerDealer: state.orgsPerDealerStats.loading,
    orgsPerDealerPieConfig: state.orgsPerDealerStats.pie,
    orgsPerDealerHistogramConfig: state.orgsPerDealerStats.histogram,
    orgsPerDealerHistogramKeys: state.orgsPerDealerStats.histogramKeys,

    loadingTotalDeviceStats: state.deviceStats.loading,
    totalDeviceChartConfig: state.deviceStats.totalDeviceChartConfig,

    loadingOverall: state.overallStats.loading,
    overallChartConfig: state.overallStats.chartConfig,
    overallData: state.overallStats.data,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateChartOptions: upadateChartOptions,
  changeMaximizedChart,

  getAllTimeDeviceStats: attemptRetrieveDeviceStats.bind(null, new Date(2010, 1, 1), new Date()),
  getOverallStats: attemptRetrieveOverallStats,
  getCurrentReadersPerOrgStats: attemptRetrieveCurrentReadersPerOrgStats,
  getCurrentCredentialsPerOrgStats: attemptRetrieveCurrentCredentialsPerOrgStats,
  getCurrentOrgsPerDealerStats: attemptRetrieveCurrentOrgsPerDealerStats,

  getAggregateOrgStats: aggregateOrgStatBetweenDates,
  getAggregateReaderStats: aggregateReaderStatBetweenDates,
  getAggregateCredentialStats: aggregateCredentialStatBetweenDates,
  getAggregateCreditStats: aggregateCreditStatBetweenDates,
  getAggregateDeviceStats: aggregateDeviceStatBetweenDates,
  getAggregateDealerStats: aggregateDealerStatBetweenDates,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReportsContainer)
    ), { componentName: 'Reports' })
