import { Dispatch } from 'redux'

import {
  EMessageType,
  IPaginatedResponseCS
} from '@unikey/unikey-commons/release/csupp'

import {
  addAlert,
  portalRedirect,
  redirectToLogout,
  setupJobTrackingFor,
  checkJobStatusOnInterval,
  oops403Key
} from '../internal';


export interface IApiActions {
  request: () => void
  success: () => void
  failure: () => void
}

export interface IActionBuilderCustomizations {
  type: string,
  title?: string,
  message?: string,
  duration?: number,
  delay?: number,
  preventAlert?: boolean,
  after?: (dux: IExposeRedux) => void
}

export interface IApiActionSetNames {
  request: IActionBuilderCustomizations,
  success: IActionBuilderCustomizations,
  failure: IActionBuilderCustomizations,
  tableMetaUpdate?: IActionBuilderCustomizations,
  handle404?: (error?: any) => void,
  checkNumPendingReqs?: (requestName: string) => number,
  edit?: IActionBuilderCustomizations,
  onInterval?: IActionBuilderCustomizations,
}

export interface IExposeRedux {
  getState: () => any,
  dispatch: any,
}

export class ApiReduxAction<T> {
  requestAction: IActionBuilderCustomizations;
  successAction: IActionBuilderCustomizations;
  failureAction: IActionBuilderCustomizations;
  tableMetaAction?: IActionBuilderCustomizations;
  actionOnInterval?: IActionBuilderCustomizations;
  raiseAction?: Dispatch<any>;
  getState?: any;
  request?: (getState: any, requestParams?: any) => (...a: any) => Promise<T>;
  handle404?: (error?: any) => void;
  getNumPendingReqs?: (requestName: string) => number;
  clearJobStatusInterval?: () => void;

  constructor(actions: IApiActionSetNames, apiCall?: (dux: IExposeRedux, params?: any) => (...input: any) => Promise<T>) {

    this.requestAction = actions.request;
    this.successAction = actions.success;
    this.failureAction = actions.failure;
    this.tableMetaAction = actions.tableMetaUpdate;
    this.request = apiCall;
    this.handle404 = actions.handle404;
    this.getNumPendingReqs = actions.checkNumPendingReqs;
    this.actionOnInterval = actions.onInterval;
  }

  setApiRequest = (req: any) => {
    this.request = req;
  }

  // invokes the action cycle
  go = (requestParams: any = {}): any => {
    return (dispatch: Dispatch<any>, getState: any): Promise<any> => {
      this.raiseAction = dispatch;
      this.getState = getState;
      
      
      const tracking = setupJobTrackingFor(this.requestAction.type);
      // add the tracking argument as the final argument and send the request
      // earlier arguments are bound in the actions file 
      // when creating this ApiReduxAction instance
      const outgoing = this.request!({ dispatch, getState }, requestParams)(tracking)
      .then(this.success, this.failure);
      
      this.raiseAction!({
        type: this.requestAction.type,
        pending: (this.getNumPendingReqs?.(this.requestAction.type) || 0) > 0,
        requestParams,
      })

      // start the interval tracking if the request config references one
      if (this.actionOnInterval) {
        this.clearJobStatusInterval = checkJobStatusOnInterval(
          this.raiseAction.bind(null, {
            ...this.actionOnInterval,
            numPending: this.getNumPendingReqs?.(this.requestAction.type) || 0
          }),
          this.actionOnInterval?.duration);
      }

      return outgoing;
    };
  }

  success = (value: T) => {
    this.raiseAction!({
      value,
      type: this.successAction.type,
      pending: (this.getNumPendingReqs?.(this.requestAction.type) || 0) > 0,
    })
    this.clearJobStatusInterval?.();

    if (this.successAction.title) {
      this.raiseAction!(addAlert({
        id: Date.now(),
        type: EMessageType.success,
        titleKey: this.successAction.title ?? '_genericSuccessMessage',
        messageKeys: [this.successAction.message],
        duration: this.successAction.duration,
      }));
    }
    
    if (this.tableMetaAction) {
      const tableResults = (value as any) as IPaginatedResponseCS<any, any>;
      this.raiseAction!({
        type: this.tableMetaAction.type,
        totalCount: tableResults.total_count,
        currPage: tableResults.paginationSummary.currPage,
        pageSize: tableResults.paginationSummary.pageSize
      })
      
    }
    return Promise.resolve(value);
  }

  failure = (err: any) => {
    if (err?.error === 'frontend_stale_request') {
      return;
    }

    const errorMessageKeys = [];
    if (this.failureAction.message) {
      errorMessageKeys.push(...[this.failureAction.message]);
    }
    if (err?.error_description) {
      errorMessageKeys.push(err.error_description);
    }
    if (err?.data) {
      errorMessageKeys.push(err.data.error);
    }
    if (err?.message) {
      errorMessageKeys.push(err.message);
    } 
    if (err?.status === 403) {
      portalRedirect(oops403Key);
    }

    if (!this.failureAction.preventAlert) {
      this.raiseAction!(addAlert({
        id: Date.now(),
        type: EMessageType.error,
        titleKey: this.failureAction.title ?? '_genericErrorMessage',
        messageKeys: errorMessageKeys,
        duration: this.failureAction.duration,
      }));
    }

    this.raiseAction!({
      type: this.failureAction.type,
      pending: (this.getNumPendingReqs?.(this.requestAction.type) || 0) > 0,
    })


    this.clearJobStatusInterval?.();

    return Promise.reject(err);
  }

  dispatcher = (dispatch: Dispatch<any>, getState: any) => {
    this.raiseAction = dispatch;
    this.getState = getState;
    return this.go;
  }
}