import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames';

import {
  UniRating,
  Editable,
  UniWorkflow,
  UniInput,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  IMultiInputUpdate,
  EStarRatings,
} from '@unikey/unikey-commons/release/csupp';

import partnerConfig from '@alias-current-partner-customizations';

import {
  appInsights,
  updateFeedback,
  toggleFeedbackModal,
  resetFeedbackForm,
  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  feedbackRating: EStarRatings,
  feedbackText: Editable<string>,
  loading: boolean,
  authUsername: string,
  authUserId: string,
  submitFeedback(): void,
  updateFeedback(update: IMultiInputUpdate): void,
  toggleFeedbackModal(): void,
  resetFeedbackForm(): void,
}

class FeedbackContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  _toggleAndResetFeedbackForm = () => {
    this.props.resetFeedbackForm();
  }
  
  
  _sendFeedback = () => {
    this.props.updateFeedback({ loading: true });
    appInsights.trackEvent(
      { name: 'feedback' },
      { 
        reason: this.props.feedbackText.value,
        score: this.props.feedbackRating,
        username: this.props.authUsername,
        userId: this.props.authUserId
      });

    setTimeout(() => {
      this._toggleAndResetFeedbackForm();
    }, 800);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <UniWorkflow
        inModal
        titleKey="feedback"
        titleIcon="star" 
        handleClose={this._toggleAndResetFeedbackForm}>

        <p><strong><UniLocalize translate="weWantToHearFromYou" /></strong></p>
        <p><strong><UniLocalize translate="provideFeedbackOnYourExperience" /></strong></p>
        
        <UniInput
          textarea={true}
          focusOnInitialRender={true}
          editable={this.props.feedbackText}
          labelKey="feedback"
          placeholderKey="yourFeedbackHere"
          handleUpdate={(reason: Editable<string>) => { this.props.updateFeedback({ reason }); }} />
        <br />

        <UniRating
          max={EStarRatings.fiveStar}
          size="md" 
          labelKey="pleaseRateYourExperience"
          rating={this.props.feedbackRating}
          handleUpdate={(rating: EStarRatings) => { this.props.updateFeedback({ rating })} } />
        <br />

        {/* step actions */}
        <UniOverlapGroup>

          <UniOverlapButton
            handleClick={() => this._sendFeedback()}
            textKey="save"
            icon="check"
            showLoader={this.props.loading}
            disabled={this.props.feedbackRating === EStarRatings.unknown || !this.props.feedbackText.valid}
            tooltipPosition="right" />

          <UniOverlapButton
            handleClick={() => { this._toggleAndResetFeedbackForm(); }}
            textKey="cancel"
            icon="close"
            secondary={true}
            tooltipPosition="right" />

        </UniOverlapGroup>
        
      </UniWorkflow>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    feedbackText: state.feedbackModal.reason,
    feedbackRating: state.feedbackModal.rating,
    loading: state.feedbackModal.loading,
    authUsername: state.authenticatedUser?.username,
    authUserId: state.authenticatedUser?.userId,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateFeedback,
  toggleFeedbackModal,
  resetFeedbackForm
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(FeedbackContainer)
    ), { componentName: 'Feedback' })
