import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client'
import { decodeJwt } from '@unikey/unikey-commons/release/csupp';
import { setConfiguration } from 'react-grid-system';
import {
  supportApi,
  oidcAuthority,
  oidcClientId,
  oidcClientSecret,
  oidcRedirectUri,
  oidcScope,
  mockAuth,
  buildOidcAcrValues,
  devLogger,
  portalRedirect,
  clearAllAuthValues,
  logoutKey
} from '../../internal';

setConfiguration({ 
  maxScreenClass: 'xxxl',
  breakpoints: [576, 768, 992, 1200, 1660, 2410],
  containerWidths: [540, 740, 960, 1140, 1580, 1840],
  gridColumns: 24
});

export const userManager = createUserManager({
  authority: oidcAuthority,
  client_id: oidcClientId,
  client_secret: oidcClientSecret,
  redirect_uri: oidcRedirectUri,
  accessTokenExpiringNotificationTime: 30, // 30 seconds till expiration
  automaticSilentRenew: !mockAuth,
  silentRequestTimeout: 4000,
  scope: oidcScope,
  response_type: 'code',
  prompt: 'login',
  // revokeAccessTokenOnSignout: true,
  staleStateAge: 600,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  // when true this attempts to fetch the user data from a preconfigured 
  // route in identity server after the authentication happens. 
  // currently failing since we don thave the separate route setup.
  // we want to request from backend anyways. 
  loadUserInfo: false,
  acr_values: buildOidcAcrValues(),
});

export const handleRefreshError = (reason: any) => {
  clearAllAuthValues();
  portalRedirect({
    pathname: logoutKey
  });

  console.warn(JSON.stringify(reason));
  return Promise.reject(reason);
}

export const requestRefreshToken = () => {
  // only request a refresh token if we are in the authenticated section of the portal
  // otherwise it will kick us out from unauthenticated pages (ex: version, legal, etc)
  if (/\#\/portal/.test(window.location.hash)) {
    return userManager.signinSilent().then(handleTokenRefreshed, handleRefreshError);
  }
  return Promise.resolve();
}

export const handleTokenRefreshed = async (user: any): Promise<string> => {
  devLogger(`userManager - refreshed token at: ${new Date().toLocaleTimeString()}`);
  devLogger(`refreshed token - ${user.access_token.slice(-10)}`);
  const decoded: any = decodeJwt(user.access_token);
  const userId = decoded?.sub || user.profile?.sub;
  const loginMethod = decoded?.idp || user.profile?.idp;
  const loginIdpUserId = decoded?.idp_sub || user.profile?.idp_sub;
  const deviceId = decoded?.device_id || user.profile?.device_id;

  supportApi.setAccessToken(user.access_token, userId, deviceId, user.refresh_token, loginMethod, loginIdpUserId);
  return userId;
};


// load the new jwt and refresh tokens each time they are refreshed
userManager.events.addUserLoaded(handleTokenRefreshed);

userManager.events.addSilentRenewError((err: any) => {
  devLogger('userManager - silent renew token error');
  
  // will redirect to login
  handleRefreshError(err);

  console.warn(JSON.stringify(err));
  return err;
});

userManager.events.addAccessTokenExpired((err: any) => {
  devLogger('token - the access token has expired');
  console.warn(JSON.stringify(err));
  
  // if the token is expired use our helper to request a new token 
  // using the refresh token handle any error appropriately
  requestRefreshToken();
});


// refresh the token right away on page load or page refresh
setTimeout(requestRefreshToken, 600);


// --- other events supported ---
// userLoaded: Raised when a user session has been established (or re-established).
// userUnloaded: Raised when a user session has been terminated.
// accessTokenExpiring: Raised prior to the access token expiring.
// accessTokenExpired: Raised after the access token has expired.
// silentRenewError: Raised when the automatic silent renew has failed.
// userSignedIn [1.9.0]: Raised when the user is signed in.
// userSignedOut [1.1.0]: Raised when the user's sign-in status at the OP has changed.
// userSessionChanged: Raised 