import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniKeyVal,
  UniConditionalRender,
  CredentialCS,
  ICredentialCapabilityCS,
  ECapabilityStatus,
  ECapabilityType,
  ECredentialStatus,
  ECredentialType,
  IPartnerCredImpls,
  UniTable,
  IUniTable_Column,
  IUniTable_Action,
  EDeviceType,
  EDeviceStatus,
  EDeviceCredLinkStatus,
  DeviceCS,
  EUniTable_FooterStyle,
} from '@unikey/unikey-commons/release/csupp';

import {
  IGetSingleCredentialActionParams,
  attemptRetrieveCredentialById,
  CredentialInvitationDetailsContainer,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig,
  ENavPages
} from '../internal';


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  credential: CredentialCS,
  loading: boolean,
  match: any,
  partnerCredImpls: IPartnerCredImpls,
  redeemedDevices: DeviceCS[],
  getCredentialById(params: IGetSingleCredentialActionParams): void
}

class CredentialDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getCredentialById({ credId: this.props.match.params.credentialId, partnerCredImpls: this.props.partnerCredImpls });

  }

  _getCredTypeNameKey(cred: CredentialCS) {
    return cred.id ? cred.getTypeNameKey() : '';
  }

  _getCredStatusNameKey(cred: CredentialCS) {
    return cred.id ? cred.getStatusNameKey() : '';
  }
  
  _isCredentialLinkInactive = (rowItem: CredentialCS) => {
    return rowItem.deviceCredLinkStatus === EDeviceCredLinkStatus.disabled || rowItem.deviceCredLinkStatus === EDeviceCredLinkStatus.revoked;
  }

  _buildColumnsAndActions = () => {

    const columns = new Map<string, IUniTable_Column>()
      .set('deviceType', {
        nameKey: 'type',
        type: 'enum',
        enumType: EDeviceType,
        filterName: 'device_type',
        size: 4,
        evalItalicized: this._isCredentialLinkInactive
      })
      .set('osVersion', {
        nameKey: 'osVersion',
        type: 'string',
        size: 4,
        evalItalicized: this._isCredentialLinkInactive
      })
      .set('deviceCredLinkStatus', {
        nameKey: 'deviceCredLinkStatus',
        type: 'enum',
        enumType: EDeviceCredLinkStatus,
        size: 4,
        evalItalicized: this._isCredentialLinkInactive
      })
      .set('created', {
        nameKey: 'created',
        type: 'date',
        size: 4,
        evalItalicized: this._isCredentialLinkInactive
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 4,
        evalItalicized: this._isCredentialLinkInactive
      })

    const actions = new Map<string, IUniTable_Action>();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.deviceDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: DeviceCS) => false,
      evalVisible: (rowItem: DeviceCS) => true
    })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const capabilityChipCollection = (this.props.credential.capabilities || []).reduce((capMap: Map<string, any>, cap: ICredentialCapabilityCS, index: number) => {
      if (ECapabilityStatus.available === cap.status) {
        capMap.set(`capability-${index}`, {
          nameKey: ECapabilityType[cap.capability]
        });
      }
      return capMap;
    }, new Map())

    
    const { columns, actions } = this._buildColumnsAndActions();
    

    return (
      <section className='myAccount-container'>
        <Row>
          <Col>
            <h3 className="page-title-non-table">{this.props.credential.email}</h3>
          </Col>
        </Row>

        <UniConditionalRender visible={!!this.props.credential.id}>
          <UniKeyVal
            label="credential"
            showLoader={this.props.loading}
            primaryStateButtonSet={[]}
            secondaryStateButtonSet={[]}
            fields={[
              {
                keyName: 'id',
                value: this.props.credential.id,
                type: 'string',
                disabled: true
              },
              {
                keyName: 'email',
                value: this.props.credential.email,
                type: 'string',
                disabled: true
              },
              {
                keyName: 'label',
                value: '' + this.props.credential.label,
                type: 'string',
                disabled: true
              },
              {
                keyName: 'credentialStatus',
                value: '' + this.props.credential.status,
                type: 'enum',
                enumType: ECredentialStatus,
                disabled: true
              },
              {
                keyName: 'credentialType',
                value: this.props.credential.type,
                type: 'enum',
                enumType: ECredentialType,
                disabled: true
              },
              {
                keyName: 'facilityCode',
                value: `${this.props.credential.facilityCode}`,
                type: 'string',
                disabled: true,
                hidden: this.props.credential.type === ECredentialType.unikeyV1 || this.props.credential.type === ECredentialType.raw
              },
              {
                keyName: 'cardNumber',
                value: `${this.props.credential.cardNumber}`,
                type: 'string',
                disabled: true,
                hidden: this.props.credential.type === ECredentialType.unikeyV1 || this.props.credential.type === ECredentialType.raw
              },
              {
                keyName: 'credentialDataBitLength',
                value: `${this.props.credential.credentialDataBitLength}`,
                type: 'string',
                disabled: true,
                hidden: !(this.props.credential.type === ECredentialType.unikeyV1 || this.props.credential.type === ECredentialType.raw)
              },
              {
                keyName: 'credentialDataHex',
                value: `${this.props.credential.credentialDataHex}`,
                type: 'string',
                disabled: true,
                hidden: !(this.props.credential.type === ECredentialType.unikeyV1 || this.props.credential.type === ECredentialType.raw)
              },
              // {
              //   keyName: 'credentialDataB64',
              //   value: `${this.props.credential.credentialDataB64}`,
              //   type: 'string',
              //   disabled: true,
              //   hidden: !(this.props.credential.type === ECredentialType.unikeyV1 || this.props.credential.type === ECredentialType.raw)
              // },
              {
                keyName: 'credentialCapabilities',
                value: 'test',
                type: 'chips',
                disabled: true,
                collection: capabilityChipCollection
              },
              {
                keyName: 'organizationId',
                value: '' + this.props.credential.organizationId,
                type: 'link',
                linkTo: navConfig.get(ENavPages.organizationDetails)!.linkTo([this.props.credential.organizationId])!
              }
            ]} />

            <UniConditionalRender visible={!!this.props.redeemedDevices}>
              <UniTable
                searchable={false}
                titleKey="redeemedDevicesList"
                createButtonTextKey="device"
                data={this.props.redeemedDevices}
                footerStyle={EUniTable_FooterStyle.none}
                columnConfig={columns}
                actionsConfig={actions}
                showLoader={false} />
            </UniConditionalRender> 

          {/* show the credential invitation details on this page also */}
          <CredentialInvitationDetailsContainer match={this.props.match} />
        </UniConditionalRender>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    credential: state.credentialDetails.credentialData,
    redeemedDevices: state.credentialDetails.credentialData?.devices ?? [],
    loading: state.credentialDetails.loading
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => bindActionCreators({
  getCredentialById: attemptRetrieveCredentialById,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CredentialDetailsContainer)
    ), { componentName: 'CredentialDetails' })
