import {
  portalActions,

  localeMessages
} from '../../internal'


const browserLocale = window.navigator.language.toLowerCase();
// TODO: Could show a toast message that the browser's language is not supported?
const defaultLocale = localeMessages.has(browserLocale) ? browserLocale : 'en';

// default locale is always english
export const i18n = (state = { localeCode: defaultLocale, localeInput: '' }, action: any) => {
  switch (action.type) {
    case portalActions.UPDATE_LOCALE:
      return {
        ...state,
        localeCode: action.localeCode
      }
    case portalActions.UPDATE_LOCALE_INPUT:
      return {
        ...state,
        localeInput: action.localeInput
      }
    default:
      return state;
  }
};
