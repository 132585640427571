import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom'

import {
  ReaderLogCS,
  UniWorkflow,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender
} from '@unikey/unikey-commons/release/csupp';

import {
  navConfig,
  ENavPages,
  setReaderHistoryDetails,
  toggleReaderHistoryDetailsModal,
  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  historyDetails: ReaderLogCS,
  setReaderHistoryDetails(selectedDetails: ReaderLogCS): void,
  toggleModal(): void
}

class ReaderHistoryDetailsContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  _close = () => {
    this.props.setReaderHistoryDetails({});
    this.props.toggleModal();
  } 
  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <UniWorkflow
        inModal
        size="wider"
        titleKey="readerEventDetails"
        titleIcon="history" 
        handleClose={this._close}>

        <br />
        <Container>
          <Row nogutter>
            <Col md={8}><h4><UniLocalize translate="event" /></h4></Col>
            <Col>{this.props.historyDetails.type}</Col>
          </Row>

          <Row nogutter>
            <Col md={8}><h4><UniLocalize translate="Date" /></h4></Col>
            <Col>{this.props.historyDetails.timestamp?.toLocaleString?.()}</Col>
          </Row>

          <Row nogutter>
            <Col md={8}><h4><UniLocalize translate="performerType" /></h4></Col>
            <Col>{this.props.historyDetails.performerType}</Col>
          </Row>

          <Row nogutter>
            <Col md={8}><h4><UniLocalize translate="performer" /></h4></Col>
            <UniConditionalRender visible={this.props.historyDetails.additionalData.credential_id}>
              <Col>
                <NavLink aria-label={this.props.historyDetails.performerUserName}
                  to={navConfig.get(ENavPages.credentialDetails)!.linkTo([this.props.historyDetails.additionalData.credential_id])} >
                  {this.props.historyDetails.performerUserName}
                </NavLink>
              </Col>
            </UniConditionalRender>

            <UniConditionalRender visible={!this.props.historyDetails.additionalData.credential_id}>
              <Col>
                {this.props.historyDetails.performerUserName}

                <UniConditionalRender visible={this.props.historyDetails.performerType === 'User'}>
                  &nbsp;
                  ({this.props.historyDetails.performerId})
                </UniConditionalRender>

                <UniConditionalRender visible={this.props.historyDetails.performerType === 'Reader'}>
                  <UniLocalize translate="selfEnrolled" />
                </UniConditionalRender>
              </Col>
            </UniConditionalRender>
          </Row>

          <Row nogutter>
            <Col md={8}><h4><UniLocalize translate="readerName" /></h4></Col>
            <Col>{this.props.historyDetails.additionalData.reader_name}</Col>
          </Row>

          <Row nogutter>
            <Col md={8}><h4><UniLocalize translate="enrolledOrg" /></h4></Col>
            <Col>
              <NavLink aria-label={this.props.historyDetails.additionalData.org_name}
                to={navConfig.get(ENavPages.organizationDetails)!.linkTo([this.props.historyDetails.additionalData.org_id])} >
                {this.props.historyDetails.additionalData.org_name}
              </NavLink>
              {/* indicate this is a manufacutring org */}
              <UniConditionalRender visible={this.props.historyDetails.additionalData.org_is_default}>
                &nbsp;
                (<UniLocalize translate="manufacturingOrg"/>)
              </UniConditionalRender>
            </Col>
          </Row>

        </Container>

        {/* step actions */}
        <UniOverlapGroup>

          <UniOverlapButton
            handleClick={this._close}
            textKey="cancel"
            icon="close"
            tooltipPosition="right" />

        </UniOverlapGroup>
        
      </UniWorkflow>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    historyDetails: state.readerHistory.selectedDetails
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal: toggleReaderHistoryDetailsModal,
  setReaderHistoryDetails,
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReaderHistoryDetailsContainer)
    ), { componentName: 'ReaderHistoryDetails' })
