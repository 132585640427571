import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ScreenClassRender, Container, Row, Col } from 'react-grid-system'
import classNames from 'classnames';

import {
  UniWorkflow,
  UniConditionalRender,
  UniOverlapGroup,
  UniOverlapButton,
  UniInput,
  UniImg,
  UniLocalize,
  Editable,
  IMultiInputUpdate,
  darkThemeName,
  resiPasswordRequirementsV10n,
  passwordMatchV10n
} from '@unikey/unikey-commons/release/identity';

import {
  IPartnerCustomizations,
  IConfirmationActionParams,
  buildOidcAcrValues,
  updateSetPasswordTokenForm,
  attemptSetPasswordWithToken,
  dismissAllAlerts,
  partnerKey,
  ENavPages, navConfig,
  PartnerCustomizations,
  loginKey
} from '../internal'

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  loading: boolean,
  darkModeTheme: string,
  darkModeActive: boolean,
  password: Editable,
  confirmPassword: Editable,
  dismissAllAlerts(): void,
  setThemeMode(theme: string): void,
  handleUpdateForm(form: IMultiInputUpdate): void,
  attemptSetPasswordWithToken(data: IConfirmationActionParams): Promise<void>,
}

class SetPasswordTokenContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  _handleSaveClick = () => {
    this.props.dismissAllAlerts();
    const jwt = this.props.match.params?.actionToken;

    this.props.attemptSetPasswordWithToken({
      token: jwt,
      newPass: this.props.password.value
    }).then(() => {
      // redirect on success after 2 seconds
      setTimeout(() => {
        this.props.history.push(loginKey);
      }, 2000);
    })
  };

  _handlePasswordChange = (change: Editable) => this.props.handleUpdateForm({ password: change })
  _handleConfirmChange = (change: Editable) => this.props.handleUpdateForm({ confirmPassword: change })

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const formValid = this.props.password.valid && this.props.confirmPassword.valid;
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });
    return (
        <section className='access-container'>
          <section className='set-password-container'>
            {/* access flow should conform to max width restrictions for massive screens */}
            <Container>
              <UniImg className={imgClassName} textKey="partner logo" src={this.props.darkModeActive ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
              <UniWorkflow
                titleKey="setPassword"
                titleIcon="fingerprint"
                size="wider" >

                <p><UniLocalize translate='_setPasswordInstructions' /></p>

                <UniInput
                  type="password"
                  preventAutofill
                  editable={this.props.password}
                  labelKey="password"
                  placeholderKey="password"
                  handleUpdate={this._handlePasswordChange}
                  validations={new Map([resiPasswordRequirementsV10n])} />
                
                <UniInput
                  type="password"
                  preventAutofill
                  editable={this.props.confirmPassword}
                  labelKey="confirmPassword"
                  placeholderKey="confirmPassword"
                  handleUpdate={this._handleConfirmChange}
                  validations={new Map([passwordMatchV10n(this.props.password.value)])}/>

                <UniOverlapGroup foldEarly={true}>
                  <UniOverlapButton
                    handleClick={this._handleSaveClick}
                    textKey="save"
                    icon="forward"
                    showLoader={!!this.props.loading}
                    disabled={!formValid} />
                </UniOverlapGroup>

              </UniWorkflow>
            </Container>
          </section>
        </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    password: state.setPasswordTokenForm.password,
    confirmPassword: state.setPasswordTokenForm.confirmPassword,
    loading: state.actionTokens.loading,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  handleUpdateForm: updateSetPasswordTokenForm,
  attemptSetPasswordWithToken,
  dismissAllAlerts
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(SetPasswordTokenContainer)
), { componentName: 'SetPasswordToken', unauthenticated: true })

