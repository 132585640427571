import {
  IFirmwareImageCS
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  productActions
} from '../../internal'

const initialProductState = {
  loading: true,
  productData: [{}],
  activeColumn: '',
  modalOpen: false,
  activeProduct: null
};

export const products = (state = initialProductState, action: any) => {
  switch (action.type) {
    case productActions.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case productActions.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        productData: action.value
      }
    case productActions.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        productData: []
      }
    default:
      return state
  }
}


const initialProductDetailsState = {
  loading: true,
  data: {},
};

export const productDetails = (state = initialProductDetailsState, action: any) => {
  switch (action.type) {
    case productActions.GET_SINGLE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case productActions.GET_SINGLE_PRODUCT_SUCCESS:
      // sort the product images alphabetically
      const foundProduct = action.value;
      foundProduct.images = foundProduct.images.sort((a: IFirmwareImageCS, b: IFirmwareImageCS): number => {
        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
      });
      return {
        ...state,
        loading: action.pending,
        data: foundProduct
      }
    case productActions.GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: {}
      }
    default:
      return state
  }
}