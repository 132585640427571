import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'


import {
  Editable,
  UniTable,
  UniKeyVal,
  UniLocalize,
  UniOverflowActions,
  UniConditionalRender,
  IUniMenu_ItemConfig,
  IUniConfirm_Config,
  ReaderCS,
  ActivityLogCS,
  FirmwareCS,
  CredentialCS,
  ReaderLogCS,
  EReaderStatus,
  EPermissionSessionStatusCS,
  ECredentialStatus,
  ECredentialType,
  IUniTable_Sort,
  IMultiInputUpdate,
  IUniSelect_Option,
  IUniTable_Filter,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  UniMessage,
  EMessageType,
  UniTag,
  todayYesterdayOrDate,
} from '@unikey/unikey-commons/release/csupp';

import {
  ActivityLogList,
  getReaderById,
  handleReaderChange,
  attemptUpdateReader,
  attemptResetReaderEnrollment,
  attemptRetrieveProductFirmware,
  IGetProductFirmwareParams,
  attemptRetrieveReaderCredentials,
  updateReaderCredentialsQueryParams,
  updateReaderCredentialsTableMeta,
  attemptRetrieveReaderHistory,
  attemptRetrieveActivityLogs,
  updateReaderHistoryQueryParams,
  updateReaderHistoryTableMeta,
  toggleReaderHistoryDetailsModal,
  setReaderHistoryDetails,
  openConfirmModal,
  closeConfirmModal,
  navConfig, ENavPages,
  PartnerCustomizations, IPartnerCustomizations,
  getTableSortDirection, getTableParamsFromUpdate,
  buildTableUpdateFunc, TTableUpdateFunc,
} from '../internal';
import ReaderHistoryDetailsContainer from './ReaderHistoryDetailsContainer';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,

  reader: ReaderCS,
  readerName: Editable<string>,
  readerStatus: Editable<number>,
  readerSessionStatus: EPermissionSessionStatusCS,
  readerAvailableUpgradeVersion: Editable<string>,
  readerFirmwareVersion: Editable<string>,

  productFirmwareVersions: FirmwareCS[],
  loading: boolean,
  saving: boolean,
  isUniKeyActor: boolean,

  credentials: CredentialCS[],
  credentialListQueryParams: IPaginatedParamsCS,
  credentialListAppliedFilters: IUniTable_Filter[],
  credentialListAppliedSorts: IUniTable_Sort[],
  credentialListLoading: boolean,
  credentialListPaginationSummary: IUniTable_PaginationSummary,

  readerHistory: ReaderLogCS[],
  readerHistoryQueryParams: IPaginatedParamsCS,
  readerHistoryLoading: boolean,
  readerHistoryAppliedFilters: IUniTable_Filter[],
  readerHistoryAppliedSorts: IUniTable_Sort[],
  readerHistoryPaginationSummary: IUniTable_PaginationSummary,

  readerHistoryDetailsOpen: boolean,

  // activity log stuff
  activityLogs: ActivityLogCS[],
  activityLogListQueryParams: IPaginatedParamsCS,
  activityLogListLoading: IUniTable_Filter[],
  activityLogListAppliedFilters: IUniTable_Sort[],
  activityLogListAppliedSort: boolean,
  activityLogListPaginationSummary: IUniTable_PaginationSummary,
  getActivityLogs(deviceId: string, activityLogsQueryParams: IPaginatedParamsCS): void,
  updateActivityLogsListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateActivityLogsListQueryParams?(params: IPaginatedParamsCS): void,

  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,

  resetEnrollment(readerId: string): Promise<void>,

  handleReaderChange(updates: IMultiInputUpdate): void,
  saveReader(readerId: string): Promise<any>
  getReaderById(readerId: string): Promise<void>,
  getProductFirmwareVersions(params: IGetProductFirmwareParams): Promise<void>,

  getReaderCredentials(readerId: string): Promise<void>,
  updateReaderCredentialTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateReaderCredentialQueryParams?(params: IPaginatedParamsCS): void,

  getReaderHistory(readerId: string): Promise<void>,
  updateReaderHistoryTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateReaderHistoryQueryParams?(params: IPaginatedParamsCS): void,

  setReaderHistoryDetails(selectedDetails: ReaderLogCS): void,
  toggleReaderHistoryDetailsModal(): void
}

class ReaderDetailsContainer extends Component<IProps> {
  _updateReaderCredentialTable: TTableUpdateFunc; 
  _updateReaderHistoryTable: TTableUpdateFunc;

  constructor(props: IProps) {
    super(props);

    this._updateReaderCredentialTable = buildTableUpdateFunc(
      props.getReaderCredentials.bind(this, props.match.params.readerId),
      props.updateReaderCredentialTableMeta,
      props.updateReaderCredentialQueryParams
    )

    this._updateReaderHistoryTable = buildTableUpdateFunc(
      props.getReaderHistory.bind(this, props.match.params.readerId),
      props.updateReaderHistoryTableMeta,
      props.updateReaderHistoryQueryParams
    )

  }

  componentDidMount() {
    this.props.getReaderById(this.props.match.params.readerId);
    this.props.getReaderCredentials(this.props.match.params.readerId);
    this.props.getReaderHistory(this.props.match.params.readerId);

    if (this.props.isUniKeyActor) {
      this.props.getActivityLogs(this.props.match.params.readerId, this.props.activityLogListQueryParams);
    }
  }

  _retrieveAdditionalDataForForm = () => {
    return this.props.getProductFirmwareVersions({
      productId: this.props.reader.productId!, 
      onlyAvailable: true,
      onlySinceGA: true
    });
  }

  _getProductVersionOptions(): IUniSelect_Option[] {
    const options = this.props.productFirmwareVersions.map((v: FirmwareCS): IUniSelect_Option => {
      return {
        value: v.version,
        nameKey: `${v.version} \t-\t ${v.description}`,
        selected: v.version === this.props.readerAvailableUpgradeVersion.value
      };
    });
    // also include null option
    // TODO: its been asked for us to be able to support setting back to null
    // currently, the api doesnt let us do this, but ill leave it here in case we ever get to it.
    options.unshift({ value: 'null', selected: false });
    return options;
  }

  _reload = (): Promise<any> => {
    return Promise.resolve(this.componentDidMount())
  }

  _saveReader = () => {
    return this.props.saveReader(this.props.reader.id);
  }

  _openResetEnrollmentModal = () => {
    this.props.openConfirmDialog({
      titleKey: 'resetEnrollment',
      titleIcon: 'undo',
      theme: 'warn',
      messageKeys: ['youAreAboutToResetReaderEnrollment', this.props.readerName.value, '_explainResetReaderEnrollment', 'areYouSure'],
      confirmTextKey: 'reset',
      cancelHandler: this.props.closeConfirmModal,
      confirmHandler: () => {
        this.props.resetEnrollment(this.props.reader.id);
        this.props.closeConfirmModal()
        this._reload();
      }
    });
  }

  _credentialStatusTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-status">
        <UniTag textKey={rowItem.getStatusNameKey()} dot />
      </div>
    )
  }

  _credentialOrganizationNameTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-organization">
        {rowItem.getOrganizationName()}
      </div>
    )
  }

  // credential table
  _credentialTypeTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-type">
        <UniTag textKey={rowItem.getTypeNameKey()} inverted />
      </div>
    )
  }

  _buildReaderCredentialTableColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: true,
        size: 6
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        template: this._credentialStatusTemplate,
        type: 'enum',
        enumType: ECredentialStatus,
        size: 4
      })
      .set('organization', {
        nameKey: 'organization',
        template: this._credentialOrganizationNameTemplate,
        size: 6
      })
      .set('type', {
        nameKey: 'type',
        template: this._credentialTypeTemplate,
        type: 'enum',
        enumType: ECredentialType,
        size: 4,
        // called type on the credential model, but db has it as credential_type. This is why it must be provided here.
        filterName: 'credentialType'
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 4
      })
      .set('cardNumber', {
        nameKey: 'cardNumber',
        isFilterable: true,
        size: 0,        
      })
      .set('label', {
        nameKey: 'label',
        isFilterable: true,
        size: 0
      })
      .set('id', {
        nameKey: 'id',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('organizationId', {
        nameKey: 'organizationId',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: CredentialCS) => this.props.history.push(navConfig.get(ENavPages.credentialDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: CredentialCS) => false,
      evalVisible: (rowItem: CredentialCS) => true
    })

    return { columns, actions };
  }
  
  _buildReaderHistoryTableColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('type', {
        nameKey: 'event',
        isSortable: false,
        type: 'string',
        size: 4
      })
      .set('org', {
        nameKey: 'organization',
        isSortable: false,
        size: 6,
        template: (rowItem: ReaderLogCS) => rowItem.additionalData?.org_name ? rowItem.additionalData?.org_name : (rowItem.additionalData?.org_is_default ? (<UniLocalize translate="manufacturingOrg"/>) : (<i><UniLocalize translate="_notApplicable" /></i>))
      })
      .set('timestamp', {
        nameKey: 'date',
        isSortable: false,
        type: 'date',
        size: 4
      })
      .set('performerName ', {
        nameKey: 'performer',
        isSortable: false,
        template: (rowItem: ReaderLogCS) => rowItem.performerUserName ? rowItem.performerUserName : rowItem.performerType,
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 4
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: ReaderLogCS) => { this.props.setReaderHistoryDetails(rowItem); this.props.toggleReaderHistoryDetailsModal(); },
      evalDisabled: (rowItem: ReaderLogCS) => false,
      evalVisible: (rowItem: ReaderLogCS) => true
    })

    return { columns, actions };
  }


  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const credTableColsAndActions = this._buildReaderCredentialTableColumnsAndActions();
    const readerHistoryColsAndActions = this._buildReaderHistoryTableColumnsAndActions();

    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('resetEnrollment', {
        textKey: 'resetEnrollment',
        theme: 'error',
        handleClick: this._openResetEnrollmentModal,
        hidden: !this.props.reader?.serialNumber,
        disabled: !this.props.reader?.organization || this.props.readerSessionStatus === EPermissionSessionStatusCS.revoked,
        disabledReasonKeys: this.props.readerSessionStatus === EPermissionSessionStatusCS.revoked ? ['sessionRevoked', '_explainRevokedSessionEnrollment'] : ['_readerNotYetEnrolled', '_explainReaderResetNotAvailable'],
      });

    return (
      <section className='readerDetails-container'>
        <>
          <Row>
            <Col>
              <h3 className="page-title-non-table">{this.props.readerName.value}</h3>
            </Col>

            <UniConditionalRender visible={!!this.props.reader?.serialNumber}>
              <Col xs={6} lg={2}>
                <UniOverflowActions
                  className="title-actions"
                  nameKey="readerActions"
                  icon="arrowDropDownCircle"
                  actions={actionsMenuContents}
                  allowForOverlapAlignment={this.props.isUniKeyActor ? ['md', 'lg', 'xl', 'xxl'] : []}
                  theme="primary" />
              </Col>
            </UniConditionalRender>
          </Row>

          <UniConditionalRender visible={!!this.props.reader.id}>
            <UniKeyVal
              label="reader-details"
              saveClick={this._saveReader}
              cancelClick={this._reload}
              isSaving={this.props.saving}
              preventSave={!this.props.readerAvailableUpgradeVersion.valid}
              preventEdit={!this.props.isUniKeyActor}
              onEdit={this._retrieveAdditionalDataForForm}
              fields={[
                {
                  keyName: 'id',
                  value: `${this.props.reader.id}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'serialNumber',
                  value: `${this.props.reader.serialNumber}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'name',
                  value: `${this.props.readerName.value}`,
                  type: 'string',
                  handleUpdate: (name: Editable<string>) => this.props.handleReaderChange({ name }),
                  disabled: !this.props.isUniKeyActor
                },
                {
                  keyName: 'firmwareVersion',
                  value: `${this.props.readerFirmwareVersion.value}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'availableUpgradeVersion',
                  value: `${this.props.readerAvailableUpgradeVersion.value}`,
                  type: 'select',
                  options: this._getProductVersionOptions(),
                  handleUpdate: (version: Editable<string>) => this.props.handleReaderChange({ availableUpgradeVersion: version }),
                  disabled: false,
                  keyInfo: {
                    icon: 'infoOutline',
                    textKeys: [ 'availableUpgradeVersion', '_explainAvailableUpgradeVersion' ]
                  }
                },
                {
                  keyName: 'status',
                  value: `${this.props.readerStatus.value}`,
                  enumType: EReaderStatus,
                  type: 'enum',
                  disabled: true
                },
                {
                  keyName: 'upgradeAvailable',
                  value: `${this.props.reader.upgradeAvailable}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'sessionStatus',
                  value: `${this.props.readerSessionStatus}`,
                  enumType: EPermissionSessionStatusCS,
                  type: 'enum',
                  disabled: true
                },
                {
                  keyName: 'readerCertificateDate',
                  value: `${this.props.reader.created}`,
                  type: 'dateAndTime',
                  disabled: true
                },
                {
                  keyName: 'productId',
                  value: `${this.props.reader.productId}`,
                  type: 'link',
                  linkTo: navConfig.get(ENavPages.productDetails)!.linkTo([this.props.reader.productId])!
                }
              ]} />
          </UniConditionalRender>

          <Row nogutter>
            <Col lg={14} xl={12}>

              <h3><UniLocalize translate="certificateDetails" /></h3>
              <UniConditionalRender visible={!!this.props.reader.id}>
                <UniKeyVal
                  label="certificate-details"
                  stacked={true}
                  primaryStateButtonSet={[]}
                  secondaryStateButtonSet={[]}
                  allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
                  fields={[
                    {
                      keyName: 'certificateName',
                      value: `${this.props.reader.certificateName}`,
                      type: 'string',
                      disabled: true
                    }
                  ]} />
              </UniConditionalRender>
            </Col>

            <Col lg={10} xl={12}>
              <h3>
                <UniLocalize translate="enrollment" /> 
                <UniConditionalRender visible={this.props.readerSessionStatus === EPermissionSessionStatusCS.revoked}>
                  &nbsp;(<UniLocalize translate="revoked" />)
                </UniConditionalRender>
              </h3>
              {/* as long as the reader does not have an inactive session status, we should show enrollment options.
                * Readers with revoked session statuses are considered "dead" -DANG */}
                <UniKeyVal
                  key={this.props.reader?.id}
                  label="organization-enrollment"
                  stacked={true}
                  primaryStateButtonSet={!this.props.reader?.serialNumber ? [] : [
                    {
                      textKey: 'resetEnrollment',
                      icon: 'undo',
                      className: 'fail',
                      disabled: !this.props.reader?.organization || this.props.readerSessionStatus === EPermissionSessionStatusCS.revoked,
                      disabledReasonKeys: this.props.readerSessionStatus === EPermissionSessionStatusCS.revoked ? ['sessionRevoked', '_explainRevokedSessionEnrollment'] : ['_readerNotYetEnrolled', '_explainReaderResetNotAvailable'],
                      clickHandler: this._openResetEnrollmentModal
                    }
                  ]}
                  secondaryStateButtonSet={[]}
                  allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
                  fields={[
                    {
                      keyName: 'organization',
                      value: `${this.props.reader.organization?.name ? this.props.reader.organization?.name : '_readerNotYetEnrolled'}`,
                      type: this.props.reader.organization?.name ? 'link' : 'string',
                      linkTo: navConfig.get(ENavPages.organizationDetails)!.linkTo([this.props.reader.organization?.id])!
                    }
                  ]} />
            </Col>
          </Row>
        </>

        {/* Display the credentials with access to this org */}
        <section className='credentialList-container'>
          <UniTable
            searchable={true}
            advancedFiltering={true}
            titleKey="credentialsList"
            createButtonTextKey="credential"
            handleUpdate={this._updateReaderCredentialTable}
            data={this.props.credentials}
            paginationSummary={this.props.credentialListPaginationSummary}
            columnConfig={credTableColsAndActions.columns}
            activeSorts={this.props.credentialListAppliedSorts}
            actionsConfig={credTableColsAndActions.actions}
            activeFilters={this.props.credentialListAppliedFilters}
            showLoader={this.props.credentialListLoading} />
        </section>

        {/* Display the log history for this reader */}
        <section className='readerHistory-container'>
          <UniTable
            searchable={false}
            advancedFiltering={false}
            titleKey="readerLogs"
            createButtonTextKey="readerHistory"
            handleUpdate={this._updateReaderHistoryTable}
            data={this.props.readerHistory}
            delimiterConfig={{
              display: (log: ReaderLogCS) => todayYesterdayOrDate(log?.timestamp)!,
              value: (log: ReaderLogCS) => log?.timestamp?.toLocaleDateString()!
            }}
            paginationSummary={this.props.readerHistoryPaginationSummary}
            columnConfig={readerHistoryColsAndActions.columns}
            activeSorts={this.props.readerHistoryAppliedSorts}
            actionsConfig={readerHistoryColsAndActions.actions}
            activeFilters={this.props.readerHistoryAppliedFilters}
            showLoader={this.props.readerHistoryLoading} />

          <UniConditionalRender visible={this.props.readerHistoryDetailsOpen}>
            <ReaderHistoryDetailsContainer />
          </UniConditionalRender>

        </section>

        {/* Display the device's activity logs */}
        <UniConditionalRender visible={this.props.isUniKeyActor}>
          <ActivityLogList 
              key={`${this.props.activityLogs?.[0]?.start}${this.props.activityLogListLoading}`}
              match={this.props.match}
              history={this.props.history}
              logs={this.props.activityLogs}
              logListQueryParams={this.props.activityLogListQueryParams}
              logListLoading={this.props.activityLogListLoading}
              logListAppliedFilters={this.props.activityLogListAppliedFilters}
              logListAppliedSort={this.props.activityLogListAppliedSort}
              logListPaginationSummary={this.props.activityLogListPaginationSummary}
              getScopedLogs={this.props.getActivityLogs.bind(this, this.props.match.params.readerId)}
              updateLogListQueryParams={this.props.updateActivityLogsListQueryParams}
              updateLogListTableMeta={this.props.updateActivityLogsListTableMeta} />
        </UniConditionalRender>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    // reader details stuff
    reader: state.readerDetails.readerData,
    readerName: state.readerDetails.readerData.name,
    readerAvailableUpgradeVersion: state.readerDetails.readerData.availableUpgradeVersion,
    readerFirmwareVersion: state.readerDetails.readerData.firmwareVersion,
    readerStatus: state.readerDetails.readerData.status,
    readerSessionStatus: state.readerDetails.readerData.sessionStatus,
    loading: state.readerDetails.loading,
    saving: state.readerDetails.saving,
    productFirmwareVersions: state.readerDetails.productFirmwareVersions,
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
    // credential list stuff
    credentials: state.readerCreds.data.models,
    credentialListQueryParams: state.readerCreds.queryParams,
    credentialListLoading: state.readerCreds.loading,
    credentialListAppliedFilters: state.readerCreds.tableFilters,
    credentialListAppliedSorts: state.readerCreds.tableSorts,
    credentialListPaginationSummary: state.readerCreds.paginationSummary,
    // reader history stuff
    readerHistory: state.readerHistory.data.models,
    readerHistoryQueryParams: state.readerHistory.queryParams,
    readerHistoryLoading: state.readerHistory.loading,
    readerHistoryAppliedFilters: state.readerHistory.tableFilters,
    readerHistoryAppliedSorts: state.readerHistory.tableSorts,
    readerHistoryPaginationSummary: state.readerHistory.paginationSummary,

    readerHistoryDetailsOpen: state.readerHistory.detailsOpen,

    // activity log list stuff
    activityLogs: state.activityLogs.data.models,
    activityLogListLoading: state.activityLogs.loading,
    activityLogListQueryParams: state.activityLogs.queryParams,
    activityLogListAppliedFilters: state.activityLogs.tableFilters,
    activityLogListAppliedSort: state.activityLogs.tableSorts[0],
    activityLogListPaginationSummary: state.activityLogs.paginationSummary,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getReaderById,
  handleReaderChange,
  saveReader: attemptUpdateReader,
  getProductFirmwareVersions: attemptRetrieveProductFirmware,
  getReaderCredentials: attemptRetrieveReaderCredentials,
  updateReaderCredentialQueryParams: updateReaderCredentialsQueryParams,
  updateReaderCredentialTableMeta: updateReaderCredentialsTableMeta,
  getReaderHistory: attemptRetrieveReaderHistory,
  updateReaderHistoryQueryParams,
  updateReaderHistoryTableMeta,
  toggleReaderHistoryDetailsModal,
  setReaderHistoryDetails,
  resetEnrollment: attemptResetReaderEnrollment,
  openConfirmDialog: openConfirmModal,
  closeConfirmModal,
  getActivityLogs: attemptRetrieveActivityLogs,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReaderDetailsContainer)
    ), { componentName: 'ReaderDetails' })
