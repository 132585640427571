import {
  IUniToast_Alert,
  EMessageType
} from '@unikey/unikey-commons/release/csupp'

type IAlerter = (config: IUniToast_Alert) => void;

export const checkJWTExpired = (decodedToken: any, alerter?: IAlerter): boolean => {
  const nowMs = Date.now();
  // ensure token is still within the pre-expiration period
  const isExpired = (decodedToken.exp * 1000) < nowMs;
  if (isExpired && alerter) {
    alerter({
      id: Date.now(),
      type: EMessageType.warn,
      titleKey: 'tokenExpired',
      messageKeys: ['_tokenExpiredExplination']
    });
  }
  return isExpired;
}

export const getJWTExpiryString = (decodedToken: any) => {
  return new Date(decodedToken.exp * 1000).toLocaleString();
}