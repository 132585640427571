import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'


import {
  UniKeyVal,
  UniConditionalRender,
  UniLocalize,
  ICredentialInvitationCS,
  ECredentialInvitationStatus
} from '@unikey/unikey-commons/release/csupp';

import {
  getCredentialInvitationById,
  PartnerCustomizations, IPartnerCustomizations,
  dateOrLoading
} from '../internal';


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  invitation: ICredentialInvitationCS,
  loading: boolean,
  match: any,
  getCredentialInvitationById(credentialId: string): void
}

class CredentialInvitationDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getCredentialInvitationById(this.props.match.params.credentialId);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <section className='credentialInvitationDetails-container'>
        <Container fluid style={{ padding: 0 }}>
          <Row nogutter>
            <h3><UniLocalize translate="invitationDetails" /></h3>
          </Row>
        </Container>

        <UniConditionalRender visible={!!this.props.invitation.id}>
          <UniKeyVal
            label="credential-invitation"
            primaryStateButtonSet={[]}
            secondaryStateButtonSet={[]}
            showLoader={this.props.loading}
            fields={[
              {
                keyName: 'id',
                type: 'string',
                value: this.props.invitation.id,
                disabled: true
              },
              {
                keyName: 'status',
                value: this.props.invitation.status,
                type: 'enum',
                enumType: ECredentialInvitationStatus,
                disabled: true
              },
              {
                keyName: 'created',
                value: dateOrLoading(this.props.invitation.created, this.props.loading),
                type: 'dateAndTime',
                disabled: true
              },
              {
                keyName: 'expiration',
                value: dateOrLoading(this.props.invitation.expiration, this.props.loading),
                type: 'dateAndTime',
                disabled: true
              }
            ]} />
        </UniConditionalRender>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    invitation: state.invitationDetails.invitationData,
    loading: state.credentialDetails.loading
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCredentialInvitationById,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CredentialInvitationDetailsContainer)
    ), { componentName: 'CredentialInvitationDetails' })
