import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { i18n } from './i18nReducer'
import { oidc, oidcAuthStatus } from './oidcReducers'
import { statsOptions, readerStats, orgStats, credentialStats, overallStats, creditStats, deviceStats, dealerStats, readersPerOrgStats, credentialsPerOrgStats, orgsPerDealerStats } from './statisticReducer'
import { alerts } from './alertReducer'
import { dealers, dealerDetails, dealerOrgs, dealerAdmins, dealerInvites } from './dealerReducer'
import { portal, feedbackModal } from './portalReducer'
import { admins, adminDetails, adminRoles, adminInviteDetails, adminInvites } from './adminReducer'
import { coupons, couponDetails, couponForm, credits, couponAssignment } from './couponReducer'
import { setPasswordTokenForm, actionTokens, resendVerifyEmailSplashForm, changePasswordForm, addPasswordModal } from './userReducer'
import { captcha } from './captchaReducer'
import { devices, deviceDetails, deviceNotificationForm } from './deviceReducer'
import { confirm } from './confirmReducer'
import { products, productDetails } from './productReducer'
import { activityLogs } from './activityLogReducer'
import { invitationDetails } from './invitationReducer'
import { credentials, credentialDetails } from './credentialReducer'
import { firmwareList, firmwareEditor, firmwareDetails, firmwareReaders } from './firmwareReducer'
import { readers, readerDetails, readerCreds, readerHistory } from './readersReducer'
import { organizations, orgDetails, orgReaders, orgCreds, orgAdmins } from './organizationsReducer'
import { authenticatedUser, loginForm } from './authenticationReducer'

export const createRootReducer = (history: History) => combineReducers({
  i18n,
  oidc,
  alerts,
  portal,
  admins,
  dealers,
  confirm,
  credits,
  devices,
  readers,
  captcha,
  coupons,
  orgStats,
  orgCreds,
  products,
  orgAdmins,
  loginForm,
  orgDetails,
  orgReaders,
  dealerOrgs,
  adminRoles,
  couponForm,
  // newFirmware,
  deviceStats,
  readerStats,
  dealerStats,
  creditStats,
  credentials,
  readerCreds,
  actionTokens,
  dealerAdmins,
  adminInvites,
  overallStats,
  statsOptions,
  firmwareList,
  adminDetails,
  activityLogs,
  readerHistory,
  feedbackModal,
  couponDetails,
  dealerInvites,
  readerDetails,
  dealerDetails,
  organizations,
  deviceDetails,
  productDetails,
  oidcAuthStatus,
  firmwareEditor,
  firmwareReaders,
  firmwareDetails,
  credentialStats,
  addPasswordModal,
  couponAssignment,
  invitationDetails,
  authenticatedUser,
  credentialDetails,
  orgsPerDealerStats,
  readersPerOrgStats,
  adminInviteDetails,
  changePasswordForm,
  setPasswordTokenForm,
  credentialsPerOrgStats,
  deviceNotificationForm,
  resendVerifyEmailSplashForm,
  router: connectRouter(history)
})
