import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

interface IProps {
  isUniKeyActor: boolean,
  oidcUpdater: number,
  currentExpiry?: string,
  tokenExpired?: boolean,
  tokenRefreshAttemptCompleted?: boolean,
}

class AuthExpiryContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="auth-expiry-countdown-container">
          IsUniKeyActor: {this.props.isUniKeyActor ? 'Y' : 'N'} | {this.props.oidcUpdater} | Current Token Expires: {this.props?.currentExpiry}
        </div>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
    tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted,
    currentExpiry: state.oidcAuthStatus.currentExpiry,
    tokenExpired: state.oidcAuthStatus.isExpired,
    oidcUpdater: state.oidcAuthStatus.updater,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthExpiryContainer)
