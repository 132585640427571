import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Row, Col } from 'react-grid-system'

import {
  UniTable,
  UniLocalize,
  UniConditionalRender,
  FirmwareCS,
  ProductCS,
  EFirmwareVersionStatus,
  IUniConfirm_Config,
  UniToggle,
  EUniTable_FooterStyle
} from '@unikey/unikey-commons/release/csupp';

import {
  toggleModal,
  attemptRetrieveProductFirmware,
  attemptFlagFirmwareAsGenerallyAvailable,
  attemptChangeFirmwareStatus,
  toggleFirmwareCreateModal,
  FirmwareCreateContainer,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig, ENavPages,
  openConfirmModal,
  closeConfirmModal,
  changeAvailibilityViewType,
  IGetProductFirmwareParams,
  IChangeFirmwareStatusActionParams
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  createModalOpen: boolean,
  firmwareData: FirmwareCS[],
  listLoading: boolean,
  history: any,
  product: ProductCS,
  hideUnavailableVersions: boolean,
  isUniKeyActor: boolean,
  toggleFirmwareCreateModal(): void,
  getProductFirmware(params: IGetProductFirmwareParams): Promise<void>,
  flagAsGenerallyAvailable(firmwareId: string): Promise<any>,
  changeFirmwareAvailability(params: IChangeFirmwareStatusActionParams): Promise<any>
  openFirmwareStatusConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
  changeAvailibilityViewType(hideAvailibility: boolean): void,
}

class FirmwareListContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    const productId = this.props.match.params.productId;
    this.props.getProductFirmware({ productId, onlyAvailable: this.props.hideUnavailableVersions });
  }

  changeViewTypeAndRefreshList = (hideAvailibility: boolean) => {
    this.props.changeAvailibilityViewType(hideAvailibility);
    this.props.getProductFirmware({ productId: this.props.product.id, onlyAvailable: hideAvailibility });
  }

  refreshListOfFirmwareVersions = () => {
    return this.props.getProductFirmware({ productId: this.props.product.id, onlyAvailable: this.props.hideUnavailableVersions });
  }

  _generallyAvailableTemplate(rowItem: any) {
    return (
      <UniConditionalRender visible={rowItem.isGenerallyAvailable}>
        <div className="generally-available-version">
          <UniLocalize translate="generallyAvailableAbbreviation" />
        </div>
      </UniConditionalRender>
    )
  }

  _buildColumnsAndActions() {
    const columns = new Map()
      .set('version', {
        nameKey: 'version',
        isSortable: false,
        type: 'string',
        evalItalicized: (rowItem: FirmwareCS) => rowItem.status === EFirmwareVersionStatus.unavailable,
        size: 2,
      })
      .set('is_generally_available', {
        nameKey: '_emptyString',
        isSortable: false,
        template: this._generallyAvailableTemplate,
        type: 'boolean',
        size: 2
      })
      .set('description', {
        nameKey: 'description',
        isSortable: false,
        type: 'string',
        evalItalicized: (rowItem: FirmwareCS) => rowItem.status === EFirmwareVersionStatus.unavailable,
        size: 6
      })
      .set('releaseNotes', {
        nameKey: 'releaseNotes',
        type: 'string',
        evalItalicized: (rowItem: FirmwareCS) => rowItem.status === EFirmwareVersionStatus.unavailable,
        size: 10
      })
      .set('actions', {
        nameKey: 'actions',
        size: 2,
        collapsed: true,
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: FirmwareCS) => { this.props.history.push(navConfig.get(ENavPages.firmwareDetails)!.linkTo([this.props.product.id, rowItem.id])!) },
      evalDisabled: (rowItem: FirmwareCS) => false,
      evalVisible: (rowItem: FirmwareCS) => true
    })
      .set('setAsGenerallyAvailable', {
        nameKey: 'setAsGenerallyAvailable',
        icon: 'flag',
        theme: 'secondary',
        func: (rowItem: FirmwareCS) => this.props.openFirmwareStatusConfirmDialog({
          titleKey: 'setAsGenerallyAvailable',
          messageKeys: ['_confirmSetGaVersionTo:', rowItem.version],
          confirmTextKey: 'save',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this.props.flagAsGenerallyAvailable(rowItem.id!).then(this.refreshListOfFirmwareVersions),
              this.props.closeConfirmModal()
          }
        }),
        // func: (rowItem: FirmwareCS) => this.props.flagAsGenerallyAvailable(rowItem.id!).then(this.refreshListOfFirmwareVersions),
        evalDisabled: (rowItem: FirmwareCS) => rowItem.isGenerallyAvailable,
        evalVisible: (rowItem: FirmwareCS) => this.props.isUniKeyActor
      })
      .set('markAsUnavailable', {
        nameKey: 'markAsUnavailable',
        icon: 'doNotDisturbOn',
        func: (rowItem: FirmwareCS) => this.props.openFirmwareStatusConfirmDialog({
          titleKey: 'updateFirmwareVersion',
          messageKeys: ['_confirmChangeFirmwareVersionStatusTo:', 'unavailable'],
          confirmTextKey: 'save',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this.props.changeFirmwareAvailability({ firmwareId: rowItem.id!, statusTo: EFirmwareVersionStatus.unavailable }).then(this.refreshListOfFirmwareVersions),
              this.props.closeConfirmModal()
          }
        }),
        evalDisabled: (rowItem: FirmwareCS) => rowItem.isGenerallyAvailable,
        evalVisible: (rowItem: FirmwareCS) => this.props.isUniKeyActor && rowItem.status !== EFirmwareVersionStatus.unavailable
      })
      .set('setAsAvailable', {
        nameKey: 'markAsAvailable',
        icon: 'doNotDisturbOff',
        func: (rowItem: FirmwareCS) => this.props.openFirmwareStatusConfirmDialog({
          titleKey: 'updateFirmwareVersion',
          messageKeys: ['_confirmChangeFirmwareVersionStatusTo:', 'available'],
          confirmTextKey: 'save',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this.props.changeFirmwareAvailability({ firmwareId: rowItem.id!, statusTo: EFirmwareVersionStatus.available }).then(this.refreshListOfFirmwareVersions),
              this.props.closeConfirmModal()
          }
        }),
        evalDisabled: (rowItem: FirmwareCS) => rowItem.isGenerallyAvailable,
        evalVisible: (rowItem: FirmwareCS) => this.props.isUniKeyActor && rowItem.status !== EFirmwareVersionStatus.available
      });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const { columns, actions } = this._buildColumnsAndActions();
    return (
      <section className='firmwareList-container'>
        
        <UniConditionalRender visible={!!this.props.firmwareData}>
          <UniTable
            searchable={false}
            advancedFiltering={false}
            titleKey="firmwareList"
            createButtonTextKey="firmware"
            titleToggleTooltipKey="showOrHideUnavailableVersions"
            handleTitleToggleClick={(val: boolean) => this.changeViewTypeAndRefreshList(!val)}
            data={this.props.firmwareData}
            handleCreateClick={this.props.isUniKeyActor ? this.props.toggleFirmwareCreateModal : undefined}
            columnConfig={columns}
            actionsConfig={actions}
            showLoader={this.props.listLoading}
            footerStyle={EUniTable_FooterStyle.none} />
        </UniConditionalRender>

        {/* Workflow Modal */}
        <UniConditionalRender visible={this.props.createModalOpen}>
          <FirmwareCreateContainer product={this.props.product} match={this.props.match} />
        </UniConditionalRender>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    product: state.productDetails.data,
    createModalOpen: state.firmwareEditor.editing,
    firmwareData: state.firmwareList.firmwareData || [],
    listLoading: state.firmwareList.loading,
    hideUnavailableVersions: state.firmwareList.hideUnavailable,
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal,
  toggleFirmwareCreateModal,
  openRemoveAdminConfirmDialog: openConfirmModal,
  getProductFirmware: attemptRetrieveProductFirmware,
  flagAsGenerallyAvailable: attemptFlagFirmwareAsGenerallyAvailable,
  changeFirmwareAvailability: attemptChangeFirmwareStatus,
  openFirmwareStatusConfirmDialog: openConfirmModal,
  closeConfirmModal,
  changeAvailibilityViewType
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(FirmwareListContainer)
    ), { componentName: 'FirmwareList' })
