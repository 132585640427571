import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTag,
  UniTable,
  UniWorkflow,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
  UniCodeBlock,
  ActivityLogCS,
  EActivityLogStatusTypeCS,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  darkThemeName,
  todayYesterdayOrDate,
  EUniTable_FooterStyle
} from '@unikey/unikey-commons/release/csupp'

import {
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations,
  dateAndTimeOrLoading
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  activeThemeName: string,

  logs: ActivityLogCS[],
  logListQueryParams: IPaginatedParamsCS,
  logListAppliedFilters: IUniTable_Filter[],
  logListAppliedSorts: IUniTable_Sort[],
  logListLoading: boolean,
  logListPaginationSummary: IUniTable_PaginationSummary,

  loading: boolean,
  getScopedLogs(): Promise<void>,
  updateLogListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateLogListQueryParams?(params: IPaginatedParamsCS): void,
}

interface IState {
  individualLogOpen: ActivityLogCS,
  saving: boolean
}

class ActivityLogList extends Component<IProps, IState> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);
    
    this.state = {
      individualLogOpen: {} as ActivityLogCS,
      saving: false
    };

    this._updateTable = buildTableUpdateFunc(
      props.getScopedLogs,
      props.updateLogListTableMeta,
      props.updateLogListQueryParams
    )
  }

  _clearOpenedLog = () => {
    this.setState({
      individualLogOpen: {} as ActivityLogCS,
      saving: false
    })
  }

  requestDurationTemplate = (rowItem: any) => {
    const cName = classNames('request-duration', {
      'lt-tenth-s': rowItem.durationMs < 100, 
      'gt-half-s': rowItem.durationMs > 500, 
      'gt-one-s': rowItem.durationMs > 1000,
      'gt-one-point-five-s': rowItem.durationMs > 1500,
      'gt-two-s': rowItem.durationMs > 2000
    }
    );
    return (
      <div className={cName}>
        {`${rowItem.durationMs}ms`}
      </div>
    )
  }


  _buildLogsTableColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('requestVerb', {
        nameKey: 'verb',
        filterName: 'request_verb',
        isSortable: false,
        type: 'tag',
        size: 2
      })
      .set('request', {
        nameKey: 'request',
        filterName: 'request',
        isSortable: false,
        type: 'string',
        template: (rowItem: ActivityLogCS) => rowItem.requestUrl,
        tooltip: (rowItem: ActivityLogCS) => {
          const fullName = rowItem.getRequestName();
          return {
            hidden: fullName.length < 40,
            textKeys: [rowItem.requestVerb, rowItem.requestHost, rowItem.requestUrl]
          };
        },
        size: 6
      })
      .set('responseStatus', {
        nameKey: 'code',
        filterName: 'http_response_status',
        isSortable: false,
        type: 'tag',
        size: 2
      })
      .set('start', {
        nameKey: 'timestamp',
        isSortable: false,
        type: 'dateAndTime',
        size: 4,
      })
      .set('durationMS', {
        nameKey: 'duration',
        isSortable: false,
        type: 'string',
        template: this.requestDurationTemplate, 
        size: 2
      })
      .set('clientIp', {
        nameKey: 'clientIp',
        filterName: 'client_ip',
        isSortable: false,
        type: 'string',
        size: 4,
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 2,
      })
      .set('startRange', {
        nameKey: 'start',
        filterName: 'start',
        isSortable: false,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'date',
        size: 0
      })
      .set('endRange', {
        nameKey: 'end',
        filterName: 'end',
        isSortable: false,
        isFilterable: true,
        type: 'date',
        size: 0
      })

    const actions = new Map();
      actions
      .set('view', {
        nameKey: 'viewLogDetails',
        icon: 'removeRedEye',
        isDefaultAction: true,
        func: (rowItem: ActivityLogCS) => this.setState({ individualLogOpen: rowItem }),
        evalDisabled: (rowItem: any) => false,
        evalVisible: (rowItem: any) => true
      })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const { columns: logTableColumns, actions: logTableActions } = this._buildLogsTableColumnsAndActions();

    return (
      <section className='scoped-activity-logs-container'>

        {/* Display the list of scoped (dealer/admin) logs */}
        <section className='logList-component'>
          <UniTable
            searchable={true}
            advancedFiltering={true}
            titleKey="activityLogs"
            createButtonTextKey="log"
            handleUpdate={this._updateTable}
            data={this.props.logs}
            paginationSummary={this.props.logListPaginationSummary}
            footerStyle={EUniTable_FooterStyle.paginatedMini}
            columnConfig={logTableColumns}
            delimiterConfig={{
              display: (log: ActivityLogCS) => todayYesterdayOrDate(log?.start)!,
              value: (log: ActivityLogCS) => log?.start?.toLocaleDateString?.() ?? ''
            }}
            activeSorts={this.props.logListAppliedSorts}
            actionsConfig={logTableActions}
            activeFilters={this.props.logListAppliedFilters}
            showLoader={this.props.logListLoading} />
        </section>

        <UniConditionalRender visible={!!this.state.individualLogOpen?.start}>
          <section className='log-details-component'>
            <UniWorkflow
              inModal
              titleKey="logDetails"
              titleIcon="precisionManufacturing"
              size="widest" 
              handleClose={this._clearOpenedLog}>
                <Row>
                  <Col xs={24} md={16} xl={24}>
                    <h4><UniLocalize translate="requestName" /></h4>
                    <p>{this.state.individualLogOpen?.getRequestName?.()}</p>
                  </Col>
                  <Col xs={12} md={8}>
                    <h4><UniLocalize translate="status" /></h4>
                    <p><UniLocalize translate={EActivityLogStatusTypeCS[this.state.individualLogOpen?.status]} /></p>
                  </Col>
                  <Col xs={12} md={16} xl={8}>
                    <h4><UniLocalize translate="timestamp" /></h4>
                    <p>{dateAndTimeOrLoading(this.state.individualLogOpen?.start)}</p>
                  </Col>
                  <Col xs={12} md={4}>
                    <h4><UniLocalize translate="duration" /></h4>
                    <p>{this.requestDurationTemplate(this.state.individualLogOpen)}</p>
                  </Col>
                  <Col xs={12} md={4}>
                    <h4><UniLocalize translate="code" /></h4>
                    <p><UniTag textKey={`${this.state.individualLogOpen?.responseStatus}`} /></p>
                  </Col>
                  <Col xs={24}>
                    <h4><UniLocalize translate="requestHeaders" /></h4>
                    <UniCodeBlock content={this.state.individualLogOpen?.requestHeaders ?? 'N/A'} syntax="json" useDarkMode={this.props.activeThemeName === darkThemeName} />
                  </Col>
                  <Col xs={24}>
                    <h4><UniLocalize translate="requestBody" /></h4>
                    <UniCodeBlock content={this.state.individualLogOpen?.requestBody ?? 'N/A'} syntax="json" useDarkMode={this.props.activeThemeName === darkThemeName} />
                  </Col>
                  <Col xs={24}>
                    <h4><UniLocalize translate="responseHeaders" /></h4>
                    <UniCodeBlock content={this.state.individualLogOpen?.responseHeaders ?? 'N/A'} syntax="json" useDarkMode={this.props.activeThemeName === darkThemeName} />
                  </Col>
                  <Col xs={24}>
                    <h4><UniLocalize translate="responseBody" /> (Code: {this.state.individualLogOpen?.responseStatus}) (SubStatusCode: {this.state.individualLogOpen?.responseSubStatus})</h4>
                    <UniCodeBlock content={this.state.individualLogOpen?.responseBody ?? 'N/A'} syntax="json" useDarkMode={this.props.activeThemeName === darkThemeName} />
                  </Col>
                </Row>
                <UniOverlapGroup>
                  <UniOverlapButton
                    handleClick={this._clearOpenedLog}
                    textKey="close"
                    icon="close" />
                </UniOverlapGroup>

            </UniWorkflow>

          </section>
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    activeThemeName: state.portal.darkModeTheme
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ActivityLogList)
    ), { componentName: 'ActivityLogList' })
