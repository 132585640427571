import { IUniToast_Alert } from '@unikey/unikey-commons/release/csupp'

import {
  portalActions,
} from '../../internal';

// populate the number of alerts initially by pulling the cache values
const initialAlertsFromCache: string | null = sessionStorage.getItem('alertHistory');
const initialNumAlerts: number = initialAlertsFromCache ? JSON.parse(initialAlertsFromCache).length : 0;

const initialAlertState: any = {
  showingAlerts: new Map<string, IUniToast_Alert>(),
  numAlerts: initialNumAlerts,
  review: false,
  alertUpdate: 0 // this is used only because the states dont pick up on the changes deep within the Map().
};

export const alerts = (state = initialAlertState, action: any) => {
  switch (action.type) {
    case portalActions.ADD_ALERT:
      // add the new alert to the sessionStorage
      const prevAlerts: string | null = sessionStorage.getItem('alertHistory');
      let historyOfAlerts: IUniToast_Alert[] = [];
      if (prevAlerts) {
        historyOfAlerts = JSON.parse(prevAlerts);
      }
      historyOfAlerts.push(action.alert);
      sessionStorage.setItem('alertHistory', JSON.stringify(historyOfAlerts));

      state.showingAlerts.set(action.alert.id, action.alert);
      return {
        ...state,
        showingAlerts: state.showingAlerts,
        numAlerts: historyOfAlerts.length,
        alertUpdate: state.alertUpdate + 1
      }
    case portalActions.DISMISS_ALERT:
      state.showingAlerts.delete(action.alertId);
      let numAlerts = state.numAlerts;
      let review = state.review;

      if (action.clearFromCache) {
        const aHist: string | null = sessionStorage.getItem('alertHistory');
        sessionStorage.removeItem('')
        let allAlerts: IUniToast_Alert[] = [];
        if (aHist) {
          allAlerts = JSON.parse(aHist);
        }
        const remainingAlerts = allAlerts.filter((currAlert: IUniToast_Alert) => {
          return currAlert.id !== action.alertId
        });
        numAlerts = remainingAlerts.length;
        sessionStorage.setItem('alertHistory', JSON.stringify(remainingAlerts));
        // if we've deleted the last alert, then auto close the modal (set review to false)
        if (numAlerts === 0) {
          review = false;
        }
      }

      return {
        ...state,
        showingAlerts: state.showingAlerts,
        numAlerts,
        review,
        alertUpdate: state.alertUpdate + 1
      }
    case portalActions.DISMISS_ALL_ALERTS:
      state.showingAlerts.clear();
      return {
        ...state,
        showingAlerts: state.showingAlerts,
        alertUpdate: state.alertUpdate + 1
      }
    case portalActions.SHOW_ALL_ALERTS:
      // add the new alert to the sessionStorage
      const alertHistory: string | null = sessionStorage.getItem('alertHistory');
      let history: IUniToast_Alert[];
      if (alertHistory) {
        history = JSON.parse(alertHistory);
        history.forEach((alert: IUniToast_Alert) => {
          alert.inReview = true;
          state.showingAlerts.set(alert.id, alert);
        });
      }
      return {
        ...state,
        showingAlerts: state.showingAlerts,
        review: true,
        alertUpdate: state.alertUpdate + 1
      }
    case portalActions.HIDE_ALL_ALERTS:
      return {
        ...state,
        showingAlerts: new Map<string, IUniToast_Alert>(),
        review: false,
        alertUpdate: state.alertUpdate + 1

      }
    case portalActions.WIPE_ALL_ALERTS:
      sessionStorage.setItem('alertHistory', '[]')
      return {
        ...state,
        showingAlerts: new Map<string, IUniToast_Alert>(),
        review: false,
        alertUpdate: state.alertUpdate + 1,
        numAlerts: 0

      }
    default:
      return state;
  }
}
