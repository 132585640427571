import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniConditionalRender,
  UniTooltip,
  UniIcon,
  IUniConfirm_Config,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  IUniTable_Filter,
  IUniTable_Column,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IReaderCS,
  ReaderCS,
  EReaderStatus,
  OrganizationCS,
  UniLocalize
} from '@unikey/unikey-commons/release/csupp';

import {
  navConfig, ENavPages,
  getTableSortDirection, getTableParamsFromUpdate,
  buildTableUpdateFunc, TTableUpdateFunc,
  toggleModal,
  openConfirmModal,
  closeConfirmModal,
  updateReadersTableMeta,
  updateReaderListQueryParams,
  attemptRetrieveReaders,
  attemptRetrieveOrgReaders,
  attemptResetReaderEnrollment,
  readerUpgradeAvailableTemplate,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  readerData: IPaginatedResponseCS<IReaderCS, ReaderCS>,
  match?: any,
  readerListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  loading: boolean,
  history: any,
  
  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,

  resetEnrollment(readerId: string): Promise<void>,
  updateQueryParams?(params: IPaginatedParamsCS): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  getAllReaders(): Promise<void>
}

class ReaderListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this._updateTable = buildTableUpdateFunc(
      props.getAllReaders,
      props.updateTableMeta,
      props.updateQueryParams
    )

  }

  componentDidMount() {
    this.props.getAllReaders();
  }

  _buildColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        template: (rowItem: ReaderCS) => rowItem.name ? rowItem.name : (<i><UniLocalize translate="notProvided" /></i>),
        size: 4
      })
      .set('serialNumber', {
        nameKey: 'serialNumber',
        isFilterable: true,
        type: 'string',
        size: 4
      })
      .set('upgradeAvailableIcon', {
        nameKey: '_emptyString',
        template: readerUpgradeAvailableTemplate,
        size: 2
      })
      .set('firmwareVersion', {
        nameKey: 'version',
        isFilterable: true,
        size: 4,
        // for api filtering by different name
        filterName: 'firmwareVersion'
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        type: 'enum-tagdot',
        enumType: EReaderStatus,
        size: 4
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        size: 2
      })
      .set('id', {
        nameKey: 'id',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('productId', {
        nameKey: 'productId',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('availableUpgradeVersion', {
        nameKey: 'availableUpgradeVersion',
        isFilterable: true,
        size: 0
      })
      .set('certificateName', {
        nameKey: 'certificateName',
        size: 0
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: ReaderCS) => this.props.history.push(navConfig.get(ENavPages.readerDetails)!.linkTo([rowItem.id])!),
      evalDisbled: (rowItem: ReaderCS) => false,
      evalVisible: (rowItem: ReaderCS) => true
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='readerList-container'>
        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="readersList"
          createButtonTextKey="reader"
          handleUpdate={this._updateTable}
          data={this.props.readerData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.loading} />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    readerData: state.readers.data,
    modalOpen: state.readers.modalOpen,
    readerListQueryParams: state.readers.queryParams,
    loading: state.readers.loading,
    appliedFilters: state.readers.tableFilters,
    appliedSorts: state.readers.tableSorts,
    paginationSummary: state.readers.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal,
  getAllReaders: attemptRetrieveReaders,
  getOrgReaders: attemptRetrieveOrgReaders,
  updateTableMeta: updateReadersTableMeta,
  updateQueryParams: updateReaderListQueryParams,
  resetEnrollment: attemptResetReaderEnrollment,
  openConfirmDialog: openConfirmModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReaderListContainer)
    ), { componentName: 'ReaderList' })
