import {
  IUniToast_Alert,
  IUniConfirm_Config
} from '@unikey/unikey-commons/release/csupp'


export enum confirmActions {
  OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL',
  CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL',
}

export function openConfirmModal(config: IUniConfirm_Config) {
  return {
    type: confirmActions.OPEN_CONFIRMATION_MODAL,
    config,
  }
}

export function closeConfirmModal() {
  return {
    type: confirmActions.CLOSE_CONFIRMATION_MODAL
  }
}