
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import partnerConfig from '@alias-current-partner-customizations';

import {
  IUniToast_Alert,
  UniImg,
  EMessageType
} from '@unikey/unikey-commons/release/csupp';

import {
  mockAuth,
  PartnerCustomizations,
  checkValidAuthenticationOrRedirect,
  landingKey,
  addAlert,
  attemptLogoutRequest,
  portalRedirect,
  loginKey
} from '../internal';


interface IProps {
  match: any,
  history: any,
  darkModeActive: boolean,
  externalRedirect?: boolean,
  isActorSet?: boolean,
  tokenRefreshAttemptCompleted?: boolean,
  addAlert(config: IUniToast_Alert): void,
  logout(): void
  ensureAuthenticatedAndVerified(): any
}

class SplashContainer extends Component<IProps> {
  splashHangRedirectRef?: NodeJS.Timeout; 
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // as long as we are not expecting the redirect to happen from another source, 
    // then go ahead and make request to redirect appropriately    
    if (!this.props.externalRedirect && !mockAuth) {
      // make a request to see if we are authenticated still
      // redirect to portalLanding, login, verify, or otherwise
      if(this.props.tokenRefreshAttemptCompleted && !this.props.ensureAuthenticatedAndVerified?.()) {
        this.props.logout()
      } else if (this.props.match.path === '/') {
        this.props.history.push(landingKey);
      }
    }

    // TODO: there is a "UserManager.getUser() user not found" message logged 
    // to console sometimes. When this happens, it causes this spash screen to hang.
    // Nothing is exposed to hook into that currently, but we need to investigate 
    // a way to redirect directly when the oidc library's user is not found vs 
    // using the fallback below to redirect after an extended wait.


    // fallback to not hang on splash screen for too long. 
    // force back to login if waiting for > 10 seconds
    this.splashHangRedirectRef = setTimeout(() => {
      console.warn("Splash duration longer than expected.");
      
      // display redirecting now message to the user.
      this.props.addAlert({
        id: Date.now(),
        type: EMessageType.warn,
        titleKey: 'onSplashTooLogin',
        messageKeys: ['sendingToLogin']
      })

      // after 2 more seconds redirect the user to login
      setTimeout(() => {
        portalRedirect(loginKey);
      }, 2500);
    }, 100000);
  }

  componentWillUnmount() {
    if (this.splashHangRedirectRef) {
      clearTimeout(this.splashHangRedirectRef);
    }
  }

  render() {
    return (
      <>
        <div className="splash-container">
          <UniImg className="partner-logo splash" textKey="partner logo" src={this.props.darkModeActive ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
        </div>
      </>
    )
  }
}

function mapStateToProps(state: any, props: IProps) {
  return {
    isActorSet: state.authenticatedUser.actorTypeSet,
    tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted, 
    externalRedirect: !!props.externalRedirect,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ensureAuthenticatedAndVerified: checkValidAuthenticationOrRedirect,
  logout: attemptLogoutRequest,
  addAlert
}, dispatch)


export default  PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    (SplashContainer)
  ), { componentName: 'Splash', unauthenticated: true })
