import {
  confirmActions,
} from '../../internal'

const initialConfirmState = {
  config: {},
  open: false
};

export function confirm(state = initialConfirmState, action: any) {
  switch (action.type) {
    case confirmActions.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        open: true,
        config: action.config
      }
    case confirmActions.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        open: false,
        config: {}
      }
    default:
      return state
  }
}