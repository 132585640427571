import {
  IUniToast_Alert,
  IMultiInputUpdate
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  storeHistory,
  loginKey,
  mockAuth,
  logoutKey,
  ENavPages,
  landingKey,
  oops404Key,

} from '../../internal'

export enum portalActions {
  ADD_ALERT = 'CREATE_ALERT',
  DISMISS_ALERT = 'DISMISS_ALERT',
  DISMISS_ALL_ALERTS = 'DISMISS_ALL_ALERTS',
  SHOW_ALL_ALERTS = 'SHOW_ALL_ALERTS',
  HIDE_ALL_ALERTS = 'HIDE_ALL_ALERTS',
  WIPE_ALL_ALERTS = 'WIPE_ALL_ALERTS',
  CAPTCHA_CHANGE = 'CAPTCHA_CHANGE',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  TOGGLE_FEEDBACK_MODAL = 'TOGGLE_FEEDBACK_MODAL',
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  RESET_FEEDBACK = 'RESET_FEEDBACK',
  TOGGLE_AVATAR_MENU = 'TOGGLE_AVATAR_MENU',
  UPDATE_LOCALE = 'UPDATE_LOCALE',
  UPDATE_LOCALE_INPUT = 'UPDATE_LOCALE_INPUT',
  NAVIGATION_CHANGE = 'NAVIGATION_CHANGE',
  SET_DARK_MODE_THEME = 'SET_DARK_MODE_THEME',
  CLEAR_APPLICATION_STATE = 'CLEAR_APPLICATION_STATE'
}

export function setDarkModeTheme(theme: string) {
  return {
    type: portalActions.SET_DARK_MODE_THEME,
    theme
  }
}

export function addAlert(alertInfo: IUniToast_Alert) {
  return {
    type: portalActions.ADD_ALERT,
    alert: alertInfo,
  }
}

export function showAllAlerts() {
  return {
    type: portalActions.SHOW_ALL_ALERTS
  }
}

export function hideAllAlerts() {
  return {
    type: portalActions.HIDE_ALL_ALERTS
  }
}


export function dismissAllAlerts() {
  return {
    type: portalActions.DISMISS_ALL_ALERTS,
  }
}

export function dismissAlert(clearFromCache: boolean, alertId: number) {
  return {
    type: portalActions.DISMISS_ALERT,
    alertId,
    clearFromCache
  }
}

export function wipeAllAlerts() {
  return {
    type: portalActions.WIPE_ALL_ALERTS
  }
}

export function updateCaptcha(captchaValue: string | null) {
  return {
    type: portalActions.CAPTCHA_CHANGE,
    value: captchaValue
  }
}

export function toggleModal() {
  return {
    type: portalActions.TOGGLE_MODAL
  }
}

export function toggleFeedbackModal() {
  return {
    type: portalActions.TOGGLE_FEEDBACK_MODAL
  }
}

export function updateFeedback(feedback: IMultiInputUpdate) {
  return {
    type: portalActions.UPDATE_FEEDBACK,
    feedback
  }
}

export function resetFeedbackForm() {
  return {
    type: portalActions.RESET_FEEDBACK,
  }
}

export function languageChange(localeCode: string) {
  return {
    type: portalActions.UPDATE_LOCALE,
    localeCode
  }
}

export function localeInputChange(localeInput: string) {
  return {
    type: portalActions.UPDATE_LOCALE_INPUT,
    localeInput
  }
}

export function toggleAvatarMenu(open?: boolean) {
  return {
    type: portalActions.TOGGLE_AVATAR_MENU,
    open
  }
}


export function clearApplicationState() {
  supportApi.clearAuthValues();
  return {
    type: portalActions.CLEAR_APPLICATION_STATE
  }
}