import { Dispatch } from 'redux'

import {
  ReaderLogCS,
  IPaginatedParamsCS,
  IUniTable_UpdatePaginationSummary,
  IMultiInputUpdate,
  IReaderUpdateCS,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  setPageSizeInCache
} from '../../internal'

export enum readerActions {
  GET_READERS_REQUEST = 'GET_READERS_REQUEST',
  GET_READERS_SUCCESS = 'GET_READERS_SUCCESS',
  GET_READERS_FAILURE = 'GET_READERS_FAIL',

  GET_READER_DETAILS_REQUEST = 'GET_READER_DETAILS_REQUEST',
  GET_READER_DETAILS_SUCCESS = 'GET_READER_DETAILS_SUCCESS',
  GET_READER_DETAILS_FAILURE = 'GET_READER_DETAILS_FAILURE',

  UPDATE_READERS_QUERY_PARAMS = 'UPDATE_READERS_QUERY_PARAMS',
  UPDATE_READERS_TABLE_META = 'UPDATE_READERS_TABLE_META',

  HANDLE_READER_FORM_DATA_CHANGE = 'HANDLE_READER_FORM_DATA_CHANGE',
  UPDATE_READER_REQUEST = 'UPDATE_READER_REQUEST',
  UPDATE_READER_SUCCESS = 'UPDATE_READER_SUCCESS',
  UPDATE_READER_FAILURE = 'UPDATE_READER_FAILURE',

  GET_READER_CREDENTIALS_REQUEST = 'GET_READER_CREDENTIALS_REQUEST',
  GET_READER_CREDENTIALS_FAILURE = 'GET_READER_CREDENTIALS_FAILURE',
  GET_READER_CREDENTIALS_SUCCESS = 'GET_READER_CREDENTIALS_SUCCESS',
  UPDATE_READER_CREDENTIALS_QUERY_PARAMS = 'UPDATE_READER_CREDENTIALS_QUERY_PARAMS',
  UPDATE_READER_CREDENTIALS_TABLE_META = 'UPDATE_READER_CREDENTIALS_TABLE_META',

  GET_READER_HISTORY_REQUEST = 'GET_READER_HISTORY_REQUEST',
  GET_READER_HISTORY_FAILURE = 'GET_READER_HISTORY_FAILURE',
  GET_READER_HISTORY_SUCCESS = 'GET_READER_HISTORY_SUCCESS',
  UPDATE_READER_HISTORY_QUERY_PARAMS = 'UPDATE_READER_HISTORY_QUERY_PARAMS',
  UPDATE_READER_HISTORY_TABLE_META = 'UPDATE_READER_HISTORY_TABLE_META',

  RESET_READER_ENROLLMENT_REQUEST = 'RESET_READER_ENROLLMENT_REQUEST',
  RESET_READER_ENROLLMENT_SUCCESS = 'RESET_READER_ENROLLMENT_SUCCESS',
  RESET_READER_ENROLLMENT_FAILURE = 'RESET_READER_ENROLLMENT_FAIL',

  TOGGLE_READER_HISTORY_DETAILS = 'TOGGLE_READER_HISTORY_DETAILS',
  SET_READER_HISTORY_DETAILS = 'SET_READER_HISTORY_DETAILS'
}

// READERS LIST
const getReaderListAction = new ApiReduxAction(supportApi, {
  request: { type: readerActions.GET_READERS_REQUEST },
  success: { type: readerActions.GET_READERS_SUCCESS },
  failure: {
    type: readerActions.GET_READERS_FAILURE,
    title: 'getReadersFail',
  },
  tableMetaUpdate: {
    type: readerActions.UPDATE_READERS_TABLE_META
  }
}, (dux: IExposeRedux) => {
  const params = dux.getState().readers.queryParams;
  return supportApi.read.getAllReaders.bind(supportApi.read, params);
});
export const attemptRetrieveReaders = getReaderListAction.go;

export function updateReaderListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: readerActions.UPDATE_READERS_QUERY_PARAMS,
    queryParams
  }
}

export function updateReadersTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: readerActions.UPDATE_READERS_TABLE_META,
    ...meta
  }
}

// SINLE READER
const getSingleReaderAction = new ApiReduxAction(supportApi, {
  request: { type: readerActions.GET_READER_DETAILS_REQUEST },
  success: { type: readerActions.GET_READER_DETAILS_SUCCESS },
  failure: {
    type: readerActions.GET_READER_DETAILS_FAILURE,
    title: 'getReaderDetailsFail',
  }
}, (dux: IExposeRedux, readerId: string) => {
  return supportApi.read.getReaderById.bind(supportApi.read, readerId);
});
export const getReaderById = getSingleReaderAction.go;


export function handleReaderChange(changes: IMultiInputUpdate) {
  return {
    type: readerActions.HANDLE_READER_FORM_DATA_CHANGE,
    ...changes
  }
}

// UPDATE SINGLE READER
const updateSingleReaderAction = new ApiReduxAction(supportApi, {
  request: { type: readerActions.UPDATE_READER_REQUEST },
  success: { type: readerActions.UPDATE_READER_SUCCESS },
  failure: {
    type: readerActions.UPDATE_READER_FAILURE,
    title: 'updateReaderFail',
  }
}, (dux: IExposeRedux, readerId: string) => {
    const desiredUpgradeVerion = dux.getState().readerDetails.readerData.availableUpgradeVersion.value;
    const readerName = dux.getState().readerDetails.readerData.name.value;
    const version = desiredUpgradeVerion === "null" ? null : desiredUpgradeVerion;
    if (version === '_selectOne') {
      // dispatch(addAlert({
      //   id: Date.now(),
      //   titleKey: 'updateReaderFail',
      //   type: EMessageType.error,
      //   messageKeys: ['mustChooseAValidVersion']
      // }));
      return () => Promise.reject('mustChooseAValidVersion');
    }

    const readerUpdateBody: IReaderUpdateCS = {
      available_upgrade_version: version,
      name: readerName
    };

    return supportApi.read.updateReader.bind(supportApi.read, readerId, readerUpdateBody);
});
export const attemptUpdateReader = updateSingleReaderAction.go;

const getReaderCredentialsListAction = new ApiReduxAction(supportApi, {
  request: { type: readerActions.GET_READER_CREDENTIALS_REQUEST },
  success: { type: readerActions.GET_READER_CREDENTIALS_SUCCESS  },
  failure: {
    type: readerActions.GET_READER_CREDENTIALS_FAILURE,
    title: 'getReaderCredentialsFail',
  },
  tableMetaUpdate: {
    type: readerActions.UPDATE_READER_CREDENTIALS_TABLE_META
  }
}, (dux: IExposeRedux, readerId: string) => {
  const params = dux.getState().readerCreds.queryParams;
  return supportApi.read.getReaderCredentials.bind(supportApi.read, readerId, params);
});
export const attemptRetrieveReaderCredentials = getReaderCredentialsListAction.go;

export function updateReaderCredentialsQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: readerActions.UPDATE_READER_CREDENTIALS_QUERY_PARAMS,
    queryParams
  }
}

export function updateReaderCredentialsTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: readerActions.UPDATE_READER_CREDENTIALS_TABLE_META,
    ...meta
  }
}

// READER ENROLLMENT 
const resetReaderEnrollmentAction = new ApiReduxAction(supportApi, {
  request: { type: readerActions.RESET_READER_ENROLLMENT_REQUEST },
  success: {
    type: readerActions.RESET_READER_ENROLLMENT_SUCCESS,
    title: 'enrollmentReset',
    message: 'successfullyResetReaderEnrollment'
   },
  failure: {
    type: readerActions.RESET_READER_ENROLLMENT_FAILURE,
    title: 'resetReaderEnrollmentFailed',
  }
}, (dux: IExposeRedux, readerId: string) => {
  return supportApi.read.resetReaderEnrollment.bind(supportApi.read, readerId);
});
export const attemptResetReaderEnrollment = resetReaderEnrollmentAction.go;

// READER HISTORY
const getReaderHistoryListAction = new ApiReduxAction(supportApi, {
  request: { type: readerActions.GET_READER_HISTORY_REQUEST },
  success: { type: readerActions.GET_READER_HISTORY_SUCCESS  },
  failure: {
    type: readerActions.GET_READER_HISTORY_FAILURE,
    title: 'getReaderHistoryFail',
  },
  tableMetaUpdate: {
    type: readerActions.UPDATE_READER_HISTORY_TABLE_META
  }
}, (dux: IExposeRedux, readerId: string) => {
  const params = dux.getState().readerHistory.queryParams;
  return supportApi.read.getReaderHistory.bind(supportApi.read, readerId, params);
});
export const attemptRetrieveReaderHistory = getReaderHistoryListAction.go;

export function updateReaderHistoryQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: readerActions.UPDATE_READER_CREDENTIALS_QUERY_PARAMS,
    queryParams
  }
}

export function updateReaderHistoryTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: readerActions.UPDATE_READER_HISTORY_TABLE_META,
    ...meta
  }
}

export function toggleReaderHistoryDetailsModal() {
  return {
    type: readerActions.TOGGLE_READER_HISTORY_DETAILS
  }
}

export function setReaderHistoryDetails(logDetails: ReaderLogCS) {
  return {
    type: readerActions.SET_READER_HISTORY_DETAILS,
    selected: logDetails
  }
}