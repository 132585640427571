import { Dispatch } from 'redux'

import {
  FirmwareCS,
  ReaderCS,
  IReaderCS,
  IFirmwarePostCS,
  IFirmwarePutCS,
  IMultiInputUpdate,
  IPaginatedParamsCS,
  IPaginatedResponseCS,
  EFirmwareVersionStatus,
  IFilterCS,
  EMessageType,
  ITrackedRequest,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  setPageSizeInCache
} from '../../internal'

export enum firmwareActions {
  GET_FIRMWARE_LIST_REQUEST = 'GET_FIRMWARE_LIST_REQUEST',
  GET_FIRMWARE_LIST_SUCCESS = 'GET_FIRMWARE_LIST_SUCCESS',
  GET_FIRMWARE_LIST_FAILURE = 'GET_FIRMWARE_LIST_FAILURE',

  GET_SINGLE_FIRMWARE_REQUEST = 'GET_SINGLE_FIRMWARE_REQUEST',
  GET_SINGLE_FIRMWARE_SUCCESS = 'GET_SINGLE_FIRMWARE_SUCCESS',
  GET_SINGLE_FIRMWARE_FAILURE = 'GET_SINGLE_PRODUCT_FIRMWARE_FAILURE',

  CREATE_FIRMWARE_REQUEST = 'CREATE_FIRMWARE_REQUEST',
  CREATE_FIRMWARE_SUCCESS = 'CREATE_FIRMWARE_SUCCESS',
  CREATE_FIRMWARE_FAILURE = 'CREATE_FIRMWARE_FAILURE',

  TOGGLE_FIRMWARE_CREATE_MODAL = 'TOGGLE_FIRMWARE_CREATE_MODAL',

  HANDLE_FIRMWARE_FORM_DATA_CHANGE = 'HANDLE_FIRMWARE_FORM_DATA_CHANGE',
  UPDATE_NEW_FIRMWARE_WORKFLOW_STEP = 'UPDATE_NEW_FIRMWARE_WORKFLOW_STEP',

  FLAG_FIRMWARE_GA_REQUEST = 'FLAG_FIRMWARE_GA_REQUEST',
  FLAG_FIRMWARE_GA_SUCCESS = 'FLAG_FIRMWARE_GA_SUCCESS',
  FLAG_FIRMWARE_GA_FAILURE = 'FLAG_FIRMWARE_GA_FAILURE',

  UPDATE_FIRMWARE_REQUEST = 'UPDATE_FIRMWARE_REQUEST',
  UPDATE_FIRMWARE_SUCCESS = 'UPDATE_FIRMWARE_SUCCESS',
  UPDATE_FIRMWARE_FAILURE = 'UPDATE_FIRMWARE_FAILURE',

  GET_FIRMWARE_READERS_REQUEST = 'GET_FIRMWARE_READERS_REQUEST',
  GET_FIRMWARE_READERS_SUCCESS = 'GET_FIRMWARE_READERS_SUCCESS',
  GET_FIRMWARE_READERS_FAILURE = 'GET_FIRMWARE_READERS_FAILURE',

  UPDATE_FIRMWARE_READERS_QUERY_PARAMS = 'UPDATE_FIRMWARE_READERS_QUERY_PARAMS',
  UPDATE_FIRMWARE_READERS_TABLE_META = 'UPDATE_FIRMWARE_READERS_TABLE_META',

  SET_CURRENT_FIRMWARE = 'SET_CURRENT_FIRMWARE',
  CLEAR_FIRMWARE_FORM_DATA = 'CLEAR_FIRMWARE_FORM_DATA',

  CHANGE_FIRMWARE_LIST_VIEW_AVAILABILITY_PARAM = 'CHANGE_FIRMWARE_LIST_VIEW_AVAILABILITY_PARAM',
  HANDLE_FIRMWARE_IMAGES_OVERWRITE_CHANGE = 'HANDLE_FIRMWARE_IMAGES_OVERWRITE_CHANGE',
}

export interface IGetProductFirmwareParams { 
  productId: string,
  onlyAvailable?: boolean,
  onlySinceGA?: boolean,
}
const getProductFirmwareVersionListAction = new ApiReduxAction(supportApi, {
  request: { type: firmwareActions.GET_FIRMWARE_LIST_REQUEST },
  success: { type: firmwareActions.GET_FIRMWARE_LIST_SUCCESS },
  failure: {
    type: firmwareActions.GET_FIRMWARE_LIST_FAILURE,
    title: 'getProductFirmwareFail',
  }
}, (dux: IExposeRedux, params: IGetProductFirmwareParams) => {
  return supportApi.pdct.getProductFirmwareVersions.bind(supportApi.pdct, params.productId, !!params.onlyAvailable, !!params.onlySinceGA);
});
export const attemptRetrieveProductFirmware = getProductFirmwareVersionListAction.go;

export function toggleFirmwareCreateModal() {
  return {
    type: firmwareActions.TOGGLE_FIRMWARE_CREATE_MODAL,
  };
}

export function clearFirmwareFormData() {
  return {
    type: firmwareActions.CLEAR_FIRMWARE_FORM_DATA
  }
}

export function handleFirmwareFormDataChange(fwParts: IMultiInputUpdate = {}, images: any[], imageTemplates: any[]) {
  return {
    type: firmwareActions.HANDLE_FIRMWARE_FORM_DATA_CHANGE,
    ...fwParts,
    images,
    imageTemplates
  };
}

export function changeAvailibilityViewType(hideUnavailable: boolean) {
  return {
    type: firmwareActions.CHANGE_FIRMWARE_LIST_VIEW_AVAILABILITY_PARAM,
    hideUnavailable
  }
}

export function handleFirmwareImageOverwriteChange(image: any, imageIndex: number) {
  return {
    type: firmwareActions.HANDLE_FIRMWARE_IMAGES_OVERWRITE_CHANGE,
    image,
    imageIndex
  }
}

const createNewFirmwareVersionAction = new ApiReduxAction(supportApi, {
  request: { type: firmwareActions.CREATE_FIRMWARE_REQUEST },
  success: {
    type: firmwareActions.CREATE_FIRMWARE_SUCCESS,
    title: 'changesSaved',
    message: 'successfullyAddedFirmwareVersion'
  },
  failure: {
    type: firmwareActions.CREATE_FIRMWARE_FAILURE,
    title: 'uploadProductFirmwareFail',
  }
}, (dux: IExposeRedux, productId: string) => {  
    const firmwareData = dux.getState().firmwareEditor.formData;
    const toCreate: IFirmwarePostCS = {
      version: firmwareData.version.value,
      description: firmwareData.description.value,
      releaseNotes: firmwareData.releaseNotes.value,
      images: firmwareData.images,
      imageTemplates: firmwareData.imageTemplates,
    };
    return supportApi.pdct.uploadProductFirmwareVersion.bind(supportApi.pdct, productId, toCreate)
});
export const attemptCreateNewFirmwareForProduct = createNewFirmwareVersionAction.go;

export function updateNewFirmwareWorkflowStep(stepTo: number) {
  return {
    type: firmwareActions.UPDATE_NEW_FIRMWARE_WORKFLOW_STEP,
    stepTo
  };
}


export interface IChangeFirmwareStatusActionParams {
  firmwareId: string,
  statusTo: EFirmwareVersionStatus
}

const changeFirmwareStatusAction = new ApiReduxAction(supportApi, {
  request: { type: firmwareActions.UPDATE_FIRMWARE_REQUEST },
  success: {
    type: firmwareActions.UPDATE_FIRMWARE_SUCCESS,
    title: 'firmwareVersionUpdated',
    message: 'successfullyUpdatedStatus'
  },
  failure: {
    type: firmwareActions.UPDATE_FIRMWARE_FAILURE,
    title: 'updateFirmwareFail',
  }
}, (dux: IExposeRedux, params: IChangeFirmwareStatusActionParams) => {
    const toUpdate: Partial<IFirmwarePutCS> = {
      status: `${params.statusTo}`
    }
    return supportApi.firm.updateFirmwareVersionJson.bind(supportApi.firm, params.firmwareId, toUpdate)
});
export const attemptChangeFirmwareStatus = changeFirmwareStatusAction.go;


const flagFirmwarVersionAsGaAction = new ApiReduxAction(supportApi, {
  request: { type: firmwareActions.FLAG_FIRMWARE_GA_REQUEST },
  success: {
    type: firmwareActions.FLAG_FIRMWARE_GA_SUCCESS,
    title: 'changesSaved',
    message: 'successfullyUpdatedFirmwareVersion'
  },
  failure: {
    type: firmwareActions.FLAG_FIRMWARE_GA_FAILURE,
    title: 'flagFirmwareGaFail',
  }
}, (dux: IExposeRedux, firmwareId: string) => {
  return supportApi.firm.flagAsGenerallyAvailable.bind(supportApi.firm, firmwareId);
});
export const attemptFlagFirmwareAsGenerallyAvailable = flagFirmwarVersionAsGaAction.go;


const updateFirmwareAction = new ApiReduxAction(supportApi, {
  request: { type: firmwareActions.UPDATE_FIRMWARE_REQUEST },
  success: {
    type: firmwareActions.UPDATE_FIRMWARE_SUCCESS,
    title: 'changesSaved',
    message: 'successfullyUpdatedFirmware'
  },
  failure: {
    type: firmwareActions.UPDATE_FIRMWARE_FAILURE,
    title: 'updateFirmwareFail',
  }
}, (dux: IExposeRedux) => {
  const formParts = dux.getState().firmwareEditor.formData;
  const toUpdate: Partial<IFirmwarePutCS> = {
    id: formParts.id,
    version: formParts.version.value,
    description: formParts.description.value,
    releaseNotes: formParts.releaseNotes.value,
    images: formParts.images,
    imageTemplates: formParts.imageTemplates,
    status: formParts.status.value
  };
  return supportApi.firm.updateFirmwareVersionMultiPartForm.bind(supportApi.firm, toUpdate);
});
export const attemptUpdateFirmware = updateFirmwareAction.go;


export interface IGetSingleFirmwareActionParams {
  productId: string,
  firmwareId: string
}
const getSingleFirmwareAction = new ApiReduxAction(supportApi, {
  request: { type: firmwareActions.GET_SINGLE_FIRMWARE_REQUEST },
  success: { type: firmwareActions.GET_SINGLE_FIRMWARE_SUCCESS },
  failure: {
    type: firmwareActions.GET_SINGLE_FIRMWARE_FAILURE,
    title: 'failedToRetrieveFirmware',
  }
}, (dux: IExposeRedux, params: IGetSingleFirmwareActionParams) => {
  return (trackingOptions: ITrackedRequest) => {
    return supportApi.pdct.getProductFirmwareVersions(params.productId, false, false, trackingOptions)
      .then((fimList: FirmwareCS[]) => {
        const foundFirmware =  fimList.filter((f: FirmwareCS) => {
          return f.id === params.firmwareId;
        });
        return Promise.resolve(foundFirmware?.[0]);
      });
    };
});
export const attemptGetFirmwareById = getSingleFirmwareAction.go;

export function setCurrentFirmware(firmware: FirmwareCS) {
  return {
    type: firmwareActions.SET_CURRENT_FIRMWARE,
    firmware
  }
}

// FIRMWARE READERS LIST
export function attemptRetrieveFirmwareReaders(productId: string, firmwareVersion: string): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    const params = getState().firmwareReaders.queryParams;

    // ensure that we're always attaching the desired productId and version but never more than once
    var foundProductFilter = false;
    var foundVersionFilter = false;
    const necessaryProductFilter: IFilterCS = { fieldName: 'productId', matchType: 'EQ', value: productId };
    const necessaryVersionFilter: IFilterCS = { fieldName: 'firmwareVersion', matchType: 'EQ', value: firmwareVersion };
    // create a new filters list that is a cleaned version of the one passed in
    // to prevent duplicate/conflicting params due to the hard-filter here
    const finalFilters = params.filters.reduce((allFilters: IFilterCS[], filter: IFilterCS) => {
      if (filter.fieldName === 'productId') {
        foundProductFilter = true;
        allFilters.push(necessaryProductFilter);
      }
      else if (filter.fieldName === 'firmwareVersion') {
        foundVersionFilter = true;
        allFilters.push(necessaryVersionFilter);
      }
      else {
        allFilters.push(filter);
      }
      return allFilters;
    }, []);

    // add the hard filters to the outgoing params
    if (!foundProductFilter) {
      finalFilters?.push?.(necessaryProductFilter);
    }
    if (!foundVersionFilter) {
      finalFilters?.push?.(necessaryVersionFilter);
    }
    // set the final filters back on the outgoing parameters for search
    params.filters = finalFilters;

    dispatch(getFirmwareReadersRequest())
    const tracking = setupJobTrackingFor(firmwareActions.GET_FIRMWARE_READERS_REQUEST);
    return supportApi.read.getAllReaders(params, tracking).then((readersResult: IPaginatedResponseCS<IReaderCS, ReaderCS>) => {
      dispatch(getFirmwareReadersSuccess(readersResult))
      dispatch(updateFirmwareReadersTableMeta({
        totalCount: readersResult.total_count,
        pageNum: readersResult.paginationSummary.currPage,
        pageSize: readersResult.paginationSummary.pageSize
      }))
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else if (err.status === 404) {
        const pagination = getState().firmwareReaders.queryParams;
        // if the api responds with a 404, we should fake success with 0 readers instead of erroring
        dispatch(getFirmwareReadersSuccess({
          total_count: 0, results: [], models: [], paginationSummary: {
            pageCount: 1,
            currPage: 1,
            totalCount: 0,
            pageSize: pagination.pageSize
          }
        }))
        dispatch(updateFirmwareReadersTableMeta({
          pageCount: 1,
          pageNum: 1,
          totalCount: 0,
          pageSize: pagination.pageSize
        }))
      } else {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'getFirmwareReadersFail',
          type: EMessageType.error,
          messageKeys: [err?.data?.Messages?.[0]]
        }));
        dispatch(getFirmwareReadersFailure())
      }
    });
  }
}


export function getFirmwareReadersRequest() {
  return {
    type: firmwareActions.GET_FIRMWARE_READERS_REQUEST
  }
}

export function getFirmwareReadersSuccess(readersResult: IPaginatedResponseCS<IReaderCS, ReaderCS>) {
  return {
    type: firmwareActions.GET_FIRMWARE_READERS_SUCCESS,
    readersResult
  }
}

export function getFirmwareReadersFailure() {
  return {
    type: firmwareActions.GET_FIRMWARE_READERS_FAILURE,
  }
}


export function updateFirmwareReadersQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: firmwareActions.UPDATE_FIRMWARE_READERS_QUERY_PARAMS,
    queryParams
  }
}

export function updateFirmwareReadersTableMeta(meta: any) {
  return {
    type: firmwareActions.UPDATE_FIRMWARE_READERS_TABLE_META,
    filters: meta.filters,
    sorts: meta.sorts,
    currPage: meta.pageNum,
    pageSize: meta.pageSize,
    totalCount: meta.totalCount
  }
}
