import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl';
import { SignoutCallbackComponent } from 'redux-oidc';

import {
  supportApi,
  attemptLogoutRequest,
  SplashContainer,
  userManager,
  navConfig,
  ENavPages,
  loginKey,
  redirectToLogin,
  clearAllAuthValues
} from '../internal';

interface IProps {
  match: any,
  history: any,
  logout(): void,
  redirectToLogin(): void,
}

class OidcLogoutContainer extends Component<IProps> { 
  constructor(props: IProps) {
    super(props);
  }

  successCallback = (user?: any) => {
    // send the user to the login page after clearing their info
    const continueHashFromCache = supportApi.cacheImpl.get('continueTo');
    clearAllAuthValues();

    // set continue for redirect after we get back from sso login
    supportApi.cacheImpl.set('continueTo', continueHashFromCache);
    this.props.history.push(loginKey)
  };

  errorCallback = (error: any) => {
    console.warn(error);
    clearAllAuthValues();
    this.props.history.push(loginKey);
  };

  render() {
    return (
      <SignoutCallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}>
        <SplashContainer
          match={this.props.match}
          history={this.props.history}
          externalRedirect={true} />
      </SignoutCallbackComponent>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  logout: attemptLogoutRequest,
  redirectToLogin
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OidcLogoutContainer)
