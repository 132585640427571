import {
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi
} from '../../internal'

export enum productActions {
  GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAIL',

  SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT',

  GET_SINGLE_PRODUCT_REQUEST = 'GET_SINGLE_PRODUCT_REQUEST',
  GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS',
  GET_SINGLE_PRODUCT_FAILURE = 'GET_SINGLE_PRODUCT_FAILURE',
}

// PRODUCTS
const getProductListAction = new ApiReduxAction(supportApi, {
  request: { type: productActions.GET_PRODUCTS_REQUEST },
  success: { type: productActions.GET_PRODUCTS_SUCCESS },
  failure: {
    type: productActions.GET_PRODUCTS_FAILURE,
    title: 'getProductsFail',
  }
}, (dux: IExposeRedux) => {
  return supportApi.pdct.getAllProducts.bind(supportApi.pdct);
});
export const attemptRetrieveProducts = getProductListAction.go;

// PRODUCT DETAILS
const getSingleProduct = new ApiReduxAction(supportApi, {
  request: { type: productActions.GET_SINGLE_PRODUCT_REQUEST },
  success: { type: productActions.GET_SINGLE_PRODUCT_SUCCESS },
  failure: {
    type: productActions.GET_SINGLE_PRODUCT_FAILURE,
    title: 'getSingleProductFail',
  }
}, (dux: IExposeRedux, productId: string) => {
  return supportApi.pdct.getProductById.bind(supportApi.pdct, productId);
});
export const attemptRetrieveSingleProduct = getSingleProduct.go;