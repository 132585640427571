import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniConditionalRender,
  FirmwareCS,
  ProductCS,
  UniKeyVal,
} from '@unikey/unikey-commons/release/csupp';

import {
  attemptRetrieveSingleProduct,
  FirmwareListContainer,
  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  product: ProductCS,
  history: any,
  loading: boolean,
  getProductById(productId: string): void
}

class ProductDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getProductById(this.props.match && this.props.match.params.productId);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    var numProductImages = 0;
    var numFirmwareVersions = 0;

    if (this.props.product.id && this.props.product.images) {
      numProductImages = this.props.product.images.length;
      numFirmwareVersions = this.props.product.firmwareVersions.length;
    }
    return (
      <section className='productDetails-container'>
        <Row>
          <Col>
            <h3 className="page-title-non-table">{this.props.product && this.props.product.name}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>


            <UniKeyVal
              label="product-details"
              primaryStateButtonSet={[]}
              secondaryStateButtonSet={[]}
              showLoader={!this.props.product.id && this.props.loading}
              fields={[
                {
                  keyName: 'productId',
                  value: '' + this.props.product.id,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'productDescriptor',
                  value: this.props.product.descriptor,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'numberOfImages',
                  value: '' + numProductImages,
                  type: 'string',
                  disabled: true
                }
              ]} />

            <UniConditionalRender visible={!!this.props.product.id}>
              <FirmwareListContainer
                match={this.props.match}
                history={this.props.history} />
            </UniConditionalRender>

          </Col>
        </Row>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    product: state.productDetails.data,
    loading: state.productDetails.loading
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getProductById: attemptRetrieveSingleProduct
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ProductDetailsContainer)
    ), { componentName: 'ProductDetails' })
