import { INavConfig_FlowPage, INavConfig_NestedPage } from '@unikey/unikey-commons/release/csupp'

import {
  OrganizationListContainer,
  OrganizationDetailsContainer,
  DealerListContainer,
  DealerDetailsContainer,
  DeviceListContainer,
  DeviceDetailsContainer,
  CouponListContainer,
  CouponDetailsContainer,
  ReaderListContainer,
  ReaderDetailsContainer,
  ProductListContainer,
  ProductDetailsContainer,
  FirmwareDetailsContainer,
  CredentialListContainer,
  CredentialDetailsContainer,
  SetPasswordTokenContainer,
  AdminListContainer,
  AdminDetailsContainer,
  AnalyticsContainer,
  ActivityLogListContainer,
  VersionContainer,
} from './containers'

export enum ENavPages {
  landing = 'dealers',
  dealers = 'dealers',
  dealerDetails = 'dealerDetails',
  organizations = 'organizations',
  organizationDetails = 'organizationDetails',
  credentials = 'credentials',
  credentialDetails = 'credentialDetails',
  devices = 'devices',
  deviceDetails = 'deviceDetails',
  creditsAndCoupons = 'creditsAndCoupons',
  couponDetails = 'couponDetails',
  readers = 'readers',
  readerDetails = 'readerDetails',
  admins = 'admins',
  adminDetails = 'adminDetails',
  products = 'products',
  productDetails = 'productDetails',
  firmwareDetails = 'firmwareDetails',
  activityLogs = 'activityLogs',
  setPassword = 'setPassword',  
  analytics = 'analytics',
  version = 'version',
  actionToken = 'token',
  oidcToken = 'oidc',
  oidcRenew = 'renew',
}

const navConfig: Map<string, INavConfig_FlowPage | INavConfig_NestedPage> = new Map();

// Dealers
navConfig.set(ENavPages.dealers, {
  icon: 'accountBalance',
  nameKey: ENavPages.dealers,
  flowMatch: /portal\/dealers.*/,
  component: DealerListContainer,
  linkTo: (r: any = []) => '/portal/dealers',
});
navConfig.set(ENavPages.dealerDetails, {
  nameKey: ENavPages.dealerDetails,
  icon: 'removeRedEye',
  pageMatch: /portal\/dealers\/[^\/]{1,}\/?$/,
  component: DealerDetailsContainer,
  breadcrumbs: [navConfig.get('dealers')!],
  linkTo: (r: any = []) => `/portal/dealers/${r[0] ? r[0] : ':dealerId'}`,
});

// Organizations
navConfig.set(ENavPages.organizations, {
  icon: 'business',
  nameKey: ENavPages.organizations,
  flowMatch: /portal\/organizations.*/,
  component: OrganizationListContainer,
  linkTo: (r: any = []) => '/portal/organizations',
});
navConfig.set(ENavPages.organizationDetails, {
  icon: 'removeRedEye',
  nameKey: ENavPages.organizationDetails,
  pageMatch: /portal\/organizations\/[^\/]{1,}\/?$/,
  component: OrganizationDetailsContainer,
  breadcrumbs: [navConfig.get(ENavPages.organizations)!],
  linkTo: (r: any = []) => `/portal/organizations/${r[0] ? r[0] : ':organizationId'}`,
});

// Credentials
navConfig.set(ENavPages.credentials, {
  icon: 'vpnKey',
  nameKey: ENavPages.credentials,
  flowMatch: /portal\/credentials.*/,
  component: CredentialListContainer,
  linkTo: (r: any = []) => '/portal/credentials',
});
navConfig.set(ENavPages.credentialDetails, {
  icon: 'removeRedEye',
  nameKey: ENavPages.credentialDetails,
  pageMatch: /portal\/credentials\/[^\/]{1,}\/?$/,
  component: CredentialDetailsContainer,
  breadcrumbs: [navConfig.get(ENavPages.credentials)!],
  linkTo: (r: any = []) => `/portal/credentials/${r[0] ? r[0] : ':credentialId'}`,
});

navConfig.set(ENavPages.devices, {
  icon: 'devices',
  nameKey: ENavPages.devices,
  flowMatch: /portal\/devices.*/,
  component: DeviceListContainer,
  linkTo: (r: any = []) => '/portal/devices',
});
navConfig.set(ENavPages.deviceDetails, {
  nameKey: ENavPages.deviceDetails,
  icon: 'removeRedEye',
  pageMatch: /portal\/devices\/[^\/]{1,}\/?$/,
  component: DeviceDetailsContainer,
  breadcrumbs: [navConfig.get('devices')!],
  linkTo: (r: any = []) => `/portal/devices/${r[0] ? r[0] : ':deviceId'}`,
});


// Coupons
navConfig.set(ENavPages.creditsAndCoupons, {
  icon: 'confirmationNumber',
  nameKey: 'coupons',
  flowMatch: /portal\/coupons.*/,
  component: CouponListContainer,
  linkTo: (r: any = []) => '/portal/coupons',
  evalHidden: (shouldSee: boolean) => shouldSee 
});
navConfig.set(ENavPages.couponDetails, {
  icon: 'confirmationNumber',
  nameKey: ENavPages.couponDetails,
  component: CouponDetailsContainer,
  pageMatch: /portal\/coupons\/[^\/]{1,}\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.creditsAndCoupons)!],
  linkTo: (r: any = []) => `/portal/coupons/${r[0] ? r[0] : ':couponId'}`,
});

// Readers
navConfig.set(ENavPages.readers, {
  icon: 'settingsRemote',
  nameKey: ENavPages.readers,
  flowMatch: /portal\/readers.*/,
  component: ReaderListContainer,
  linkTo: (r: any = []) => '/portal/readers',
});
navConfig.set(ENavPages.readerDetails, {
  icon: 'settingsRemote',
  nameKey: ENavPages.readerDetails,
  component: ReaderDetailsContainer,
  pageMatch: /portal\/readers\/[^\/]{1,}\/?$/,
  breadcrumbs: [navConfig.get(ENavPages.readers)!],
  linkTo: (r: any = []) => `/portal/readers/${r[0] ? r[0] : ':readerId'}`,
});

// Admins
navConfig.set(ENavPages.admins, {
  icon: 'people',
  nameKey: ENavPages.admins,
  flowMatch: /portal\/admins.*/,
  component: AdminListContainer,
  linkTo: (r: any = []) => '/portal/admins',
});
navConfig.set(ENavPages.adminDetails, {
  icon: 'person',
  nameKey: ENavPages.adminDetails,
  pageMatch: /portal\/admins\/[^\/]{1,}\/?$/,
  component: AdminDetailsContainer,
  breadcrumbs: [navConfig.get(ENavPages.admins)!],
  linkTo: (r: any = []) => `/portal/admins/${r[0] ? r[0] : ':adminId'}`,
});

// Products
navConfig.set(ENavPages.products, {
  icon: 'localOffer',
  nameKey: ENavPages.products,
  flowMatch: /portal\/products.*/,
  component: ProductListContainer,
  linkTo: (r: any = []) => '/portal/products',
});
navConfig.set(ENavPages.productDetails, {
  icon: 'removeRedEye',
  nameKey: ENavPages.productDetails,
  pageMatch: /portal\/products\/[^\/]{1,}\/?$/,
  component: ProductDetailsContainer,
  breadcrumbs: [navConfig.get(ENavPages.products)!],
  linkTo: (r: any = []) => `/portal/products/${r[0] ? r[0] : ':productId'}`,
});
navConfig.set(ENavPages.firmwareDetails, {
  icon: 'removeRedEye',
  nameKey: ENavPages.firmwareDetails,
  pageMatch: /portal\/products\/[^\/]{1,}\/firmware\/[^\/]{1,}\/?$/,
  component: FirmwareDetailsContainer,
  breadcrumbs: [navConfig.get(ENavPages.products)!, navConfig.get(ENavPages.productDetails)!],
  linkTo: (r: any = []) => `/portal/products/${r[0] ? r[0] : ':productId'}/firmware/${r[1] ? r[1] : ':firmwareId'}`,
});

// ActivityLogs
navConfig.set(ENavPages.activityLogs, {
  icon: 'precisionManufacturing',
  nameKey: ENavPages.activityLogs,
  flowMatch: /portal\/activitylogs.*/,
  component: ActivityLogListContainer,
  linkTo: (r: any = []) => '/portal/activitylogs',
  evalHidden: (shouldSee: boolean) => shouldSee // hide this item if its not a unikey actor
});


// Analytics
navConfig.set(ENavPages.analytics, {
  icon: 'dataUsage',
  nameKey: ENavPages.analytics,
  flowMatch: /portal\/analytics.*/,
  component: AnalyticsContainer,
  linkTo: (r: any = []) => `/portal/analytics`,
  evalHidden: (shouldSee: boolean) => shouldSee // hide this item if its not a unikey actor
});


// hidden version page
navConfig.set(ENavPages.version, {
  icon: 'about',
  nameKey: ENavPages.version,
  component: VersionContainer,
  flowMatch: /portal\/version\/?$/,
  linkTo: (r: any = []) => '/portal/version',
  evalHidden: () => true, // hidden from left nav always
});

// action token pages
navConfig.set(ENavPages.setPassword, {
  icon: 'about',
  nameKey: ENavPages.setPassword,
  component: SetPasswordTokenContainer,
  flowMatch: /account\/password\/([^\/]{1,})\/?$/,
  linkTo: (r: any = []) => `/account/password/${r[0] ? r[0] : ':actionToken'}`,
  evalHidden: () => true, // hidden from left nav always
});

export const loginKey = '/login';
export const landingKey = '/portal/dealers';
export const oops404Key = '/oops/404';
export const oops403Key = '/oops/403';
export const logoutKey = '/logout';
export const tokenKey = '/token';
export const splashKey = '/splash';
export const versionKey = '/version';
export const verifyKey = '/verify';
export const oopsKey = '/oops';

export { navConfig };

