import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, matchPath } from 'react-router'
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system'
import classNames from 'classnames';

import {
  darkThemeName,
  UniThemeToggle,
  UniLeftNav,
  UniButton,
  UniAvatar,
  UniMenu,
  UniConfirm,
  UniBreadcrumbs,
  UniConditionalRender,
  UniImg,
  UserCS,
  IUniConfirm_Config,
  INavConfig_FlowPage,
  INavConfig_NestedPage
} from '@unikey/unikey-commons/release/csupp';

import {
  partnerKey,
  SplashContainer,
  FooterContainer,
  toggleAvatarMenu,
  showAllAlerts,
  toggleModal,
  setDarkModeTheme,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig,
  ENavPages,
  environment
} from '../internal'

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends IPartnerCustomizations {
  match: any,
  history: any, // TODO: pull the appropriate type from connected-react-router
  location: any,
  authenticatedUser: any,
  authenticatedUsername?: string,
  isUniKeyActor: boolean,
  hideCouponFlow?: boolean,
  activePath: string,
  avatarMenuOpen: boolean,
  numAlerts: number,
  navigationConfig: Map<string, INavConfig_FlowPage | INavConfig_NestedPage>,
  minimized?: boolean,
  logo?: string,
  darkModeTheme: string,
  confirmDialogOpen: boolean,
  confirmDialogConfig: IUniConfirm_Config,
  tokenRefreshAttemptCompleted?: boolean,
  currentExpiry?: string,
  tokenExpired?: boolean,
  oidcUpdater?: number,
  changeDarkModeTheme(theme: string): void,
  showAllAlerts(): void,
  toggleModal(): void,
  toggleAvatarMenu(open?: boolean): void
}

class Portal extends Component<IProps> {
  avatarMenuConfig: Map<string, any>;
  logoSrc: any;
  leftNavPages: Map<string, INavConfig_FlowPage>;

  constructor(props: IProps) {
    super(props);

    this.avatarMenuConfig = new Map()
    .set('logout', {
      textKey: 'logout',
      handleClick: this.props.history.push.bind(this, '/logout')
    });

    this._avatarMenuToggle = this._avatarMenuToggle.bind(this);
    this.leftNavPages = this._calcLeftNavPages(props);
  }

  componentDidUpdate(prevProps: IProps) {
    // if we've calculated new permissions, we should refigure the leftNav pages
    if (!prevProps.isUniKeyActor && this.props.isUniKeyActor) {
      this.leftNavPages = this._calcLeftNavPages(this.props);
    }
  }

  _calcLeftNavPages = (props: IProps) => {
    return [...props.navigationConfig.keys()].reduce((map: Map<string, INavConfig_FlowPage>, navKey: string) => {
      const item: INavConfig_FlowPage | INavConfig_NestedPage = props.navigationConfig.get(navKey)!;
      if ((item as INavConfig_FlowPage).flowMatch) {
        var includeCurrFlowItem = true;
        
        if (item.nameKey === 'coupons') {
          // special case for coupons
          // all other flows are shown/hidden absed on (is/isnt) unikey user
          includeCurrFlowItem = !props.hideCouponFlow;
        } else {
          // if something is restricted access, only add to the left nav if the user has elevated permissions
          if (((item as INavConfig_FlowPage).evalHidden ? (item as INavConfig_FlowPage).evalHidden!(!props.isUniKeyActor) : false)) {
            includeCurrFlowItem = false;
          }
        }

        if (includeCurrFlowItem) {
          map.set(navKey, (item as INavConfig_FlowPage)!);
        }
      }
      return map;
    }, new Map());
  }

  _determineActivePage(): INavConfig_FlowPage | INavConfig_NestedPage {
    var matchedNavConfig: any = {};
    [...this.props.navigationConfig.values()].some((navItem: INavConfig_FlowPage | INavConfig_NestedPage) => {
      matchedNavConfig = navItem;
      if ((navItem as INavConfig_FlowPage).flowMatch) {
        return (navItem as INavConfig_FlowPage).flowMatch.test(this.props.activePath);
      } else if ((navItem as INavConfig_NestedPage).pageMatch) {
        return (navItem as INavConfig_NestedPage).pageMatch.test(this.props.activePath);
      } else {
        return false;
      }
    });
    return (matchedNavConfig as INavConfig_FlowPage | INavConfig_NestedPage);
  }

  _avatarMenuToggle() {
    this.props.toggleAvatarMenu(!this.props.avatarMenuOpen);
  }

  _getCurrentPageBreadcrumbs(location: any) {
    var activeNav: any;
    [...navConfig.values()].some((navItem: any, index: number) => {
      if (location && navItem.pageMatch && navItem.pageMatch!.test(location.pathname)) {
        activeNav = Object.assign({}, navItem, { active: true });
        return true;
      }
      return false;
    });
    if (activeNav && activeNav.breadcrumbs) {
      return [].concat(activeNav.breadcrumbs, activeNav);
    }
    return [];
  }

  _displayAlertHistory = () => {
    this.props.toggleModal();
    this.props.showAllAlerts();
  }

  _determineSubNavItems = (activePage: INavConfig_FlowPage | INavConfig_NestedPage): INavConfig_NestedPage[] => {
    // active page does not have breadcrumbs, then we're not in a nested page, so dont show any pagess
    if (!Array.isArray((activePage as INavConfig_NestedPage).breadcrumbs)) {
      return [];
    }
    return ([...navConfig.values()].filter((flowData: INavConfig_FlowPage | INavConfig_NestedPage, index: number): boolean => {
      if (!Array.isArray((flowData as INavConfig_NestedPage).breadcrumbs)) {
        return false;
      } else if ((flowData as INavConfig_NestedPage).breadcrumbs[0].nameKey === (activePage as INavConfig_NestedPage).breadcrumbs[0].nameKey) {
        // if the current page shares the same parent as teh active page, then show it in this subnav
        return true;
      }
      return false;
    }) as INavConfig_NestedPage[]);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const logoImgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    const activePage = this._determineActivePage();
    var pathParams: string[] = [];
    if ((activePage as INavConfig_NestedPage).pageMatch) {
      pathParams = this.props.activePath.match((activePage as INavConfig_NestedPage).pageMatch) ? this.props.activePath.match((activePage as INavConfig_NestedPage).pageMatch)!.slice(1) : [];
    }

    const currPageSubNavOptions: INavConfig_NestedPage[] = this._determineSubNavItems(activePage);
    const routes = [...this.props.navigationConfig.values()].map((navItem: INavConfig_FlowPage | INavConfig_NestedPage) => {
      const ComponentName: any = navItem.component;
      // TODO: There is a bug with connected-react-router not passing the correct match to the switch component
      // to get around this issue, we are passing in an extra unique key so that the route
      // recognizes when it matched AND the location is different from before.
      // After bug is fixed we should be able to remove the `-${props.activePath}` in the key below

      return (
        <Route path={navItem.linkTo()} exact={true} key={`route-${navItem.nameKey}-${this.props.activePath}`} children={(routeProps: any) => (
          <>
            <UniConditionalRender visible={!!(navItem as INavConfig_NestedPage).breadcrumbs}>
              <UniBreadcrumbs
                navConfig={navConfig}
                // crumbs={this._getCurrentPageBreadcrumbs(routeProps.location)}
                activePage={activePage}
                subNavOptions={currPageSubNavOptions}
                navigateTo={this.props.history.push.bind(this)}
                {...routeProps} />
            </UniConditionalRender>
            <ComponentName {...routeProps} />
          </>
        )} />
      );
    });

    return (
      <ScreenClassRender render={(screenClass: string) => (
        <>
          <section className="portal-container">
            <div className="portal-nav">
              <UniLeftNav
                activeNav={activePage}
                initiallyClosed={this.props.minimized || environment === 'development'}
                flowConfig={this.leftNavPages} />
            </div>

            <div className="portal-main">
              <div className={classNames('left-of-secondary-nav', { 'room-for-secondary-nav': screenClass !== 'xs' })}>
                <div className={classNames('portal-header', { 'room-for-mobile-nav': screenClass === 'xs' })}>
                  <Row>
                    <Col xs={10} sm={8} offset={{ xs: 2, sm: 0, md: 0, xl: 2 }} style={{ marginBottom: '6px' }}>
                      <UniImg className={logoImgClassName} textKey="partner logo" src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
                    </Col>

                    <Col xs={12} sm={16} xl={14}>

                      <UniConditionalRender visible={!!this.props.authenticatedUsername}>
                        <UniAvatar
                          firstName={this.props.authenticatedUser?.firstName?.value ?? ''}
                          lastName={this.props.authenticatedUser?.lastName?.value ?? ''}
                          username={this.props.authenticatedUser?.username ?? ''}
                          handleClick={this._avatarMenuToggle}
                          namePosition="right"
                          nameVisible={screenClass !== 'xs' && screenClass !== 'sm'}>
                          <UniMenu
                            toggleMenuVisibility={this._avatarMenuToggle}
                            visible={this.props.avatarMenuOpen}
                            items={this.avatarMenuConfig}
                            theme="default" />
                        </UniAvatar>
                      </UniConditionalRender>

                      <UniButton
                        className="alert-history-btn"
                        textKey="alertHistory"
                        icon="notifications"
                        iconSize="md"
                        hideText={true}
                        theme="inverted"
                        handleClick={this._displayAlertHistory}
                        disabled={this.props.numAlerts === 0}>
                        <UniConditionalRender visible={this.props.numAlerts > 0}>
                          <div className="alert-notice" />
                        </UniConditionalRender>
                      </UniButton>

                      <UniThemeToggle
                        className="top-nav-btn"
                        hideText={true}
                        onThemeChange={this.props.changeDarkModeTheme} />

                    </Col>
                  </Row>
                </div>
              </div>

              <div className="portal-content">
                <Row>
                  <Col lg={20} offset={{ lg: 2 }}>
                    {/* passing in a custom location so that we can navigate in this nested switch statement as expected */}
                    <Switch location={Object.assign(this.props.location, { pathname: this.props.activePath })} >
                      {routes}
                      <Route component={SplashContainer} />
                    </Switch>
                  </Col>
                </Row>
              </div>
            </div>

          </section>
          <FooterContainer />

          {/* Confirm dialog can appear anywhere in the portal,so we're including the markdown for it here rather than repeating it. */}
          <UniConditionalRender visible={this.props.confirmDialogOpen}>
            <UniConfirm {...this.props.confirmDialogConfig} />
          </UniConditionalRender>
        </>
      )} />
    )
  }
}

function mapStateToProps(state: any) {
  const userFromCache = sessionStorage.getItem('user');
  let user;
  if (userFromCache) {
    user = new UserCS(JSON.parse(userFromCache));
  }
  else {
    user = state.authenticatedUser.user;
  }
  return {
    authenticatedUser: user,
    authenticatedUsername: user?.username,
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
    authenticatedToken: state.authenticatedUser.parsedToken,
    activePath: state.router.location.pathname,
    avatarMenuOpen: state.portal.avatarMenuOpen,
    numAlerts: state.alerts.numAlerts,
    confirmDialogConfig: state.confirm.config,
    confirmDialogOpen: state.confirm.open,
    darkModeTheme: state.portal.darkModeTheme,
    tokenRefreshAttemptCompleted: state.oidcAuthStatus.refreshAttempted, 
    tokenExpired: state.oidcAuthStatus.isExpired, 
    currentExpiry: state.oidcAuthStatus.currentExpiry,
    oidcUpdater: state.oidcAuthStatus.updater,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  changeDarkModeTheme: setDarkModeTheme,
  toggleAvatarMenu,
  toggleModal,
  showAllAlerts
}, dispatch);

export default PartnerCustomizations(connect(mapStateToProps, mapDispatchToProps)(Portal),
  { componentName: 'Portal', unauthenticated: false, waitForToken: true })
