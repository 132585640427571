import { Dispatch } from 'redux'
import { Promise } from 'bluebird';

import {
  DealerCS,
  CouponCS,
  IPaginatedParamsCS,
  ICreateCouponRequestCS,
  IMultiInputUpdate,
  EMessageType,
  IUniTable_UpdatePaginationSummary,
  ITrackedRequest,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  checkJobStatusOnInterval,
  setPageSizeInCache,
} from '../../internal'

export enum couponActions {
  GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS',
  GET_COUPONS_FAILURE = 'GET_COUPONS_FAIL',

  SET_ACTIVE_COUPON = 'SET_ACTIVE_COUPON',

  GET_SINGLE_COUPON_REQUEST = 'GET_SINGLE_COUPON_REQUEST',
  GET_SINGLE_COUPON_SUCCESS = 'GET_SINGLE_COUPON_SUCCESS',
  GET_SINGLE_COUPON_FAILURE = 'GET_SINGLE_COUPON_FAILURE',

  UPDATE_COUPON_QUERY_PARAMS = 'UPDATE_COUPON_QUERY_PARAMS',
  UPDATE_COUPON_TABLE_META = 'UPDATE_COUPON_TABLE_META',

  GET_CREDIT_UNIT_SUMMARY_REQUEST = 'GET_CREDIT_UNIT_SUMMARY_REQUEST',
  GET_CREDIT_UNIT_SUMMARY_SUCCESS = 'GET_CREDIT_UNIT_SUMMARY_SUCCESS',
  GET_CREDIT_UNIT_SUMMARY_FAILURE = 'GET_CREDIT_UNIT_SUMMARY_FAILURE',

  HANDLE_UNIT_LIMIT_CHANGE = 'HANDLE_UNIT_LIMIT_CHANGE',

  UPDATE_UNIT_LIMIT_REQUEST = 'UPDATE_UNIT_LIMIT_REQUEST',
  UPDATE_UNIT_LIMIT_SUCCESS = 'UPDATE_UNIT_LIMIT_SUCCESS',
  UPDATE_UNIT_LIMIT_FAILURE = 'UPDATE_UNIT_LIMIT_FAILURE',

  TOGGLE_CREATE_COUPON_MODAL = 'TOGGLE_CREATE_COUPON_MODAL',
  TOGGLE_ASSIGN_COUPON_MODAL = 'TOGGLE_ASSIGN_COUPON_MODAL',

  UPDATE_CREATE_COUPON_JOB_STATUS = 'UPDATE_CREATE_COUPON_JOB_STATUS',
  CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST',
  CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE',
  CLEAR_CREATE_COUPON_FORM = 'CLEAR_CREATE_COUPON_FORM',

  HANDLE_COUPON_CHANGE = 'HANDLE_COUPONS_CHANGE',
  HANDLE_COUPON_CREATE_STEP_CHANGE = 'HANDLE_COUPONS_CREATE_STEP_CHANGE',

  UPDATE_ASSIGN_COUPONS_TO_DEALER_JOB_STATUS = 'UPDATE_ASSIGN_COUPONS_TO_DEALER_JOB_STATUS',
  ASSIGN_COUPONS_TO_DEALER_REQUEST = 'ASSIGN_COUPONS_TO_DEALER_REQUEST',
  ASSIGN_COUPONS_TO_DEALER_SUCCESS = 'ASSIGN_COUPONS_TO_DEALER_SUCCESS',
  ASSIGN_COUPONS_TO_DEALER_FAILURE = 'ASSIGN_COUPONS_TO_DEALER_FAILURE',

  HANDLE_ASSIGN_COUPONS_TO_DEALER_CHANGE = 'HANDLE_ASSIGN_COUPONS_TO_DEALER_CHANGE',
  HANDLE_ASSIGN_COUPONS_TO_DEALER_STEP_CHANGE = 'HANDLE_ASSIGN_COUPONS_TO_DEALER_STEP_CHANGE',
  CLEAR_ASSIGN_COUPONS_TO_DEALER_FORM = 'CLEAR_ASSIGN_COUPONS_TO_DEALER_FORM'
}

// Coupons
const getCouponListAction = new ApiReduxAction(supportApi, {
  request: { type: couponActions.GET_COUPONS_REQUEST },
  success: { type: couponActions.GET_COUPONS_SUCCESS },
  failure: {
    type: couponActions.GET_COUPONS_FAILURE,
    title: 'getCouponsFail',
  },
  tableMetaUpdate: {
    type: couponActions.UPDATE_COUPON_TABLE_META
  }
}, (dux: IExposeRedux) => {
  const params = dux.getState().coupons.queryParams;
  return supportApi.coup.getAllCoupons.bind(supportApi.coup, params);
});
export const attemptRetrieveCoupons = getCouponListAction.go;

export function updateCouponListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: couponActions.UPDATE_COUPON_QUERY_PARAMS,
    queryParams
  }
}

export function updateCouponTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: couponActions.UPDATE_COUPON_TABLE_META,
    ...meta
  }
}


// Coupon Credit LImit
const getCouponCreditLimitSummaryAction = new ApiReduxAction(supportApi, {
  request: { type: couponActions.GET_CREDIT_UNIT_SUMMARY_REQUEST },
  success: { type: couponActions.GET_CREDIT_UNIT_SUMMARY_SUCCESS },
  failure: {
    type: couponActions.GET_CREDIT_UNIT_SUMMARY_FAILURE,
    title: 'getCreditUnitSummaryFail',
  }
}, (dux: IExposeRedux) => {
  return supportApi.coup.getCreditSummary.bind(supportApi.coup);
});
export const attemptRetrieveCreditUnitSummary = getCouponCreditLimitSummaryAction.go;


export function handleUnitLimitChange(changes: IMultiInputUpdate) {
  return {
    type: couponActions.HANDLE_UNIT_LIMIT_CHANGE,
    ...changes
  };
}


// Update Coupon Credit Limit
const updateCouponCreditLimitAction = new ApiReduxAction(supportApi, {
  request: { type: couponActions.UPDATE_UNIT_LIMIT_REQUEST },
  success: {
    type: couponActions.UPDATE_UNIT_LIMIT_SUCCESS,
    title: 'creditUnitLimitUpdated',
    message: 'creditUnitLimitUpdatedSuccessfully'
  },
  failure: {
    type: couponActions.UPDATE_UNIT_LIMIT_FAILURE,
    title: 'updateUnitLimitFail',
  }
}, (dux: IExposeRedux) => {
  const limitAmt = dux.getState().credits.updateLimit.units.value;
  return supportApi.coup.updateCreditLimit.bind(supportApi.coup, limitAmt);
});
export const attemptUpdateUnitLimit = updateCouponCreditLimitAction.go;

// Single Coupon
const getSingleCouponAction = new ApiReduxAction(supportApi, {
  request: { type: couponActions.GET_SINGLE_COUPON_REQUEST },
  success: { type: couponActions.GET_SINGLE_COUPON_SUCCESS },
  failure: {
    type: couponActions.GET_SINGLE_COUPON_FAILURE,
    title: 'getSingleCouponFail',
  }
}, (dux: IExposeRedux, couponId: string) => {
  return supportApi.coup.getCouponById.bind(supportApi.coup, couponId);
});
export const attemptRetrieveCouponById = getSingleCouponAction.go;

export function toggleCreateCouponsModal() {
  return {
    type: couponActions.TOGGLE_CREATE_COUPON_MODAL,
  }
}

export function clearAndToggleCreateCouponForm() {
  return {
    type: couponActions.CLEAR_CREATE_COUPON_FORM
  }
}


export function handleCouponChange(changes: IMultiInputUpdate, isGiveaway?: boolean) {
  return {
    type: couponActions.HANDLE_COUPON_CHANGE,
    ...changes,
    giveaway: isGiveaway
  };
}

export function handleCouponCreateStepChange(newStepIndex: number) {
  return {
    type: couponActions.HANDLE_COUPON_CREATE_STEP_CHANGE,
    newStepIndex
  };
}

// Create Coupons
const createCouponAction = new ApiReduxAction(supportApi, {
  onInterval: { type: couponActions.UPDATE_CREATE_COUPON_JOB_STATUS },
  request: { type: couponActions.CREATE_COUPON_REQUEST },
  success: {
    type: couponActions.CREATE_COUPON_SUCCESS,
    title: 'couponsCreated',
    message: 'successfullyCreatedTheCoupons'
  },
  failure: {
    type: couponActions.CREATE_COUPON_FAILURE,
    title: 'createCouponsFail',
  }
}, (dux: IExposeRedux) => {
  const couponCriteria = dux.getState().couponForm.newCoupon;
  const couponsToCreate = couponCriteria.giveaway ? 1 : Number(couponCriteria.duplicates?.value || 1);
  const requestData: ICreateCouponRequestCS = {
    units: couponCriteria.units.value,
    emailTo: couponCriteria.email.value,
    isGiveaway: couponCriteria.giveaway
  };

  return (trackingOptions: Partial<ITrackedRequest>) => {
    return Promise.map([...Array(couponsToCreate).fill(couponsToCreate)], (v: number, index: number) => {
      return Promise.delay(100 * index, supportApi.coup.createCoupon(requestData, trackingOptions));
    });
  };
});
export const attemptCreateCoupons = createCouponAction.go;

export function peekCreateCouponsJobStatus() {
  return {
    type: couponActions.UPDATE_CREATE_COUPON_JOB_STATUS
  }
}

export function toggleAssignCouponModal() {
  return {
    type: couponActions.TOGGLE_ASSIGN_COUPON_MODAL
  }
}

// assign coupon(s) to a dealer
export interface IAssignCouponToDealerActionParams {
  couponIds: string[],
  dealerId: string
}

const assignCouponToDealerAction = new ApiReduxAction(supportApi, {
  onInterval: { type: couponActions.UPDATE_ASSIGN_COUPONS_TO_DEALER_JOB_STATUS },
  request: { type: couponActions.ASSIGN_COUPONS_TO_DEALER_REQUEST },
  success: {
    type: couponActions.ASSIGN_COUPONS_TO_DEALER_SUCCESS,
    title: 'couponsAssigned',
    message: 'successfullyAssignedTheCoupons'
  },
  failure: {
    type: couponActions.ASSIGN_COUPONS_TO_DEALER_FAILURE,
    title: 'assignDealerCouponsFail',
  }
}, (dux: IExposeRedux, params: IAssignCouponToDealerActionParams) => {  
  return (trackingOptions: Partial<ITrackedRequest>) => {
    return Promise.map(params.couponIds, (cId: string, index: number) => {
      return Promise.delay(200 * index, supportApi.coup.assignCouponToDealer(cId, params.dealerId, trackingOptions));
    });
  };
});
export const attemptAssignCouponsToDealer = assignCouponToDealerAction.go;

export function handleAssignCouponStepChange(newStepIndex: number) {
  return {
    type: couponActions.HANDLE_ASSIGN_COUPONS_TO_DEALER_STEP_CHANGE,
    newStepIndex
  };
}

export function clearCouponAssignmentForm() {
  return {
    type: couponActions.CLEAR_ASSIGN_COUPONS_TO_DEALER_FORM
  }
}

export function handleAssignCouponFormChange(dealersMap?: Map<string, DealerCS>, couponsMap?: Map<string, CouponCS>) {
  return {
    type: couponActions.HANDLE_ASSIGN_COUPONS_TO_DEALER_CHANGE,
    dealersMap,
    couponsMap
  };
}