import { Dispatch } from 'redux'

import {  
  AuthUserCS,
  EMessageType,
  IMultiInputUpdate,
  decodeJwt,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  identityApi,
  supportApi,
  addAlert,
  wipeAllAlerts,
  clearApplicationState,
  redirectToLogin,
  portalRedirect,
  clearAllAuthValues,
  oops403Key,
  verifyKey
} from '../../internal'

export enum authActions {
  UPDATE_USERNAME = 'UPDATE_USERNAME',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',

  GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',

  SET_ACTOR_TYPE = 'SET_ACTOR_TYPE',
  
  GET_IDENTITY_AUTH_ACCOUNT_REQUEST = 'GET_IDENTITY_AUTH_ACCOUNT_REQUEST',
  GET_IDENTITY_AUTH_ACCOUNT_SUCCESS = 'GET_IDENTITY_AUTH_ACCOUNT_SUCCESS',
  GET_IDENTITY_AUTH_ACCOUNT_FAILURE = 'GET_IDENTITY_AUTH_ACCOUNT_FAILURE',

}

export function oidcLoginRequest() {
  return {
    type: authActions.LOGIN_REQUEST
  }
}

export function oidcLoginSuccess(user: any, isUniKeyActor: boolean) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(wipeAllAlerts())
    dispatch({
      type: authActions.LOGIN_SUCCESS,
      user,
      isUniKeyActor
    })
  }
}

export function oidcLoginFailure(errMessage: string) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(addAlert({
      id: Date.now(),
      titleKey: 'loginFailure',
      type: EMessageType.error,
      messageKeys: [errMessage]
    }));
    dispatch(logoutRequest())
    dispatch({
      type: authActions.LOGIN_FAILURE,
      errMessage
    })
  }
}

export function logoutRequest() {
  return {
    type: authActions.LOGOUT_REQUEST
  }
}

export function logoutSuccess() {
  return {
    type: authActions.LOGOUT_SUCCESS
  }
}

export function attemptLogoutRequest(preventOidcRedirect: boolean = false) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    clearAllAuthValues();
    if (!preventOidcRedirect) {
      // oidc handles logout, so we just need to clear our own 
      // application state and cache then redirect the user
      dispatch(redirectToLogin({ preventContinue: true }));
      dispatch(clearApplicationState())
    }
  }
}

export function setAuthActorFromAccessTokenOrRedirect(): any {  
  return (dispatch: Dispatch<any>, getState: any): any => {
    const foundToken = supportApi.getAccessToken();
    const isActorTypeSet = getState().authenticatedUser.actorTypeSet;
    if (!isActorTypeSet && foundToken) {
      const decoded = decodeJwt(foundToken);
      const authUser = new AuthUserCS(foundToken);
      // index of since we dont know if it will be an array of strings or a single string
      if (decoded.role?.indexOf('unikey_admin') >= 0) {
        dispatch(setAuthActor({ privelaged: true, user: authUser }))
      } else if (decoded.role?.indexOf('partner_admin') >= 0) {
        dispatch(setAuthActor({ privelaged: false, user: authUser }))
      } else {
        // the user does not have a valid role to communicate with the support api
        // must be either unikey_admin or partner_admin
        dispatch(setAuthActor({ privelaged: false, user: authUser }))
        // the first api request will kick them to the 403 screen
        // doing so here causes an issue with the oidc redirect
      }
    }
  }
}

export function checkValidAuthenticationOrRedirect(): any {  
  return (dispatch: Dispatch<any>, getState: any): any => {
    try {
      const foundToken = supportApi.getAccessToken();
      if (foundToken) {
        dispatch(setAuthActorFromAccessTokenOrRedirect());
        return true;
      } 
      else {
        return false;
      }
    } catch {
      dispatch(redirectToLogin())
      return false;
    }
  }
}

export interface ISetActorParams {
  privelaged: boolean,
  user?: AuthUserCS
}

export function setAuthActor(params: ISetActorParams) {
  return {
    type: authActions.SET_ACTOR_TYPE,
    isUniKeyActor: !!params.privelaged,
    user: params.user
  }
}

// Auth User Details
const getAuthUserOptionalSilent = new ApiReduxAction(supportApi, {
  request: { type: authActions.GET_CURRENT_USER_REQUEST },
  success: { type: authActions.GET_CURRENT_USER_SUCCESS },
  failure: { type: authActions.GET_CURRENT_USER_FAILURE, },
  // handle401: (dux: IExposeRedux) => dux.dispatch({ type: authActions.GET_CURRENT_USER_FAILURE }),
  handle404: (dux: IExposeRedux) => dux.dispatch({ type: authActions.GET_CURRENT_USER_FAILURE }),
}, (dux: IExposeRedux) => {
  return supportApi.getAuthUser.bind(supportApi, false);
});
export const attemptRetrieveAuthUserOptional = getAuthUserOptionalSilent.go;


// get identity login info
const getIdentityAuthInfoAction = new ApiReduxAction({
  request: { type: authActions.GET_IDENTITY_AUTH_ACCOUNT_REQUEST },
  success: { type: authActions.GET_IDENTITY_AUTH_ACCOUNT_SUCCESS },
  failure: {
    type: authActions.GET_IDENTITY_AUTH_ACCOUNT_FAILURE,
    title: 'getIdentityAuthInfoFailure'
  },
}, (dispatch: any, getState: any) => {
  return identityApi.acct.getAuthInfo.bind(identityApi.acct);
});
export const attemptGetIdentityAccount = getIdentityAuthInfoAction.go;
