import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classNames from 'classnames';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system'

import {
  Editable,
  UniWorkflow,
  UniImg,
  UniInput,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
} from '@unikey/unikey-commons/release/resi';

import {
  AccountID
} from '@unikey/unikey-commons/release/identity';

import {
  landingKey,
  attemptResendEmailVerification,
  attemptGetIdentityAccount,
  FooterContainer,
  PartnerCustomizations, IPartnerCustomizations,
  environment, partnerKey,
  portalRedirect
} from '../internal';
import partnerConfig from '@alias-current-partner-customizations';


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  loading?: boolean,
  alreadySent?: boolean,
  darkModeActive: boolean,
  getAuthInfo(): Promise<AccountID>,
  resendEmail(): Promise<void>
}


class VerifyContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // here also for the refresh case.
    this._checkAuthInfo();
  }

  _checkAuthInfo() {
    this.props.getAuthInfo().then((acct: AccountID) => {
      if (acct.emailVerified) {
        portalRedirect(landingKey);
      }
    })
  }

  _submit = () => {
    this.props.resendEmail().then(this._checkAuthInfo);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    return (
      <ScreenClassRender render={(screenClass: string) => (
        <section className="access-container">
          <section className="verify-container">
            <Container>
              <UniImg className={imgClassName} textKey="partner logo" src={this.props.darkModeActive ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
              <UniWorkflow
                titleKey="verifyEmail"
                titleIcon="verifiedUser" >

                <p><strong><UniLocalize translate="emailVerificiationRequired" /></strong></p>
                <p><UniLocalize translate="mustVerifyEmailBeforeAccessing" /></p>
                <p><UniLocalize translate="requestNewVerificationEmail" /></p>

                <div className="below-workflow">
                  <a className="link-left" href="/#/login"><UniLocalize translate="backToHome" /></a>
                </div>
                <UniOverlapGroup>
                  <UniOverlapButton
                    handleClick={() => this._submit()}
                    textKey="resendEmail"
                    icon="send"
                    showLoader={this.props.loading}
                    disabled={this.props.alreadySent || this.props.loading}
                    tooltipPosition="right" />
                </UniOverlapGroup>
              </UniWorkflow>
            </Container>
          </section>
          <FooterContainer />
        </section>
      )} />
    )
  }
}

function mapStateToProps(state: any) {
  return {
    emailVerified: state.identity?.authIdentity?.acct?.emailVerified,
    alreadySent: state.user?.resendVerifyEmailSplashForm?.sent,
    loading: state.user?.resendVerifyEmailSplashForm?.loading,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getAuthInfo: attemptGetIdentityAccount,
  resendEmail: attemptResendEmailVerification,
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(VerifyContainer)
  ), { componentName: 'Verify', unauthenticated: true })
