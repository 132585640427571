import { Dispatch } from 'redux'

import {
  IStatResultsCS,
  ICreditStatsCS,
  IDeviceStatsCS,
  IReaderStatsCS,
  IDealerStatsCS,
  IOverallStatsCS,
  ICredentialStatsCS,
  IOrganizationStatsCS,
  IOrgsPerDealerStatsCS,
  IReadersPerOrgStatsCS,
  ICredentialsPerOrgStatsCS,
  IDevicePlatformStatsCS,
} from '@unikey/unikey-commons/release/csupp'

import { supportApi, redirectToLogin, setupJobTrackingFor } from '../../internal'

export enum statActions {
  UPDATE_STATS_OPTIONS = 'UPDATE_STATS_OPTIONS',
  CHANGE_MAXIMIZED_CHART = 'CHANGE_MAXIMIZED_CHART',

  GET_READER_STATS_REQUEST = 'GET_READER_STATS_REQUEST',
  GET_READER_STATS_SUCCESS = 'GET_READER_STATS_SUCCESS',
  GET_READER_STATS_FAILURE = 'GET_READER_STATS_FAIL',
  SET_READER_CHART_CONFIG = 'SET_READER_CHART_CONFIG',

  GET_ORGANIZATION_STATS_REQUEST = 'GET_ORGANIZATION_STATS_REQUEST',
  GET_ORGANIZATION_STATS_SUCCESS = 'GET_ORGANIZATION_STATS_SUCCESS',
  GET_ORGANIZATION_STATS_FAILURE = 'GET_ORGANIZATION_STATS_FAIL',
  SET_ORGANIZATION_CHART_CONFIG = 'SET_ORGANIZATION_CHART_CONFIG',

  GET_OVERALL_STATS_REQUEST = 'GET_OVERALL_STATS_REQUEST',
  GET_OVERALL_STATS_SUCCESS = 'GET_OVERALL_STATS_SUCCESS',
  GET_OVERALL_STATS_FAILURE = 'GET_OVERALL_STATS_FAIL',
  SET_OVERALL_CHART_CONFIG = 'SET_OVERALL_CHART_CONFIG',

  GET_DEVICE_STATS_REQUEST = 'GET_DEVICE_STATS_REQUEST',
  GET_DEVICE_STATS_SUCCESS = 'GET_DEVICE_STATS_SUCCESS',
  GET_DEVICE_STATS_FAILURE = 'GET_DEVICE_STATS_FAIL',
  SET_DEVICE_CHART_CONFIG = 'SET_DEVICE_CHART_CONFIG',
  SET_CURRENT_DEVICE_PIE_CHART_CONFIG = 'SET_CURRENT_DEVICE_PIE_CHART_CONFIG',
  SET_TOTAL_DEVICE_PIE_CHART_CONFIG = 'SET_TOTAL_DEVICE_PIE_CHART_CONFIG',

  GET_CREDIT_STATS_REQUEST = 'GET_CREDIT_STATS_REQUEST',
  GET_CREDIT_STATS_SUCCESS = 'GET_CREDIT_STATS_SUCCESS',
  GET_CREDIT_STATS_FAILURE = 'GET_CREDIT_STATS_FAIL',
  SET_CREDIT_CHART_CONFIG = 'SET_CREDIT_CHART_CONFIG',

  GET_CREDENTIAL_STATS_REQUEST = 'GET_CREDENTIAL_STATS_REQUEST',
  GET_CREDENTIAL_STATS_SUCCESS = 'GET_CREDENTIAL_STATS_SUCCESS',
  GET_CREDENTIAL_STATS_FAILURE = 'GET_CREDENTIAL_STATS_FAIL',
  SET_CREDENTIAL_CHART_CONFIG = 'SET_CREDENTIAL_CHART_CONFIG',

  GET_DEALER_STATS_REQUEST = 'GET_DEALER_STATS_REQUEST',
  GET_DEALER_STATS_SUCCESS = 'GET_DEALER_STATS_SUCCESS',
  GET_DEALER_STATS_FAILURE = 'GET_DEALER_STATS_FAIL',
  SET_DEALER_CHART_CONFIG = 'SET_DEALER_CHART_CONFIG',

  GET_READERS_PER_ORG_STATS_REQUEST = 'GET_READERS_PER_ORG_STATS_REQUEST',
  GET_READERS_PER_ORG_STATS_SUCCESS = 'GET_READERS_PER_ORG_STATS_SUCCESS',
  GET_READERS_PER_ORG_STATS_FAILURE = 'GET_READERS_PER_ORG_STATS_FAILURE',
  SET_READERS_PER_ORG_CHART_CONFIG = 'SET_READERS_PER_ORG_CHART_CONFIG',
  SET_READERS_PER_ORG_HISTOGRAM_CHART_CONFIG = 'SET_READERS_PER_ORG_HISTOGRAM_CHART_CONFIG',

  GET_CREDENTIALS_PER_ORG_STATS_REQUEST = 'GET_CREDENTIALS_PER_ORG_STATS_REQUEST',
  GET_CREDENTIALS_PER_ORG_STATS_SUCCESS = 'GET_CREDENTIALS_PER_ORG_STATS_SUCCESS',
  GET_CREDENTIALS_PER_ORG_STATS_FAILURE = 'GET_CREDENTIALS_PER_ORG_STATS_FAILURE',
  SET_CREDENTIALS_PER_ORG_CHART_CONFIG = 'SET_CREDENTIALS_PER_ORG_CHART_CONFIG',

  GET_ORGS_PER_DEALER_STATS_REQUEST = 'GET_ORGS_PER_DEALER_STATS_REQUEST',
  GET_ORGS_PER_DEALER_STATS_SUCCESS = 'GET_ORGS_PER_DEALER_STATS_SUCCESS',
  GET_ORGS_PER_DEALER_STATS_FAILURE = 'GET_ORGS_PER_DEALER_STATS_FAILURE',
  SET_ORGS_PER_DEALER_CHART_CONFIG = 'SET_ORGS_PER_DEALER_CHART_CONFIG',
}


export enum EChartAggregate {
  unknown,
  cumulative, // 1
  isolated
}

export enum EChartInterval {
  unknown,
  daily, // 1
  weekly,
  monthly, // 3
  quarterly,
  yearly // 5
}

export enum EChartTimePeriod {
  unknown,
  oneMonth, // 1
  threeMonths,
  sixMonths, // 3
  oneYear,
  threeYears // 5
}

export interface IChartOptions {
  start?: Date,
  end?: Date,
  interval?: EChartInterval,
  aggregate?: EChartAggregate,
  timePeriod?: EChartTimePeriod,
}

function aggregateStatsOverIntervals(statsApiRequest: (start: Date, end: Date, tracking: any) => Promise<IStatResultsCS<any>>, start: Date, end: Date, tracking: any, intervalType: EChartInterval, aggregateIntervals: EChartAggregate): any[] {
  var intervalStart = start;
  var intervalEnd = new Date(start.getTime());
  var intervalAdjust: number;
  const intervalRequests: any = [];

  // make the requests for each interval until we are are to the end date
  do {
    switch (intervalType) {
      case EChartInterval.daily:
        intervalAdjust = intervalStart.getDate() + 1;
        intervalEnd = new Date(intervalEnd.setDate(intervalAdjust));
        break;
      case EChartInterval.weekly:
        intervalAdjust = intervalStart.getDate() + 7;
        intervalEnd = new Date(intervalEnd.setDate(intervalAdjust));
        break;
      case EChartInterval.monthly:
        intervalAdjust = intervalStart.getMonth() + 1;
        intervalEnd = new Date(intervalEnd.setMonth(intervalAdjust));
        break;
      case EChartInterval.quarterly:
        intervalAdjust = intervalStart.getMonth() + 3;
        intervalEnd = new Date(intervalEnd.setMonth(intervalAdjust));
        break;
      case EChartInterval.yearly:
        intervalAdjust = intervalStart.getFullYear() + 1;
        intervalEnd = new Date(intervalEnd.setFullYear(intervalAdjust));
        break;
      default:
        intervalAdjust = intervalStart.getMonth() + 1;
        intervalEnd = new Date(intervalEnd.setMonth(intervalAdjust));
        break;
    }

    intervalRequests.push(statsApiRequest(aggregateIntervals === EChartAggregate.cumulative ? start : intervalStart, intervalEnd, tracking));

    // increment the interval start for the next iteration
    intervalStart = new Date(intervalEnd.getTime());
  } while (intervalEnd.getTime() < end.getTime());

  return intervalRequests;
}

function buildHistoricalChartConfigFromStats(stats: Array<IStatResultsCS<any>>) {
  const dataSetKeys = Object.keys(stats[0].data);

  return stats.reduce((totalConfig: any, currConfig: IStatResultsCS<any>): any => {
    const currRow = {
      name: currConfig.end.toISOString().split('T')[0],
      ...currConfig.data
    };

    totalConfig.entries.push(currRow);
    return totalConfig;
  }, { entries:[], dataSetKeys });
}

export function upadateChartOptions(options: IChartOptions) {
  return {
    type: statActions.UPDATE_STATS_OPTIONS,
    ...options
  }
}

export function changeMaximizedChart(chartName: string) {
  return {
    type: statActions.CHANGE_MAXIMIZED_CHART,
    maximizedChart: chartName
  }
}

// Readers
export function aggregateReaderStatBetweenDates(start: Date, end: Date, intervalType: EChartInterval, aggregateIntervals: EChartAggregate = 1) {
  const tracking = setupJobTrackingFor(statActions.GET_READER_STATS_REQUEST);
  const requests: any = aggregateStatsOverIntervals(supportApi.stat.getReaderStats.bind({ api: supportApi }), start, end, tracking, intervalType, aggregateIntervals);

  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getReaderStatsRequest())
    Promise.all(requests).then((readerStats: any) => {// IStatResultsCS<IReaderStatsCS>[]) => { // IReaderStatsCS[]
      const chartConfig: any = buildHistoricalChartConfigFromStats(readerStats);

      dispatch(setReaderChartConfig(chartConfig));
      return readerStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getReaderStatsFailure())
      }
    })
  }
}

export function getReaderStatsRequest() {
  return {
    type: statActions.GET_READER_STATS_REQUEST
  }
}

export function getReaderStatsSuccess(readerStats: IStatResultsCS<IReaderStatsCS>) {
  return {
    type: statActions.GET_READER_STATS_SUCCESS,
    readerStats
  }
}

export function getReaderStatsFailure() {
  return {
    type: statActions.GET_READER_STATS_FAILURE,
  }
}

export function setReaderChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_READER_CHART_CONFIG,
    chartConfig
  }
}


// Dealers
export function aggregateDealerStatBetweenDates(start: Date, end: Date, intervalType: EChartInterval, aggregateIntervals: EChartAggregate = 1) {
  const tracking = setupJobTrackingFor(statActions.GET_DEALER_STATS_REQUEST);
  const requests: any = aggregateStatsOverIntervals(supportApi.stat.getDealerStats.bind({ api: supportApi }), start, end, tracking, intervalType, aggregateIntervals);

  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getDealerStatsRequest())
    Promise.all(requests).then((dealerStats: any) => {// IStatResultsCS<IDealerStatsCS>[]) => { // IDealerStatsCS[]
      const chartConfig: any = buildHistoricalChartConfigFromStats(dealerStats);

      dispatch(setDealerChartConfig(chartConfig));
      return dealerStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getDealerStatsFailure())
      }
    })
  }
}

// export function attemptRetrieveDealerStats(start: Date, end: Date): any {
//   return (dispatch: Dispatch<any>, getState: any): any => {

//     const tracking = setupJobTrackingFor(statActions.GET_DEALER_STATS_REQUEST);
//     dispatch(getDealerStatsRequest())
//     return supportApi.stat.getDealerStats(start, end, tracking).then((dealerStats: IStatResultsCS<IDealerStatsCS>) => {
//       dispatch(getDealerStatsSuccess(dealerStats))
//       return dealerStats;
//     }, (err: any) => {
//       if (err.status === 401) {
//         dispatch(redirectToLogin())
//       } else {
//         dispatch(getDealerStatsFailure())
//       }
//     })
//   }
// }

export function getDealerStatsRequest() {
  return {
    type: statActions.GET_DEALER_STATS_REQUEST
  }
}

export function getDealerStatsSuccess(dealerStats: IStatResultsCS<IDealerStatsCS>) {
  return {
    type: statActions.GET_DEALER_STATS_SUCCESS,
    dealerStats
  }
}

export function getDealerStatsFailure() {
  return {
    type: statActions.GET_DEALER_STATS_FAILURE,
  }
}

export function setDealerChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_DEALER_CHART_CONFIG,
    chartConfig
  }
}


// Credentials
export function aggregateCredentialStatBetweenDates(start: Date, end: Date, intervalType: EChartInterval, aggregateIntervals: EChartAggregate = 1) {
  const tracking = setupJobTrackingFor(statActions.GET_CREDENTIAL_STATS_REQUEST);

  const requests: Array<Promise<IStatResultsCS<ICredentialStatsCS>>> = aggregateStatsOverIntervals(supportApi.stat.getCredentialStats.bind({ api: supportApi }), start, end, tracking, intervalType, aggregateIntervals);

  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getCredentialStatsRequest())
    Promise.all(requests).then((credentialStats: Array<IStatResultsCS<ICredentialStatsCS>>) => { // ICredentialStatsCS[]
      const chartConfig: any = buildHistoricalChartConfigFromStats(credentialStats);

      dispatch(setCredentialChartConfig(chartConfig));
      return credentialStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getCredentialStatsFailure())
      }
    })
  }
}

// export function attemptRetrieveCredentialStats(start: Date, end: Date): any {
//   return (dispatch: Dispatch<any>, getState: any): any => {
//     dispatch(getCredentialStatsRequest())
//     return supportApi.stat.getCredentialStats(start, end).then((credentialStats: IStatResultsCS<ICredentialStatsCS>) => {
//       dispatch(getCredentialStatsSuccess(credentialStats))
//       return credentialStats;
//     }, (err: any) => {
//       if (err.status === 401) {
//         dispatch(redirectToLogin())
//       } else {
//         dispatch(getCredentialStatsFailure())
//       }
//     })
//   }
// }

export function getCredentialStatsRequest() {
  return {
    type: statActions.GET_CREDENTIAL_STATS_REQUEST
  }
}

export function getCredentialStatsSuccess(credentialStats: IStatResultsCS<ICredentialStatsCS>) {
  return {
    type: statActions.GET_CREDENTIAL_STATS_SUCCESS,
    credentialStats
  }
}

export function getCredentialStatsFailure() {
  return {
    type: statActions.GET_CREDENTIAL_STATS_FAILURE,
  }
}

export function setCredentialChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_CREDENTIAL_CHART_CONFIG,
    chartConfig
  }
}



// Organizations
export function aggregateOrgStatBetweenDates(start: Date, end: Date, intervalType: EChartInterval, aggregateIntervals: EChartAggregate = 1) {
  const tracking = setupJobTrackingFor(statActions.GET_ORGANIZATION_STATS_REQUEST);

  const requests: Array<Promise<IStatResultsCS<IOrganizationStatsCS>>> = aggregateStatsOverIntervals(supportApi.stat.getOrganizationStats.bind({ api: supportApi }), start, end, tracking, intervalType, aggregateIntervals);

  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getOrgStatsRequest())
    Promise.all(requests).then((orgStats: Array<IStatResultsCS<IOrganizationStatsCS>>) => { // IOrganizationStatsCS[]
      const chartConfig: any = buildHistoricalChartConfigFromStats(orgStats);

      dispatch(setOrgChartConfig(chartConfig));
      return orgStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getOrgStatsFailure())
      }
    })
  }
}

// export function attemptRetrieveOrgStats(start: Date, end: Date): any {
//   return (dispatch: Dispatch<any>, getState: any): any => {
//     dispatch(getOrgStatsRequest())
//     return supportApi.stat.getOrganizationStats(start, end).then((orgStats: IStatResultsCS<IOrganizationStatsCS>) => {
//       dispatch(getOrgStatsSuccess(orgStats))
//       return orgStats;
//     }, (err: any) => {
//       if (err.status === 401) {
//         dispatch(redirectToLogin())
//       } else {
//         dispatch(getOrgStatsFailure())
//       }
//     })
//   }
// }

export function getOrgStatsRequest() {
  return {
    type: statActions.GET_ORGANIZATION_STATS_REQUEST
  }
}

export function getOrgStatsSuccess(orgStats: IStatResultsCS<IOrganizationStatsCS>) {
  return {
    type: statActions.GET_ORGANIZATION_STATS_SUCCESS,
    orgStats
  }
}

export function getOrgStatsFailure() {
  return {
    type: statActions.GET_ORGANIZATION_STATS_FAILURE,
  }
}

export function setOrgChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_ORGANIZATION_CHART_CONFIG,
    chartConfig
  }
}


// Credits
export function aggregateCreditStatBetweenDates(start: Date, end: Date, intervalType: EChartInterval, aggregateIntervals: EChartAggregate = 1) {
  const tracking = setupJobTrackingFor(statActions.GET_CREDIT_STATS_REQUEST);

  const requests: Array<Promise<IStatResultsCS<ICreditStatsCS>>> = aggregateStatsOverIntervals(supportApi.stat.getCreditStats.bind({ api: supportApi }), start, end, tracking, intervalType, aggregateIntervals);

  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getCreditStatsRequest())
    Promise.all(requests).then((creditStats: Array<IStatResultsCS<ICreditStatsCS>>) => { // ICreditStatsCS[]
      const chartConfig: any = buildHistoricalChartConfigFromStats(creditStats);

      dispatch(setCreditChartConfig(chartConfig));
      return creditStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getCreditStatsFailure())
      }
    })
  }
}

// export function attemptRetrieveCreditStats(start: Date, end: Date): any {
//   return (dispatch: Dispatch<any>, getState: any): any => {
//     dispatch(getCreditStatsRequest())
//     return supportApi.stat.getCreditStats(start, end).then((creditStats: IStatResultsCS<ICreditStatsCS>) => {
//       dispatch(getCreditStatsSuccess(creditStats))
//       return creditStats;
//     }, (err: any) => {
//       if (err.status === 401) {
//         dispatch(redirectToLogin())
//       } else {
//         dispatch(getCreditStatsFailure())
//       }
//     })
//   }
// }

export function getCreditStatsRequest() {
  return {
    type: statActions.GET_CREDIT_STATS_REQUEST
  }
}

export function getCreditStatsSuccess(creditStats: IStatResultsCS<ICreditStatsCS>) {
  return {
    type: statActions.GET_CREDIT_STATS_SUCCESS,
    creditStats
  }
}

export function getCreditStatsFailure() {
  return {
    type: statActions.GET_CREDIT_STATS_FAILURE,
  }
}

export function setCreditChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_CREDIT_CHART_CONFIG,
    chartConfig
  }
}

// Devices
export function aggregateDeviceStatBetweenDates(start: Date, end: Date, intervalType: EChartInterval, aggregateIntervals: EChartAggregate = 1) {
  const tracking = setupJobTrackingFor(statActions.GET_DEVICE_STATS_REQUEST);

  const requests: Array<Promise<IStatResultsCS<IDeviceStatsCS>>> = aggregateStatsOverIntervals(supportApi.stat.getDeviceStats.bind({ api: supportApi }), start, end, tracking, intervalType, aggregateIntervals);
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getDeviceStatsRequest())
    Promise.all(requests).then((deviceStats: Array<IStatResultsCS<IDeviceStatsCS>>) => { // IDeviceStatsCS[]
      // the device statistics endpoint returns things nested a little deeper than the others,
      // so we're going to flatten it so that it can be handled in the same generic way as the other stats endpoints
      const deviceStatsFlat: any = deviceStats.map((intervalStats: IStatResultsCS<IDeviceStatsCS>) => {
        const newStats: IStatResultsCS<any> = Object.keys(intervalStats.data).reduce((totalFlatDeviceData: any, deviceType: string) => {
          const deviceData: any = (intervalStats.data as any)[deviceType];
          Object.keys(deviceData).forEach((statType: string) => {
            totalFlatDeviceData[`${deviceType}${statType.charAt(0).toUpperCase() + statType.slice(1)}`] = deviceData[statType];
          });

          return totalFlatDeviceData;
        }, {})
        return {
          begin: intervalStats.begin,
          end: intervalStats.end,
          data: newStats
        }
      });
      const chartConfig: any = buildHistoricalChartConfigFromStats(deviceStatsFlat);
      dispatch(setDeviceChartConfig(chartConfig));
      return deviceStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getDeviceStatsFailure())
      }
    })
  }
}

export function attemptRetrieveDeviceStats(start: Date, end: Date): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getDeviceStatsRequest())
    const tracking = setupJobTrackingFor(statActions.GET_DEVICE_STATS_REQUEST);
    return supportApi.stat.getDeviceStats(start, end, tracking).then((deviceStats: IStatResultsCS<IDeviceStatsCS>) => {
      dispatch(getDeviceStatsSuccess(deviceStats))
      const chartConfig = [
        { name: 'android', value: deviceStats.data.android.total },
        { name: 'apple', value: deviceStats.data.apple.total }
      ];
      dispatch(setTotalDevicePeChartConfig(chartConfig))
      return deviceStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getDeviceStatsFailure())
      }
    })
  }
}

export function getDeviceStatsRequest() {
  return {
    type: statActions.GET_DEVICE_STATS_REQUEST
  }
}

export function getDeviceStatsSuccess(deviceStats: IStatResultsCS<IDeviceStatsCS>) {
  return {
    type: statActions.GET_DEVICE_STATS_SUCCESS,
    deviceStats
  }
}

export function getDeviceStatsFailure() {
  return {
    type: statActions.GET_DEVICE_STATS_FAILURE,
  }
}

export function setDeviceChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_DEVICE_CHART_CONFIG,
    chartConfig
  }
}

export function setTotalDevicePeChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_TOTAL_DEVICE_PIE_CHART_CONFIG,
    chartConfig
  }
}

export function setCurrentDevicePeChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_CURRENT_DEVICE_PIE_CHART_CONFIG,
    chartConfig
  }
}


// Overalls
export function attemptRetrieveOverallStats(): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getOverallStatsRequest())
    const tracking = setupJobTrackingFor(statActions.GET_OVERALL_STATS_REQUEST);

    return supportApi.stat.getOverallStats(tracking).then((overallStats: IOverallStatsCS) => {
      const columns = Object.keys(overallStats);
      
      const chartConfig = {
        dataSetKeys: ['value'],
        data: columns.map((colName: string) => {
          return { name: colName, value: (overallStats as any)[colName] }
        })
      }
      dispatch(setOverallChartConfig(chartConfig))
      dispatch(getOverallStatsSuccess(overallStats))
      return overallStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getOverallStatsFailure())
      }
    })
  }
}

export function getOverallStatsRequest() {
  return {
    type: statActions.GET_OVERALL_STATS_REQUEST
  }
}

export function getOverallStatsSuccess(overallStats: IOverallStatsCS) {
  return {
    type: statActions.GET_OVERALL_STATS_SUCCESS,
    overallStats
  }
}

export function getOverallStatsFailure() {
  return {
    type: statActions.GET_OVERALL_STATS_FAILURE,
  }
}

export function setOverallChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_OVERALL_CHART_CONFIG,
    chartConfig
  }
}


// Readers Per Org Stats
export function attemptRetrieveCurrentReadersPerOrgStats(totalDealerReaders: number): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getReadersPerOrgStatsRequest())
    const tracking = setupJobTrackingFor(statActions.GET_READERS_PER_ORG_STATS_REQUEST);
    return supportApi.stat.getCurrentReadersPerOrgStats(tracking).then((readersPerOrgStats: IReadersPerOrgStatsCS[]) => {
      const lessThanThresholdOrgs: Array<string | number> = [];
      var combinedTotalsLessThanThresholdOrgs: number = 0;
      var numOrgsLessThanThreshold: number = 0;
      const chartConfig = readersPerOrgStats.reduce((config: any, orgReaders: IReadersPerOrgStatsCS) => {
        // if this organization makes up less than one percent of the total in the dealer, then bundle it in with other less than .05%
        if (orgReaders.total / totalDealerReaders < 0.005) {
          combinedTotalsLessThanThresholdOrgs += orgReaders.total;
          numOrgsLessThanThreshold++;
        } else {
          config.pie.push({ name: orgReaders.organization_name, value: orgReaders.total })
        }

        //  add the histogram entry
        config.histogramKeys[orgReaders.total] = (config.histogramKeys[orgReaders.total] ?? 0) + 1;
        return config;
      }, {pie: [], histogramKeys: {}, histogram: []});
      chartConfig.pie.push({ name: `(${numOrgsLessThanThreshold}) Organizations Less Than 0.5%`, value: combinedTotalsLessThanThresholdOrgs });
      
      // convert the raw histogram to data needed for the chart config
      Object.keys(chartConfig.histogramKeys).forEach(key => {
        chartConfig.histogram.push({ name: key, value: chartConfig.histogramKeys[key] });
      })

      dispatch(setReadersPerOrgChartConfig(chartConfig))

      dispatch(getReadersPerOrgStatsSuccess(readersPerOrgStats))
      return readersPerOrgStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getReadersPerOrgStatsFailure())
      }
    })
  }
}

export function getReadersPerOrgStatsRequest() {
  return {
    type: statActions.GET_READERS_PER_ORG_STATS_REQUEST
  }
}

export function getReadersPerOrgStatsSuccess(readersPerOrgStats: IReadersPerOrgStatsCS[]) {
  return {
    type: statActions.GET_READERS_PER_ORG_STATS_SUCCESS,
    readersPerOrgStats
  }
}

export function getReadersPerOrgStatsFailure() {
  return {
    type: statActions.GET_READERS_PER_ORG_STATS_FAILURE,
  }
}

export function setReadersPerOrgChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_READERS_PER_ORG_CHART_CONFIG,
    chartConfig
  }
}


export function setReadersPerOrgHistogramChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_READERS_PER_ORG_HISTOGRAM_CHART_CONFIG,
    chartConfig
  }
}


// Credentials Per Org Stats
export function attemptRetrieveCurrentCredentialsPerOrgStats(totalDealerCreds: number): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getCredentialsPerOrgStatsRequest())
    const tracking = setupJobTrackingFor(statActions.GET_CREDENTIALS_PER_ORG_STATS_REQUEST);
    return supportApi.stat.getCurrentCredentialsPerOrgStats(tracking).then((CredentialsPerOrgStats: ICredentialsPerOrgStatsCS[]) => {
      var combinedTotalsLessThanThresholdOrgs: number = 0;
      var numOrgsLessThanThreshold: number = 0;
      const chartConfig = CredentialsPerOrgStats.reduce((config: any, orgCreds: ICredentialsPerOrgStatsCS) => {
        // if this organization makes up less than one percent of the total in the dealer, then bundle it in with other less than 0.5%
        if (orgCreds.total / totalDealerCreds < 0.005) {
          combinedTotalsLessThanThresholdOrgs += orgCreds.total;
          numOrgsLessThanThreshold++;
        } else {
          config.pie.push({ name: orgCreds.organization_name, value: orgCreds.total });
        }

         //  add the histogram entry
         config.histogramKeys[orgCreds.total] = (config.histogramKeys[orgCreds.total] ?? 0) + 1;
        return config;
      }, {pie: [], histogramKeys: {}, histogram: []});
      chartConfig.pie.push({ name: `(${numOrgsLessThanThreshold}) Organizations Less Than 0.5%`, value: combinedTotalsLessThanThresholdOrgs });

      // convert the raw histogram to data needed for the chart config
      Object.keys(chartConfig.histogramKeys).forEach(key => {
        chartConfig.histogram.push({ name: key, value: chartConfig.histogramKeys[key] });
      })

      dispatch(setCredentialsPerOrgChartConfig(chartConfig))

      dispatch(getCredentialsPerOrgStatsSuccess(CredentialsPerOrgStats))
      return CredentialsPerOrgStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getCredentialsPerOrgStatsFailure())
      }
    })
  }
}

export function getCredentialsPerOrgStatsRequest() {
  return {
    type: statActions.GET_CREDENTIALS_PER_ORG_STATS_REQUEST
  }
}

export function getCredentialsPerOrgStatsSuccess(CredentialsPerOrgStats: ICredentialsPerOrgStatsCS[]) {
  return {
    type: statActions.GET_CREDENTIALS_PER_ORG_STATS_SUCCESS,
    CredentialsPerOrgStats
  }
}

export function getCredentialsPerOrgStatsFailure() {
  return {
    type: statActions.GET_CREDENTIALS_PER_ORG_STATS_FAILURE,
  }
}

export function setCredentialsPerOrgChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_CREDENTIALS_PER_ORG_CHART_CONFIG,
    chartConfig
  }
}

// Orgs Per Dealer Stats
export function attemptRetrieveCurrentOrgsPerDealerStats(totalOrgs: number): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(getOrgsPerDealerStatsRequest())
    const tracking = setupJobTrackingFor(statActions.GET_ORGS_PER_DEALER_STATS_REQUEST);
    return supportApi.stat.getCurrentOrgsPerDealerStats(tracking).then((OrgsPerDealerStats: IOrgsPerDealerStatsCS[]) => {
      var combinedTotalsLessThanThresholdOrgs: number = 0;
      var numOrgsLessThanThreshold: number = 0;
      const chartConfig = OrgsPerDealerStats.reduce((config: any, dealerOrgs: IOrgsPerDealerStatsCS) => {
        // if this organization makes up less than one percent of the total in the dealer, then bundle it in with other less than 0.5%
        if (dealerOrgs.total / totalOrgs < 0.005) {
          combinedTotalsLessThanThresholdOrgs += dealerOrgs.total;
          numOrgsLessThanThreshold++;
        } else {
          config.pie.push({ name: dealerOrgs.dealer_name, value: dealerOrgs.total });
        }
        //  add the histogram entry
        config.histogramKeys[dealerOrgs.total] = (config.histogramKeys[dealerOrgs.total] ?? 0) + 1;
        return config;
      }, {pie: [], histogramKeys: {}, histogram: []});
      chartConfig.pie.push({ name: `(${numOrgsLessThanThreshold}) Organizations Less Than 0.5%`, value: combinedTotalsLessThanThresholdOrgs });
      
      // convert the raw histogram to data needed for the chart config
      Object.keys(chartConfig.histogramKeys).forEach(key => {
        chartConfig.histogram.push({ name: key, value: chartConfig.histogramKeys[key] });
      })

      dispatch(setOrgsPerDealerChartConfig(chartConfig))

      dispatch(getOrgsPerDealerStatsSuccess(OrgsPerDealerStats))
      return OrgsPerDealerStats;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(getOrgsPerDealerStatsFailure())
      }
    })
  }
}

export function getOrgsPerDealerStatsRequest() {
  return {
    type: statActions.GET_ORGS_PER_DEALER_STATS_REQUEST
  }
}

export function getOrgsPerDealerStatsSuccess(OrgsPerDealerStats: IOrgsPerDealerStatsCS[]) {
  return {
    type: statActions.GET_ORGS_PER_DEALER_STATS_SUCCESS,
    OrgsPerDealerStats
  }
}

export function getOrgsPerDealerStatsFailure() {
  return {
    type: statActions.GET_ORGS_PER_DEALER_STATS_FAILURE,
  }
}

export function setOrgsPerDealerChartConfig(chartConfig: any) {
  return {
    type: statActions.SET_ORGS_PER_DEALER_CHART_CONFIG,
    chartConfig
  }
}
