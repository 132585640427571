import React, { Component } from 'react'

import {
  IPaginationQueryBuilderParamsC,
  IUniTable_Sort,
  IUniTable_Filter,
  IUniTable_UpdatePaginationSummary,
  IPaginatedParamsCS,

  UniConditionalRender,
  UniIcon
} from '@unikey/unikey-commons/release/csupp'

import { 
  supportApi
} from '../internal'

const pageSizeCacheKey = 'queryParamsPageSize';
export const setPageSizeInCache = (pageSize?: number) => {
  if (Number.isInteger(pageSize)) {
    supportApi.cacheImpl.set(pageSizeCacheKey, pageSize);
  }
  return;
}

export const getPageSizeFromCache = (): number => {
  const pageSize = supportApi.cacheImpl.get(pageSizeCacheKey);
  const numericPageSize = Number(pageSize);
  return numericPageSize ?? undefined;
}

export const initialPageSize = getPageSizeFromCache() || 10; // default page size

export const getTableSortDirection = (qps: IPaginatedParamsCS): 'asc' | 'desc' | undefined => {
  if (Array.isArray(qps.sorts) && qps.sorts.length > 0) {
    return qps.sorts[0].reverse ? 'asc' : 'desc';
  }
  return undefined;
}

export const getTableParamsFromUpdate = (update: IUniTable_UpdatePaginationSummary): IPaginatedParamsCS => {
  const params: IPaginatedParamsCS = {};

  // make sure to update the name param even if the string is empty
  if (update.filters) {
    params.filters = update.filters.map((f: IUniTable_Filter) => {
      const field: any = {
        fieldName: f.filterKey ?? f.nameKey,
        matchType: f.matchType,
        value: f.value
      };
     
      return field;
    });
  }

  if (update.sorts) {
    params.sorts = update.sorts.map((s: IUniTable_Sort) => {
      return {
        fieldName: s.field!,
        reverse: s.direction === 'asc' ? true : false
      }
    });
  }

  if (update.currPage) {
    params.pageNum = update.currPage;
  }

  if (update.pageSize) {
    params.limit = Number(update.pageSize);
  }
  return params;
}

export type TTableUpdateFunc = (update: IUniTable_UpdatePaginationSummary) => void;
export const buildTableUpdateFunc = (
  fetchTableData: (queryParams: IPaginatedParamsCS) => Promise<void>,
  updateTableMeta: (metaSummary: IUniTable_UpdatePaginationSummary) => void, 
  updateQueryParams?: (params: IPaginatedParamsCS) => void,
  ): TTableUpdateFunc  => {
    return (update: IUniTable_UpdatePaginationSummary) => {
      if (updateQueryParams) {
        const params = getTableParamsFromUpdate(update);
        updateTableMeta(update);
        updateQueryParams(params);
        return fetchTableData(params);
      }
    }
  }

export const readerUpgradeAvailableTemplate = (rowItem: any) => {
  let tooltipTargetId = 'ua-';
  if (rowItem.upgradeAvailable) {
    tooltipTargetId += `${rowItem.id}`;
  }
  const upgradeAvailRef = React.createRef<HTMLDivElement>();
  return (
    <UniConditionalRender visible={rowItem.upgradeAvailable} >
      <div id={tooltipTargetId} className="upgrade-available-version" ref={upgradeAvailRef}>
        <UniIcon
          size="sm"
          name="update"
          tooltipTextKeys={['upgradeAvailable']}
          tooltipPosition="left"
          tooltipTriggerElemRef={upgradeAvailRef} />
      </div>
    </UniConditionalRender>
  )
}
