import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  CredentialCS,
  ActivityLogCS,
  ECredentialType,
  UniTable,
  IUniTable_Column,
  UniConditionalRender, 
  UniKeyVal,
  DeviceCS,
  EDeviceStatus,
  UniLocalize,
  IUniMenu_ItemConfig,
  UniOverflowActions,
  EDeviceCredLinkStatus,
  IPaginatedParamsCS,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  EUniTable_FooterStyle,
  UniTag,
} from '@unikey/unikey-commons/release/csupp';

import {
  ActivityLogList,
  navConfig, ENavPages,
  togglePushNotificationForm,
  DeviceMessageFormContainer,
  attemptRetrieveSingleDevice,
  attemptRetrieveActivityLogs,
  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  device: DeviceCS,
  history: any,
  isUniKeyActor: boolean,
  loading: boolean,
  credentialsOnDevice: CredentialCS[],
  deviceHasRegistrationId?: boolean,
  messageDeviceModalOpen?: boolean,
  // activity log stuff
  activityLogs: ActivityLogCS[],
  activityLogListQueryParams: IPaginatedParamsCS,
  activityLogListLoading: IUniTable_Filter[],
  activityLogListAppliedFilters: IUniTable_Sort,
  activityLogListAppliedSort: boolean,
  activityLogListPaginationSummary: IUniTable_PaginationSummary,
  getActivityLogs(deviceId: string, activityLogsQueryParams: IPaginatedParamsCS): void,
  updateActivityLogsListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateActivityLogsListQueryParams?(params: IPaginatedParamsCS): void,

  getDeviceById(deviceId: string): void,
  togglePushNotificationModal(): void,
}

class DeviceDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getDeviceById(this.props.match && this.props.match.params.deviceId);

    if (this.props.isUniKeyActor) {
      this.props.getActivityLogs(this.props.match.params.deviceId, this.props.activityLogListQueryParams);
    }
  }

  // TODO: move to table helpers
  _credentialStatusTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-status">
        <UniLocalize translate={rowItem.getStatusNameKey()} />
      </div>
    )
  }

  // TODO: move to table helpers
  _credentialOrganizationNameTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-organization">
        {rowItem.getOrganizationName()}
      </div>
    )
  }
  
  // TODO: move to table helpers
  _credentialTypeTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-type">
        <UniTag textKey={rowItem.getTypeNameKey()} inverted />
      </div>
    )
  }

  _isCredentialLinkInactive = (rowItem: CredentialCS) => {
    return rowItem.deviceCredLinkStatus === EDeviceCredLinkStatus.disabled || rowItem.deviceCredLinkStatus === EDeviceCredLinkStatus.revoked;
  }

  _buildCredentialColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isFilterable: true,
        template: (rowItem: CredentialCS) => rowItem.email ? rowItem.email : (<i><UniLocalize translate="notProvided" /></i>),
        size: 6,
        evalItalicized: this._isCredentialLinkInactive
      })
      .set('deviceCredLinkStatus', {
        nameKey: 'deviceCredLinkStatus',
        type: 'enum-tagdot',
        enumType: EDeviceCredLinkStatus,
        size: 4,
        evalItalicized: this._isCredentialLinkInactive
      })
      .set('organization', {
        nameKey: 'organization',
        template: this._credentialOrganizationNameTemplate,
        evalItalicized: this._isCredentialLinkInactive,
        size: 4,
      })
      .set('type', {
        nameKey: 'type',
        template: this._credentialTypeTemplate,
        evalItalicized: this._isCredentialLinkInactive,
        type: 'enum',
        enumType: ECredentialType,
        size: 4,
        filterName: 'credential_type',
      })
      .set('created', {
        nameKey: 'created',
        type: 'date',
        evalItalicized: this._isCredentialLinkInactive,
        size: 4,
      })
      .set('actions', {
        nameKey: 'actions',
        size: 2
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: CredentialCS) => { this.props.history.push(navConfig.get(ENavPages.credentialDetails)!.linkTo([rowItem.id])); },
      evalDisabled: (rowItem: CredentialCS) => false,
      evalVisible: (rowItem: CredentialCS) => true
    })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildCredentialColumnsAndActions();
    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('sendPush', {
        textKey: 'sendPushNotification',
        handleClick: this.props.togglePushNotificationModal,
        disabled: !this.props.deviceHasRegistrationId,
        disabledReasonKeys: ['_cannotSendPushNotification', '_explainDeviceNotRegistered'],
      });

    return (
      <section className='DeviceDetails-container'>
        <Row>
          <Col>
            <h3 className="page-title-non-table"><UniLocalize translate="deviceDetails"/></h3>
          </Col>
          
          <UniConditionalRender visible={!!this.props.isUniKeyActor}>
            <Col xs={6} lg={4}>
              <UniOverflowActions
                className="title-actions"
                nameKey="deviceActions"
                icon="arrowDropDownCircle"
                actions={actionsMenuContents}
                allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
                theme="primary" />
            </Col>
          </UniConditionalRender>
        </Row>
        
        <Row>
          <Col md={12}>

            <UniKeyVal
              label="device-details"
              stacked
              primaryStateButtonSet={[]}
              secondaryStateButtonSet={[]}
              showLoader={!this.props.device?.id && this.props.loading}
              fields={[
                {
                  keyName: 'name',
                  value: '' + this.props.device?.name,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'status',
                  value: '' + this.props.device?.deviceStatus,
                  type: 'enum',
                  enumType: EDeviceStatus,
                  disabled: true
                },
                {
                  keyName: 'created',
                  value: '' + this.props.device?.created,
                  type: 'dateAndTime',
                  disabled: true
                },
                {
                  keyName: 'deviceId',
                  value: '' + this.props.device?.id,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'staticId',
                  value: '' + this.props.device?.staticId,
                  type: 'string',
                  disabled: true
                }
              ]} />
              
            <br/>
            
            <UniKeyVal
              label="device-details"
              stacked
              primaryStateButtonSet={[]}
              secondaryStateButtonSet={[]}
              showLoader={!this.props.device?.id && this.props.loading}
              fields={[
                {
                  keyName: 'applicationName',
                  value: '' + this.props.device?.applicationName,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'applicationVersion',
                  value: '' + this.props.device?.applicationVersion,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'sdkVersion',
                  value: '' + this.props.device?.sdkVersion,
                  type: 'string',
                  disabled: true
                }
              ]} />

          </Col>

          <Col md={12}>  
            <UniKeyVal
              label="device-manufacturer-details"
              primaryStateButtonSet={[]}
              secondaryStateButtonSet={[]}
              stacked
              showLoader={!this.props.device?.id && this.props.loading}
              allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
              fields={[
                {
                  keyName: 'family',
                  value: '' + this.props.device?.family,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'make',
                  value: '' + this.props.device?.make,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'model',
                  value: '' + this.props.device?.model,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'os',
                  value: '' + this.props.device?.os,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'osVersion',
                  value: '' + this.props.device?.osVersion,
                  type: 'string',
                  disabled: true
                }
              ]} />

            <br/>

            <UniKeyVal
              stacked
              label="device-notification-details"
              key={`${this.props.deviceHasRegistrationId}`}
              primaryStateButtonSet={this.props.isUniKeyActor ? [
                {
                  textKey: 'sendPushNotification',
                  icon: 'message',
                  disabled: !this.props.deviceHasRegistrationId,
                  disabledReasonKeys: ['_cannotSendPushNotification', '_explainDeviceNotRegistered'],
                  clickHandler: this.props.togglePushNotificationModal
                }
              ] : []}
              secondaryStateButtonSet={[]}
              showLoader={!this.props.device?.id && this.props.loading}
              allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
              fields={[
                {
                  keyName: 'notificationRegistrationId',
                  value: '' + this.props.device?.registrationId,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'notificationRegistrationToken',
                  value: '' + this.props.device?.registrationToken,
                  type: 'string',
                  disabled: true
                }
              ]} />

          </Col>
        </Row>

        <Row>
          <Col>
            <UniConditionalRender visible={!!this.props.credentialsOnDevice}>
              <UniTable
                searchable={false}
                titleKey="credentialsOnDevice"
                createButtonTextKey="credential"
                data={this.props.credentialsOnDevice}
                footerStyle={EUniTable_FooterStyle.none}
                columnConfig={columns}
                actionsConfig={actions}
                showLoader={false} />
            </UniConditionalRender> 
          </Col>
        </Row>

        <UniConditionalRender visible={this.props.isUniKeyActor}>
          {/* Display the device's activity logs */}
          <ActivityLogList 
            key={`${this.props.activityLogs?.[0]?.start}${this.props.activityLogListLoading}`}
            match={this.props.match}
            history={this.props.history}
            logs={this.props.activityLogs}
            logListQueryParams={this.props.activityLogListQueryParams}
            logListLoading={this.props.activityLogListLoading}
            logListAppliedFilters={this.props.activityLogListAppliedFilters}
            logListAppliedSort={this.props.activityLogListAppliedSort}
            logListPaginationSummary={this.props.activityLogListPaginationSummary}
            getScopedLogs={this.props.getActivityLogs.bind(this, this.props.match.params.deviceId)}
            updateLogListQueryParams={this.props.updateActivityLogsListQueryParams}
            updateLogListTableMeta={this.props.updateActivityLogsListTableMeta} />
        </UniConditionalRender> 

        <UniConditionalRender visible={this.props.messageDeviceModalOpen}>
          <DeviceMessageFormContainer />
        </UniConditionalRender>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  const device = state.deviceDetails.deviceData;
  return {
    device,
    deviceHasRegistrationId: !!device?.registrationId,
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
    credentialsOnDevice: device?.credentials ?? [],
    messageDeviceModalOpen: state.deviceNotificationForm.modalOpen,
    loading: state.deviceDetails.loading,
    // activity log list stuff
    activityLogs: state.activityLogs.data.models,
    activityLogListLoading: state.activityLogs.loading,
    activityLogListQueryParams: state.activityLogs.queryParams,
    activityLogListAppliedFilters: state.activityLogs.tableFilters,
    activityLogListAppliedSort: state.activityLogs.tableSorts[0],
    activityLogListPaginationSummary: state.activityLogs.paginationSummary,
 
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getDeviceById: attemptRetrieveSingleDevice,
  togglePushNotificationModal: togglePushNotificationForm,
  getActivityLogs: attemptRetrieveActivityLogs,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DeviceDetailsContainer)
    ), { componentName: 'DeviceDetails' })
