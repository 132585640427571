import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import classNames from 'classnames';

import {
  darkThemeName,
  AuthUserCS,
  UniWorkflow,
  UniImg,
  UniButton,
  UniLocalize,
  UniConditionalRender,
} from '@unikey/unikey-commons/release/csupp';

import partnerConfig from '@alias-current-partner-customizations';
import {
  appInsights,
  FooterContainer,
  partnerKey,
  attemptRetrieveAuthUserOptional,
  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';

interface IProps extends  IPartnerCustomizations, WrappedComponentProps {
  match: any,
  darkModeTheme: string,
  authUser: AuthUserCS,
  attemptRetrieveAuthUserOptional(): Promise<void>,
}
interface IState {
  supportAccessRequested: boolean
}

class OopsContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      supportAccessRequested: false
    };
  }

  componentDidMount() {
    this.props.attemptRetrieveAuthUserOptional();
  }

  _requestSupportAccess = () => {
    this.setState({ supportAccessRequested: true });
    appInsights.trackEvent(
      { name: 'supportAccessRequest' },
      { 
        reason: 'Requesting Support Access for User from 403 landing page',
        username: this.props.authUser?.username,
        userId: this.props.authUser?.id
      });
  }
  render() {
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    return (
      <section className='access-container'>
        <section className='oops-container'>
          <UniImg textKey="partner logo" className={imgClassName} src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />

          {/* restrict width on massive screens */}
          <Container>
            <UniWorkflow
              titleKey="whoops"
              titleIcon="sentimentDissatisfied">

              <p><strong><UniLocalize translate='_somethingWentWrong' /></strong></p>

              <UniConditionalRender visible={this.props.match.params.reason === "404"} >
                <h2><UniLocalize translate="_error404Code" /></h2>
                <p><UniLocalize translate="_error404Message" /></p>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.match.params.reason === "403"} >
                <h2><UniLocalize translate="_error403Code" /></h2>
                <p><UniLocalize translate="_error403Message" /></p>
                
                <UniButton 
                  icon={this.state.supportAccessRequested ? 'done' : undefined}
                  textKey={this.state.supportAccessRequested ? 'accessRequested' : 'requestSupportAccess'}
                  handleClick={this._requestSupportAccess} 
                  disabled={this.state.supportAccessRequested} />
                <br />

              </UniConditionalRender>

              <UniConditionalRender visible={this.props.match.params.reason === "emailtemplate"} >
                <h2><UniLocalize translate="_errorEmailCode" /></h2>
                <p><UniLocalize translate="_errorEmailMessage" /></p>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.match.params.reason !== "403" && this.props.match.params.reason !== "404" && this.props.match.params.reason !== "emailtemplate"} >
                <h2><UniLocalize translate="_error404Code" /></h2>
                <p><UniLocalize translate="_error404Message" /></p>
              </UniConditionalRender>

              <div className="below-workflow">
                <a className="link-left" href="/#/login"><UniLocalize translate="goToLogin" /></a>
              </div>
            </UniWorkflow>
          </Container>

        </section>
        <FooterContainer />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    darkModeTheme: state.portal.darkModeTheme,
    authUser: state.authenticatedUser?.user,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
attemptRetrieveAuthUserOptional
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(OopsContainer)
  ), { componentName: 'Oops' })