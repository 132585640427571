import { Editable } from '@unikey/unikey-commons';
import {
  portalActions,
  inputInitializer
} from '../../internal'

const initialPortalState = {
  avatarMenuOpen: false,
  modalOpen: false
};

export function portal(state = initialPortalState, action: any) {
  switch (action.type) {
    case portalActions.TOGGLE_AVATAR_MENU:
      return {
        ...state,
        avatarMenuOpen: !!action.open
      }
    case portalActions.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case portalActions.SET_DARK_MODE_THEME:
      return {
        ...state,
        darkModeTheme: action.theme
      }
    default:
      return state
  }
}


const initialFeedbackState = {
  modalOpen: false,
  optOutModalOpen: false,
  loading: false,
  rating: new Editable<number>({ value: 0 }),
  reason: new Editable<string>({ value: '' })
}

export function feedbackModal(state = initialFeedbackState, action: any) {
  switch (action.type) {
    case portalActions.TOGGLE_FEEDBACK_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case portalActions.UPDATE_FEEDBACK:
      return {
        ...state,
        rating: Number.isInteger(action.feedback?.rating) ? action.feedback.rating : state.rating,
        reason: action.feedback?.reason ? action.feedback.reason : state.reason,
        loading: action.feedback?.loading ? action.feedback.loading : state.loading
      }
    case portalActions.RESET_FEEDBACK: 
      return {
        state,
        ...initialFeedbackState
      }
    default:
      return state;
  }
}