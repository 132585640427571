import { Dispatch } from 'redux'

import {
  UserCS,
  RoleCS,
  IUserCS,
  InviteCS,
  IRoleCS,
  IDealerInvitationCS,
  Editable,
  IPaginatedParamsCS,
  IPaginatedResponseCS,
  IMultiInputUpdate,
  IUserUpdateRequestCS,
  EMessageType,
  IUniTable_UpdatePaginationSummary,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  setPageSizeInCache,
} from '../../internal'

export enum activityLogActions {
  GET_ACTIVITY_LOGS_REQUEST = 'GET_ACTIVITY_LOGS_REQUEST',
  GET_ACTIVITY_LOGS_FAILURE = 'GET_ACTIVITY_LOGS_FAILURE',
  GET_ACTIVITY_LOGS_SUCCESS = 'GET_ACTIVITY_LOGS_SUCCESS',
  UPDATE_ACTIVITY_LOGS_QUERY_PARAMS = 'UPDATE_ACTIVITY_LOGS_QUERY_PARAMS',
  UPDATE_ACTIVITY_LOGS_TABLE_META = 'UPDATE_ACTIVITY_LOGS_TABLE_META',

  CLEAR_ACTIVITY_LOGS = 'CLEAR_ACTIVITY_LOGS',
  UPDATE_ENTITY_ID_FOR_ACTIVITY_LOG = 'UPDATE_ENTITY_ID_FOR_ACTIVITY_LOG'
}

// Activity Logs
const getActivityLogListAction = new ApiReduxAction(supportApi, {
  request: { type: activityLogActions.GET_ACTIVITY_LOGS_REQUEST },
  success: { type: activityLogActions.GET_ACTIVITY_LOGS_SUCCESS },
  failure: {
    type: activityLogActions.GET_ACTIVITY_LOGS_FAILURE,
    title: 'getActivityLogsFail',
  },
  tableMetaUpdate: {
    type: activityLogActions.UPDATE_ACTIVITY_LOGS_TABLE_META
  }
}, (dux: IExposeRedux, adminId: string) => {
  const params = dux.getState().activityLogs.queryParams;
  return supportApi.alog.getActivityLogForEntity.bind(supportApi.alog, adminId, params);
});
export const attemptRetrieveActivityLogs = getActivityLogListAction.go;

export function updateActivityLogsQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: activityLogActions.UPDATE_ACTIVITY_LOGS_QUERY_PARAMS,
    queryParams
  }
}

export function updateActivityLogsTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: activityLogActions.UPDATE_ACTIVITY_LOGS_TABLE_META,
    ...meta
  }
}

export function handleEntityIdChange(entityId: Editable<string>) {
  return {
    type: activityLogActions.UPDATE_ENTITY_ID_FOR_ACTIVITY_LOG,
    entityId
  };
}

export function clearActivityLogs() {
  return {
    type: activityLogActions.CLEAR_ACTIVITY_LOGS
  };
}