import {
  noop,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi
} from '../../internal'

export enum invitationActions {
  GET_CREDENTIAL_INVITATION_REQUEST = 'GET_CREDENTIAL_INVITATION_REQUEST',
  GET_CREDENTIAL_INVITATION_SUCCESS = 'GET_CREDENTIAL_INVITATION_SUCCESS',
  GET_CREDENTIAL_INVITATION_FAILURE = 'GET_CREDENTIAL_INVITATION_FAIL',
}

// CREDENTIAL INVITATIONS
const getCredentialInvitationAction = new ApiReduxAction(supportApi, {
  request: { type: invitationActions.GET_CREDENTIAL_INVITATION_REQUEST },
  success: { type: invitationActions.GET_CREDENTIAL_INVITATION_SUCCESS },
  failure: {
    type: invitationActions.GET_CREDENTIAL_INVITATION_FAILURE,
    title: 'getCredentialInvitationFail',
  },
  handle404: noop
}, (dux: IExposeRedux, credentialId: string) => {
  return supportApi.cred.getCredentialInvitation.bind(supportApi.cred, credentialId);
});
export const getCredentialInvitationById = getCredentialInvitationAction.go;
