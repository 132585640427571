import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniGauge,
  UniKeyVal,
  UniTable,
  UniLocalize,
  UniConditionalRender,
  DealerCS,
  UserCS,
  EUserStatusCS,
  DealerInviteCS,
  EInviteStatusCS,
  IDealerAddressCS,
  OrganizationCS,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  TSize,
  EOrganizationStatus,
  IDealerAddressModelCS,
  S10nModelC,
  ES10nModelType,
  Editable,
  IMultiInputUpdate,
  notBlankV10n,
} from '@unikey/unikey-commons/release/csupp';

import {
  AdminInviteList,
  CouponAssignmentContainer,
  attemptUpdateDealer,
  attemptResendAdminInvite,
  attemptRetrieveDealerById,
  attemptRetrieveDealerAddress,
  attemptRetrieveDealerOrganizations,
  updateDealerOrganizationsQueryParams,
  updateDealerOrganizationsTableMeta,
  attemptRetrieveDealerAdmins,
  updateDealerAdminsQueryParams,
  updateDealerAdminsTableMeta,
  attemptRetrieveDealerInvites,
  updateDealerInvitesQueryParams,
  updateDealerInvitesTableMeta,
  updateOrgListQueryParams,
  toggleAssignCouponModal,
  handleDealerChange,
  IUpdateDealerParams,
  navConfig, ENavPages,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection,
  PartnerCustomizations, IPartnerCustomizations,
  dateOrLoading
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  dealer: DealerCS,
  history: any,
  address: IDealerAddressModelCS,
  
  organizations: OrganizationCS[],
  orgListQueryParams: IPaginatedParamsCS,
  orgListAppliedFilters: IUniTable_Filter[],
  orgListAppliedSorts: IUniTable_Sort[],
  orgListLoading: boolean,
  orgListPaginationSummary: IUniTable_PaginationSummary,
  
  admins: UserCS[],
  adminListQueryParams: IPaginatedParamsCS,
  adminListAppliedFilters: IUniTable_Filter[],
  adminListAppliedSorts: IUniTable_Sort[],
  adminListLoading: boolean,
  adminListPaginationSummary: IUniTable_PaginationSummary,

  invites: DealerInviteCS[],
  inviteListQueryParams: IPaginatedParamsCS,
  inviteListLoading: IUniTable_Filter[],
  inviteListAppliedFilters: IUniTable_Sort[],
  inviteListAppliedSorts: boolean,
  inviteListPaginationSummary: IUniTable_PaginationSummary,

  couponAssignModalOpen: boolean,
  
  loading: boolean,
  saving: boolean,
  showSubBrand?: boolean,
  handleDealerChange(changes: IMultiInputUpdate): void,
  attemptUpdateDealer(updateParams: IUpdateDealerParams): Promise<void>,
  getDealerById(dealerId: string): void,
  getDealerAddress(dealerId: string): void,
  getDealerOrgs(dealerId: string): Promise<void>,
  updateOrgListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateOrgListQueryParams?(params: IPaginatedParamsCS): void,
  getDealerAdmins(dealerId: string): Promise<void>,
  updateAdminListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateAdminListQueryParams?(params: IPaginatedParamsCS): void,
  getDealerInvites(dealerId: string): Promise<void>,
  updateInviteListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateInviteListQueryParams?(params: IPaginatedParamsCS): void,
  resendAdminInvite(inviteId: string): Promise<void>,
  toggleCouponAssignmentModal(): Promise<void>
}

class DealerDetailsContainer extends Component<IProps> {
  _updateOrgsTable: TTableUpdateFunc; 
  _updateAdminsTable: TTableUpdateFunc;

  constructor(props: IProps) {
    super(props);
    
    this._updateOrgsTable = buildTableUpdateFunc(
      props.getDealerOrgs.bind(this, props.match.params.dealerId),
      props.updateOrgListTableMeta,
      props.updateOrgListQueryParams
    )
    
    this._updateAdminsTable = buildTableUpdateFunc(
      props.getDealerAdmins.bind(this, props.match.params.dealerId),
      props.updateAdminListTableMeta,
      props.updateAdminListQueryParams
    )

  }

  componentDidMount() {
    this.props.getDealerById(this.props.match.params.dealerId);
    this.props.getDealerOrgs(this.props.match.params.dealerId);
    this.props.getDealerAdmins(this.props.match.params.dealerId);
    this.props.getDealerInvites(this.props.match.params.dealerId);
  }

  _resendInvite = (inviteId: string) => {
    this.props.resendAdminInvite(inviteId);
  }

  _buildOrgTableColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        size: 8,
      })
      .set('creditSummary', {
        nameKey: 'creditSummary',
        isSortable: false,
        size: 6,
        template: (rowItem: OrganizationCS, rowIndex: number) => (
          <>
            {/* limited org credits display */}
            <UniConditionalRender visible={rowItem.creditsAllocated !== null}>
              <UniGauge
                id={`organizationCreditAllocation-gauge-${rowIndex}`}
                className="table-row-gauge"
                key={rowIndex}
                nameKey="organizationCreditAllocation"
                size="sm"
                stacked={false}
                max={rowItem.creditsAllocated!}
                vals={[
                  {
                    nameKey: 'organizationCreditsConsumed',
                    value: rowItem.creditsClaimed!,
                    theme: 'secondary'
                  }
                ]}
              />
            </UniConditionalRender>
            {/* unlimited org credits display */}
            <UniConditionalRender visible={rowItem.creditsAllocated === null}>
              <i><UniLocalize translate="unlimited" /></i>
            </UniConditionalRender>
          </>

        )
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        type: 'enum-tagdot',
        enumType: EOrganizationStatus,
        size: 6
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 2
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.organizationDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    })

    return { columns, actions };
  }
  
  _buildAdminTableColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        size: 6,
      })
      .set('roleNameKey', {
        nameKey: 'role',
        isSortable: false,
        isFilterable: false,
        type: 'tag',
        size: 4
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        type: 'enum-tagdot',
        translate: true,
        enumType: EUserStatusCS,
        size: 4
      })
      .set('firstName', {
        nameKey: 'firstName',
        isSortable: true,
        isFilterable: true,
        type: 'string',
        size: 2,
      })
      .set('lastName', {
        nameKey: 'lastName',
        isSortable: true,
        isFilterable: true,
        type: 'string',
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 0
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.adminDetails)!.linkTo([rowItem.id])!),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    })

    return { columns, actions };
  }
  
  _updateAndReloadDealer = (): Promise<any> => {
    return this.props.attemptUpdateDealer({ dealerId: this.props.match.params.dealerId, newName: this.props.dealer.name?.value }).then(() => {
      return this.props.getDealerById(this.props.match && this.props.match.params.dealerId);
    });
  }

  _reloadOnCancel = (): Promise<any> => {
    return Promise.resolve(this.componentDidMount())
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const dealerNotCompatibleWithCreditSystem = this.props.dealer.subscriptionModel === ES10nModelType.reader || this.props.dealer.subscriptionModel === ES10nModelType.unknown;

    const { columns: orgTableColumns, actions: orgTableActions } = this._buildOrgTableColumnsAndActions();
    const { columns: adminTableColumns, actions: adminTableActions } = this._buildAdminTableColumnsAndActions();

    return (
      <section className='dealerDetails-container'>

        {/* Key vals for images belonging to this product */}
        <Row>
          <Col>
            <h3 className="page-title-non-table">{this.props.dealer.name?.value}</h3>
          </Col>
        </Row>

        <UniKeyVal
          label="dealer-info"
          showLoader={this.props.loading}
          saveClick={this._updateAndReloadDealer}
          cancelClick={this._reloadOnCancel}
          isSaving={this.props.saving}
          fields={[
            {
              keyName: 'id',
              value: this.props.dealer.id!,
              type: 'string',
              disabled: true
            },
            {
              keyName: 'name',
              value: '' + this.props.dealer.name?.value,
              type: 'string',
              preventTranslate: true,
              handleUpdate: (name: Editable<string>) => this.props.handleDealerChange({ name }),
              validations: new Map([notBlankV10n])
            },
            {
              keyName: 'phoneNumber',
              value: '' + this.props.dealer.phoneNumber?.value,
              preventTranslate: true,
              type: 'string',
              disabled: true
            },
            {
              keyName: 'createdDate',
              value: dateOrLoading(this.props.dealer.created, this.props.loading),
              type: 'string',
              disabled: true
            },
            {
              keyName: 'subscriptionModel',
              value: S10nModelC.getNameKeyFromModelType(this.props.dealer.subscriptionModel!),
              type: 'string',
              disabled: true
            },
            {
              keyName: 'brand',
              value: '' + this.props.dealer?.brand,
              disabled: true,
              hidden: !this.props.showSubBrand,              
              type: 'tag'
            }
          ]} />

        <UniConditionalRender visible={!dealerNotCompatibleWithCreditSystem}>
          <Row>
            <Col>
              <h3 className="page-title-non-table"><UniLocalize translate="dealerCredits" /></h3>
            </Col>
          </Row>

          <UniKeyVal
            label="dealer-credits"
            primaryStateButtonSet={[
              {
                textKey: 'addCouponCreditsToDealer',
                icon: 'outlineAdd',
                clickHandler: this.props.toggleCouponAssignmentModal,
                disabled: dealerNotCompatibleWithCreditSystem,
                disabledReasonKeys: ['_explaindDealerNotCompatibleWithCoupons']
              }
            ]}
            secondaryStateButtonSet={[]}
            showLoader={this.props.loading}
            fields={[
              {
                keyName: 'creditUnitSummary',
                type: 'gauge',
                value: `${(this.props.dealer.issuedCredentials || 0) + (this.props.dealer.availableCredentials || 0)}`,
                hidden: this.props.dealer.availableCredentials! >= 1000000000,
                gaugeOpts: {
                  nameKey: 'creditUnitSummary',
                  stacked: false,
                  showKey: true,
                  max: (this.props.dealer.issuedCredentials || 0) + (this.props.dealer.availableCredentials || 0),
                  preventReorder: true,
                  size: 'lg',
                  vals: [
                    {
                      nameKey: 'issuedCredentials',
                      value: this.props.dealer.issuedCredentials|| 0,
                      descriptionKeys: ['_dealerCredentialsIssuedExplanation'],
                      theme: 'secondary'
                    },
                    {
                      nameKey: 'allocatedCredits',
                      value: this.props.dealer.unclaimedCredentials || 0,
                      descriptionKeys: ['_allocatedCreditsExplanation'],
                      theme: 'primary'
                    },
                    {
                      nameKey: 'unallocatedCredits',
                      value: (this.props.dealer.availableCredentials || 0) - (this.props.dealer.unclaimedCredentials || 0),
                      descriptionKeys: ['_unallocatedCreditsExplanation'],
                      theme: 'default'
                    }
                  ]
                }
              }
            ]} />
        </UniConditionalRender>

        <UniConditionalRender visible={this.props.address && Object.keys(this.props.address).length > 0} >
          <Row nogutter>
            <Col>
              <h3><UniLocalize translate="dealerAddress" /></h3>
            </Col>
          </Row>

          <UniKeyVal
            label="dealer-address"
            primaryStateButtonSet={[]}
            secondaryStateButtonSet={[]}
            showLoader={this.props.loading}
            fields={[
              {
                keyName: 'streetAddress',
                value: this.props.address && this.props.address.lines ? this.props.address.lines.join(' | ') : '',
                type: 'string',
                preventTranslate: true
              },
              {
                keyName: 'postalCode',
                value: this.props.address && this.props.address.postalCode,
                type: 'string',
                preventTranslate: true
              },
              {
                keyName: 'country',
                value: this.props.address && this.props.address.country,
                type: 'string',
                preventTranslate: true
              }
            ]} />
        </UniConditionalRender>

        {/* Display the dealer specific readers */}
        <section className='orgList-container'>
          <UniTable
            searchable={true}
            advancedFiltering={true}
            titleKey="organizationsList"
            createButtonTextKey="organization"
            handleUpdate={this._updateOrgsTable}
            data={this.props.organizations}
            paginationSummary={this.props.orgListPaginationSummary}
            columnConfig={orgTableColumns}
            activeSorts={this.props.orgListAppliedSorts}
            actionsConfig={orgTableActions}
            activeFilters={this.props.orgListAppliedFilters}
            showLoader={this.props.orgListLoading} />
        </section>

        {/* Display the list of dealer admins */}
        <section className='adminList-container'>
          <UniTable
            searchable={false}
            advancedFiltering={false}
            titleKey="adminList"
            createButtonTextKey="admin"
            handleUpdate={this._updateAdminsTable}
            data={this.props.admins}
            paginationSummary={this.props.adminListPaginationSummary}
            columnConfig={adminTableColumns}
            activeSorts={this.props.adminListAppliedSorts}
            actionsConfig={adminTableActions}
            activeFilters={this.props.adminListAppliedFilters}
            showLoader={this.props.adminListLoading} />
        </section>

        {/* Display the list of dealer invites */}
        <UniConditionalRender visible={!!this.props.dealer?.id}>
          <AdminInviteList 
            key={this.props.invites?.[0]?.expiration}
            history={this.props.history}
            match={this.props.match}
            invites={this.props.invites}
            inviteListQueryParams={this.props.inviteListQueryParams}
            inviteListLoading={this.props.inviteListLoading}
            inviteListAppliedFilters={this.props.inviteListAppliedFilters}
            inviteListAppliedSorts={this.props.inviteListAppliedSorts}
            inviteListPaginationSummary={this.props.inviteListPaginationSummary}
            getScopedInvites={this.props.getDealerInvites.bind(this, this.props.match.params.dealerId)}
            updateInviteListQueryParams={this.props.updateInviteListQueryParams}
            updateInviteListTableMeta={this.props.updateInviteListTableMeta}
            resendAdminInvite={this.props.resendAdminInvite} />
        </UniConditionalRender>

        {/* Display the coupon assignment modal when open */}
        <UniConditionalRender visible={!!this.props.couponAssignModalOpen}>
          <CouponAssignmentContainer
            initialDealer={this.props.dealer}
            actionAfterSave={() => this.props.getDealerById(this.props.match && this.props.match.params.dealerId)}  />
        </UniConditionalRender>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    // dealer details stuff
    dealer: state.dealerDetails.dealerData,
    loading: state.dealerDetails.loading,
    saving: state.dealerDetails.saving,
    address: state.dealerDetails.dealerData.address,
    // org list stuff
    organizations: state.dealerOrgs.data.models,
    orgListQueryParams: state.dealerOrgs.queryParams,
    
    orgListAppliedFilters: state.dealerOrgs.tableFilters,
    orgListAppliedSorts: state.dealerOrgs.tableSorts,
    orgListPaginationSummary: state.dealerOrgs.paginationSummary,
    // admin list stuff
    admins: state.dealerAdmins.data.models,
    adminListQueryParams: state.dealerAdmins.queryParams,
    adminListLoading: state.dealerAdmins.loading,
    adminListAppliedFilters: state.dealerAdmins.tableFilters,
    adminListAppliedSorts: state.dealerAdmins.tableSorts,
    adminListPaginationSummary: state.dealerAdmins.paginationSummary,
    // invites list stuff
    invites: state.dealerInvites.data.models,
    inviteListQueryParams: state.dealerInvites.queryParams,
    inviteListLoading: state.dealerInvites.loading,
    inviteListAppliedFilters: state.dealerInvites.tableFilters,
    inviteListAppliedSorts: state.dealerInvites.tableSorts,
    inviteListPaginationSummary: state.dealerInvites.paginationSummary,
    // coupon assignment stuff
    couponAssignModalOpen: state.couponAssignment.modalOpen
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  handleDealerChange,
  attemptUpdateDealer,
  getDealerById: attemptRetrieveDealerById,
  getDealerAddress: attemptRetrieveDealerAddress,
  getDealerOrgs: attemptRetrieveDealerOrganizations,
  getDealerAdmins: attemptRetrieveDealerAdmins,
  updateOrgListQueryParams: updateDealerOrganizationsQueryParams,
  updateOrgListTableMeta: updateDealerOrganizationsTableMeta,
  updateAdminListQueryParams: updateDealerAdminsQueryParams,
  updateAdminListTableMeta: updateDealerAdminsTableMeta,
  getDealerInvites: attemptRetrieveDealerInvites,
  updateInviteListQueryParams: updateDealerInvitesQueryParams,
  updateInviteListTableMeta: updateDealerInvitesTableMeta,
  resendAdminInvite: attemptResendAdminInvite,
  toggleCouponAssignmentModal: toggleAssignCouponModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DealerDetailsContainer)
  ), { componentName: 'DealerDetails' })
