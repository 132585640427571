import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniKeyVal,
  UniOverflowActions,
  UniConditionalRender,
  UniLocalize,
  CouponCS,
  ECouponStatus,
  TSize,
  IUniMenu_ItemConfig,
  IUniToast_Alert,
  ICouponClaimCS,
} from '@unikey/unikey-commons/release/csupp';

import { partnerKey } from '@alias-environment';
import {
  CouponAssignmentContainer,
  attemptRetrieveCouponById,
  toggleAssignCouponModal,
  PartnerCustomizations, IPartnerCustomizations,
  dateOrLoading,
  addAlert,
  navConfig, ENavPages,
  copyToClipboard,
} from '../internal';


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  coupon: CouponCS,
  loading: boolean,
  couponAssignModalOpen: boolean,

  getDesiredCoupon(couponId: string): void,
  toggleCouponAssignmentModal(): void
}

class CouponDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getDesiredCoupon(this.props.match.params.couponId);
  }

  
  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const isWrongBrand = this.props.coupon?.brand?.toUpperCase?.() !== partnerKey?.toUpperCase?.();
    const isClaimedOrAssigned = this.props.coupon.status === ECouponStatus.assigned || this.props.coupon.status === ECouponStatus.claimed;
    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('redeem', {
        textKey: 'assignCoupon',
        handleClick: this.props.toggleCouponAssignmentModal,
        disabled: isClaimedOrAssigned || isWrongBrand,
        disabledReasonKeys: [ isClaimedOrAssigned ? '_explainCouponAlreadyRedeemed' : '_explainCouponIsWrongBrand']
      });

    return (
      <section className='coupon-details-container'>

        <Row justify="between">
          <Col>
            <h3 className="page-title-non-table"><UniLocalize translate="couponDetails" /></h3>
          </Col>
          <Col xs={6} lg={4}>
            <UniOverflowActions
              className="title-actions"
              nameKey="couponActions"
              icon="arrowDropDownCircle"
              actions={actionsMenuContents}
              theme="primary" />
          </Col>
        </Row>
        
        <Row>
          <Col md={12}>
            <UniKeyVal
              label="coupon"
              showLoader={this.props.loading}
              preventEdit={true}
              stacked={true}
              fields={[
                {
                  keyName: 'id',
                  value: `${this.props.coupon.id}`,
                  type: 'string'
                },
                {
                  keyName: 'units',
                  value: `${this.props.coupon.units}`,
                  type: 'string',
                  keyInfo: {
                    icon: 'infoOutline',
                    textKeys: ['numberOfUnits', '_explainCouponUnits'],
                  }
                },
                {
                  keyName: 'couponType',
                  value: this.props.coupon.isGiveaway ? 'giveawayType' : 'paidType',
                  type: 'string'
                },
                {
                  keyName: 'status',
                  value: this.props.coupon.status,
                  type: 'enum',
                  enumType: ECouponStatus
                },
                {
                  keyName: 'redeemedBy',
                  value: '' + this.props.coupon.dealer?.name?.value,
                  type: 'link',
                  hidden: !this.props.coupon.dealer,
                  linkTo: navConfig.get(ENavPages.dealerDetails)!.linkTo([this.props.coupon.dealerId])!,
                },
                {
                  keyName: 'createdForBrand',
                  value: '' + this.props.coupon.brand,
                  type: 'tag',
                  hidden: !!this.props.coupon.dealer, // already redeemed,
                },
                {
                  keyName: 'createdDate',
                  value: dateOrLoading(this.props.coupon.createdDate),
                  type: 'string'
                },
                {
                  keyName: 'expireDate',
                  value: dateOrLoading(this.props.coupon.expiry),
                  type: 'string'
                }
              ]} />
          </Col>

          <Col md={12}>
            <UniConditionalRender visible={isClaimedOrAssigned}>
              <UniKeyVal
                label="coupon-units-consumed"
                stacked={true}
                showLoader={this.props.loading}
                preventEdit={true}
                fields={[
                  {
                    keyName: 'couponUnitSummary',
                    value: `${this.props.coupon.units}`,
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['couponUnitSummary', '_explainCouponUnitsClaimed'],
                    },
                    type: 'gauge',
                    gaugeOpts: {
                      nameKey: 'unitsClaimed',
                      size: 'lg' as TSize,
                      stacked: false,
                      max: this.props.coupon.units,
                      vals: [
                        {
                          nameKey: 'unitsClaimed',
                          value: this.props.coupon.nonFreeClaimsCount,
                          theme: 'secondary'
                        }

                      ]
                    }
                  }
                ]} />
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.coupon.status === ECouponStatus.available}>
              <UniKeyVal
                label="coupon-serial-number"
                stacked={true}
                showLoader={this.props.loading}
                preventEdit={isClaimedOrAssigned}
                secondaryStateButtonSet={[]}
                primaryStateButtonSet={[
                  {
                    textKey: 'copyToClipboard',
                    icon: 'contentCopy',
                    clickHandler: () => copyToClipboard({ fromElement: '.value-for-key-serialNumber' }, this.props.showAlertNotice)
                  }
                ]}
                fields={[
                  {
                    keyName: 'serialNumber',
                    value: `${this.props.coupon.serialNumber}`,
                    type: 'string',
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['serialNumber', '_explainSerialNumber'],
                    }
                  }
                ]} />

              <UniKeyVal
                label="coupon-serial-number"
                stacked={true}
                showLoader={this.props.loading}
                preventEdit={isClaimedOrAssigned}
                secondaryStateButtonSet={[]}
                primaryStateButtonSet={[
                  {
                    textKey: 'copyToClipboard',
                    icon: 'contentCopy',
                    clickHandler: () => copyToClipboard({ fromElement: '.value-for-key-authCode' }, this.props.showAlertNotice)
                  }
                ]}
                fields={[
                  {
                    keyName: 'authCode',
                    value: `${this.props.coupon.authCode}`,
                    type: 'string',
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['authCode', '_explainAuthCode'],
                    }
                  }
                ]} />
            </UniConditionalRender>
          </Col>
        </Row>

        {/* Display the coupon assignment modal when open */}
        <UniConditionalRender visible={!!this.props.couponAssignModalOpen}>
          <CouponAssignmentContainer 
            initialCoupon={this.props.coupon} 
            actionAfterSave={() => this.props.getDesiredCoupon(this.props.match.params.couponId)} />
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    coupon: state.couponDetails.couponData,
    loading: state.couponDetails.loading,
    // coupon assignment stuff
    couponAssignModalOpen: state.couponAssignment.modalOpen
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getDesiredCoupon: attemptRetrieveCouponById,
  toggleCouponAssignmentModal: toggleAssignCouponModal,
  showAlertNotice: addAlert,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CouponDetailsContainer)
    ), { componentName: 'CouponDetails' })
