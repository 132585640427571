import { Dispatch } from 'redux'

import {
  CredentialCS,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  ICredentialCS,
  IPartnerCredImpls,
  EMessageType,
  IUniTable_UpdatePaginationSummary,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  setPageSizeInCache
} from '../../internal'

export enum credentialActions {


  GET_CREDENTIALS_REQUEST = 'GET_CREDENTIALS_REQUEST',
  GET_CREDENTIALS_SUCCESS = 'GET_CREDENTIALS_SUCCESS',
  GET_CREDENTIALS_FAILURE = 'GET_CREDENTIALS_FAIL',

  SET_ACTIVE_CREDENTIAL = 'SET_ACTIVE_CREDENTIAL',

  UPDATE_CREDENTIAL_QUERY_PARAMS = 'UPDATE_CREDENTIAL_QUERY_PARAMS',
  UPDATE_CREDENTIAL_TABLE_META = 'UPDATE_CREDENTIAL_TABLE_META',

  HANDLE_CREDENTIAL_NAME_CHANGE = 'HANDLE_CREDENTIAL_NAME_CHANGE',

  GET_SINGLE_CREDENTIAL_REQUEST = 'GET_SINGLE_CREDENTIAL_REQUEST',
  GET_SINGLE_CREDENTIAL_SUCCESS = 'GET_SINGLE_CREDENTIAL_SUCCESS',
  GET_SINGLE_CREDENTIAL_FAILURE = 'GET_SINGLE_CREDENTALS_FAIL',
}

// CREDENTIALS
const getCredentialListAction = new ApiReduxAction(supportApi, {
  request: { type: credentialActions.GET_CREDENTIALS_REQUEST },
  success: { type: credentialActions.GET_CREDENTIALS_SUCCESS },
  failure: {
    type: credentialActions.GET_CREDENTIALS_FAILURE,
    title: 'getCredentialsFail',
  },
  tableMetaUpdate: {
    type: credentialActions.UPDATE_CREDENTIAL_TABLE_META
  }
}, (dux: IExposeRedux, partnerCredImpls: IPartnerCredImpls): any => {
  const params = dux.getState().credentials.queryParams;
  return supportApi.cred.getAllCreds.bind(supportApi.cred, params, partnerCredImpls);
});
export const attemptRetrieveCredentials = getCredentialListAction.go;

export function updateCredentialListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: credentialActions.UPDATE_CREDENTIAL_QUERY_PARAMS,
    queryParams
  }
}

export function updateCredentialTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: credentialActions.UPDATE_CREDENTIAL_TABLE_META,
    ...meta
  }
}


export function setActiveCredential(credential: CredentialCS) {
  return {
    type: credentialActions.SET_ACTIVE_CREDENTIAL,
    credential
  }
}

export interface IGetSingleCredentialActionParams {
  partnerCredImpls: IPartnerCredImpls,
  credId: string
}
// SINGLE DEALER
const getSingleCredentialAction = new ApiReduxAction(supportApi, {
  request: { type: credentialActions.GET_SINGLE_CREDENTIAL_REQUEST },
  success: { type: credentialActions.GET_SINGLE_CREDENTIAL_SUCCESS },
  failure: {
    type: credentialActions.GET_SINGLE_CREDENTIAL_FAILURE,
    title: 'getSingleCredentialFail',
  }
}, (dux: IExposeRedux, params: IGetSingleCredentialActionParams) => {
  return supportApi.cred.getCredential.bind(supportApi.deal, params.credId, params.partnerCredImpls);
});
export const attemptRetrieveCredentialById = getSingleCredentialAction.go;
