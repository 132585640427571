import {
  ReaderCS
} from '@unikey/unikey-commons/release/csupp'

import {
  portalActions,
  readerActions,
  firmwareActions,
  inputInitializer,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialReadersState = populateInitialReduxPaginatedListState(['created'], 'reverse');
export const readers = (state = initialReadersState, action: any) => {
  switch (action.type) {
    case readerActions.GET_READERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READERS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case readerActions.GET_READERS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialReadersState.data
      }
    case readerActions.UPDATE_READERS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    case portalActions.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case readerActions.UPDATE_READERS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    default:
      return state
  }
}

const initialReaderDetailsState = {
  loading: true,
  saving: false,
  readerData: ({
    id: '',
    productId: '',
    upgradeAvailable: '',
    certificateName: '',
    serialNumber: '',
    sessionStatus: 0,
    ...inputInitializer('', ['name', 'availableUpgradeVersion', 'status', 'firmwareVersion']),
  } as any),
  productFirmwareVersions: []
};

const setEditReaderFieldsBasedOnReaderResult = (readerResult: ReaderCS) => {
  return {
    ...readerResult,
    name: {
      value: readerResult.name,
      valid: true
    },
    status: {
      value: readerResult.status,
      valid: true,
    },
    availableUpgradeVersion: {
      // TODO: once null is allowed again we'll need to account for it here
      value: readerResult.availableUpgradeVersion,
      valid: readerResult.availableUpgradeVersion || readerResult.availableUpgradeVersion === null ? true : undefined,
    },
    firmwareVersion: {
      value: readerResult.firmwareVersion,
      valid: true,
    }
  }
}

export const readerDetails = (state = initialReaderDetailsState, action: any) => {
  switch (action.type) {
    case readerActions.GET_READER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        readerData: setEditReaderFieldsBasedOnReaderResult(action.value)
      }
    case readerActions.GET_READER_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        readerData: setEditReaderFieldsBasedOnReaderResult({} as ReaderCS)
      }
    case readerActions.HANDLE_READER_FORM_DATA_CHANGE:
      return {
        ...state,
        readerData: {
          id: state.readerData.id,
          upgradeAvailable: state.readerData.upgradeAvailable,
          productId: state.readerData.productId,
          name: action.name ? action.name : state.readerData.name,
          certificateName: state.readerData.certificateName,
          hardwareCertificate: state.readerData.hardwareCertificate,
          serialNumber: state.readerData.serialNumber,
          organization: state.readerData.organization,
          // TODO: once null is allowed again, we'll need to account for it here.
          availableUpgradeVersion: action.availableUpgradeVersion ? action.availableUpgradeVersion : state.readerData.availableUpgradeVersion,
          firmwareVersion: action.firmwareVersion ? action.firmwareVersion : state.readerData.firmwareVersion,
          status: action.status ? action.status : state.readerData.status,
        }
      }
    case readerActions.UPDATE_READER_REQUEST:
      return {
        ...state,
        saving: true
      }
    case readerActions.UPDATE_READER_SUCCESS:
      return {
        ...state,
        saving: action.pending,
        readerData: setEditReaderFieldsBasedOnReaderResult(action.value)
      }
    case readerActions.UPDATE_READER_FAILURE:
      return {
        ...state,
        saving: action.pending,
      }
    case firmwareActions.GET_FIRMWARE_LIST_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        productFirmwareVersions: action.value
      }
    case readerActions.RESET_READER_ENROLLMENT_REQUEST:
        return {
          ...state,
          loading: true
        }
    case readerActions.RESET_READER_ENROLLMENT_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        readerData: setEditReaderFieldsBasedOnReaderResult(action.value)
      }
    case readerActions.RESET_READER_ENROLLMENT_FAILURE:
      return {
        ...state,
        loading: action.pending,
      }
    default:
      return state
  }
}

const initialReaderCredsState = populateInitialReduxPaginatedListState(['email'], 'forward');

export const readerCreds = (state = initialReaderCredsState, action: any) => {
  switch (action.type) {
    case readerActions.GET_READER_CREDENTIALS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READER_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value
      }
    case readerActions.GET_READER_CREDENTIALS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialReaderCredsState.data
      }
    case readerActions.UPDATE_READER_CREDENTIALS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case readerActions.UPDATE_READER_CREDENTIALS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}


const initialReaderHistoryState: any = populateInitialReduxPaginatedListState(['date'], 'forward');
initialReaderHistoryState.detailsOpen = false;
initialReaderHistoryState.selectedDetails = {};

export const readerHistory = (state = initialReaderHistoryState, action: any) => {
  switch (action.type) {
    case readerActions.GET_READER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value
      }
    case readerActions.GET_READER_HISTORY_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialReaderHistoryState.data
      }
    case readerActions.UPDATE_READER_HISTORY_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case readerActions.UPDATE_READER_HISTORY_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    case readerActions.TOGGLE_READER_HISTORY_DETAILS: 
      return  {
        ...state,
        detailsOpen: !state.detailsOpen,
      }
    case readerActions.SET_READER_HISTORY_DETAILS: 
      return  {
        ...state,
        selectedDetails: action.selected,
      }
    default:
      return state
  }
}
