import {
    userActions,
    inputInitializer
  } from '../../internal'
  
  
  const initialSetPasswordState = {
    ...inputInitializer('', ['password', 'confirmPassword'])
  };
  
  export const setPasswordTokenForm = (state = initialSetPasswordState, action: any) => {
    switch (action.type) {
      case userActions.UPDATE_SET_PASSWORD_TOKEN_FORM:
        return {
          ...state,
          password: action.formChanges.password ?? state.password,
          confirmPassword: action.formChanges.confirmPassword ?? state.confirmPassword,
        }
      default:
        return state
    }
  };
  
  const initialActionTokenState = {
    data: {},
    loading: false
  };
  
  export function actionTokens(state = initialActionTokenState, action: any) {
    switch (action.type) {
      case userActions.USER_TOKEN_ACTION_REQUEST:
        return {
          ...state,
          loading: action.loading,
        }
      case userActions.USER_TOKEN_ACTION_SUCCESS:
        return {
          ...state,
          loading: action.loading,
          data: action.value,
        }
      case userActions.USER_TOKEN_ACTION_FAILURE:
        return {
          ...state,
          loading: action.loading,
        }
      default:
        return state
    }
  }



const resendVerifyEmailSplashFormState = {
  loading: false,
  sent: false,
}

export const resendVerifyEmailSplashForm = (state = resendVerifyEmailSplashFormState, action: any) => {
  switch (action.type) {
    case userActions.RESEND_VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case userActions.RESEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        sent: true,
      }
    case userActions.RESEND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
};

const initialChangePasswordFormState = {
  open: false,
}

export const changePasswordForm = (state = initialChangePasswordFormState, action: any) => {
  switch (action.type) {
    case userActions.TOGGLE_CHANGE_PASSWORD_WORKFLOW:
      return {
        ...state,
        open: !state.open
      }
    default:
      return state
  }
};

const initialAddPasswordModalState = {
  open: false,
  alreadySent: false,
  loading: false,
}

export const addPasswordModal = (state = initialAddPasswordModalState, action: any) => {
  switch (action.type) {
    case userActions.TOGGLE_ADD_PASSWORD_WORKFLOW:
      return {
        ...state,
        open: !state.open
      }
    case userActions.ADD_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        loading: action.pending
      }
    case userActions.ADD_PASSWORD_EMAIL_SUCCESS:
    case userActions.ADD_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        loading: action.pending,
        alreadySent: true
      }
    default:
      return state
  }
};

