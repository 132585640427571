import * as Oidc from 'oidc-client'
import UAParser from 'ua-parser-js'
import { v4 as uuidv4 } from 'uuid';

import {
  keepUiTheme,
  darkThemeName
} from '@unikey/unikey-commons';

import {
  supportApi,
  cspVersion,
  oidcClientId,
  oidcPartnerBrandId,
  oidcPartnerBrandCode,
  // generateDeviceCert,
} from '../internal';

export const buildOidcAcrValues = () => {
  // OIDC Authorization configured with redux by following the guide here:
  // https://github.com/maxmantz/redux-oidc/blob/HEAD/docs/API.md
  const userAgent = new UAParser();
  Oidc.Log.logger = console;
  const browserInfo = userAgent.getBrowser();
  const osInfo = userAgent.getOS();
  // const staticDeviceId = supportApi.getStaticDeviceId();
  // const deviceId = supportApi.getActiveDeviceId() || uuidv4();
  // const deviceCert = generateDeviceCert(deviceId);
  const currUiTheme = keepUiTheme();

  const acrVals = `
    appId:${oidcClientId}
    tenant:${oidcPartnerBrandId}
    tenantCode:${oidcPartnerBrandCode}
    tenantClientId:${oidcClientId}
    loginContext:Web
    deviceType:Browser
    deviceName:${browserInfo.name?.replace(/\s/g, '-')},(${osInfo.name?.replace(/\s/g, '-')})
    deviceMake:${browserInfo.name?.replace(/\s/g, '-')},${browserInfo.version?.replace(/\s/g, '-')}
    deviceModel:${osInfo.name?.replace(/\s/g, '-')},${osInfo.version?.replace(/\s/g, '-')}
    deviceVersion:rp-${cspVersion}
    isDark:${currUiTheme === darkThemeName}
    `;

  // const acrVals = `
  //   appId:${oidcClientId}
  //   tenant:${oidcPartnerBrandId}
  //   tenantCode:${oidcPartnerBrandCode}
  //   tenantClientId:${oidcClientId}
  //   loginContext:Web
  //   deviceType:Browser
  //   deviceName:${browserInfo.name?.replace(/\s/g, '-')},(${osInfo.name?.replace(/\s/g, '-')})
  //   deviceMake:${browserInfo.name?.replace(/\s/g, '-')},${browserInfo.version?.replace(/\s/g, '-')}
  //   deviceModel:${osInfo.name?.replace(/\s/g, '-')},${osInfo.version?.replace(/\s/g, '-')}
  //   deviceVersion:rp-${cspVersion}
  //   staticDeviceId:${staticDeviceId}
  //   deviceCertificate:${deviceCert}
  //   isDark:${currUiTheme === darkThemeName}`;
  return acrVals;
}