import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  IUniTable_Filter,
  IUniTable_Column,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IRoleCS,
  RoleCS,
  ERoleTypeCS,
  UniLocalize
} from '@unikey/unikey-commons/release/csupp';

import {
  supportApi,
  navConfig, ENavPages,
  getTableSortDirection, getTableParamsFromUpdate,
  toggleModal,
  updateAdminRolesTableMeta,
  updateRoleListQueryParams,
  attemptRetrieveAdminRoles,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  loading: boolean,
  history: any,
  match?: any,
  roleData: IPaginatedResponseCS<IRoleCS, RoleCS>,
  roleListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  updateQueryParams?(params: IPaginatedParamsCS): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  getAdminRoles(adminId: string, params: IPaginatedParamsCS): void,
}

class AdminRolesList extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getAdminRoles(this.props.match.params.adminId, this.props.roleListQueryParams);
  }

  // We have no way to know if the role's resourceId is a dealerId or an orgId.
  // Fetch a dealer with the resource id. If found send to dealer details page,
  // if not found, send its not a dealer, so send to the org details page.
  _goToOrgOrDealerResource = (role: RoleCS) => {
    supportApi.deal.getDealer(role.resourceId).then(() => {
      this.props.history.push(navConfig.get(ENavPages.dealerDetails)!.linkTo([role.resourceId])!);
    }, () => {
      this.props.history.push(navConfig.get(ENavPages.organizationDetails)!.linkTo([role.resourceId])!);
    });
  }

  _updateTable = (update: IUniTable_UpdatePaginationSummary) => {
    if (this.props.updateQueryParams) {
      const params = getTableParamsFromUpdate(update);

      this.props.updateTableMeta(update);
      this.props.updateQueryParams(params);
      this.props.getAdminRoles(this.props.match.params.adminId, params);
    }
  }

  _buildColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('dealerName', {
        nameKey: 'dealer',
        isSortable: false,
        type: 'string',
        template: (rowItem: RoleCS) => rowItem.dealer?.name?.value,
        evalItalicized: (rowItem: RoleCS) => rowItem.name === ERoleTypeCS.LimitedAdmin && !rowItem.organization,
        size: 6
      })
      .set('nameKey', {
        nameKey: 'adminLevel',
        isSortable: false,
        type: 'tag',
        size: 4,
        evalItalicized: (rowItem: RoleCS) => rowItem.name === ERoleTypeCS.LimitedAdmin && !rowItem.organization,
      })
      .set('orgName', {
        nameKey: 'organization',
        isSortable: false,
        type: 'string',
        template: (rowItem: RoleCS) => rowItem.organization ? rowItem.organization?.name : (<UniLocalize translate={rowItem.name === ERoleTypeCS.LimitedAdmin ? '_dash' : '_allOrgs'} /> ),
        evalItalicized: (rowItem: RoleCS) => !rowItem.organization,
        size: 6
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        evalItalicized: (rowItem: RoleCS) => rowItem.name === ERoleTypeCS.LimitedAdmin && !rowItem.organization,
        size: 3
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        collapsed: true,
        size: 2
      });

    const actions = new Map();
    actions.set('goToResource', {
      nameKey: 'goToResource',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: this._goToOrgOrDealerResource,
      evalDisbled: (rowItem: RoleCS) => false,
      evalVisible: (rowItem: RoleCS) => true
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='adminRoleList-container'>
        <UniTable
          searchable={false}
          advancedFiltering={false}
          titleKey="adminRoles"
          createButtonTextKey="role"
          handleUpdate={this._updateTable}
          data={this.props.roleData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.loading} />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    roleData: state.adminRoles.data,
    modalOpen: state.adminRoles.modalOpen,
    roleListQueryParams: state.adminRoles.queryParams,
    loading: state.adminRoles.loading,
    appliedFilters: state.adminRoles.tableFilters,
    appliedSorts: state.adminRoles.tableSorts,
    paginationSummary: state.adminRoles.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal,
  getAdminRoles: attemptRetrieveAdminRoles,
  updateTableMeta: updateAdminRolesTableMeta,
  updateQueryParams: updateRoleListQueryParams,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminRolesList)
  ), { componentName: 'AdminRolesList' })
