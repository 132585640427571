import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  ActivityLogCS,
  UniInput,
  Editable,
  UniLocalize,
  IPaginatedParamsCS,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  guidV10n
} from '@unikey/unikey-commons/release/csupp';

import {
  ActivityLogList,
  navConfig, ENavPages,
  handleEntityIdChange,
  clearActivityLogs,
  attemptRetrieveActivityLogs,
  PartnerCustomizations, IPartnerCustomizations,
  updateActivityLogsQueryParams,
  updateActivityLogsTableMeta
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  isUniKeyActor: boolean,
  loading: boolean,
  entityId: Editable<string>,
  // activity log stuff
  activityLogs: ActivityLogCS[],
  activityLogListQueryParams: IPaginatedParamsCS,
  activityLogListLoading: IUniTable_Filter[],
  activityLogListAppliedFilters: IUniTable_Sort,
  activityLogListAppliedSort: boolean,
  activityLogListPaginationSummary: IUniTable_PaginationSummary,
  getActivityLogs(deviceId: string, activityLogsQueryParams: IPaginatedParamsCS): void,
  updateActivityLogsListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateActivityLogsListQueryParams?(params: IPaginatedParamsCS): void,
  updateEntityId(entityId: Editable<string>): void,
  clearActivityLogs(): void,
}

class ActivityLogListContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.clearActivityLogs();
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <section className='ActivityLogs-container'>
        <Row>
          <Col>
            <h3 className="page-title-non-table"><UniLocalize translate="searchActivityLogsByEntityId"/></h3>
          </Col>
        </Row>

        <Row>
          <Col md={16} lg={12} xxl={8}>
            <UniInput
              value={`${this.props.entityId.value}`}
              type="string"
              placeholderKey="desiredEntityId"
              labelKey="entityId"
              handleUpdate={(entityId: Editable<string>) => {
                this.props.updateEntityId(entityId);
                if (entityId.valid) {
                  this.props.getActivityLogs(entityId.value, this.props.activityLogListQueryParams);
                } else {
                  this.props.clearActivityLogs();
                }
              }}
              focusOnInitialRender={true}
              validations={new Map([guidV10n])} />
          </Col>
        </Row>

        {/* Display the activity logs */}
        <ActivityLogList 
          key={`${this.props.entityId.value}${this.props.activityLogs?.[0]?.start}${this.props.activityLogListLoading}`}
          match={this.props.match}
          history={this.props.history}
          logs={this.props.activityLogs}
          logListQueryParams={this.props.activityLogListQueryParams}
          logListLoading={this.props.activityLogListLoading}
          logListAppliedFilters={this.props.activityLogListAppliedFilters}
          logListAppliedSort={this.props.activityLogListAppliedSort}
          logListPaginationSummary={this.props.activityLogListPaginationSummary}
          getScopedLogs={this.props.getActivityLogs.bind(this, this.props.entityId.value)}
          updateLogListQueryParams={this.props.updateActivityLogsListQueryParams}
          updateLogListTableMeta={this.props.updateActivityLogsListTableMeta} />

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
    
    // activity log list stuff
    activityLogs: state.activityLogs.data.models,
    activityLogListLoading: state.activityLogs.loading,
    activityLogListQueryParams: state.activityLogs.queryParams,
    activityLogListAppliedFilters: state.activityLogs.tableFilters,
    activityLogListAppliedSort: state.activityLogs.tableSorts[0],
    activityLogListPaginationSummary: state.activityLogs.paginationSummary,
    
    entityId: state.activityLogs.entityId,
    entityIdVal: state.activityLogs.entityId.value
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getActivityLogs: attemptRetrieveActivityLogs,
  clearActivityLogs,
  updateEntityId: handleEntityIdChange,
  updateActivityLogsListQueryParams: updateActivityLogsQueryParams,
  updateActivityLogsListTableMeta: updateActivityLogsTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ActivityLogListContainer)
    ), { componentName: 'ActivityLogList' })
