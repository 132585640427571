import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { processSilentRenew } from 'redux-oidc';

import {
  SplashContainer,
} from '../internal';

interface IProps {
  match: any,
  history: any,
}

class OidcRenewContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    processSilentRenew();
  }

  render() {
    return (
      <SplashContainer
        match={this.props.match}
        history={this.props.history}
        externalRedirect={true} />
    )
  }
}

function mapStateToProps(state: any) {
  return {

  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OidcRenewContainer)
