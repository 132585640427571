import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { NavLink } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames';

import {
  UniFooter,
  UniLocalize,
  UniConditionalRender,
  UniImg,
  UniButton,
  UniThemeToggle
} from '@unikey/unikey-commons/release/csupp';

import {
  partnerSupportLink,
  privacyPolicyDocument,
  termsOfServiceDocument
} from '@alias-environment';

import {
  storeHistory,
  logoutKey,
  navConfig,
  ENavPages,
  toggleAvatarMenu,
  setDarkModeTheme,
  toggleFeedbackModal,
} from '../internal'

import poweredByUniKeyLogo from '../assets/powered-by-unikey-small-grey.png';

interface IProps extends WrappedComponentProps{
  history?: any,
  logo?: string,
  idsVisible: boolean,
  activePath: string,
  internalSupportPage?: boolean,
  toggleFeedbackModal(): void,
  changeDarkModeTheme(theme: string): void,
}

class FooterContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const isInsidePortal: boolean = /portal\//.test(this.props.activePath);

    const footerClass = classNames('footer-container', {
      'access-footer': !isInsidePortal
    });

    return (
      <section className={footerClass}>
        <UniFooter>
          <Container>
            <Row justify="center">
              {/* inside of portal layout */}
              <UniConditionalRender visible={isInsidePortal}>
                <Col md={24} lg={6} className="links">
                  <Row justify="center">
                    <Col sm={8} lg={24}>
                      <UniThemeToggle
                        className="footer-toggle"
                        onThemeChange={this.props.changeDarkModeTheme} />
                    </Col>

                    <Col sm={8} lg={24}>
                      <UniButton
                        className="footer-toggle"
                        icon="star"
                        textKey="feedback"
                        theme="inverted"
                        hideText={false}
                        handleClick={this.props.toggleFeedbackModal} />
                    </Col>

                    <Col sm={8} lg={24}>
                      <UniButton
                        className="footer-toggle"
                        icon="logout"
                        textKey="logout"
                        theme="inverted"
                        hideText={false}
                        handleClick={() => storeHistory?.push(logoutKey)} />
                    </Col>
                  </Row>
                </Col>

                <Col sm={8} lg={6} className="links">
                  <Col xs={24}>
                    <h4 className="link-heading"><UniLocalize translate="management" /></h4>
                  </Col>
                  {/* internal links */}
                  <NavLink to={navConfig.get(ENavPages.dealers)!.linkTo([])}><UniLocalize translate={ENavPages.dealers} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.admins)!.linkTo([])}><UniLocalize translate={ENavPages.admins} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.products)!.linkTo([])}><UniLocalize translate={ENavPages.products} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.creditsAndCoupons)!.linkTo([])}><UniLocalize translate={ENavPages.creditsAndCoupons} /></NavLink>
                </Col>

                <Col sm={8} lg={6} className="links">
                  <Col xs={24}>
                    <h4 className="link-heading"><UniLocalize translate="access" /></h4>
                  </Col>
                  {/* internal links */}
                  <NavLink to={navConfig.get(ENavPages.organizations)!.linkTo([])}><UniLocalize translate={ENavPages.organizations} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.credentials)!.linkTo([])}><UniLocalize translate={ENavPages.credentials} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.devices)!.linkTo([])}><UniLocalize translate={ENavPages.devices} /></NavLink>
                  <NavLink to={navConfig.get(ENavPages.readers)!.linkTo([])}><UniLocalize translate={ENavPages.readers} /></NavLink>
                </Col>

                {/*  about & legal links */}
                <Col sm={8} lg={6} className="links">
                    <Col xs={24}>
                      <h4 className="link-heading"><UniLocalize translate="legalCenter" /></h4>
                    </Col>
                    <a href={termsOfServiceDocument}><UniLocalize translate="termsOfUse" /></a>
                    <a href={privacyPolicyDocument}><UniLocalize translate="privacyPolicy" /></a>
                    <a href={partnerSupportLink}><UniLocalize translate="support" /></a>
                    <NavLink to={navConfig.get(ENavPages.version)!.linkTo()}><UniLocalize translate={ENavPages.version} /></NavLink>
                    <a href="http://unikey.com"><UniLocalize translate="about" /></a>
                </Col>

              </UniConditionalRender>

              {/* out of portal layout */}
              <UniConditionalRender visible={!isInsidePortal}>
                {/*  about & legal links */}
                <Col sm={24} className="links non-portal">
                  <a href={termsOfServiceDocument}><UniLocalize translate="termsOfUse" /></a>
                  <a href={privacyPolicyDocument}><UniLocalize translate="privacyPolicy" /></a>
                  <a href={partnerSupportLink}><UniLocalize translate="support" /></a>
                  <NavLink to={navConfig.get(ENavPages.version)!.linkTo([])}><UniLocalize translate={ENavPages.version} /></NavLink>
                  <a href="http://unikey.com"><UniLocalize translate="about" /></a>
                </Col>

                <Col sm={24}>
                  <UniThemeToggle
                    className="footer-toggle non-portal"
                    onThemeChange={this.props.changeDarkModeTheme} />
                </Col>
              </UniConditionalRender>
            </Row>

            <Row>
              <Col>
                <div className="copyright-notice">{`Copyright © 2016-${new Date().getFullYear()} UniKey Technologies, Inc., All rights reserved.`}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <a href="http://unikey.com">
                  <UniImg className="powered-by-unikey-logo" src={poweredByUniKeyLogo} textKey="logo" />
                </a>
              </Col>
            </Row>
          </Container>

        </UniFooter>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    activePath: state.router.location.pathname,
    idsVisible: state.portal.idsVisible,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleAvatarMenu,
  toggleFeedbackModal,
  changeDarkModeTheme: setDarkModeTheme,
}, dispatch);



export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer)