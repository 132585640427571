import {
  invitationActions

} from '../../internal'

const initialCredentialDetailsState = {
  loading: true,
  invitationData: {}
};

export const invitationDetails = (state = initialCredentialDetailsState, action: any) => {
  switch (action.type) {

    case invitationActions.GET_CREDENTIAL_INVITATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case invitationActions.GET_CREDENTIAL_INVITATION_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        invitationData: action.value,
      }
    case invitationActions.GET_CREDENTIAL_INVITATION_FAILURE:
      return {
        ...state,
        loading: action.pending,
        invitationData: {}
      }
    default:
      return state
  }
}
