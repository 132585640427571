import {  
  IMultiInputUpdate,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/identity'

import {
  identityApi
} from '../../internal'

export enum userActions {
  UPDATE_SET_PASSWORD_TOKEN_FORM = 'UPDATE_SET_PASSWORD_TOKEN_FORM',

  USER_TOKEN_ACTION_REQUEST = 'USER_TOKEN_ACTION_REQUEST',
  USER_TOKEN_ACTION_SUCCESS = 'USER_TOKEN_ACTION_SUCCESS',
  USER_TOKEN_ACTION_FAILURE = 'USER_TOKEN_ACTION_FAILURE',

  RESEND_VERIFY_EMAIL_REQUEST = 'RESEND_VERIFY_EMAIL_REQUEST',
  RESEND_VERIFY_EMAIL_SUCCESS = 'RESEND_VERIFY_EMAIL_SUCCESS',
  RESEND_VERIFY_EMAIL_FAILURE = 'RESEND_VERIFY_EMAIL_FAILURE',

  TOGGLE_CHANGE_PASSWORD_WORKFLOW = 'TOGGLE_CHANGE_PASSWORD_WORKFLOW',
  TOGGLE_ADD_PASSWORD_WORKFLOW = 'TOGGLE_ADD_PASSWORD_WORKFLOW',

  ADD_PASSWORD_EMAIL_REQUEST = 'ADD_PASSWORD_EMAIL_REQUEST',
  ADD_PASSWORD_EMAIL_SUCCESS = 'ADD_PASSWORD_EMAIL_SUCCESS',
  ADD_PASSWORD_EMAIL_FAILURE = 'ADD_PASSWORD_EMAIL_FAILURE'
}

export interface IConfirmationActionParams {
  token: string,
  newEmail?: string,
  newPass?: string,
  reason?: string
}

const changeEmailWithToken = new ApiReduxAction(identityApi, {
  request: { type: userActions.USER_TOKEN_ACTION_REQUEST },
  success: {
    type: userActions.USER_TOKEN_ACTION_SUCCESS,
    title: 'success',
    message: 'primaryEmailUpdated'
  },
  failure: {
    type: userActions.USER_TOKEN_ACTION_FAILURE,
    title: 'verifyEmailFail'
  },
}, (dux: IExposeRedux, { token }: IConfirmationActionParams) => {
  return identityApi.acct.applyEmailChangeWithToken.bind(identityApi.acct, { token });
});
export const attemptChangeEmailWithToken = changeEmailWithToken.go;


const verifyEmailWithToken = new ApiReduxAction(identityApi, {
  request: { type: userActions.USER_TOKEN_ACTION_REQUEST },
  success: {
    type: userActions.USER_TOKEN_ACTION_SUCCESS,
    title: 'success',
    message: 'emailVerified'
  },
  failure: {
    type: userActions.USER_TOKEN_ACTION_FAILURE,
    title: 'verifyEmailFail'
  },
}, (dux: IExposeRedux, { token }: IConfirmationActionParams) => {
  return identityApi.acct.applyEmailVerifyWithToken.bind(identityApi.acct, { token });
});
export const attemptVerifyEmailWithToken = verifyEmailWithToken.go;


const resendEmailVerification = new ApiReduxAction(identityApi, {
  request: { type: userActions.RESEND_VERIFY_EMAIL_REQUEST },
  success: {
    type: userActions.RESEND_VERIFY_EMAIL_SUCCESS,
    title: 'emailSent',
    message: 'checkEmailForVerification',
    duration: 16000
  },
  failure: {
    type: userActions.RESEND_VERIFY_EMAIL_FAILURE,
    title: 'failedToSendEmail'
  },
}, (dux: IExposeRedux, { userId, userUpdateData }) => {
  return identityApi.acct.resendEmailVerificationToken.bind(identityApi.acct);
});
export const attemptResendEmailVerification = resendEmailVerification.go;


const setPasswordWithToken = new ApiReduxAction(identityApi, {
  request: { type: userActions.USER_TOKEN_ACTION_REQUEST },
  success: {
    type: userActions.USER_TOKEN_ACTION_SUCCESS,
    title: 'success',
    message: 'newPasswordSet'
  },
  failure: {
    type: userActions.USER_TOKEN_ACTION_FAILURE,
    title: 'passwordChangeFailure'
  }
}, (dux: IExposeRedux, { token, newPass }: IConfirmationActionParams) => {
  return identityApi.acct.applyNewPasswordWithToken.bind(identityApi.acct, { token, newPass });
});
export const attemptSetPasswordWithToken = setPasswordWithToken.go;


export const updateSetPasswordTokenForm = (formChanges: IMultiInputUpdate) => {
  return {
    type: userActions.UPDATE_SET_PASSWORD_TOKEN_FORM,
    formChanges
  }
}