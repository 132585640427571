import React, { Component } from 'react'
import classNames from 'classnames'
import tinyColor from 'tinycolor2'
import { UniConditionalRender } from '@unikey/unikey-commons'

export const dateOrLoading = (timestamp: string | Date, isLoading: boolean = false): string => {
  if (isLoading || !timestamp) {
    return '';
  }

  let date;
  if ((timestamp as Date)?.toLocaleDateString) {
    date = timestamp;
  } else {
    date = new Date(timestamp);
  }
  return (date as Date)?.toLocaleDateString?.();
}


export const dateAndTimeOrLoading = (timestamp?: string | Date, isLoading: boolean = false): string => {
  if (isLoading || !timestamp) {
    return '';
  }
  let date;
  if ((timestamp as Date).toLocaleString) {
    date = timestamp;
  } else {
    date = new Date(timestamp);
  }
  return date.toLocaleString();
}

export const calcLoaderPercentageComplete = (outstanding: number, total: number) => {
  // (remaining / total) * 100
  // make sure that we're not dividing by zero
  return ((total - outstanding) / (total + 0.0001)) * 100;
}

const encounteredBrandCodes = new Map();
export const brandCodeDisplay = (brandCode?: string) => {
  const code = (brandCode ? brandCode : '___').toUpperCase();
  var display = encounteredBrandCodes.get(code);
  const bgColor = genBrandColor(code);
  const fontColor = tinyColor(bgColor).isDark() ? '#fff' : '#000';

  if (!display) { 
    display = (
      <UniConditionalRender visible={!!brandCode}>
        <div className={classNames('brand-code-display')} style={{background: bgColor.toHexString(), color: fontColor}}>{code}</div>
      </UniConditionalRender>
    );
    encounteredBrandCodes.set(code, display);
  }
  return display;
}

// generates a color based on the brand code's name
const genBrandColor = (brandCode: string) => {  
  return tinyColor({
    r: (brandCode.charCodeAt(2) * 9) % 120 + 80,
    g: (brandCode.charCodeAt(1) * 9) % 120 + 80,
    b: (brandCode.charCodeAt(0) * 9) % 120 + 80
  }).desaturate(10)
}
