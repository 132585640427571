import {
  UserCS,
  DealerInviteCS,
  Editable
} from '@unikey/unikey-commons/release/csupp'

import {
  adminActions,
  portalActions,
  inputInitializer,  
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialAdminsState = populateInitialReduxPaginatedListState(['email'], 'forward');

export const admins = (state = initialAdminsState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMINS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        // queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case adminActions.GET_ADMINS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialAdminsState.data
      }
    case adminActions.UPDATE_ADMINS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    case adminActions.UPDATE_ADMINS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case portalActions.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    default:
      return state
  }
}

const initialAdminDetailsState = {
  loading: true,
  saving: false,
  adminData: ({
    id: '',
    status: '',
    created: '',
    brand: '',
    email: '',
    ...inputInitializer('', ['firstName', 'lastName', 'phoneNumber', 'emailStatus']),
  } as any),
};

const setEditAdminFieldsBasedOnAdminResult = (adminResult: UserCS) => {
  return {
    id: adminResult.id,
    status: adminResult.status,
    created: adminResult.created,
    brand: adminResult.brand,
    email: adminResult.email,
    emailStatus: new Editable({ value: adminResult.emailStatus }),
    firstName: new Editable({ value: adminResult.firstName }),
    lastName:new Editable({ value: adminResult.lastName }),
    phoneNumber: new Editable({ value: adminResult.phoneNumber }),
  }
}

export const adminDetails = (state = initialAdminDetailsState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        adminData: setEditAdminFieldsBasedOnAdminResult(action.value)
      }
    case adminActions.GET_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        adminData: setEditAdminFieldsBasedOnAdminResult({} as UserCS)
      }
    case adminActions.HANDLE_ADMIN_FORM_DATA_CHANGE:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          firstName: action.firstName ? action.firstName : state.adminData.firstName,
          lastName: action.lastName ? action.lastName : state.adminData.lastName,
          phoneNumber: action.phoneNumber ? action.phoneNumber : state.adminData.phoneNumber,
          emailStatus: action.emailStatus ? action.emailStatus : state.adminData.emailStatus
        }
      }
    case adminActions.UPDATE_ADMIN_REQUEST:
      return {
        ...state,
        saving: true
      }
    case adminActions.UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        saving: action.pending,
        adminData: setEditAdminFieldsBasedOnAdminResult(action.value)
      }
    case adminActions.UPDATE_ADMIN_FAILURE:
      return {
        ...state,
        saving: action.pending,
      }
    default:
      return state
  }
}

const initialAdminRolesState = populateInitialReduxPaginatedListState(['created'], 'reverse');
export const adminRoles = (state = initialAdminRolesState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMIN_ROLES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMIN_ROLES_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value
      }
    case adminActions.GET_ADMIN_ROLES_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialAdminRolesState.data
      }
    case adminActions.UPDATE_ADMIN_ROLES_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case adminActions.UPDATE_ADMIN_ROLES_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}


const initialAdminInviteDetailsState = {
  loading: true,
  upated: false,
  saving: false,
  inviteData: {},
};

export const adminInviteDetails = (state = initialAdminInviteDetailsState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMIN_INVITE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMIN_INVITE_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        inviteData: action.invite
      }
    case adminActions.GET_ADMIN_INVITE_FAILURE:
      return {
        ...state,
        loading: action.pending,
        inviteData: {} as DealerInviteCS
      }
    case adminActions.UPDATE_ADMIN_INVITE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case adminActions.UPDATE_ADMIN_INVITE_SUCCESS:
      return {
        ...state,
        saving: action.pending,
        inviteData: action.value,
        updated: true,
      }
    case adminActions.UPDATE_ADMIN_INVITE_FAILURE:
      return {
        ...state,
        saving: action.pending,
        updated: false
      }
    default:
      return state
  }
}

const initialAdminInvitesState = populateInitialReduxPaginatedListState(['status'], 'forward');
export const adminInvites = (state = initialAdminInvitesState, action: any) => {
  switch (action.type) {
    case adminActions.GET_ADMIN_INVITES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case adminActions.GET_ADMIN_INVITES_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value
      }
    case adminActions.GET_ADMIN_INVITES_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialAdminInvitesState.data
      }
    case adminActions.UPDATE_ADMIN_INVITES_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        // queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case adminActions.UPDATE_ADMIN_INVITES_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}
