import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { loadUser, OidcProvider } from 'redux-oidc';

import {
  AppContainer,
  userManager,
  configureStore
} from '../internal'

const store = configureStore();
loadUser(store, userManager);

export default class Root extends Component {
  render() {
    return (
      <>
          <Provider store={store}>
            <>
              <OidcProvider userManager={userManager} store={store}>
                <AppContainer />
              </OidcProvider>
            </>
          </Provider>
      </>
    )
  }
}