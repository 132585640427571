import {
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IMultiInputUpdate,
  ITrackedRequest,
  Editable,
} from '@unikey/unikey-commons/release/csupp'

import {
  initialPageSize
} from '../internal'

export const inputInitializer = (initialValue: any, fieldNames: string[]): IMultiInputUpdate => {
  return fieldNames.reduce((form: IMultiInputUpdate, field: string) => {
    form[field] = new Editable({ value: initialValue, valid: false });
    return form;
  }, {});
}

export const setupJobTrackingFor = (jobName?: string): Partial<ITrackedRequest> => {
  const tracked: Partial<ITrackedRequest> = {};
  if (jobName !== undefined) {
    tracked.job = jobName;
  }
  return tracked;
}

// returns a method to stop the interval call
export const checkJobStatusOnInterval = (dispatchAction: () => void, msInterval: number = 200): (() => void) => {
  const intervalRef = setInterval(() => {
    dispatchAction();
  }, msInterval);

  return clearInterval.bind(null, intervalRef);
};


export interface IReduxSingleItemState<T> {
  item: T,
  loading?: boolean,
  saving?: boolean,
  updater?: number
}

export interface IReduxListItemState<T extends IHasId> {
  mapList: Map<string, T>,
  updater: number,
  loading?: boolean,
  gridViewStyle?: boolean,
}

export interface IHasId {
  id: string
}
export interface IListValueActionTrigger<T> {
  value: T[],
  loading?: boolean
}
export function mapifyListForReducer<T extends IHasId>(value: T[]): Map<string, T> {
  return value?.reduce((totalMap: Map<string, T>, item: T) => {
    totalMap.set(item.id, item);
    return totalMap;
  }, new Map<string, T>());
}


export interface ISingleValueActionTrigger<T> {
  value?: T,
  loading?: boolean,
  saving?: boolean
}

export const getPaginatedTableMetaChanges = (latest: IUniTable_UpdatePaginationSummary, prevSummary: IUniTable_PaginationSummary) => {
  const updates: any = {};
  if (latest?.filters) {
    updates.tableFilters = latest?.filters;
  }
  if (latest?.sorts) {
    updates.tableSorts = latest?.sorts;
  }
  if (Number.isInteger(Number(latest?.currPage)) || Number.isInteger(Number(latest?.pageSize)) || Number.isInteger(Number(latest?.totalCount)) || Number.isInteger(Number(latest?.pageCount))) {
    const paginationToUpdate: any = {};
    if (Number.isInteger(Number(latest?.currPage))) {
      paginationToUpdate.currPage = latest?.currPage;
    }
    if (Number.isInteger(Number(latest?.pageSize))) {
      paginationToUpdate.pageSize = latest?.pageSize;
    }
    if (Number.isInteger(Number(latest?.totalCount))) {
      paginationToUpdate.totalCount = latest?.totalCount;
      paginationToUpdate.pageCount = Math.ceil(latest.totalCount! / (latest?.pageSize || prevSummary.pageSize));
    }
    updates.paginationSummary = Object.assign({}, prevSummary, paginationToUpdate);
  }
  return updates;
}

export type TSortDirection = 'reverse' | 'forward';
export const populateInitialReduxPaginatedListState = (sortColumns: string[], sortDir: TSortDirection = 'reverse') => {
  return {
    loading: false,
    data: {
      models: []
    },
    activeColumn: sortColumns[0],
    modalOpen: false,
    queryParams: {
      limit: initialPageSize,
      sorts: sortColumns.map(sortCol => {
        return {
          fieldName: sortCol,
          reverse: sortDir === 'reverse'
        };
      })
    },
    tableFilters: [],
    tableSorts: [{
      field: sortColumns[0],
      direction: sortDir === 'reverse' ? 'asc' : 'desc'
    }],
    paginationSummary: {
      pageSize: initialPageSize,
      currPage: 1, // default page,
      pageCount: initialPageSize, 
      totalCount: 0,
    }
  }
}