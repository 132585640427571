import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  Editable,
  UniInput,
  UniSteps,
  UniWorkflow,
  UniLocalize,
  UniFileUpload,
  UniOverlapGroup,
  UniOverlapButton,
  UniConditionalRender,
  ProductCS,
  IFirmwareImageCS,
  IMultiInputUpdate,
  notBlankV10n, majorMinorRevisionV10n,
} from '@unikey/unikey-commons/release/csupp';

import {
  toggleModal,
  handleFirmwareFormDataChange,
  updateNewFirmwareWorkflowStep,
  attemptRetrieveProductFirmware,
  attemptCreateNewFirmwareForProduct,
  clearFirmwareFormData,
  toggleFirmwareCreateModal,
  PartnerCustomizations, IPartnerCustomizations,
  IGetProductFirmwareParams
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  modalOpen: boolean,
  newFirmwareData: any, // TYPE ME
  currentStepIndex: number,
  loading?: boolean,
  product: ProductCS,
  toggleFirmwareCreateModal(): void,
  clearForm(): void,
  getProductFirmware(params: IGetProductFirmwareParams): Promise<void>,
  updateNewFirmwareData(firmware?: IMultiInputUpdate, images?: any[], imageTemplates?: any[]): void,
  changeWorkflowStep(stepTo: number): void,
  attemptCreateNewFirmwareForProduct(productId: string): Promise<any>
}

export interface IFirmwareImageBulider extends IFirmwareImageCS {
  image?: any
}

class FirmwareCreateContainer extends Component<IProps> {
  steps: any;

  constructor(props: IProps) {
    super(props);
    this.steps = [
      { nameKey: 'information' },
      { nameKey: 'images' },
      { nameKey: 'review' }
    ];
    // set the images array to the length of the number of images we expect
    props.updateNewFirmwareData(undefined, undefined, props.product.images);
    props.updateNewFirmwareData(undefined, new Array(props.product.images.length));
  }

  handleFileChange(index: number, uploadedImage: Editable<File>) {
    const images = this.props.newFirmwareData.images;
    images.splice(index, 1, uploadedImage.value);
    this._updateImages(images);
  }

  _saveFirmwareAndReloadList = () => {
   return this.props.attemptCreateNewFirmwareForProduct(this.props.match.params.productId).then(() => {
     this.props.toggleFirmwareCreateModal();
      this.props.clearForm();
      return this.props.getProductFirmware({ productId: this.props.match.params.productId, onlyAvailable: true });
    });
  }

  _updateVersion = (version: Editable<string>) => {
    this.props.updateNewFirmwareData({ version });
  }

  _updateDescription = (description: Editable<string>) => {
    this.props.updateNewFirmwareData({ description });
  }

  _updateReleaseNotes = (releaseNotes: Editable<string>) => {
    this.props.updateNewFirmwareData({ releaseNotes });
  }

  _updateImages = (images: any[]) => {
    this.props.updateNewFirmwareData(undefined, images);
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }

    const imageUploadFields = this.props.newFirmwareData.imageTemplates
    .sort((a: IFirmwareImageCS, b: IFirmwareImageCS) => a.order - b.order)
    .map((image: IFirmwareImageCS, index: number) => {
      const extensionMatch = image.description.match(/\(\.([a-z]{2,})\)/);
      const allowedExtensions = extensionMatch?.[1];
      return (
        <section className="image-container" key={'form-images-' + index}>
          <h4><UniLocalize translate="imageName" />: <div className="image-name">{image.description} {image.is_required ? '*' : ''}</div></h4>
          <UniFileUpload
            placeholderKey={image.is_required ? 'requiredImage' : 'optionalImage'}
            handleUpdate={this.handleFileChange.bind(this, index)} 
            allowedExtensions={allowedExtensions ? [allowedExtensions] : undefined} />
        </section>
      );
    });
    const imageFiles = this.props.newFirmwareData.images;

    // first step - must have version and description
    const firstStepFinished = this.props.newFirmwareData.version.valid && this.props.newFirmwareData.description.valid;

    // second step - must be
    const secondStepFinished = this.props.newFirmwareData.imageTemplates.some((t: any, index: number) => {
      const imageForTemplate = imageFiles[index];
      return t.is_required ? !!imageForTemplate : true;
    });
    const canSave = firstStepFinished || secondStepFinished;

    return (
      <section className='firmwareCreate-container'>
        <UniWorkflow
          inModal
          titleKey="addNewFirmwareVersion"
          titleIcon="cloudUpload"
          size="wider"
          handleClose={this.props.toggleFirmwareCreateModal}>
          <UniSteps
            steps={this.steps}
            activeStepIndex={this.props.currentStepIndex}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          {/* First Step - Info */}
          <UniConditionalRender visible={this.props.currentStepIndex === 0}>
            <h3><UniLocalize translate="enterFirmwareVersionInfo" /></h3>

            <UniInput
              editable={this.props.newFirmwareData.version}
              placeholderKey="_exampleVersionNumber"
              labelKey="versionNumber"
              handleUpdate={this._updateVersion}
              focusOnInitialRender={true}
              validations={new Map([majorMinorRevisionV10n])} />
            <UniInput
              editable={this.props.newFirmwareData.description}
              placeholderKey="description"
              labelKey="description"
              handleUpdate={this._updateDescription}
              validations={new Map([notBlankV10n])}
              required={true} />
            <UniInput
              editable={this.props.newFirmwareData.releaseNotes}
              labelKey="releaseNotes"
              placeholderKey="_emptyString"
              handleUpdate={this._updateReleaseNotes}
              textarea={true} />

            {/* step actions */}
            <UniOverlapGroup
              foldEarly={true}>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={!firstStepFinished}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleFirmwareCreateModal}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>

          </UniConditionalRender>

          {/* Second Step - Upload Images */}
          <UniConditionalRender visible={this.props.currentStepIndex === 1}>
            <span className="step-2-title">
              <h3><UniLocalize translate="uploadFirmwareImages" /></h3>
              <h5><UniLocalize translate="starDenotesRequiredImages" /></h5>
            </span>
            {/* Depending on the images this product needs, this part of the form is different */}
            {imageUploadFields}

            {/* step actions */}
            <UniOverlapGroup
              foldEarly={true}>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={!secondStepFinished}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={false}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleFirmwareCreateModal}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>

          {/* Third Step - Review */}
          <UniConditionalRender visible={this.props.currentStepIndex === 2}>
            <h3><UniLocalize translate="review" /></h3>
            <Container>
              <Row nogutter>
                <Col md={8}><UniLocalize translate="version" /></Col>
                <Col>{this.props.newFirmwareData.version.value}</Col>
              </Row>

              <Row nogutter>
                <Col md={8}><UniLocalize translate="description" /></Col>
                <Col>{this.props.newFirmwareData.description.value}</Col>
              </Row>

              <Row nogutter>
                <Col md={8}><UniLocalize translate="releaseNotes" /></Col>
                <Col>{this.props.newFirmwareData.releaseNotes.value}</Col>
              </Row>

              <Row nogutter>
                <Col>
                  <h4><UniLocalize translate="images" /></h4>
                </Col>
              </Row>

              {this.props.newFirmwareData.imageTemplates.map((imageTemplate: any, index: number) => (
                <Row key={'review-images-' + index} nogutter>
                  <Col md={8}>{imageTemplate.description}</Col>
                  <Col>
                    <UniConditionalRender visible={this.props.newFirmwareData.images[index]}>
                      {this.props.newFirmwareData.images[index] ? this.props.newFirmwareData.images[index].name : ''}
                    </UniConditionalRender>
                    <UniConditionalRender visible={!this.props.newFirmwareData.images[index]}>
                      <UniLocalize translate="skipped" />
                    </UniConditionalRender>
                  </Col>
                </Row>
              ))}

            </Container>

            {/* step actions */}
            <UniOverlapGroup
              foldEarly={true}>
              <UniOverlapButton
                handleClick={this._saveFirmwareAndReloadList}
                textKey="save"
                icon="save"
                showLoader={this.props.loading}
                disabled={!canSave}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={false}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={this.props.toggleFirmwareCreateModal}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>
        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    modalOpen: state.portal.modalOpen,
    product: state.productDetails.data,
    currentStepIndex: state.firmwareEditor.workflowStepIndex,
    newFirmwareData: state.firmwareEditor.formData,
    loading: state.firmwareEditor.loading,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  // toggleModal,
  toggleFirmwareCreateModal,
  attemptCreateNewFirmwareForProduct,
  clearForm: clearFirmwareFormData, 
  changeWorkflowStep: updateNewFirmwareWorkflowStep,
  updateNewFirmwareData: handleFirmwareFormDataChange,
  getProductFirmware: attemptRetrieveProductFirmware,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(FirmwareCreateContainer)
    ), { componentName: 'FirmwareCreate' })
