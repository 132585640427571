import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  darkThemeName,
  UniWorkflow,
  UniInput,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniImg
} from '@unikey/unikey-commons/release/csupp';

import {
  FooterContainer,
  supportApi,
  userManager,
  oidcLoginRequest,
  PartnerCustomizations, IPartnerCustomizations,
  oidcRegisterUri, partnerKey,
} from '../internal'

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  loading: boolean,
  darkModeTheme: string,
  attemptLogin(): void,
}

class LoginContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    const queryParams = this.props.history.location.search;
    if (queryParams?.indexOf?.('continue=') >= 0) {
      // store the continue in the cache since the oidc
      // authentication will take us away from the react app
      supportApi.cacheImpl.set('continueTo', queryParams.match(/continue=(.*)$/)[1]);
    }
  }

  _handleLogin = () => {
    oidcLoginRequest();
    return userManager.signinRedirect();
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    return (
      <section className="access-container">
        <section className="login-container">
          <Container>
            <Row>
              <Col>
                <UniImg className={imgClassName} textKey="partner logo" src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
                <UniWorkflow
                  titleKey="login"
                  titleIcon="fingerprint" >

                  <p><strong><UniLocalize translate="_welcomeMessage" /></strong></p>
                  <p><UniLocalize translate="_loginInstructions" /></p>

                  <UniOverlapGroup size="sm">
                    <UniOverlapButton
                      icon="forward"
                      handleClick={this._handleLogin}
                      textKey="login"
                      tooltipPosition="right" />
                  </UniOverlapGroup>
                </UniWorkflow>
              </Col>
            </Row>
          </Container>
        </section>
        <FooterContainer />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    loading: state.authenticatedUser.loading,
    darkModeTheme: state.portal.darkModeTheme,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  attemptLogin: oidcLoginRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginContainer))
