import {
  supportApi,
  credentialActions,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialCredentialState = populateInitialReduxPaginatedListState(['email'], 'forward');
export const credentials = (state = initialCredentialState, action: any) => {
  switch (action.type) {
    case credentialActions.GET_CREDENTIALS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case credentialActions.GET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case credentialActions.GET_CREDENTIALS_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialCredentialState.data
      }
    case credentialActions.UPDATE_CREDENTIAL_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case credentialActions.UPDATE_CREDENTIAL_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialCredentialDetailsState = {
  loading: true,
  credentialData: {}
};

export const credentialDetails = (state = initialCredentialDetailsState, action: any) => {
  switch (action.type) {

    case credentialActions.GET_SINGLE_CREDENTIAL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case credentialActions.GET_SINGLE_CREDENTIAL_SUCCESS:
      const decodedCredential = action.value?.credential?.decode?.()
      return {
        ...state,
        loading: action.pending,
        credentialData: {
          ...action.value,
          credential: decodedCredential
        }
      }
    case credentialActions.GET_SINGLE_CREDENTIAL_FAILURE:
      return {
        ...state,
        loading: action.pending,
        credentialData: {}
      }
    case credentialActions.SET_ACTIVE_CREDENTIAL:
      return {
        ...state,
        credentialData: action.credential
      }
    default:
      return state
  }
}
