
import {
  DealerCS
} from '@unikey/unikey-commons/release/csupp';

import {
  portalActions,
  dealerActions,
  setPageSizeInCache,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialDealerState = populateInitialReduxPaginatedListState(['name'], 'forward');

export const dealers = (state = initialDealerState, action: any) => {
  switch (action.type) {
    case dealerActions.GET_DEALERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALERS_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case dealerActions.GET_DEALERS_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialDealerState.data
      }
    case portalActions.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case dealerActions.UPDATE_DEALER_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case dealerActions.UPDATE_DEALER_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialDealerDetailsState = {
  loading: true,
  saving: false,
  dealerData: {} as DealerCS,
  dealerReaders: {}
};

export const dealerDetails = (state = initialDealerDetailsState, action: any) => {
  switch (action.type) {
    case dealerActions.SET_ACTIVE_DEALER:
      return {
        ...state,
        dealerData: action.dealer
      }
    case dealerActions.GET_SINGLE_DEALER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_SINGLE_DEALER_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    case dealerActions.GET_SINGLE_DEALER_SUCCESS:

      return {
        ...state,
        loading: action.loading,
        dealerData: action.value,
      }
    case dealerActions.HANDLE_DEALER_CHANGE: 
      return {
        ...state,
        dealerData: Object.assign({}, state.dealerData, {
          name: action.name ? action.name : state.dealerData.name
        })
      }
    case dealerActions.UPDATE_DEALER_REQUEST:
      return {
        ...state,
        saving: true,
      }
    case dealerActions.UPDATE_DEALER_SUCCESS:
      return {
        ...state,
        saving: false,
      }
    case dealerActions.UPDATE_DEALER_FAILURE:
      return {
        ...state,
        saving: false,
      }
    case dealerActions.GET_DEALER_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    case dealerActions.GET_DEALER_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        address: action.value
      }
    default:
      return state
  }
}

const initialDealerOrgsState = populateInitialReduxPaginatedListState(['name'], 'forward');

export const dealerOrgs = (state = initialDealerOrgsState, action: any) => {
  switch (action.type) {
    case dealerActions.GET_DEALER_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value
      }
    case dealerActions.GET_DEALER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialDealerOrgsState.data
      }
    case dealerActions.UPDATE_DEALER_ORGANIZATIONS_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case dealerActions.UPDATE_DEALER_ORGANIZATIONS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialDealerAdminsState = populateInitialReduxPaginatedListState(['status'], 'forward');

export const dealerAdmins = (state = initialDealerAdminsState, action: any) => {
  switch (action.type) {
    case dealerActions.GET_DEALER_ADMINS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALER_ADMINS_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value
      }
    case dealerActions.GET_DEALER_ADMINS_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialDealerAdminsState.data
      }
    case dealerActions.UPDATE_DEALER_ADMINS_QUERY_PARAMS:
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case dealerActions.UPDATE_DEALER_ADMINS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialDealerInvitesState = populateInitialReduxPaginatedListState(['status'], 'forward');

export const dealerInvites = (state = initialDealerInvitesState, action: any) => {
  switch (action.type) {
    case dealerActions.GET_DEALER_INVITES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case dealerActions.GET_DEALER_INVITES_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value
      }
    case dealerActions.GET_DEALER_INVITES_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialDealerInvitesState.data
      }
    case dealerActions.UPDATE_DEALER_INVITES_QUERY_PARAMS:
      setPageSizeInCache(action.queryParams.limit);
      return {
        ...state,
        loading: true,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case dealerActions.UPDATE_DEALER_INVITES_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}
