import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  decodeJwt,
  EMessageType,
  IUniToast_Alert,
  AccountID,
} from '@unikey/unikey-commons/release/identity';

import {
  supportApi,
  logoutKey,
  addAlert,
  attemptVerifyEmailWithToken,
  attemptChangeEmailWithToken,
  IConfirmationActionParams,
  SplashContainer,
  PartnerCustomizations, IPartnerCustomizations,
  environment, partnerKey,
  navConfig, ENavPages,
  checkJWTExpired,
  redirectToLogin,
  appInsights,
  requestRefreshToken,
  portalRedirect,
} from '../internal';

enum EActionTokenTypes {
  Unknown,
  EmailConfirmation = 'AccountVerification',
  PasswordCreate = 'PasswordCreate',
  PasswordReset = 'PasswordReset',
  PasswordChange = 'PasswordChange',
  ChangeEmail = 'EmailChange'
}

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  match: any,
  accessTokenExists?: boolean,
  addAlert(config: IUniToast_Alert): void,
  getAuthInfo(): Promise<AccountID>,
  redirectToLoginWithContinue(): void,
  attemptChangeEmailWithToken(params: IConfirmationActionParams): Promise<void>,
  attemptVerifyEmailWithToken(actionData: IConfirmationActionParams): Promise<void>
}

class ActionTokenContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    // here also for the refresh case.
    this._checkToken();
  }

  _checkToken() {
    const token = this.props.match?.params?.actionToken;
    if (token) {
      const decoded: any = decodeJwt(token);
      // if decode returned null, then the token is not a valid jwt
      if (decoded != null) {
        if (!checkJWTExpired(decoded, this.props.addAlert)) {
          // Depending on the token type, we'll need to handle it differently 
          // and make the appropriate calls depending on the desired token-action
          switch (decoded.pur) {
            case EActionTokenTypes.EmailConfirmation:
              this.props.attemptVerifyEmailWithToken({ token }).then(() => {
                setTimeout(() => {
                  requestRefreshToken().then(() => {
                    if (decoded.sub !== supportApi.getAuthUserId()) {
                      portalRedirect(logoutKey);
                    } else {
                      portalRedirect(navConfig.get(ENavPages.landing)!.linkTo([]));
                    }
                  })
                }, 3000);

              });
              break;
            case EActionTokenTypes.ChangeEmail:
              this.props.attemptChangeEmailWithToken({ token }).then(() => {
                // redirect to logout on success after 3 seconds
                setTimeout(() => {
                  portalRedirect(logoutKey);
                }, 3000);
              });
              
              break;
            case EActionTokenTypes.PasswordCreate:
            case EActionTokenTypes.PasswordChange:
            case EActionTokenTypes.PasswordReset:
              portalRedirect(navConfig.get(ENavPages.setPassword)!.linkTo([token]));
              break;
            default:
              this.props.addAlert({
                id: Date.now(),
                type: EMessageType.warn,
                titleKey: 'unsupportedAction',
                messageKeys: [decoded.pur]
              })

              appInsights.trackEvent({ name: 'unsupported_jwt' }, { data: token });
              break;
          }
        }
      } 
    } else {
        appInsights.trackEvent({ name: 'could_not_decode' }, { data: token });
        this.props.history.push(logoutKey);
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
        <section className='access-container'>
          <SplashContainer 
            match={this.props.match}
            history={this.props.history}
            externalRedirect={true} />
        </section>
    )
  }
}

function mapStateToProps(state: any) {
  // current user here may be either UserT or the parsed jwt vals
  const authUser = state.authenticatedUser?.currentUser;
  return {
    accessTokenExists: !!authUser,
    confirmationLoading: state.actionTokens.loading,
    confirmationData: state.actionTokens.data,
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  addAlert,
  redirectToLoginWithContinue: redirectToLogin,
  attemptVerifyEmailWithToken,
  attemptChangeEmailWithToken
}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(ActionTokenContainer)
), { componentName: 'ActionToken' })
