import { Dispatch } from 'redux'

import {
  DealerCS,
  IPaginatedParamsCS,
  IDealerAddressCS,
  EMessageType,
  IMultiInputUpdate,
  IUniTable_UpdatePaginationSummary,
  ApiReduxAction,
  IExposeRedux
} from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  addAlert,
  redirectToLogin,
  setPageSizeInCache,
  setupJobTrackingFor
} from '../../internal'

export enum dealerActions {

  GET_DEALERS_REQUEST = 'GET_DEALERS_REQUEST',
  GET_DEALERS_SUCCESS = 'GET_DEALERS_SUCCESS',
  GET_DEALERS_FAILURE = 'GET_DEALERS_FAIL',

  SET_ACTIVE_DEALER = 'SET_ACTIVE_DEALER',

  UPDATE_DEALER_QUERY_PARAMS = 'UPDATE_DEALER_QUERY_PARAMS',
  UPDATE_DEALER_TABLE_META = 'UPDATE_DEALER_TABLE_META',

  HANDLE_DEALER_CHANGE = 'HANDLE_DEALER_CHANGE',

  GET_SINGLE_DEALER_REQUEST = 'GET_SINGLE_DEALER_REQUEST',
  GET_SINGLE_DEALER_SUCCESS = 'GET_SINGLE_DEALER_SUCCESS',
  GET_SINGLE_DEALER_FAILURE = 'GET_SINGLE_DEALER_FAILURE',

  UPDATE_DEALER_REQUEST = 'UPDATE_DEALER_REQUEST',
  UPDATE_DEALER_SUCCESS = 'UPDATE_DEALER_SUCCESS',
  UPDATE_DEALER_FAILURE = 'UPDATE_DEALER_FAILURE',

  GET_DEALER_ADDRESS_REQUEST = 'GET_DEALER_ADDRESS_REQUEST',
  GET_DEALER_ADDRESS_SUCCESS = 'GET_DEALER_ADDRESS_SUCCESS',
  GET_DEALER_ADDRESS_FAILURE = 'GET_DEALER_ADDRESS_FAILURE',

  GET_DEALER_ORGANIZATIONS_REQUEST = 'GET_DEALER_ORGANIZATIONS_REQUEST',
  GET_DEALER_ORGANIZATIONS_SUCCESS = 'GET_DEALER_ORGANIZATIONS_SUCCESS',
  GET_DEALER_ORGANIZATIONS_FAILURE = 'GET_DEALER_ORGANIZATIONS_FAILURE',

  UPDATE_DEALER_ORGANIZATIONS_QUERY_PARAMS = 'UPDATE_DEALER_ORGANIZATIONS_QUERY_PARAMS',
  UPDATE_DEALER_ORGANIZATIONS_TABLE_META = 'UPDATE_DEALER_ORGANIZATIONS_TABLE_META',
  
  GET_DEALER_ADMINS_REQUEST = 'GET_DEALER_ADMINS_REQUEST',
  GET_DEALER_ADMINS_SUCCESS = 'GET_DEALER_ADMINS_SUCCESS',
  GET_DEALER_ADMINS_FAILURE = 'GET_DEALER_ADMINS_FAILURE',
  UPDATE_DEALER_ADMINS_QUERY_PARAMS = 'UPDATE_DEALER_ADMINS_QUERY_PARAMS',
  UPDATE_DEALER_ADMINS_TABLE_META = 'UPDATE_DEALER_ADMINS_TABLE_META',

  GET_DEALER_INVITES_REQUEST = 'GET_DEALER_INVITES_REQUEST',
  GET_DEALER_INVITES_SUCCESS = 'GET_DEALER_INVITES_SUCCESS',
  GET_DEALER_INVITES_FAILURE = 'GET_DEALER_INVITES_FAILURE',
  UPDATE_DEALER_INVITES_QUERY_PARAMS = 'UPDATE_DEALER_INVITES_QUERY_PARAMS',
  UPDATE_DEALER_INVITES_TABLE_META = 'UPDATE_DEALER_INVITES_TABLE_META',
}

// DEALERS
const getDealerListAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.GET_DEALERS_REQUEST },
  success: { type: dealerActions.GET_DEALERS_SUCCESS },
  failure: {
    type: dealerActions.GET_DEALERS_REQUEST,
    title: 'getDealersFail',
  },
  tableMetaUpdate: {
    type: dealerActions.UPDATE_DEALER_TABLE_META
  }
}, (dux: IExposeRedux) => {
  const params = dux.getState().dealers.queryParams;
  return supportApi.deal.getAllDealers.bind(supportApi.deal, params);
});
export const attemptRetrieveDealers = getDealerListAction.go;

export function updateDealerListQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: dealerActions.UPDATE_DEALER_QUERY_PARAMS,
    queryParams
  }
}
export function updateDealerTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: dealerActions.UPDATE_DEALER_TABLE_META,
    ...meta
  }
}

// SINGLE DEALER
const getSingleDealerAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.GET_SINGLE_DEALER_REQUEST },
  success: { type: dealerActions.GET_SINGLE_DEALER_SUCCESS },
  failure: {
    type: dealerActions.GET_SINGLE_DEALER_FAILURE,
    title: 'getSingleDealerFail',
  }
}, (dux: IExposeRedux, dealerId: string) => {
  return supportApi.deal.getDealer.bind(supportApi.deal, dealerId);
});
export const attemptRetrieveDealerById = getSingleDealerAction.go;

export function handleDealerChange(changes: IMultiInputUpdate) {
  return { 
    type: dealerActions.HANDLE_DEALER_CHANGE,
    ...changes
  }
}

export interface IUpdateDealerParams {
  dealerId: string,
  newName: string
}
const updateSingleDealerAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.UPDATE_DEALER_REQUEST },
  success: { type: dealerActions.UPDATE_DEALER_SUCCESS },
  failure: {
    type: dealerActions.UPDATE_DEALER_FAILURE,
    title: 'updateDealerFail',
  }
}, (dux: IExposeRedux, { dealerId, newName }: IUpdateDealerParams) => {
  return supportApi.deal.updateDealer.bind(supportApi.deal, dealerId, newName);
});
export const attemptUpdateDealer = updateSingleDealerAction.go;

// DEALER ADDRESS
const getSingleDealerAddressAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.GET_DEALER_ADDRESS_REQUEST },
  success: { type: dealerActions.GET_DEALER_ADDRESS_SUCCESS },
  failure: {
    type: dealerActions.GET_DEALER_ADDRESS_FAILURE,
    title: 'getDealerAddressFail',
  }
}, (dux: IExposeRedux, dealerId) => {
  return supportApi.deal.getDealerAddress.bind(supportApi.deal, dealerId);
});
export const attemptRetrieveDealerAddress = getSingleDealerAddressAction.go;

// DEALER ORGANIZATIONS
const getDealerOrgListAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.GET_DEALER_ORGANIZATIONS_REQUEST },
  success: { type: dealerActions.GET_DEALER_ORGANIZATIONS_SUCCESS },
  failure: {
    type: dealerActions.GET_DEALER_ORGANIZATIONS_FAILURE,
    title: 'getDealerOrganizationsFail',
  },
  tableMetaUpdate: {
    type: dealerActions.UPDATE_DEALER_ORGANIZATIONS_TABLE_META
  }
}, (dux: IExposeRedux, dealerId: string) => {
  const params = dux.getState().dealerOrgs.queryParams;
  return supportApi.deal.getDealerOrgs.bind(supportApi.deal, dealerId, params);
});
export const attemptRetrieveDealerOrganizations = getDealerOrgListAction.go;

export function updateDealerOrganizationsQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: dealerActions.UPDATE_DEALER_ORGANIZATIONS_QUERY_PARAMS,
    queryParams
  }
}

export function updateDealerOrganizationsTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: dealerActions.UPDATE_DEALER_ORGANIZATIONS_TABLE_META,
    ...meta
  }
}

// Dealer Admins
const getDealerAdminListAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.GET_DEALER_ADMINS_REQUEST },
  success: { type: dealerActions.GET_DEALER_ADMINS_SUCCESS },
  failure: {
    type: dealerActions.GET_DEALER_ADMINS_FAILURE,
    title: 'getDealerAdminsFail',
    // TODO: prevent alerting since there is an issue with this API call
    // retrning 500s when it should return an empty list instead
    // remove the line below once the api is fixed to return an empty list when no admins are found
    preventAlert: true
  },
  tableMetaUpdate: {
    type: dealerActions.UPDATE_DEALER_ADMINS_TABLE_META
  }
}, (dux: IExposeRedux, dealerId: string) => {
  const params = dux.getState().dealerAdmins.queryParams;
  return supportApi.user.getAdminsForDealer.bind(supportApi.user, dealerId, params);
});
export const attemptRetrieveDealerAdmins = getDealerAdminListAction.go;

export function updateDealerAdminsQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: dealerActions.UPDATE_DEALER_ADMINS_QUERY_PARAMS,
    queryParams
  }
}

export function updateDealerAdminsTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: dealerActions.UPDATE_DEALER_ADMINS_TABLE_META,
    ...meta
  }
}

// Dealer Invites
const getDealerInvitesListAction = new ApiReduxAction(supportApi, {
  request: { type: dealerActions.GET_DEALER_INVITES_REQUEST },
  success: { type: dealerActions.GET_DEALER_INVITES_SUCCESS },
  failure: {
    type: dealerActions.GET_DEALER_INVITES_FAILURE,
    title: 'getDealerInvitesFail',
  },
  tableMetaUpdate: {
    type: dealerActions.UPDATE_DEALER_INVITES_TABLE_META
  }
}, (dux: IExposeRedux, dealerId: string) => {
  const params = dux.getState().dealerInvites.queryParams;
  return supportApi.deal.getDealerInvitations.bind(supportApi.deal, dealerId, params);
});
export const attemptRetrieveDealerInvites = getDealerInvitesListAction.go;

export function updateDealerInvitesQueryParams(queryParams: IPaginatedParamsCS) {
  setPageSizeInCache(queryParams.limit);
  return {
    type: dealerActions.UPDATE_DEALER_INVITES_QUERY_PARAMS,
    queryParams
  }
}

export function updateDealerInvitesTableMeta(meta: IUniTable_UpdatePaginationSummary) {
  return {
    type: dealerActions.UPDATE_DEALER_INVITES_TABLE_META,
    ...meta
  }
}
