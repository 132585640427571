import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniGauge,
  UniTable,
  UniConditionalRender,
  UniLocalize,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Column,
  IUniTable_Sort,
  IUniTable_Filter,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  IDeviceCS,
  DeviceCS,
  EDeviceStatus,
  EDeviceType,
} from '@unikey/unikey-commons/release/csupp';

import {
  toggleModal,
  updateDeviceListQueryParams,
  updateDeviceTableMeta,
  attemptRetrieveDevices,
  brandCodeDisplay,
  navConfig, ENavPages,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection, getTableParamsFromUpdate,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  deviceData: IPaginatedResponseCS<IDeviceCS, DeviceCS>,
  deviceListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  paginationSummary: IUniTable_PaginationSummary,
  showSubBrand?: boolean,
  getDevices(): Promise<void>,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginatedParamsCS): void,
}

class DeviceListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);
    
    this._updateTable = buildTableUpdateFunc(
      props.getDevices,
      props.updateTableMeta,
      props.updateQueryParams
    )

  }

  componentDidMount() {
    this.props.getDevices();
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('deviceType', {
        nameKey: 'type',
        isSortable: true,
        isFilterable: true,
        type: 'enum-taginverted',
        enumType: EDeviceType,
        filterName: 'device_type',
        size: 4
      })
      .set('osVersion', {
        nameKey: 'osVersion',
        isFilterable: true,
        type: 'string',
        size: 4
      })
      .set('deviceStatus', {
        nameKey: 'status',
        isFilterable: true,
        isSortable: true,
        type: 'enum-tagdot',
        enumType: EDeviceStatus,
        size: 4
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 4
      })
      .set('brand', {
        nameKey: 'brand',
        isSortable: false,
        type: 'tag',
        evalItalicized: (rowItem: DeviceCS) => !rowItem.brand,
        size: this.props.showSubBrand ? 4 : 0,
        filterName: 'brandCode'
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        collapsed: true,
        size: 2
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        type: 'uuid',
        size: 0
      })
      .set('name', {
        nameKey: 'name',
        isFilterable: true,
        type: 'string',
        size: 0
      })

    const actions = new Map()
      .set('view', {
        nameKey: 'view',
        icon: 'removeRedEye',
        isDefaultAction: true,
        func: (rowItem: any) => this.props.history.push(navConfig.get(ENavPages.deviceDetails)!.linkTo([rowItem.id])!),
        evalDisabled: (rowItem: any) => false,
        evalVisible: (rowItem: any) => true
      })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();
    return (
      <section className='deviceList-container'>

        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="deviceList"
          createButtonTextKey="device"
          handleUpdate={this._updateTable}
          data={this.props.deviceData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    deviceData: state.devices.data,
    deviceListQueryParams: state.devices.queryParams,
    listLoading: state.devices.loading,
    appliedFilters: state.devices.tableFilters,
    appliedSorts: state.devices.tableSorts,
    paginationSummary: state.devices.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getDevices: attemptRetrieveDevices,
  updateQueryParams: updateDeviceListQueryParams,
  updateTableMeta: updateDeviceTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DeviceListContainer)
    ), { componentName: 'DeviceList' })
