import { Dispatch } from 'redux'

import {
  loginKey,
  mockAuth,
  logoutKey,
  portalRedirect,
  clearApplicationState,
} from '../../internal'

interface IRedirectOptions {
  preventContinue: boolean
}

export function redirectToLogin(options?: IRedirectOptions) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    if (!mockAuth) {
      var redirectConfig = {};
      // encode the desired path (not the leading #)
      const continuePath = encodeURIComponent(location.hash.slice(1));
      if (continuePath.indexOf('login') === -1 && continuePath.indexOf('logout') === -1) {
        dispatch(clearApplicationState());
        // prevent in instance where we dont want to use, ex: redirect to login after verify email
        if (options?.preventContinue) {
          redirectConfig = {
            pathname: loginKey
          };
        } else {
          redirectConfig = {
            pathname: loginKey,
            search: `?continue=${continuePath}`
          };
        }
      } else {
        redirectConfig = {
          pathname: loginKey,
          search: ''
        };
      }

      portalRedirect(redirectConfig)
    }
  }
}

export function redirectToLogout(preventContinue: boolean = false) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    // encode the desired path (not the leading #)
    const continuePath = encodeURIComponent(location.hash.slice(1));
    if (continuePath.indexOf('login') === -1 && continuePath.indexOf('logout') === -1) {
      dispatch(clearApplicationState());

      portalRedirect({
        pathname: logoutKey,
        search: preventContinue ? '' : `?continue=${continuePath}`
      });
    }
  }
}