import { Editable } from '@unikey/unikey-commons/release/csupp'

import {
  supportApi,
  deviceActions,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialDevicesState = populateInitialReduxPaginatedListState(['created'], 'reverse');
export const devices = (state = initialDevicesState, action: any) => {
  switch (action.type) {
    case deviceActions.GET_DEVICES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case deviceActions.GET_DEVICES_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case deviceActions.GET_DEVICES_FAILURE:
      return {
        ...state,
        loading: action.loading,
        data: initialDevicesState.data
      }
    case deviceActions.UPDATE_DEVICES_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case deviceActions.UPDATE_DEVICES_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialDeviceDetailsState = {
  loading: true,
  deviceData: {}
};

export const deviceDetails = (state = initialDeviceDetailsState, action: any) => {
  switch (action.type) {
    case deviceActions.GET_SINGLE_DEVICE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case deviceActions.GET_SINGLE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        deviceData: action.value,
      }
    case deviceActions.GET_SINGLE_DEVICE_FAILURE:
      return {
        ...state,
        loading: action.pending,
        deviceData: {}
      }
    default:
      return state
  }
}

const initialDeviceMesageState = {
  sending: false,
  message: new Editable<string>({ value: '' }),
  modalOpen: false
}
export const deviceNotificationForm = (state = initialDeviceMesageState, action: any) => {
  switch(action.type) {
    case deviceActions.TOGGLE_PUSH_NOTIFICATION_MODAL: 
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case deviceActions.UPDATE_PUSH_NOTIFICATION_MESSAGE: 
      return {
        ...state,
        message: action.message
      }
    case deviceActions.SEND_PUSH_NOTIFICATION_REQUEST:
    case deviceActions.SEND_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        sending: action.pending
      }
    case deviceActions.SEND_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sending: action.pending,
        message: new Editable({ value: '' }),
        modalOpen: false
      }
    default: 
      return state;
  }
}
