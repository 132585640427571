import { Editable } from '@unikey/unikey-commons/release/csupp';

import {
  activityLogActions,
  portalActions,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState
} from '../../internal'

const initialActivityLogsState: any = populateInitialReduxPaginatedListState(['start'], 'reverse');
initialActivityLogsState.entityId = new Editable({ value: '', valid: false });

export const activityLogs = (state = initialActivityLogsState, action: any) => {
  switch (action.type) {
    case activityLogActions.GET_ACTIVITY_LOGS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case activityLogActions.GET_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case activityLogActions.GET_ACTIVITY_LOGS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialActivityLogsState.data
      }
    case activityLogActions.UPDATE_ACTIVITY_LOGS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    case activityLogActions.UPDATE_ACTIVITY_LOGS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case activityLogActions.UPDATE_ENTITY_ID_FOR_ACTIVITY_LOG:
      return {
        ...state,
        entityId: action.entityId
      }
    case activityLogActions.CLEAR_ACTIVITY_LOGS:
      return {
        ...state,
        data: {
          models: []
        },
        loading: false
      }
    case portalActions.TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    default:
      return state
  }
}