import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniKeyVal,
  UniLocalize,
  UniConditionalRender,
  FirmwareCS,
  ReaderCS,
  EReaderStatus,
  IFirmwarePutCS,
  IFirmwareImageCS,
  ProductCS,
  Editable,
  IMultiInputUpdate,
  IUniKeyVal_FieldConfig,
  EFirmwareVersionStatus,
  IUniConfirm_Config,
  IUniTable_Sort,
  IUniTable_Filter,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
} from '@unikey/unikey-commons/release/csupp';

import {
  navConfig, ENavPages,
  attemptRetrieveFirmwareReaders,
  updateFirmwareReadersQueryParams,
  updateFirmwareReadersTableMeta,
  attemptGetFirmwareById,
  setCurrentFirmware,
  handleFirmwareFormDataChange,
  attemptRetrieveSingleProduct,
  attemptUpdateFirmware,
  PartnerCustomizations, IPartnerCustomizations,
  dateOrLoading,
  openConfirmModal,
  closeConfirmModal,
  getTableSortDirection,
  getTableParamsFromUpdate,
  readerUpgradeAvailableTemplate,
  IGetSingleFirmwareActionParams
} from '../internal';

import { environment } from '@alias-environment';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  currentFirmware: IFirmwarePutCS,
  firmwareCreatedDate: string,
  firmwareVersion: Editable<string>,
  firmwareStatus: Editable<number>,
  firmwareReleaseNotes: Editable<string>,
  firmwareDescription: Editable<string>,

  currentProduct: ProductCS,
  history: any,
  loading: boolean,
  saving: boolean,
  isUniKeyActor: boolean,

  readers: ReaderCS[],
  readerListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  paginationSummary: IUniTable_PaginationSummary,

  openConfirmModal(dialogConfig: IUniConfirm_Config): any,
  closeConfirmModal(): void,
  updateFirmwareFormData(firmwareParts?: IMultiInputUpdate, images?: any[], imageTempates?: any[]): void,
  updateFirmwareImages(image: any, index: number): void,
  saveFirmwareUpdate(): Promise<any>,
  saveFirmwareImages(toUpdate: IFirmwarePutCS): Promise<any>,
  setCurrentFirmware(firm: FirmwareCS): void,
  getProductById(prodId: string): Promise<ProductCS>,
  getFirmwareById(params: IGetSingleFirmwareActionParams): Promise<FirmwareCS>,
  getFirmwareReaders(productId: string, firmwareVersion: string, readerListQueryParams: IPaginatedParamsCS): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginatedParamsCS): void,
}

class FirmwareDetailsContainer extends Component<IProps> {
  productId: string;
  firmwareId: string;

  constructor(props: IProps) {
    super(props);

    this.firmwareId = props.match.params.firmwareId;
    this.productId = props.match.params.productId;
    this._loadFirmwareDetails();
  }

  _loadFirmwareDetails = async () => {
    const foundProduct: ProductCS = await this.props.getProductById(this.productId);
    const foundFirmware: FirmwareCS = await this.props.getFirmwareById({ productId: this.productId, firmwareId: this.firmwareId });
    
    this.props.setCurrentFirmware(foundFirmware);
    // once we have the product, we add the necessary images for this firmware
    this.props.updateFirmwareFormData(undefined, undefined, foundProduct.images);
    this.props.updateFirmwareFormData(undefined, new Array(foundProduct.images.length));
    
    return this.props.getFirmwareReaders(this.props.match.params.productId, foundFirmware.version, this.props.readerListQueryParams);
  }

  _updateVersion = (version: Editable<string>) => {
    this.props.updateFirmwareFormData({ version })
  }

  _updateDescription = (description: Editable<string>) => {
    this.props.updateFirmwareFormData({ description })
  }

  _updateReleaseNotes = (releaseNotes: Editable<string>) => {
    this.props.updateFirmwareFormData({ releaseNotes })
  }

  _updateStatus = (status: Editable<number>) => {
    this.props.updateFirmwareFormData({ status })
  }

  _saveFirmwareChanges = (): Promise<any> => {
    return this.props.saveFirmwareUpdate();
  }

  _updateImage = (imageIndex: number, newImage: any) => {
    const imageSet = this.props.currentFirmware.images || [];
    imageSet.splice(imageIndex, 1, newImage.value);
    return this.props.updateFirmwareFormData(undefined, imageSet);
  }

  _saveFirmwareImages = (): Promise<any> => {
    return this.props.saveFirmwareUpdate();
  }

  _reloadOnCancel = (): Promise<any> => {
    return Promise.resolve(this._loadFirmwareDetails())
  }

  _promptAndSaveFirmwareImages = (): Promise<any> => {
    this.props.openConfirmModal({
      titleKey: 'updateFirmwareVersion',
      messageKeys: ['_warnAgainstChangeFirmwareImages', 'areYouSure'],
      confirmTextKey: 'overwrite',
      cancelHandler: this.props.closeConfirmModal,
      confirmHandler: () => {
        this._saveFirmwareImages()
        this.props.closeConfirmModal()
      }
    });
    return Promise.resolve();
  }

  _buildColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        template: (rowItem: ReaderCS) => rowItem.name ? rowItem.name : (<i><UniLocalize translate="notProvided" /></i>),
        size: 10
      })
      .set('firmware_version', {
        nameKey: 'firmwareVersion',
        isSortable: false,
        type: 'string',
        size: 4,
      })
      .set('upgradeAvailableIcon', {
        nameKey: '_emptyString',
        isSortable: false,
        type: 'string',
        template: readerUpgradeAvailableTemplate,
        size: 2
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        type: 'enum-tagdot',
        enumType: EReaderStatus,
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 4
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('availableUpgradeVersion', {
        nameKey: 'availableUpgradeVersion',
        isSortable: false,
        isFilterable: true,
        type: 'string',
        size: 0
      })
      .set('certificateName', {
        nameKey: 'certificateName',
        isSortable: false,
        isFilterable: true,
        type: 'string',
        size: 0
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: ReaderCS) => this.props.history.push(navConfig.get(ENavPages.readerDetails)!.linkTo([rowItem.id])!)
    });

    return { columns, actions };
  }

  _updateTable = (update: IUniTable_UpdatePaginationSummary) => {
    if (this.props.updateQueryParams) {
      const params = getTableParamsFromUpdate(update);

      this.props.updateTableMeta(update);
      this.props.updateQueryParams(params);
      this.props.getFirmwareReaders(this.props.match.params.productId, this.props.firmwareVersion.value, params);
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const { columns, actions } = this._buildColumnsAndActions();

    var imageUploadKeyValConfigs: IUniKeyVal_FieldConfig[] = [];
    if (this.props.currentFirmware && this.props.currentFirmware.imageTemplates) {
      imageUploadKeyValConfigs = this.props.currentFirmware.imageTemplates
      .sort((a: IFirmwareImageCS, b: IFirmwareImageCS) => a.order - b.order)
      .map((imageTemplate: IFirmwareImageCS, index: number) => {
        return {
          keyName: '' + imageTemplate.description,
          required: imageTemplate.is_required,
          value: '',
          editable: new Editable<string>({ value: '' }),
          type: 'file',
          placeholderKey: 'image',
          handleUpdate: this._updateImage.bind(this, index)
        };
      });
    }

    return (
      <section className='firmwareDetails-container'>
        {/* Key vals for images belonging to this product */}
        <Row>
          <Col>
            <h3 className="page-title-non-table">{this.props.currentFirmware && this.props.firmwareVersion.value}</h3>
          </Col>
        </Row>
        <UniConditionalRender visible={this.props.currentFirmware && !!this.props.currentFirmware.id}>
          <UniKeyVal
            label="firmware-details"
            preventEdit={!this.props.isUniKeyActor}
            showLoader={this.props.loading}
            saveClick={this._saveFirmwareChanges}
            cancelClick={this._reloadOnCancel}
            isSaving={this.props.saving}
            fields={[
              {
                keyName: 'firmwareVersionId',
                value: `${this.props.currentFirmware.id}`,
                type: 'string',
                disabled: true,
              },
              {
                keyName: 'versionNumber',
                value: `${this.props.firmwareVersion.value}`,
                placeholderKey: 'major.minor.revision',
                handleUpdate: this._updateVersion,
                type: 'string',
                preventTranslate: true,
                disabled: true
              },
              {
                keyName: 'status',
                value: '' + this.props.firmwareStatus.value,
                handleUpdate: this._updateStatus,
                type: 'enum',
                enumType: EFirmwareVersionStatus
              },
              {
                keyName: 'createdDate',
                value: dateOrLoading(this.props.firmwareCreatedDate, this.props.loading),
                type: 'string',
                disabled: true
              },
              {
                keyName: 'description',
                value: `${this.props.firmwareDescription.value}`,
                placeholderKey: 'shortDescriptionOfFirmware',
                handleUpdate: this._updateDescription,
                type: 'string',
                preventTranslate: true,
                disabled: false
              },
              {
                keyName: 'releaseNotes',
                value: `${this.props.firmwareReleaseNotes.value}`,
                placeholderKey: 'optional',
                handleUpdate: this._updateReleaseNotes,
                type: 'string',
                preventTranslate: true,
                textarea: true,
                disabled: false
              }
            ]} />
        </UniConditionalRender>

        {/* Currently off for the demo, we may not want to ever support editing firmware images for existing versions anyways */}
        <UniConditionalRender visible={this.props.isUniKeyActor}>
          <Row nogutter>
            <Col>
              <h3><UniLocalize translate="providedImages" /></h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <UniKeyVal
                label="firmware-images"
                saveClick={this._promptAndSaveFirmwareImages}
                cancelClick={this._reloadOnCancel}
                showLoader={this.props.loading}
                isSaving={this.props.saving}
                preventEdit={!this.props.isUniKeyActor || environment === 'production'}
                preventSave={!this.props.isUniKeyActor || environment === 'production'}
                fields={imageUploadKeyValConfigs} />
            </Col>
          </Row>
        </UniConditionalRender>

        {/* Display the readers with this specific firmware version */}
        <section className='readerList-container'>
          <UniTable
            searchable={true}
            advancedFiltering={true}
            titleKey="readersWithThisFirmware"
            createButtonTextKey="reader"
            handleUpdate={this._updateTable}
            data={this.props.readers}
            paginationSummary={this.props.paginationSummary}
            columnConfig={columns}
            activeSorts={this.props.appliedSorts}
            actionsConfig={actions}
            activeFilters={this.props.appliedFilters}
            showLoader={this.props.listLoading} />

        </section>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    currentFirmware: state.firmwareEditor.formData,
    firmwareCreatedDate: state.firmwareEditor.formData.createdDate,
    firmwareVersion: state.firmwareEditor.formData.version,
    firmwareStatus: state.firmwareEditor.formData.status,
    firmwareReleaseNotes: state.firmwareEditor.formData.releaseNotes,
    firmwareDescription: state.firmwareEditor.formData.description,
    currentProduct: state.productDetails.data,
    loading: state.firmwareEditor.loading || state.firmwareDetails.loading,
    saving: state.firmwareDetails.saving,
    isUniKeyActor: state.authenticatedUser.isUniKeyActor,
    // reader list stuff
    readers: state.firmwareReaders.readersData.models,
    readerListQueryParams: state.firmwareReaders.queryParams,
    listLoading: state.firmwareReaders.loading,
    appliedFilters: state.firmwareReaders.tableFilters,
    appliedSorts: state.firmwareReaders.tableSorts,
    paginationSummary: state.firmwareReaders.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getProductById: attemptRetrieveSingleProduct,
  saveFirmwareUpdate: attemptUpdateFirmware,
  updateFirmwareFormData: handleFirmwareFormDataChange,
  getFirmwareById: attemptGetFirmwareById,
  setCurrentFirmware,
  openConfirmModal,
  closeConfirmModal,
  // saveFirmwareImages,
  // readers list stuff
  getFirmwareReaders: attemptRetrieveFirmwareReaders,
  updateQueryParams: updateFirmwareReadersQueryParams,
  updateTableMeta: updateFirmwareReadersTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(FirmwareDetailsContainer)
    ), { componentName: 'FirmwareDetails' })
