import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniConditionalRender,
  ProductCS,
  EUniTable_FooterStyle
} from '@unikey/unikey-commons/release/csupp';

import {
  attemptRetrieveProducts,
  toggleModal,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  productData: ProductCS[],
  listLoading: boolean,
  history: any,
  getProducts(): void,
}

class ProductListContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getProducts();
  }

  _buildColumnsAndActions() {
    const columns = new Map()
      .set('descriptor', {
        nameKey: 'descriptor',
        isSortable: false,
        size: 4
      })
      .set('name', {
        nameKey: 'name',
        isSortable: false,
        size: 10
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 4
      });

    const actions = new Map();
    actions.set('viewFirmware', {
      nameKey: 'viewFirmware',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: any) => this.props.history.push(`/portal/products/${rowItem.id}`),
      evalDisabled: (rowItem: any) => false,
      evalVisible: (rowItem: any) => true
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='productList-container'>
        <UniTable
          searchable={false}
          advancedFiltering={false}
          titleKey="productList"
          createButtonTextKey="product"
          data={this.props.productData}
          columnConfig={columns}
          actionsConfig={actions}
          showLoader={this.props.listLoading}
          footerStyle={EUniTable_FooterStyle.none} />

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    productData: state.products.productData || [],
    listLoading: state.products.loading
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getProducts: attemptRetrieveProducts
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ProductListContainer)
  ), { componentName: 'ProductList' })
