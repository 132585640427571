import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  Editable,
  UniKeyVal,
  UniLocalize,
  UniConditionalRender,
  UserCS,
  RoleCS,
  EUserStatusCS,
  EEmailStatusCS,
  DealerInviteCS,
  EInviteStatusCS,
  ActivityLogCS,
  IMultiInputUpdate,
  IPaginatedParamsCS,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
} from '@unikey/unikey-commons/release/csupp';

import {
  AdminInviteList,
  ActivityLogList,
  getAdminById,
  attemptRetrieveAdminInvitesByAdminId,
  handleAdminChange,
  attemptUpdateAdmin,
  attemptResendAdminInvite,
  attemptRetrieveAdminRoles,
  PartnerCustomizations, IPartnerCustomizations,
  navConfig,
  getTableSortDirection, getTableParamsFromUpdate,
  AdminRolesListContainer,
  dateOrLoading,
  dateAndTimeOrLoading,
  updateAdminInvitesQueryParams,
  updateAdminInvitesTableMeta,
  attemptRetrieveActivityLogs,
  updateActivityLogsQueryParams,
  updateActivityLogsTableMeta,
  
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  showSubBrand: boolean,

  admin: UserCS,
  adminEmailStatus: Editable<EEmailStatusCS>,
  firstName: Editable<string>,
  lastName: Editable<string>,
  phoneNumber: Editable<string>,
  email: string,
  emailStatus: EEmailStatusCS,
  adminStatus: EUserStatusCS,
  adminRoles: RoleCS[],

  loading: boolean,
  saving: boolean,
  isUniKeyActor: boolean,

  // invite: DealerInviteCS,
  // updatedInvite: boolean,
  // savingInvite: boolean,

  invites: DealerInviteCS[],
  inviteListQueryParams: IPaginatedParamsCS,
  inviteListLoading: IUniTable_Filter[],
  inviteListAppliedFilters: IUniTable_Sort,
  inviteListAppliedSort: boolean,
  inviteListPaginationSummary: IUniTable_PaginationSummary,

  activityLogs: ActivityLogCS[],
  activityLogListQueryParams: IPaginatedParamsCS,
  activityLogListLoading: IUniTable_Filter[],
  activityLogListAppliedFilters: IUniTable_Sort,
  activityLogListAppliedSort: boolean,
  activityLogListPaginationSummary: IUniTable_PaginationSummary,

  handleAdminChange(updates: IMultiInputUpdate): void,
  saveAdmin(adminId: string): Promise<any>
  getAdminById(adminId: string): void,
  // activity logs
  getActivityLogs(adminId: string, activityLogsQueryParams: IPaginatedParamsCS): void,
  updateActivityLogsListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateActivityLogsListQueryParams?(params: IPaginatedParamsCS): void,
  // admin invites
  getAdminInvites(adminId: string, inviteListQueryParams: IPaginatedParamsCS): void,
  resendAdminInvite(inviteId: string): Promise<void>,
  updateInviteListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateInviteListQueryParams?(params: IPaginatedParamsCS): void,
}

class AdminDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getAdminById(this.props.match.params.adminId);
    this.props.getAdminInvites(this.props.match.params.adminId, this.props.inviteListQueryParams);
    if (this.props.isUniKeyActor) {
      this.props.getActivityLogs(this.props.match.params.adminId, this.props.activityLogListQueryParams);
    }
  }

  _reloadOnCancel = (): Promise<any> => {
    return Promise.resolve(this.componentDidMount())
  }

  _saveAdmin = () => {
    return this.props.saveAdmin(this.props.admin.id!);
  }

  _resendInvite = (inviteId: string) => {
    this.props.resendAdminInvite(inviteId);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <section className='adminDetails-container'>
        <>
          <Row>
            <Col>
              <h3 className="page-title-non-table">{this.props.firstName.value} {this.props.lastName.value}</h3>
            </Col>
          </Row>

          <UniConditionalRender visible={!!this.props.admin.id}>
            <UniKeyVal
              label="admin-details"
              saveClick={this._saveAdmin}
              cancelClick={this._reloadOnCancel}
              isSaving={this.props.saving}
              fields={[
                {
                  keyName: 'id',
                  value: `${this.props.admin.id}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'firstName',
                  value: `${this.props.firstName.value}`,
                  type: 'string',
                  preventTranslate: true,
                  handleUpdate: (firstName: Editable<string>) => this.props.handleAdminChange({ firstName }),
                },
                {
                  keyName: 'lastName',
                  value: `${this.props.lastName.value}`,
                  type: 'string',
                  preventTranslate: true,
                  handleUpdate: (lastName: Editable<string>) => this.props.handleAdminChange({ lastName }),
                },
                {
                  keyName: 'phoneNumber',
                  value: `${this.props.phoneNumber.value}`,
                  type: 'string',
                  preventTranslate: true,
                  handleUpdate: (phoneNumber: Editable<string>) => this.props.handleAdminChange({ phoneNumber }),
                },
                {
                  keyName: 'email',
                  value: `${this.props.admin.email}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'emailStatus',
                  value: `${this.props.adminEmailStatus.value}`,
                  type: 'enum',
                  enumType: EEmailStatusCS,
                  disabled: false,
                  handleUpdate: (emailStatus: Editable<EEmailStatusCS>) => this.props.handleAdminChange({ emailStatus })
                },
                {
                  keyName: 'adminStatus',
                  value: `${this.props.adminStatus}`,
                  enumType: EUserStatusCS,
                  type: 'enum',
                  disabled: true
                },
                {
                  keyName: 'brand',
                  value: `${this.props.admin.brand}`,
                  disabled: true,
                  hidden: !this.props.showSubBrand,              
                  type: 'tag',
                },
                {
                  keyName: 'created',
                  value: dateAndTimeOrLoading(this.props.admin.created, this.props.loading),
                  type: 'string',
                  disabled: true
                }
              ]} />
          </UniConditionalRender>

          <AdminRolesListContainer
            match={this.props.match}
            history={this.props.history} />

          <UniConditionalRender visible={this.props.invites?.length > 0}>
            {/* Display the list of dealer invites */}
              <AdminInviteList 
                key={this.props.invites?.[0]?.expiration}
                match={this.props.match}
                history={this.props.history}
                invites={this.props.invites}
                inviteListQueryParams={this.props.inviteListQueryParams}
                inviteListLoading={this.props.inviteListLoading}
                inviteListAppliedFilters={this.props.inviteListAppliedFilters}
                inviteListAppliedSort={this.props.inviteListAppliedSort}
                inviteListPaginationSummary={this.props.inviteListPaginationSummary}
                getScopedInvites={this.props.getAdminInvites.bind(this, this.props.match.params.adminId)}
                updateInviteListQueryParams={this.props.updateInviteListQueryParams}
                updateInviteListTableMeta={this.props.updateInviteListTableMeta} 
                resendAdminInvite={this.props.resendAdminInvite} />

          </UniConditionalRender> 

          <UniConditionalRender visible={this.props.isUniKeyActor}>
            {/* Display the admin's activity logs */}
              <ActivityLogList 
                key={`${this.props.activityLogs?.[0]?.start}${this.props.activityLogListLoading}`}
                match={this.props.match}
                history={this.props.history}
                logs={this.props.activityLogs}
                logListQueryParams={this.props.activityLogListQueryParams}
                logListLoading={this.props.activityLogListLoading}
                logListAppliedFilters={this.props.activityLogListAppliedFilters}
                logListAppliedSort={this.props.activityLogListAppliedSort}
                logListPaginationSummary={this.props.activityLogListPaginationSummary}
                getScopedLogs={this.props.getActivityLogs.bind(this, this.props.match.params.adminId)}
                updateLogListQueryParams={this.props.updateActivityLogsListQueryParams}
                updateLogListTableMeta={this.props.updateActivityLogsListTableMeta} />
          </UniConditionalRender> 


        </>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    // admin details stuff
    admin: state.adminDetails.adminData,
    firstName: state.adminDetails.adminData.firstName,
    lastName: state.adminDetails.adminData.lastName,
    phoneNumber: state.adminDetails.adminData.phoneNumber,
    email: state.adminDetails.adminData.email,
    adminEmailStatus: state.adminDetails.adminData.emailStatus,
    adminStatus: state.adminDetails.adminData.status,
    adminRoles: state.adminRoles.data.models,
    loading: state.adminDetails.loading,
    saving: state.adminDetails.saving,
    // invite: state.adminInviteDetails.inviteData,
    // savingInvite: state.adminInviteDetails.saving,
    // updatedInvite: state.adminInviteDetails.updated,
    // invite list stuff
    invites: state.adminInvites.data.models,
    inviteListQueryParams: state.adminInvites.queryParams,
    inviteListLoading: state.adminInvites.loading,
    inviteListAppliedFilters: state.adminInvites.tableFilters,
    inviteListAppliedSort: state.adminInvites.tableSorts[0],
    inviteListPaginationSummary: state.adminInvites.paginationSummary,
    // activity log list stuff
    activityLogs: state.activityLogs.data.models,
    activityLogListLoading: state.activityLogs.loading,
    activityLogListQueryParams: state.activityLogs.queryParams,
    activityLogListAppliedFilters: state.activityLogs.tableFilters,
    activityLogListAppliedSort: state.activityLogs.tableSorts[0],
    activityLogListPaginationSummary: state.activityLogs.paginationSummary,

    isUniKeyActor: state.authenticatedUser.isUniKeyActor
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getAdminById,
  handleAdminChange,
  saveAdmin: attemptUpdateAdmin,
  getAdminInvites: attemptRetrieveAdminInvitesByAdminId,
  getActivityLogs: attemptRetrieveActivityLogs,
  updateActivityLogsListQueryParams: updateActivityLogsQueryParams,
  updateActivityLogsListTableMeta: updateActivityLogsTableMeta,
  resendAdminInvite: attemptResendAdminInvite,
  updateInviteListQueryParams: updateAdminInvitesQueryParams,
  updateInviteListTableMeta: updateAdminInvitesTableMeta,
  
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminDetailsContainer)
    ), { componentName: 'AdminDetails' })
