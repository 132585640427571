import {
  supportApi,

  statActions,
  EChartAggregate,
  EChartInterval,
  EChartTimePeriod

} from '../../internal'

const currentDate = new Date();
const tempDate = new Date();
// set initially 6 months back
const intervalAdjust = currentDate.getMonth() - 6;
const initialStatsState = {
  // initially start 3 months back
  startDate: new Date(tempDate.setMonth(intervalAdjust)),
  endDate: currentDate,
  timePeriod: EChartTimePeriod.sixMonths,
  interval: EChartInterval.monthly,
  chartAggregate: EChartAggregate.cumulative,
  maximizedChart: ''
};

export const statsOptions = (state = initialStatsState, action: any) => {
  switch (action.type) {
    case statActions.UPDATE_STATS_OPTIONS:
      const toUpdate: any = {};

      if (action.interval) {
        toUpdate.interval = action.interval
      }
      if (action.timePeriod) {
        toUpdate.timePeriod = action.timePeriod
      }
      if (action.end) {
        toUpdate.endDate = action.end
      }
      if (action.start) {
        toUpdate.startDate = action.start
      }
      if (action.aggregate !== undefined) {
        toUpdate.chartAggregate = action.aggregate;
      }
      return {
        ...state,
        ...toUpdate
      }
    case statActions.CHANGE_MAXIMIZED_CHART:
      return {
        ...state,
        maximizedChart: action.maximizedChart
      }
    default:
      return state
  }
}

const initialReaderStatsState = {
  loading: true,
  data: {},
  chartConfig: []
};

export const readerStats = (state = initialReaderStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_READER_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_READER_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_READER_STATS_REQUEST) > 0,
        data: action.readerStats,
      }
    case statActions.GET_READER_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_READER_STATS_REQUEST) > 0,
        data: initialReaderStatsState.data
      }
    case statActions.SET_READER_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_READER_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    default:
      return state
  }
}

const initialOrgStatsState = {
  loading: true,
  data: {},
  chartConfig: {
    dataSetKeys: [],
    entries: []
  }
};

export const orgStats = (state = initialOrgStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_ORGANIZATION_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_ORGANIZATION_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_ORGANIZATION_STATS_REQUEST) > 0,
        data: action.readerStats,
      }
    case statActions.GET_ORGANIZATION_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_ORGANIZATION_STATS_REQUEST) > 0,
        data: initialOrgStatsState.data
      }
    case statActions.SET_ORGANIZATION_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_ORGANIZATION_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    default:
      return state
  }
}

const initialDeviceStatsState = {
  loading: true,
  data: {},
  chartConfig: {
    dataSetKeys: [],
    entries: []
  },
  totalDeviceChartConfig: [],
};

export const deviceStats = (state = initialDeviceStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_DEVICE_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_DEVICE_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEVICE_STATS_REQUEST) > 0,
        data: action.deviceStats,
      }
    case statActions.GET_DEVICE_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEVICE_STATS_REQUEST) > 0,
        data: initialDeviceStatsState.data
      }
    case statActions.SET_DEVICE_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEVICE_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    case statActions.SET_TOTAL_DEVICE_PIE_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEVICE_STATS_REQUEST) > 0,
        totalDeviceChartConfig: action.chartConfig
      }
    default:
      return state
  }
}

const initialOverallStatsState = {
  loading: true,
  data: {},
  chartConfig: {
    dataSetKeys: [],
    data: []
  }
};

export const overallStats = (state = initialOverallStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_OVERALL_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_OVERALL_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_OVERALL_STATS_REQUEST) > 0,
        data: action.overallStats,
      }
    case statActions.GET_OVERALL_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_OVERALL_STATS_REQUEST) > 0,
        data: initialOverallStatsState.data
      }
    case statActions.SET_OVERALL_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_OVERALL_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    default:
      return state
  }
}

const initialCredentialStatsState = {
  loading: true,
  data: {},
  chartConfig: {
    dataSetKeys: [],
    entries: []
  }
};

export const credentialStats = (state = initialCredentialStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_CREDENTIAL_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_CREDENTIAL_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDENTIAL_STATS_REQUEST) > 0,
        data: action.credentialStats,
      }
    case statActions.GET_CREDENTIAL_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDENTIAL_STATS_REQUEST) > 0,
        data: initialCredentialStatsState.data
      }
    case statActions.SET_CREDENTIAL_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDENTIAL_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    default:
      return state
  }
}


const initialDealerStatsState = {
  loading: true,
  data: {},
  chartConfig: {
    dataSetKeys: [],
    entries: []
  }
};

export const dealerStats = (state = initialDealerStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_DEALER_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_DEALER_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEALER_STATS_REQUEST) > 0,
        data: action.dealerStats,
      }
    case statActions.GET_DEALER_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEALER_STATS_REQUEST) > 0,
        data: initialDealerStatsState.data
      }
    case statActions.SET_DEALER_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_DEALER_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    default:
      return state
  }
}

const initialCreditStatsState = {
  loading: true,
  data: {},
  chartConfig: {
    dataSetKeys: [],
    entries: []
  }
};

export const creditStats = (state = initialCreditStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_CREDIT_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_CREDIT_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDIT_STATS_REQUEST) > 0,
        data: action.creditStats,
      }
    case statActions.GET_CREDIT_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDIT_STATS_REQUEST) > 0,
        data: initialCreditStatsState.data
      }
    case statActions.SET_CREDIT_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDIT_STATS_REQUEST) > 0,
        chartConfig: action.chartConfig
      }
    default:
      return state
  }
}


const initialReadersPerOrgStatsState = {
  loading: true,
  data: [],
  pie: [],
  histogram: [],
  histogramKeys: []
};

export const readersPerOrgStats = (state = initialReadersPerOrgStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_READERS_PER_ORG_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_READERS_PER_ORG_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_READERS_PER_ORG_STATS_REQUEST) > 0,
        data: action.readersPerOrgStats,
      }
    case statActions.GET_READERS_PER_ORG_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_READERS_PER_ORG_STATS_REQUEST) > 0,
        data: initialReadersPerOrgStatsState.data
      }
    case statActions.SET_READERS_PER_ORG_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_READERS_PER_ORG_STATS_REQUEST) > 0,
        pie: action.chartConfig.pie,
        histogram: action.chartConfig.histogram,
        histogramKeys: Object.keys(action.chartConfig.histogramKeys)
      }
    default:
      return state
  }
}


const initialCredentialsPerOrgStatsState = {
  loading: true,
  data: [],
  pie: [],
  histogram: [],
  histogramKeys: []
};

export const credentialsPerOrgStats = (state = initialCredentialsPerOrgStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_CREDENTIALS_PER_ORG_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_CREDENTIALS_PER_ORG_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDENTIALS_PER_ORG_STATS_REQUEST) > 0,
        data: action.credentialsPerOrgStats,
      }
    case statActions.GET_CREDENTIALS_PER_ORG_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDENTIALS_PER_ORG_STATS_REQUEST) > 0,
        data: initialCredentialsPerOrgStatsState.data
      }
    case statActions.SET_CREDENTIALS_PER_ORG_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_CREDENTIALS_PER_ORG_STATS_REQUEST) > 0,
        pie: action.chartConfig.pie,
        histogram: action.chartConfig.histogram,
        histogramKeys: Object.keys(action.chartConfig.histogramKeys)
      }
    default:
      return state
  }
}


const initialOrgsPerDealerStatsState = {
  loading: true,
  data: [],
  pie: [],
  histogram: [],
  histogramKeys: []
};

export const orgsPerDealerStats = (state = initialOrgsPerDealerStatsState, action: any) => {
  switch (action.type) {
    case statActions.GET_ORGS_PER_DEALER_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case statActions.GET_ORGS_PER_DEALER_STATS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_ORGS_PER_DEALER_STATS_REQUEST) > 0,
        data: action.orgsPerDealerStats,
      }
    case statActions.GET_ORGS_PER_DEALER_STATS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_ORGS_PER_DEALER_STATS_REQUEST) > 0,
        data: initialOrgsPerDealerStatsState.data
      }
    case statActions.SET_ORGS_PER_DEALER_CHART_CONFIG:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(statActions.GET_ORGS_PER_DEALER_STATS_REQUEST) > 0,
        pie: action.chartConfig.pie,
        histogram: action.chartConfig.histogram,
        histogramKeys: Object.keys(action.chartConfig.histogramKeys)
      }
    default:
      return state
  }
}