import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { NavLink } from 'react-router-dom'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniKeyVal,
  UniTable,
  UniWorkflow,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
  DealerCS,
  UserCS,
  EUserStatusCS,
  DealerInviteCS,
  EInviteStatusCS,
  IDealerAddressCS,
  OrganizationCS,
  IPaginatedParamsCS,
  IUniTable_Column,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  TSize,
  EOrganizationStatus,
  IDealerAddressModelCS,
  S10nModelC,
  ES10nModelType
} from '@unikey/unikey-commons/release/csupp';

import {
  navConfig, ENavPages,
  getTableSortDirection, getTableParamsFromUpdate,
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations,
  dateAndTimeOrLoading
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,

  invites: DealerInviteCS[],
  inviteListQueryParams: IPaginatedParamsCS,
  inviteListAppliedFilters: IUniTable_Filter[],
  inviteListAppliedSorts: IUniTable_Sort[],
  inviteListLoading: boolean,
  inviteListPaginationSummary: IUniTable_PaginationSummary,

  loading: boolean,
  getScopedInvites(): Promise<void>,
  updateInviteListTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateInviteListQueryParams?(params: IPaginatedParamsCS): void,
  resendAdminInvite(inviteId: string): Promise<void>,
}

interface IState {
  individualInviteOpen: DealerInviteCS,
  saving: boolean
}

class AdminInviteList extends Component<IProps, IState> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this.state = {
      individualInviteOpen: {} as DealerInviteCS,
      saving: false
    };

    
    this._updateTable = buildTableUpdateFunc(
      props.getScopedInvites,
      props.updateInviteListTableMeta,
      props.updateInviteListQueryParams
    )
  }

  _clearOpenedInvite = () => {
    this.setState({
      individualInviteOpen: {} as DealerInviteCS,
      saving: false
    })
  }

  _resendInvite = (inviteId: string) => {
    this.props.resendAdminInvite(inviteId).then(() => {
          this.props.getScopedInvites();
    });
  }

  _resendFromDetails = () => {
    this.setState({ saving: true });
    this.props.resendAdminInvite(this.state.individualInviteOpen!.id).then(() => {
          this.props.getScopedInvites();
      this._clearOpenedInvite();
    })
  }

  _buildInvitesTableColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('inviteeEmail', {
        nameKey: 'sentTo',
        filterName: 'invitee_email',
        isSortable: false,
        isFilterable: true,
        isPrimaryFilter: true,
        type: 'string',
        size: 6,
      })
      .set('status', {
        nameKey: 'status',
        isSortable: false,
        type: 'enum-tagdot',
        enumType: EOrganizationStatus,
        size: 4
      })
      .set('expiration', {
        nameKey: 'expiration',
        isSortable: false,
        type: 'dateAndTime',
        size: 5
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        collapsed: true,
        size: 2,
      })
      .set('inviteeId', {
        nameKey: 'adminId',
        filterName: 'invitee_user_id',
        isSortable: false,
        isPrimaryFilter: true,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('id', {
        nameKey: 'id',
        isSortable: false,
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
      actions
      .set('view', {
        nameKey: 'viewInviteDetails',
        icon: 'removeRedEye',
        isDefaultAction: true,
        func: (rowItem: any) => this.setState({ individualInviteOpen: rowItem }),
        evalDisabled: (rowItem: any) => false,
        evalVisible: (rowItem: any) => true
      })
      .set('resend', {
        nameKey: 'resendAdminInvite',
        icon: 'send',
        isDefaultAction: false,
        func: (rowItem: DealerInviteCS) => { this._resendInvite(rowItem.id); this._clearOpenedInvite(); } ,
        evalDisabled: (rowItem: any) => rowItem.status === EInviteStatusCS.accepted,
        evalVisible: (rowItem: any) => true
      })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const { columns: inviteTableColumns, actions: inviteTableActions } = this._buildInvitesTableColumnsAndActions();

    return (
      <section className='scoped-admin-invites-container'>

        {/* Display the list of scoped (dealer/admin) invites */}
        <section className='inviteList-component'>
          <UniTable
            searchable={false}
            advancedFiltering={false}
            titleKey="invitations"
            createButtonTextKey="invite"
            handleUpdate={this._updateTable}
            data={this.props.invites}
            paginationSummary={this.props.inviteListPaginationSummary}
            columnConfig={inviteTableColumns}
            activeSorts={this.props.inviteListAppliedSorts}
            actionsConfig={inviteTableActions}
            activeFilters={this.props.inviteListAppliedFilters}
            showLoader={this.props.inviteListLoading} />
        </section>

        <UniConditionalRender visible={!!this.state.individualInviteOpen?.id}>
          <section className='invite-details-component'>
            <UniWorkflow
              inModal
              titleKey="inviteDetails"
              titleIcon="mail"
              size="wider" 
              handleClose={this._clearOpenedInvite}>

                <h4><UniLocalize translate="id" /></h4>
                <p><UniLocalize translate={this.state.individualInviteOpen?.id} /></p>

                <h4><UniLocalize translate="sentTo" /></h4>
                <p>
                  {/* show link to accepted user account (if accepted user is available) */}
                  <UniConditionalRender visible={!!this.state.individualInviteOpen?.inviteeUserId}>
                    <NavLink to={navConfig.get(ENavPages.adminDetails)!.linkTo([this.state.individualInviteOpen?.inviteeUserId])}>
                      <UniLocalize translate={this.state.individualInviteOpen?.inviteeEmail} />
                    </NavLink>
                  </UniConditionalRender>

                  <UniConditionalRender visible={!this.state.individualInviteOpen?.inviteeUserId}>
                    {this.state.individualInviteOpen.inviteeEmail}
                  </UniConditionalRender>
                </p>

                <h4><UniLocalize translate="invitedBy" /></h4>
                <p><UniLocalize translate={this.state.individualInviteOpen?.inviterEmail} /></p>
                
                <h4><UniLocalize translate="expireDate" /></h4>
                <p><UniLocalize translate={dateAndTimeOrLoading(this.state.individualInviteOpen?.expiration)} /></p>
                
                <h4><UniLocalize translate="status" /></h4>
                <p><UniLocalize translate={EInviteStatusCS[this.state.individualInviteOpen?.status]} /></p>
                
                <h4><UniLocalize translate="created" /></h4>
                <p><UniLocalize translate={this.state.individualInviteOpen?.created} /></p>

                <UniOverlapGroup>
                  <UniOverlapButton
                    handleClick={this._resendFromDetails}
                    textKey="resendAdminInvite"
                    icon="send"
                    showLoader={this.state.saving}
                    disabled={this.state.individualInviteOpen?.status === EInviteStatusCS.accepted}
                    tooltipPosition="right" />
                  <UniOverlapButton
                    handleClick={this._clearOpenedInvite}
                    textKey="close"
                    icon="close"
                    secondary={true} />
                </UniOverlapGroup>

            </UniWorkflow>

          </section>
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(AdminInviteList)
    ), { componentName: 'AdminInviteList' })
