import { FirmwareCS } from '@unikey/unikey-commons/release/csupp'
import {
  supportApi,
  firmwareActions,
  initialPageSize,
  inputInitializer,
} from '../../internal'

const initialProductFirmwareState = {
  loading: true,
  firmwareData: [],
  hideUnavailable: true,
  editorOpen: true
};
export const firmwareList = (state = initialProductFirmwareState, action: any) => {
  switch (action.type) {
    case firmwareActions.GET_FIRMWARE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }
    case firmwareActions.GET_FIRMWARE_LIST_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    case firmwareActions.GET_FIRMWARE_LIST_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        firmwareData: action.value
      }
    case firmwareActions.CHANGE_FIRMWARE_LIST_VIEW_AVAILABILITY_PARAM:
      return {
        ...state,
        hideUnavailable: action.hideUnavailable
      }
    case firmwareActions.FLAG_FIRMWARE_GA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case firmwareActions.FLAG_FIRMWARE_GA_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    case firmwareActions.FLAG_FIRMWARE_GA_SUCCESS:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
const setFirmwareFormData = (firmware: FirmwareCS) => {
  return {
    id: firmware.id || '',
    createdDate: firmware.created || '',
    version: {
      value: firmware.version,
      valid: true,
    },
    description: {
      value: firmware.description,
      valid: true,
    },
    releaseNotes: {
      value: firmware.releaseNotes,
      valid: true,
    },
    status: {
      value: firmware.status,
      valid: true
    },
    imageTemplates: firmware.imageTemplates || [],
    images: firmware.images || [],
  };
};

const initialFirmwareEditorState = {
  loading: false,
  editing: false,
  workflowStepIndex: 0,
  formData: ({
    id: '',
    createdDate: '',
    ...inputInitializer('', ['version', 'description', 'releaseNotes', 'status']),
    imageTemplates: [],
    images: [],
  } as any)
};
// used for update and create
export const firmwareEditor = (state = initialFirmwareEditorState, action: any) => {
  switch (action.type) {
    case firmwareActions.TOGGLE_FIRMWARE_CREATE_MODAL:
      return {
        ...state,
        ...initialFirmwareEditorState,
        editing: !state.editing,
      }
    case firmwareActions.CREATE_FIRMWARE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case firmwareActions.CREATE_FIRMWARE_SUCCESS:
      return {
        ...state,
        loading: action.pending,
      }
    case firmwareActions.CREATE_FIRMWARE_FAILURE:
      return {
        ...state,
        loading: action.pending,
      }
    case firmwareActions.CLEAR_FIRMWARE_FORM_DATA:
      return {
        ...state,
        ...initialFirmwareEditorState
      }
    case firmwareActions.HANDLE_FIRMWARE_FORM_DATA_CHANGE:
      return {
        ...state,
        formData: {
          id: state.formData.id,
          createdDate: state.formData.createdDate,
          version: action.version ? action.version : state.formData.version,
          status: action.status ? action.status : state.formData.status,
          description: action.description ? action.description : state.formData.description,
          releaseNotes: action.releaseNotes ? action.releaseNotes : state.formData.releaseNotes,
          imageTemplates: action.imageTemplates ? action.imageTemplates : state.formData.imageTemplates,
          images: action.images ? action.images : state.formData.images,
        }
      }
    case firmwareActions.UPDATE_NEW_FIRMWARE_WORKFLOW_STEP:
      return {
        ...state,
        workflowStepIndex: action.stepTo
      }
    case firmwareActions.SET_CURRENT_FIRMWARE:
      return {
        ...state,
        formData: setFirmwareFormData(action.firmware)
      }
    default:
      return state
  }
}

// editor used for form data state
const initialFirmwareDetailsState = {
  saving: false,
  loading: false
};
export const firmwareDetails = (state = initialFirmwareDetailsState, action: any) => {
  switch (action.type) {
    case firmwareActions.GET_SINGLE_FIRMWARE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case firmwareActions.GET_SINGLE_FIRMWARE_FAILURE:
      return {
        ...state,
        loading: action.loading,
      }
    case firmwareActions.GET_SINGLE_FIRMWARE_SUCCESS:
      return {
        ...state,
        loading: action.loading,
      }
    case firmwareActions.UPDATE_FIRMWARE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case firmwareActions.UPDATE_FIRMWARE_SUCCESS:
      return {
        ...state,
        saving: action.pending,
      }
    case firmwareActions.UPDATE_FIRMWARE_FAILURE:
      return {
        ...state,
        saving: action.pending,
      }
    default:
      return state
  }
}

const initialfirmwareReadersState = {
  loading: true,
  readersData: {
    models: []
  },
  activeColumn: '',
  queryParams: {
    limit: initialPageSize,
    sorts: [],
    filters: []
  },
  tableFilters: [],
  tableSorts: [{
    column: 'name',
    direction: 'desc'
  }],
  paginationSummary: {
    pageSize: initialPageSize,
    currPage: 1 // default page
  }
};

export const firmwareReaders = (state = initialfirmwareReadersState, action: any) => {
  switch (action.type) {
    case firmwareActions.GET_FIRMWARE_READERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case firmwareActions.GET_FIRMWARE_READERS_SUCCESS:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(firmwareActions.GET_FIRMWARE_READERS_REQUEST) > 0,
        readersData: action.readersResult
      }
    case firmwareActions.GET_FIRMWARE_READERS_FAILURE:
      return {
        ...state,
        loading: supportApi.pendingReqs.getNum(firmwareActions.GET_FIRMWARE_READERS_REQUEST) > 0,
        readersData: initialfirmwareReadersState.readersData
      }
    case firmwareActions.UPDATE_FIRMWARE_READERS_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case firmwareActions.UPDATE_FIRMWARE_READERS_TABLE_META:
      const stateToUpdate: any = {};

      if (action.filters) {
        stateToUpdate.tableFilters = action.filters;
      }

      if (action.sorts) {
        stateToUpdate.tableSorts = action.sorts;
      }
      if (action.currPage || action.pageSize || action.totalCount || action.pageCount) {
        const paginationToUpdate: any = {};
        if (Number.isInteger(Number(action.currPage))) {
          paginationToUpdate.currPage = action.currPage;
        }
        if (Number.isInteger(Number(action.pageSize))) {
          paginationToUpdate.pageSize = action.pageSize;
        }
        if (Number.isInteger(Number(action.totalCount))) {
          paginationToUpdate.totalCount = action.totalCount;
          paginationToUpdate.pageCount = Math.ceil(action.totalCount / (action.pageSize || state.paginationSummary.pageSize));
        }
        stateToUpdate.paginationSummary = Object.assign({}, state.paginationSummary, paginationToUpdate);
      }
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}
