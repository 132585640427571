import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  IUniTable_Column,
  UniLocalize,
  UniConditionalRender,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  IUniTable_Filter,
  IPaginatedResponseCS,
  IPaginatedParamsCS,
  ICredentialCS,
  ECredentialStatus,
  ECredentialType,
  CredentialCS,
  IPartnerCredImpls,
  UniTag

} from '@unikey/unikey-commons/release/csupp';

import {
  ENavPages, navConfig,
  attemptRetrieveCredentials,
  toggleModal,
  updateCredentialListQueryParams,
  updateCredentialTableMeta,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection, getTableParamsFromUpdate,
  PartnerCustomizations, IPartnerCustomizations,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,

  partnerCredImpls: IPartnerCredImpls,
  credData: IPaginatedResponseCS<ICredentialCS, CredentialCS>,
  credListQueryParams: IPaginatedParamsCS,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  listLoading: boolean,
  paginationSummary: IUniTable_PaginationSummary,

  getCreds(): Promise<void>,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginatedParamsCS): void,
}

class CredentialListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this._updateTable = buildTableUpdateFunc(
      props.getCreds,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getCreds();
  }

  // // TODO: move to table helpers
  // _credentialStatusTemplate(rowItem: CredentialCS) {
  //   return (
  //     <div className="credential-status">
  //       <UniLocalize translate={rowItem.getStatusNameKey()} />
  //     </div>
  //   )
  // }

  // TODO: move to table helpers
  _credentialOrganizationNameTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-organization">
        {rowItem.getOrganizationName()}
      </div>
    )
  }
  
  // TODO: move to table helpers
  _credentialTypeTemplate(rowItem: CredentialCS) {
    return (
      <div className="credential-type">
        <UniTag textKey={rowItem.getTypeNameKey()} inverted />
      </div>
    )
  }

  _buildColumnsAndActions = () => {
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: true,
        isPrimaryFilter: true,
        isFilterable: true,
        template: (rowItem: CredentialCS) => rowItem.email ? rowItem.email : (<i><UniLocalize translate="notProvided" /></i>),
        size: 6
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        isFilterable: true,
        type: 'enum-tagdot',
        enumType: ECredentialStatus,
        size: 3
      })
      .set('organization', {
        nameKey: 'organization',
        type: 'string',
        size: 4
      })
      .set('type', {
        nameKey: 'type',
        isFilterable: true,
        // template: this._credentialTypeTemplate,
        type: 'enum-taginverted',
        enumType: ECredentialType,
        size: 4,
        // called type on the credential model, but db has it as credential_type. This is why it must be provided here.
        filterName: 'credential_type'
      })
      .set('created', {
        nameKey: 'created',
        isSortable: true,
        type: 'date',
        size: 4
      })
      .set('actions', {
        nameKey: 'actions',
        size: 2
      })
      .set('cardNumber', {
        nameKey: 'cardNumber',
        isFilterable: true,
        size: 0
      })
      .set('label', {
        nameKey: 'label',
        isFilterable: true,
        size: 0
      })
      .set('id', {
        nameKey: 'id',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })
      .set('organizationId', {
        nameKey: 'organizationId',
        isFilterable: true,
        type: 'uuid',
        size: 0
      })

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: CredentialCS) => { this.props.history.push(navConfig.get(ENavPages.credentialDetails)!.linkTo([rowItem.id])); },
      evalDisabled: (rowItem: CredentialCS) => false,
      evalVisible: (rowItem: CredentialCS) => true
    })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='credentialList-container'>
        <UniTable
          searchable={true}
          advancedFiltering={true}
          titleKey="credentialsList"
          createButtonTextKey="credential"
          handleUpdate={this._updateTable}
          data={this.props.credData.models}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          activeSorts={this.props.appliedSorts}
          actionsConfig={actions}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    credData: state.credentials.data,
    credListQueryParams: state.credentials.queryParams,
    listLoading: state.credentials.loading,
    appliedFilters: state.credentials.tableFilters,
    appliedSorts: state.credentials.tableSorts,
    paginationSummary: state.credentials.paginationSummary
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => bindActionCreators({
  getCreds: attemptRetrieveCredentials.bind(null, ownProps.partnerCredImpls),
  updateQueryParams: updateCredentialListQueryParams,
  updateTableMeta: updateCredentialTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CredentialListContainer)
    ), { componentName: 'CredentialList' })
