import React, { Component } from 'react'
import { IContainerCustomization } from '../PartnerCustomizations'
import {
  UniKeyV1CredentialC,
  RawCredentialC,
  WiegandCredentialC,
  Corp1000CredentialC,
  TSBnFCredentialC,
  TSBwFCredentialC,
  ECredentialType,
  IPartnerCredImpls
} from '@unikey/unikey-commons/release/csupp'

import { PdkUniKeyV1Credential } from './models/pdkU1Credential'

export default () => {

  const partnerCredImpls: IPartnerCredImpls = {
    PartnerRCred: RawCredentialC,
    PartnerWCred: WiegandCredentialC,
    PartnerU1Cred: PdkUniKeyV1Credential,
    PartnerC1000Cred: Corp1000CredentialC,
    Partner37BwFCred: TSBwFCredentialC,
    Partner37BnFCred: TSBnFCredentialC,
  };

  const CredentialDetails: IContainerCustomization = {
    props: {
      partnerCredImpls
    }
  }

  const CredentialList: IContainerCustomization = {
    props: {
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.unikeyV1
    }
  }

  return {
    CredentialDetails,
    CredentialList
  }
}
