import {
  IUniToast_Alert,
  EMessageType
} from '@unikey/unikey-commons/release/csupp';

interface ICopyOptions {
  fromElement?: string,
  fromValue?: string
}

export const copyToClipboard = (desired: ICopyOptions, showAlertNotice?: (aletConfig: IUniToast_Alert) => void): void => {

  if (desired.fromElement) {
    const range = document.createRange();
    range.selectNode(document.querySelector(desired.fromElement)!);
    window.getSelection()?.removeAllRanges(); // clear current selection
    window.getSelection()?.addRange(range); // to select text
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();// to deselect
  } else if (desired.fromValue) {
    navigator.clipboard.writeText(desired.fromValue);
  }

  if (showAlertNotice) {
    // show an alert with the coppied text
    navigator.clipboard.readText().then(clippedText => {
      showAlertNotice!({
        id: Date.now(),
        titleKey: 'coppiedToClipboard',
        type: EMessageType.success,
        messageKeys: [clippedText],
        duration: 2000
      });
    });
  }
  return;
}