import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniOverlapGroup,
  UniOverlapButton,
  UniWorkflow,
  UniInput,
  Editable,
  DeviceCS,
  UniLocalize,
  UniToggle,
  numV10n,
  noop,
  lengthV10n,
} from '@unikey/unikey-commons/release/csupp';

import {
  togglePushNotificationForm,
  updatePushNotificationMessage,
  PartnerCustomizations, IPartnerCustomizations, ISendPushRequestActionParams, attemptSendDevicePushNotification,
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  message: Editable<string>,
  sending: boolean,
  device: DeviceCS,
  updateMessage(message: Editable<string>): void,
  toggleModal(): void,
  sendMessage(params: ISendPushRequestActionParams): Promise<void>
}

class DeviceMessageFormContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  _toggleAndResetForm = () => {
    this.props.updateMessage(new Editable({ value: '' }))
    this.props.toggleModal();
  }

  _sendMessage = () => {
    this.props.sendMessage({
      deviceId: this.props.device.id,
      message: this.props.message.value
    })
  }
  
  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <UniWorkflow
        inModal
        titleKey="pushNotification"
        titleIcon="message" 
        handleClose={this._toggleAndResetForm}>

        <p><strong><UniLocalize translate="sendMessageToDevice" /></strong></p>
        
        <UniToggle
          value={!!this.props.message.value}
          options={[
            { value: false, nameKey: 'silentPush', disabled: true },
            { value: true, nameKey: 'alertPush', disabled: true }
          ]}
          theme="info"
          size="md"
          handleUpdate={noop} />

        <UniInput
          textarea={true}
          labelKey="message"
          focusOnInitialRender={true}
          editable={this.props.message}
          placeholderKey="yourMessageHere"
          validations={new Map([lengthV10n(0, 30, 'messageMustBe30CharactersOrLess')])}
          handleUpdate={(msg: Editable<string>) => { this.props.updateMessage(msg); }} />
        <br />

       

        {/* step actions */}
        <UniOverlapGroup>

          <UniOverlapButton
            handleClick={this._sendMessage}
            textKey="save"
            icon="check"
            showLoader={this.props.sending}
            disabled={!this.props.message.valid}
            tooltipPosition="right" />

          <UniOverlapButton
            handleClick={() => { this._toggleAndResetForm(); }}
            textKey="cancel"
            icon="close"
            secondary={true}
            tooltipPosition="right" />

        </UniOverlapGroup>
        
      </UniWorkflow>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    device: state.deviceDetails.deviceData,
    message: state.deviceNotificationForm.message,
    sending: state.deviceNotificationForm.sending,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateMessage: updatePushNotificationMessage, 
  toggleModal: togglePushNotificationForm,
  sendMessage: attemptSendDevicePushNotification
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DeviceMessageFormContainer)
    ), { componentName: 'DeviceMessageForm' })
